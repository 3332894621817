import { useSelector } from 'react-redux'
import { t } from 'i18next'
import { Box, Typography, LinearProgress } from '@mui/material'

import { RootState } from '@/state'
import { ReactComponent as IconChest } from '@/assets/icons/icon-chest.svg'
import { useLevelStyles } from './style'

type Props = {
  Div: Element
  sidebarClient: string
}

export function SidebarProgressBarComponent({ Div, sidebarClient }: Props) {
  const progressType = Div.getAttribute('data-bar-progress-type') || 'level'

  const { gamificationPlayer, gamificationOpt } = useSelector(
    ({ player }: RootState) => player,
  )

  const { classes } = useLevelStyles({ sidebarClient })

  const linearBars = Array.from(new Array(6), (_, index) => (
    <Box className="linearBar" key={`border_${index}`} />
  ))

  return (
    <Box className={classes.level}>
      <Typography variant="body2">
        {progressType === 'level' ? t('level') : t('rank')}
      </Typography>
      <Box className={classes.progressWrapper}>
        <Box className="linearBorder" />
        <Box className="linearBars">{linearBars}</Box>
        <Box className="linearNumber">
          <Typography variant="caption">
            {gamificationPlayer.level.levelNumber}
          </Typography>
        </Box>
        <LinearProgress
          variant="determinate"
          value={
            gamificationOpt?.enable
              ? progressType === 'level'
                ? gamificationPlayer.level.progress
                : gamificationPlayer.rank.progress
              : 0
          }
        />
        {!!gamificationPlayer?.pointsUntilNextLevel &&
          !!gamificationPlayer?.pointsUntilNextRank && (
            <>
              <Typography variant="caption" className="linearBalance">{`${
                gamificationPlayer.xpBalance
              } / ${
                gamificationPlayer.xpBalance +
                (progressType === 'level'
                  ? gamificationPlayer.pointsUntilNextLevel
                  : gamificationPlayer.pointsUntilNextRank)
              }`}</Typography>
              <Box className="linearChest">
                <IconChest />
              </Box>
            </>
          )}
      </Box>
    </Box>
  )
}
