import { t } from 'i18next'
import { RewardTypeEnum } from '@/enums'
import { MissionBundlesModels } from '@/state'

import { parsePeriodTime } from './period-time-format'

export const rewardType = (type: RewardTypeEnum) => {
  switch (type) {
    case RewardTypeEnum.LEVEL_BOOSTER:
      return t('levelBooster')

    case RewardTypeEnum.TOKEN_BOOSTER:
      return t('tokenBooster')

    case RewardTypeEnum.MISSION_BOOSTER:
      return t('missionBooster')

    case RewardTypeEnum.TOKENS:
      return t('tokens')

    case RewardTypeEnum.BONUS_OFFER:
      return t('bonusOffer')

    default:
      return t('levelBooster')
  }
}

export const rewardText = (
  reward: MissionBundlesModels.RewardCategoryType,
  playerLocale: string,
) => {
  const translation =
    reward.translations?.find(
      (item) => item.language.toUpperCase() === playerLocale.toUpperCase(),
    ) || reward.translations?.[0]

  let rewardText

  if (
    reward.rewardType !== RewardTypeEnum.TOKENS &&
    reward.rewardType !== RewardTypeEnum.BONUS_OFFER
  ) {
    rewardText =
      translation?.name ||
      `${rewardType(reward.rewardType)} (${
        reward.boosterRate
      }x - ${parsePeriodTime(reward.timeFrame)})`
  } else if (reward.rewardType === RewardTypeEnum.TOKENS) {
    rewardText =
      translation?.name || `${reward.tokens} ${rewardType(reward.rewardType)}`
  } else {
    rewardText =
      translation?.name ||
      `${rewardType(reward.rewardType)} ID: ${reward.bonusId}`
  }

  return rewardText
}
