import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { OnSiteActions, OnSiteModels, RootState, useAppDispatch } from '@/state'

import { useGlobalUi } from '@/hooks'
import { SocketEventsEnum, TrackingTypeEnum, UIDialogsEnum } from '@/enums'
import { getSocketInstance } from '@/utils'

export const useOnsiteNotification = () => {
  const dispatch = useAppDispatch()

  const { onsiteNotifications } = useSelector(({ onSite }: RootState) => onSite)

  const { dialogOnSite, handleOpenOnSiteDialog, handleCloseOnSiteDialog } =
    useGlobalUi()

  const currentNotification: OnSiteModels.SocketOnSiteNotificationResponse =
    onsiteNotifications?.length > 0 && onsiteNotifications[0]

  const notificationMessage = currentNotification?.payload?.msg

  const handleCloseOnsite = () => {
    dispatch(
      OnSiteActions.removeOnSiteNotification(currentNotification?.messageId),
    )

    handleCloseOnSiteDialog()
  }

  useEffect(() => {
    if (currentNotification && !dialogOnSite.open) {
      const socket = getSocketInstance()

      socket.emit(SocketEventsEnum.ONSITE_SEND_EVENT, {
        trackingType: TrackingTypeEnum.OPENED,
        message: currentNotification,
      })

      socket.emit(SocketEventsEnum.ACK, {
        messageId: currentNotification.messageId,
      })

      handleOpenOnSiteDialog({
        id: UIDialogsEnum.ON_SITE_NOTIFICATION,
      })
    }
  }, [onsiteNotifications]) // eslint-disable-line

  return {
    dialogOnSite,
    currentNotification,
    notificationMessage,
    handleCloseOnsite,
  }
}
