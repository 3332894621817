import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import { OrderModels, OrderThunks } from '@/state'

export const getAllOrdersExtraReducer = (
  builder: ActionReducerMapBuilder<OrderModels.OrderState>,
) => {
  builder.addCase(OrderThunks.getAllOrdersThunk.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(
    OrderThunks.getAllOrdersThunk.fulfilled,
    (state, { payload }) => {
      const { docs, totalDocs, totalPages, limit, page } = payload.response
      state.orders = docs || []
      state.pagination = {
        total: totalDocs || 0,
        limit: limit || 12,
        page: page || 1,
        pages: totalPages || 0,
      }
      state.isLoading = false
    },
  )

  builder.addCase(OrderThunks.getAllOrdersThunk.rejected, (state) => {
    state.isLoading = false
  })
}
