import { useEffect } from 'react'
import { SocketEventsEnum, TrackingTypeEnum } from '@/enums'
import { OnSiteModels } from '@/state'

import { getSocketInstance } from '@/utils'
import { useOnsiteNotification } from '../hooks'

type Props = {
  content: React.ReactNode
  notificationObject: OnSiteModels.SocketOnSiteNotificationResponse
}

export const OnSiteHTMLContentComponent = ({
  content,
  notificationObject,
}: Props) => {
  const { handleCloseOnsite } = useOnsiteNotification()

  useEffect(() => {
    const handleClick = () => {
      getSocketInstance().emit(SocketEventsEnum.ONSITE_SEND_EVENT, {
        trackingType: TrackingTypeEnum.CLICKED,
        message: notificationObject,
      })
    }

    const button = document.querySelector('.onsite-notification__ctaButton')

    if (button) {
      button.addEventListener('click', handleClick)
      button.addEventListener('click', handleCloseOnsite)
    }

    return () => {
      if (button) {
        button.removeEventListener('click', handleClick)
        button.removeEventListener('click', handleCloseOnsite)
      }
    }
  }, []) // eslint-disable-line

  return <>{content}</>
}
