import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '@/state'
import { OrdersActions, OrderThunks } from '@/state/order'

import { LIMIT } from '@/constants'

export const useOrderActions = () => {
  const dispatch = useAppDispatch()

  const { pagination } = useSelector(({ orders }: RootState) => orders)

  const { playerId } = useSelector(({ initConfig }: RootState) => initConfig)

  const filters = {
    playerId: playerId,
    limit: LIMIT,
  }

  const handlePrevPage = () => {
    const prevPage = pagination.page - 1
    dispatch(OrdersActions.setPage(prevPage))
    dispatch(
      OrderThunks.getAllOrdersThunk({
        ...filters,
        limit: pagination.limit,
        page: prevPage,
      }),
    )
  }

  const handleNextPage = () => {
    const nextPage = pagination.page + 1
    dispatch(OrdersActions.setPage(nextPage))
    dispatch(
      OrderThunks.getAllOrdersThunk({
        ...filters,
        limit: pagination.limit,
        page: nextPage,
      }),
    )
  }

  return { handlePrevPage, handleNextPage }
}
