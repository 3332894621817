import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import { RewardShopModels, RewardShopThunks } from '@/state'
import { RewardShopTypeEnum } from '@/enums'

export const getAllProductsExtraReducer = (
  builder: ActionReducerMapBuilder<RewardShopModels.RewardShopState>,
) => {
  builder.addCase(RewardShopThunks.getAllProductsThunk.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(
    RewardShopThunks.getAllProductsThunk.fulfilled,
    (state, { payload }) => {
      const { docs, totalDocs, totalPages, limit, page } = payload.response

      if (docs) {
        if (payload.rewardType === RewardShopTypeEnum.EXTERNAL) {
          state.externalProducts = docs || []
        }

        if (payload.rewardType === RewardShopTypeEnum.BOOSTER) {
          state.boosterProducts = docs || []
        }

        if (payload.rewardType === RewardShopTypeEnum.BONUS) {
          state.bonusProducts = docs || []
        }

        if (payload.rewardType === RewardShopTypeEnum.XP) {
          state.xpProducts = docs || []
        }

        state.pagination = {
          total: totalDocs || 0,
          limit: limit || 8,
          page: page || 1,
          pages: totalPages || 0,
        }
      }

      state.isLoading = false
      state.isError =
        payload.response.statusCode &&
        (payload.response.statusCode === 400 ||
          payload.response.statusCode === 404 ||
          payload.response.statusCode === 500)
          ? true
          : state.isError
    },
  )

  builder.addCase(RewardShopThunks.getAllProductsThunk.rejected, (state) => {
    state.isLoading = false
  })
}
