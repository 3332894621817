import { Box, CircularProgress } from '@mui/material'
import { BoosterColorsType } from '@/state/my-booster/models'
import { useStyles } from './style'

type Props = {
  percentageProgress: number
  image: JSX.Element
  boosterType: string
  boosterRate: number
  boosterColors: BoosterColorsType
}

export function CircularProgressComponent({
  percentageProgress,
  image,
  boosterType,
  boosterRate,
  boosterColors,
}: Props) {
  const { classes } = useStyles({ boosterType, boosterColors })

  return (
    <>
      <CircularProgress
        variant="determinate"
        value={100}
        size={40}
        thickness={4}
        className="completed"
        sx={{ position: 'absolute' }}
      />
      <CircularProgress
        variant="determinate"
        value={percentageProgress}
        size={40}
        thickness={4}
        className="circular"
      />

      <Box className={classes.rateContainer}>
        {image}
        <Box className="rate">{`${boosterRate}x`}</Box>
      </Box>
    </>
  )
}
