import { Divider, alpha } from '@mui/material'
import { IconColorByStatus } from '@/constants'
import { MissionHistoryModels } from '@/state'

import { AccordionCustomComponent } from '@/components/common'
import {
  useMissionBundleItem,
  MissionBundleDetailsComponent,
  MissionBundleTitleComponent,
} from '@/components/mission-history'

type Props = {
  missionBundle: MissionHistoryModels.MissionHistoryBundleType
}

export const MissionHistoryBundleComponent = ({ missionBundle }: Props) => {
  const {
    currentHistoryBundles,
    getTimeFrameFormat,
    handleOneMissionBundle,
    handleUpdateBundleStatus,
    handleClaimEasterEgg,
  } = useMissionBundleItem(missionBundle)

  return (
    <AccordionCustomComponent
      title={
        <MissionBundleTitleComponent
          missionBundle={missionBundle}
          color={IconColorByStatus[missionBundle.status].color}
          icon={IconColorByStatus[missionBundle.status].icon}
          timeFrameFormat={getTimeFrameFormat()}
          handleUpdateBundleStatus={handleUpdateBundleStatus}
        />
      }
      sx={{
        borderLeft: `4px solid ${alpha(
          IconColorByStatus[missionBundle.status].color,
          0.8,
        )}`,
        flexDirection: 'row',
      }}
      handleOnExpanded={handleOneMissionBundle}
    >
      <Divider sx={{ marginTop: -1, marginBottom: 2 }} />
      <MissionBundleDetailsComponent
        currentMissionBundle={currentHistoryBundles?.find(
          (mission) => mission?._id === missionBundle?._id,
        )}
        missionStatus={missionBundle.status}
        handleClaimEasterEgg={handleClaimEasterEgg}
      />
    </AccordionCustomComponent>
  )
}
