import { fontFallbacks } from '@/assets/fonts'
import { AssetsModels } from '@/state'

export const insertMikadoFont = (assetsRoute) => {
  if (!document.getElementById('MikadoFont') && assetsRoute) {
    fontFallbacks(assetsRoute as string).forEach(
      (font: AssetsModels.FontFamilyType) => {
        const newStyle = document.createElement('style')
        newStyle.setAttribute('id', 'MikadoFont')
        newStyle.appendChild(
          document.createTextNode(`
      @font-face {
        font-family: ${font.fontFamily};
        font-style: ${font.fontStyle};
        font-weight: ${font.fontWeight};
        src: ${font.src}
      }
    `),
        )
        document.head.appendChild(newStyle)
      },
    )
  }
}
