import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { LottieRefCurrentProps } from 'lottie-react'

import gsap from 'gsap'
import { PlayerActions, RootState, useAppDispatch } from '@/state'
import { getFormatNumber } from '@/utils'

type Props = {
  bigWin: boolean
  showAnimation: boolean
  setShowAnimation: (showAnimation: boolean) => void
}

export const useTokensLottieAnimations = ({
  setShowAnimation,
  bigWin,
  showAnimation,
}: Props) => {
  const { playerLocale, client } = useSelector(
    ({ initConfig }: RootState) => initConfig,
  )

  const dispatch = useAppDispatch()

  const { gamificationPlayer } = useSelector(({ player }: RootState) => player)

  const { oldTokens, tokens } = gamificationPlayer

  const smallLottieRef = useRef<LottieRefCurrentProps>()
  const bigLottieRef = useRef<LottieRefCurrentProps>()

  // Global Refs

  const numberRef = useRef()
  const boxRef = useRef()

  // Small Win Refs

  const circleRef = useRef()

  const tlBox = gsap.timeline()
  const tlCircle = gsap.timeline()

  // Big Win Refs
  const opacityCircleRef = useRef()
  const bigCircleOneRef = useRef()
  const bigCircleTwoRef = useRef()
  const bigBoxRef = useRef()

  const tlOpacityCircleRef = gsap.timeline()
  const tlBigCircleOneRef = gsap.timeline()
  const tlBigCircleTwoRef = gsap.timeline()
  const tlBigBox = gsap.timeline()

  const triggerGlobalAnimations = () => {
    tlBox.fromTo(
      boxRef.current,
      {
        opacity: 0,
        scale: 0.1,
      },
      {
        delay: bigWin ? 1.8 : 0.5,
        duration: 0.5,
        opacity: 1,
        transformOrigin: 'center center',
        scale: bigWin ? 1.5 : 1,
      },
    )

    tlBox.to(boxRef.current, {
      transformOrigin: 'center center',
      duration: 0.8,
      opacity: 0,
      scale: bigWin ? 1 : 1.1,
    })

    gsap.fromTo(
      numberRef.current,
      {
        textContent: oldTokens,
        roundProps: 'textContent',
      },
      {
        delay: 0.8,
        duration: 0.5,
        textContent: tokens,
        roundProps: 'textContent',
        stagger: {
          each: 1.0,
          onUpdate: function () {
            this.targets()[0].innerHTML = getFormatNumber(
              this.targets()[0].textContent,
              playerLocale,
            )
          },
        },
      },
    )
  }

  const triggerSmallWinAnimations = () => {
    tlCircle.fromTo(
      circleRef.current,
      {
        opacity: 1,
        scale: 0,
      },
      {
        delay: 0.5,
        duration: 0.4,
        opacity: 0.6,
        transformOrigin: 'center center',
        scale: 3.5,
      },
    )

    tlCircle.to(circleRef.current, {
      duration: 0.5,
      scale: 4,
      opacity: 0,
    })
  }

  const triggerBigWinAnimations = () => {
    tlOpacityCircleRef.fromTo(
      opacityCircleRef.current,
      {
        opacity: 1,
        scale: 0,
        top: 6,
      },
      {
        delay: 0.5,
        duration: 0.7,
        opacity: 0.1,
        transformOrigin: 'top center',
        scale: 9,
        top: '-35px',
      },
    )

    tlOpacityCircleRef.to(opacityCircleRef.current, {
      duration: 0,
      scale: 1,
      opacity: 0,
    })

    tlBigCircleOneRef.fromTo(
      bigCircleOneRef.current,
      {
        opacity: 1,
        scale: 0,
        top: 6,
      },
      {
        delay: 0.65,
        duration: 0.8,
        transformOrigin: 'top center',
        scale: 9,
        top: '-35px',
      },
    )

    tlBigCircleOneRef.to(bigCircleOneRef.current, {
      duration: 0.9,
      scale: 0,
      opacity: 1,
      top: 6,
    })

    tlBigCircleTwoRef.fromTo(
      bigCircleTwoRef.current,
      {
        opacity: 1,
        scale: 0,
        top: 6,
      },
      {
        delay: 0.85,
        duration: 0.8,
        transformOrigin: 'top center',
        scale: 9,
        top: '-35px',
      },
    )

    tlBigCircleTwoRef.to(bigCircleTwoRef.current, {
      duration: 0.9,
      scale: 0,
      opacity: 1,
      top: 6,
    })

    tlBigBox.fromTo(
      bigBoxRef.current,
      {
        opacity: 0,
        scale: 0.1,
      },
      {
        delay: 1.85,
        duration: 0.8,
        opacity: 0.3,
        transformOrigin: 'center center',
        scale: 1.7,
      },
    )

    tlBigBox.to(bigBoxRef.current, {
      transformOrigin: 'center center',
      duration: 0.8,
      opacity: 0,
    })
  }

  useEffect(() => {
    if (bigLottieRef.current && bigWin && showAnimation) {
      triggerCurrentAnimation(bigLottieRef.current, 2500)
    }

    if (smallLottieRef.current && !bigWin && showAnimation) {
      triggerCurrentAnimation(smallLottieRef.current, 1500)
    }
  }, [smallLottieRef, bigLottieRef, showAnimation]) // eslint-disable-line

  const triggerCurrentAnimation = (
    currentAnimation: LottieRefCurrentProps,
    timeOut: number,
  ) => {
    currentAnimation.play()
    triggerGlobalAnimations()
    if (bigWin) {
      triggerBigWinAnimations()
    } else {
      triggerSmallWinAnimations()
    }
    setTimeout(() => {
      currentAnimation.stop()
      setShowAnimation(false)
      dispatch(PlayerActions.updateWalletTokens())
    }, timeOut)
  }

  return {
    bigBoxRef,
    bigCircleOneRef,
    bigCircleTwoRef,
    bigLottieRef,
    boxRef,
    circleRef,
    client,
    numberRef,
    opacityCircleRef,
    playerLocale,
    smallLottieRef,
    tokens,
    oldTokens,
  }
}
