import { useEffect, useRef, useState } from 'react'
import { MissionHistoryModels, MissionModels } from '@/state'

export const useSliderMission = (
  listMissions:
    | MissionHistoryModels.MissionHistoryBundleDetailsType[]
    | MissionModels.MissionType[], // TODO: This type will change in the future
) => {
  const [currentBullet, setCurrentBullet] = useState(0)
  const [numberBullets, setNumberBullets] = useState(0)
  const [showArrowRight, setShowArrowRight] = useState(false)
  const [showArrowLeft, setShowArrowLeft] = useState(false)

  const sliderRef = useRef(null)

  const handleScrollBullet = (bulletId: number, left: boolean = false) => {
    if (bulletId === 0) {
      return (sliderRef.current.scrollLeft = 0)
    }
    if (bulletId === numberBullets - 1) {
      return (sliderRef.current.scrollLeft = sliderRef.current.scrollWidth)
    }
    if (left) {
      const difference = currentBullet - bulletId
      return (sliderRef.current.scrollLeft -=
        sliderRef.current.clientWidth * difference)
    }
    const difference = bulletId - currentBullet
    sliderRef.current.scrollLeft += sliderRef.current.clientWidth * difference
  }

  const handleScrollArrows = (left: boolean = false) => {
    left
      ? (sliderRef.current.scrollLeft -= sliderRef.current.clientWidth)
      : (sliderRef.current.scrollLeft += sliderRef.current.clientWidth)
  }

  const handleEventScroll = () => {
    setShowArrowLeft(sliderRef.current.scrollLeft > 0)
    setShowArrowRight(
      sliderRef.current.scrollLeft + sliderRef.current.clientWidth <
        sliderRef.current.scrollWidth,
    )

    if (sliderRef.current.scrollLeft > sliderRef.current.clientWidth) {
      const bullet = Math.round(
        sliderRef.current.scrollLeft / sliderRef.current.clientWidth,
      )
      setCurrentBullet(bullet)
    } else {
      setCurrentBullet(0)
    }
  }

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.addEventListener('scroll', handleEventScroll)
    }
  }, [sliderRef]) // eslint-disable-line

  useEffect(() => {
    setShowArrowRight(listMissions.length > 3)
    setNumberBullets(
      listMissions.length > 3 ? Math.ceil(listMissions.length / 3) : 1,
    )
  }, [listMissions])

  return {
    sliderRef,
    showArrowLeft,
    showArrowRight,
    numberBullets,
    currentBullet,
    handleScrollArrows,
    handleScrollBullet,
  }
}
