import { makeStyles } from 'tss-react/mui'

export const useAnimations = makeStyles()(() => {
  return {
    animations: {
      '& .opacityAnimation': {
        animation: 'opacityAnimation 1.5s infinite',

        '@keyframes opacityAnimation': {
          '0%': {
            opacity: 1,
          },
          '50%': {
            opacity: 0.5,
          },
          '100%': {
            opacity: 1,
          },
        },
      },

      '& .boostersAnimation': {
        opacity: 0,
        transform: 'translateX(0)',

        '&.show': {
          animation: 'slideIn 1s linear forwards, fadeIn 1s ease forwards',
        },

        '&.hide': {
          animation:
            'slideOut 0.5s linear forwards, fadeOut 0.3s ease forwards',
        },
      },

      '@keyframes fadeIn': {
        '0%': {
          opacity: 0,
        },
        '100%': {
          opacity: 1,
        },
      },

      '@keyframes fadeOut': {
        '0%': {
          opacity: 1,
        },
        '100%': {
          opacity: 0,
        },
      },

      '@keyframes slideIn': {
        '0%': {
          transform: 'translateX(-100%)',
        },
        '20%, 50%, 80%, 100%': {
          transform: 'translateX(0)',
        },
        '40%': {
          transform: 'translateX(-4px)',
        },
      },

      '@keyframes slideOut': {
        '0%': {
          transform: 'translateX(0)',
        },
        '100%': {
          transform: 'translateX(-100%)',
        },
      },
    },
  }
})
