import { useEffect, useRef, useState } from 'react'
import { NotificationTypeEnum } from '@/enums'
import { useGlobalUi } from '@/hooks'

export const useNotificationInitial = () => {
  const { modal } = useGlobalUi()

  const [isVisible, setIsVisible] = useState(false)

  const [isVisibleLevel, setIsVisibleLevel] = useState(false)

  const containerRef = useRef(null)

  useEffect(() => {
    const timerIn = setTimeout(() => {
      setIsVisible(true)
    }, 250)

    const timerLevel = setTimeout(() => {
      if (modal.type === NotificationTypeEnum.LEVEL_UP) {
        setIsVisibleLevel(true)
      }
    }, 1500)

    const timerOut = setTimeout(() => {
      setIsVisible(false)
    }, 3000)

    return () => {
      clearTimeout(timerIn)
      clearTimeout(timerLevel)
      clearTimeout(timerOut)
    }
  }, []) // eslint-disable-line

  return {
    isVisible,
    isVisibleLevel,
    containerRef,
  }
}
