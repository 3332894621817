import { useTranslation } from 'react-i18next'
import { Box, Button, Typography } from '@mui/material'
import { MissionModels } from '@/state'

import { ReactComponent as RightArrow } from '@/assets/icons/missions/icon-slider-right.svg'
import { ReactComponent as LeftArrow } from '@/assets/icons/missions/icon-slider-left.svg'
import { useGlobalUi } from '@/hooks'

import { DialogTypeEnum, UIDialogsEnum } from '@/enums'
import { useSliderMissionStyles } from './style'
import { useSliderMission } from '../hooks'

import { MissionItemComponent } from '../../mission-item'
import { DialogMissionComponent } from '../../dialog-mission'

type Props = {
  listMissions: MissionModels.MissionsByBundleType[]
  title: string
  expanded?: boolean
  handleSeeAll?: () => void
}

export const SliderMissionsComponent = ({
  listMissions,
  title,
  expanded,
  handleSeeAll,
}: Props) => {
  const { t } = useTranslation()

  const { classes } = useSliderMissionStyles({ expanded })

  const { handleOpenDialog } = useGlobalUi()

  const {
    sliderRef,
    showArrowLeft,
    showArrowRight,
    numberBullets,
    currentBullet,
    handleScrollArrows,
    handleScrollBullet,
  } = useSliderMission(listMissions)

  const bullets = Array.from(new Array(numberBullets), (_, index) => (
    <Box
      className={`sliderMissionBullet ${
        index === currentBullet ? 'active' : ''
      }`}
      key={`bullet_${index}`}
      onClick={() => {
        handleScrollBullet(index, index < currentBullet)
      }}
    />
  ))

  return (
    <>
      <Box className={classes.sliderMissionWrapper}>
        <Box className={classes.sliderMissionHeader}>
          <Typography>{t(title)}</Typography>
          {expanded && (
            <Button variant="text" onClick={() => handleSeeAll()}>
              <Typography variant="caption">{t('seeAll')}</Typography>
            </Button>
          )}
        </Box>

        <Box className={classes.sliderMissionItems} ref={sliderRef}>
          {listMissions?.map((mission, index) => {
            return (
              <Box
                key={`slider_${mission._id}`}
                sx={
                  !expanded
                    ? { zIndex: listMissions.length - index }
                    : { zIndex: 1 }
                }
                onClick={() =>
                  handleOpenDialog({
                    id: UIDialogsEnum.MISSION_DETAILS,
                    object: {
                      missionId: mission._id,
                      title: t(
                        `${mission.missionBundleSettings.timeFrame.timeFrameType}`,
                      ),
                      type: DialogTypeEnum.ACTIVE_MISSIONS,
                    },
                  })
                }
              >
                <MissionItemComponent
                  status={mission.status}
                  expanded={expanded}
                  endDate={mission.endDate}
                  state={mission.state}
                />
              </Box>
            )
          })}
        </Box>
        {expanded && (
          <>
            {showArrowLeft && (
              <Button
                className={`${classes.cardArrow} left`}
                onClick={() => handleScrollArrows(true)}
              >
                <LeftArrow />
              </Button>
            )}
            {showArrowRight && (
              <Button
                className={`${classes.cardArrow} right`}
                onClick={() => handleScrollArrows()}
              >
                <RightArrow />
              </Button>
            )}
            <Box className={classes.sliderMissionBullets}>{bullets}</Box>
          </>
        )}
      </Box>
      <DialogMissionComponent />
    </>
  )
}
