import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import { PlayerModels, PlayerThunks } from '@/state'

export const getTokenDetailsExtraReducer = (
  builder: ActionReducerMapBuilder<PlayerModels.PlayerState>,
) => {
  builder.addCase(
    PlayerThunks.getTokenDetailsThunk.fulfilled,
    (state, { payload }) => {
      state.gamificationPlayer.tokens = payload.balance || 0
      state.gamificationPlayer.oldTokens = payload.balance || 0
      state.gamificationPlayer.previousData = {
        ...state.gamificationPlayer.previousData,
        previousTokens: payload.balance || 0,
      }
      state.gamificationPlayer.statusCode =
        payload?.statusCode?.toString() || ''
    },
  )
}
