import { createAsyncThunk } from '@reduxjs/toolkit'
import Container from 'typedi'
import { Rest } from '@/utils/api.utils'

import { MissionHistoryModels, WidgetEndpoints } from '@/state'
import { MissionHistoryActionTypes } from '../constants'

const apiService: Rest = Container.get(Rest)

export const getAllMissionBundlesThunk = createAsyncThunk(
  MissionHistoryActionTypes.GET_ALL_BUNDLES,
  async (
    payload: MissionHistoryModels.GetAllMissionsBundlesPayload,
    thunkAPI,
  ) => {
    try {
      const { date, ...cleanPayload } = payload
      const response: MissionHistoryModels.GetAllMissionBundlesResponse =
        await apiService.sendData({
          method: 'GET',
          endpoint: WidgetEndpoints.GET_ALL_BUNDLES,
          queries: cleanPayload,
        })

      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
