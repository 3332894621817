import { Tab, styled } from '@mui/material'

export const TabButton = styled(Tab)(() => ({
  borderRadius: 16,
  minHeight: 30,
  fontWeight: 700,
  textTransform: 'none',
  color: '#ffffff',
  padding: '2px 16px',
  marginRight: 8,
  '&.Mui-selected': {
    color: '#ffffff',
    backgroundColor: '#ccc',
  },
}))
