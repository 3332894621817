import { makeStyles } from 'tss-react/mui'

interface Props {
  openSidebar?: boolean
}

export const useHomeStyles = makeStyles<Props>()((theme, { openSidebar }) => {
  return {
    homeContent: {
      marginTop: openSidebar ? 48 : 0,
      display: 'flex',
      gap: 1,
      flexDirection: openSidebar ? 'row' : 'column',

      [theme.breakpoints.down('sm')]: {
        marginTop: 20,
      },
    },
  }
})
