import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { MyBoosterThunks, RootState, useAppDispatch } from '@/state'

import { ActiveBoosterType } from '@/state/my-booster/models'

export const useActiveBoosters = () => {
  const dispatch = useAppDispatch()

  const [showAllItems, setShowAllItems] = useState(null)

  const [hideAllItems, setHideAllItems] = useState(false)

  const [orderBoosters, setOrderBoosters] = useState<ActiveBoosterType[]>([])

  const { activeBoosters } = useSelector(
    ({ myBooster }: RootState) => myBooster,
  )

  useEffect(() => {
    if (activeBoosters?.length > 0) {
      setOrderBoosters(
        [...activeBoosters].sort(
          (a, b) =>
            new Date(a.timeEnd).getTime() - new Date(b.timeEnd).getTime(),
        ),
      )
    } else {
      setOrderBoosters([])
    }
  }, [activeBoosters]) // eslint-disable-line

  useEffect(() => {
    dispatch(MyBoosterThunks.getLevelActiveBoostersThunk())
    dispatch(MyBoosterThunks.getTokenActiveBoostersThunk())
  }, []) // eslint-disable-line

  const handleShowAllItems = () => {
    setShowAllItems(!showAllItems)
  }

  const handleHideAllItems = () => {
    setHideAllItems(!hideAllItems)
  }

  return {
    showAllItems,
    hideAllItems,
    orderBoosters,
    handleShowAllItems,
    handleHideAllItems,
  }
}
