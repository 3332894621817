import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import { RewardShopModels, RewardShopThunks } from '@/state'

export const orderProductExtraReducer = (
  builder: ActionReducerMapBuilder<RewardShopModels.RewardShopState>,
) => {
  builder.addCase(RewardShopThunks.orderProductThunk.pending, (state) => {
    state.isLoadingOrder = true
  })
  builder.addCase(
    RewardShopThunks.orderProductThunk.fulfilled,
    (state, { payload }) => {
      state.orderProduct = payload
      state.isLoadingOrder = false
    },
  )
  builder.addCase(RewardShopThunks.orderProductThunk.rejected, (state) => {
    state.isLoadingOrder = false
  })
}
