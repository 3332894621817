import { t } from 'i18next'
import { PeriodTimeFrameEnum } from '@/enums'

export const parsePeriodTime = (periodString) => {
  const regex =
    /^P(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)W)?(?:(\d+)D)?(?:T(?:(\d+)H)?(?:(\d+)M)?)?$/
  const match = periodString.match(regex)

  let periodText
  if (match) {
    for (let index = 1; index < 7; index++) {
      if (match[index]) {
        periodText = `${match[index]} ${t(`${PeriodTimeFrameEnum[index]}`, {
          count: Number(match[index]),
        })}`
        index = 7
      }
    }
  }

  return periodText
}
