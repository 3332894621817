import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import {
  GlobalSettingsModels,
  GlobalSettingsThunks,
  PlayerModels,
} from '@/state'
import { GlobalSettingsEnum } from '@/enums'

export const getGlobalSettingsExtraReducer = (
  builder: ActionReducerMapBuilder<GlobalSettingsModels.GlobalSettingsState>,
) => {
  builder.addCase(
    GlobalSettingsThunks.getGlobalSettingsThunk.fulfilled,
    (state, { payload }) => {
      const reasons = Object.values(payload).find(
        (item) => item.key === GlobalSettingsEnum.OPT_OUT_REASONS,
      )

      const assetsRoute = Object.values(payload).find(
        (item) => item.key === GlobalSettingsEnum.ASSETS_ROUTE,
      )

      const casinoPickupEnable = Object.values(payload).find(
        (item) => item.key === GlobalSettingsEnum.CASINO_PICKUP_ENABLE,
      )

      const missionsEnable = Object.values(payload).find(
        (item) => item.key === GlobalSettingsEnum.MISSIONS_ENABLE,
      )

      state.gamificationReasons =
        (reasons?.value as PlayerModels.ReasonOptStatusType[]) ||
        state.gamificationReasons
      state.assetsRoute = (assetsRoute?.value as string) || state.assetsRoute
      state.casinoPickup = casinoPickupEnable?.value as boolean
      state.missionsEnable = missionsEnable?.value as boolean
    },
  )
}
