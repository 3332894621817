import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Box, Button, Grid, Typography } from '@mui/material'
import { MenuItemType, RewardShopTypeEnum } from '@/enums'
import { PaginationComponent, SkeletonLoader } from '@/components/common'

import { ProductCardComponent, useRewardShop } from '@/components/reward-shop'
import { useMenu } from '@/components/shop-layout'
import { RootState } from '@/state'

import { ALL_REWARDS_LIMIT } from '@/constants'
import { useStyles } from './style'

type Props = {
  specificRewardType?: RewardShopTypeEnum
}

export function CategoryComponent({ specificRewardType }: Props) {
  const { client } = useSelector(({ initConfig }: RootState) => initConfig)

  const { classes } = useStyles({ client })

  const { t } = useTranslation()

  const {
    boosterProducts,
    externalProducts,
    bonusProducts,
    xpProducts,
    rewardType,
    pagination,
    isLoading,
    handleOpenProductDetail,
    handleChangePage,
    getAllItems,
  } = useRewardShop()

  const { handleChangeMenuItem } = useMenu()

  const getListProducts = () => {
    if (
      rewardType === RewardShopTypeEnum.BOOSTER ||
      specificRewardType === RewardShopTypeEnum.BOOSTER
    ) {
      return boosterProducts
    }

    if (
      rewardType === RewardShopTypeEnum.EXTERNAL ||
      specificRewardType === RewardShopTypeEnum.EXTERNAL
    ) {
      return externalProducts
    }

    if (
      rewardType === RewardShopTypeEnum.XP ||
      specificRewardType === RewardShopTypeEnum.XP
    ) {
      return xpProducts
    }

    return bonusProducts
  }

  const productList = getListProducts()

  useEffect(() => {
    if (pagination.limit !== ALL_REWARDS_LIMIT && specificRewardType) {
      getAllItems(specificRewardType)
    }
  }, [pagination, specificRewardType]) // eslint-disable-line

  return (
    <>
      {productList.length > 0 && (
        <Box className={classes.products}>
          {!specificRewardType && (
            <Box className="categoryContent fullList">
              <Typography variant="h6">
                {t(`rewardCategory${rewardType}`)}
              </Typography>
              {client === 'JP' && (
                <Button
                  onClick={() => {
                    handleChangeMenuItem(MenuItemType.REWARD_SHOP)
                  }}
                >
                  {t('back')}
                </Button>
              )}
            </Box>
          )}
          <Grid container spacing={2}>
            {!isLoading ? (
              productList.map((product) => {
                return (
                  <ProductCardComponent
                    key={product._id}
                    handleOpenProductDetail={handleOpenProductDetail}
                    product={product}
                  />
                )
              })
            ) : (
              <Grid item xs={12} mt={4}>
                <SkeletonLoader element="product-category" />
                <SkeletonLoader element="product-category" />
              </Grid>
            )}

            {pagination.pages > 1 && (
              <Grid item xs={12} mt={4}>
                <PaginationComponent
                  pagination={pagination}
                  handlePrevPage={() =>
                    handleChangePage(pagination.page - 1, specificRewardType)
                  }
                  handleNextPage={() =>
                    handleChangePage(pagination.page + 1, specificRewardType)
                  }
                />
              </Grid>
            )}
          </Grid>
        </Box>
      )}
    </>
  )
}
