import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  MenuItemType,
  RewardProcessorStatusEnum,
  RewardShopBoosterTypeEnum,
  RewardShopTypeEnum,
} from '@/enums'

import {
  MyBoosterModels,
  MyBoosterThunks,
  RewardShopActions,
  RewardShopThunks,
  RootState,
  useAppDispatch,
} from '@/state'
import {
  RewardShopComponent,
  RewardShopHistoryComponent,
  MyBoostersComponent,
} from '@/components'
import { LIMIT } from '@/constants'

export const useShopLayout = () => {
  const dispatch = useAppDispatch()

  const {
    menuSelected,
    orderProduct,
    isError,
    bonusProducts,
    boosterProducts,
    externalProducts,
    xpProducts,
  } = useSelector(({ rewardShop }: RootState) => rewardShop)

  const { playerId } = useSelector(({ initConfig }: RootState) => initConfig)

  const { gamificationOpt } = useSelector(({ player }: RootState) => player)

  const filtersBoosters: MyBoosterModels.BoosterFiltersType = {
    playerId: playerId,
    rewardType: [
      RewardShopBoosterTypeEnum.LEVEL,
      RewardShopBoosterTypeEnum.TOKEN,
    ],
    status: RewardProcessorStatusEnum.PENDING,
    limit: LIMIT,
    page: 1,
  }

  const getInitialRewardShopProducts = () => {
    dispatch(
      RewardShopThunks.getAllProductsThunk({
        limit: 8,
        rewardType: RewardShopTypeEnum.EXTERNAL,
      }),
    )

    dispatch(
      RewardShopThunks.getAllProductsThunk({
        limit: 8,
        rewardType: RewardShopTypeEnum.BOOSTER,
      }),
    )

    dispatch(
      RewardShopThunks.getAllProductsThunk({
        limit: 8,
        rewardType: RewardShopTypeEnum.BONUS,
      }),
    )

    dispatch(
      RewardShopThunks.getAllProductsThunk({
        limit: 8,
        rewardType: RewardShopTypeEnum.XP,
      }),
    )
  }

  useEffect(() => {
    dispatch(
      MyBoosterThunks.getAllMyBoostersThunk({
        ...filtersBoosters,
      }),
    )
  }, []) // eslint-disable-line

  const getTabContent = () => {
    switch (menuSelected) {
      case MenuItemType.REWARD_SHOP:
        return <RewardShopComponent />

      case MenuItemType.BOOSTERS:
        return <MyBoostersComponent />

      case MenuItemType.HISTORY:
        return <RewardShopHistoryComponent />
    }
  }

  const isLoading = !bonusProducts || !boosterProducts || !externalProducts

  const isNotRewards =
    bonusProducts?.length === 0 &&
    externalProducts?.length === 0 &&
    boosterProducts?.length === 0 &&
    xpProducts?.length === 0

  useEffect(() => {
    if (!gamificationOpt.enable && menuSelected !== MenuItemType.REWARD_SHOP) {
      dispatch(RewardShopActions.setMenuSelected(MenuItemType.REWARD_SHOP))
    }
  }, [gamificationOpt]) // eslint-disable-line

  return {
    getTabContent,
    getInitialRewardShopProducts,
    orderProduct,
    isError,
    isLoading,
    isNotRewards,
  }
}
