import { Button, Typography, useTheme } from '@mui/material'
import { MissionModels } from '@/state'
import { IconColorByStatus } from '@/constants'

import { useGlobalUi } from '@/hooks'
import { MissionDialogsEnum } from '@/enums'
import { ProgressComponent } from '../'

export type MissionProgressProps = {
  mission: MissionModels.MissionType
  index?: number
}

export function MissionProgressComponent({
  mission,
  index,
}: MissionProgressProps) {
  const { handleOpenDialog } = useGlobalUi()

  const theme = useTheme()

  return (
    <>
      <Typography variant="body2" display="flex" alignItems="center" gap={1}>
        {index !== undefined ? `${index + 1}.` : ''}
        <Button
          sx={{
            textAlign: 'left',
            textTransform: 'none',
            color: theme.palette.text.primary,
            '&:hover': {
              background: 'none',
              textDecoration: 'underline',
            },
          }}
          variant="text"
          onClick={() => {
            mission._id &&
              handleOpenDialog({
                id: MissionDialogsEnum.MISSION_HISTORY,
                object: { ...mission },
              })
          }}
        >
          {mission.name}
        </Button>
      </Typography>
      <ProgressComponent
        state={mission.state}
        color={IconColorByStatus[mission.status].color}
      />
    </>
  )
}
