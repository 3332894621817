import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  alpha,
  filledInputClasses,
  useTheme,
} from '@mui/material'

import { ReasonOptStatusType } from '@/state/player/models'
import { RootState } from '@/state'
import { FormValues } from '../hooks/use-gamification-mechanics-modal.hook'

import { useStyles } from './style'

type Props = {
  reasons: ReasonOptStatusType[]
  formValues: FormValues
  handleOnChange: (field: string, newValue: string) => void
}

export const MechanicsOptOutFormComponent = ({
  reasons,
  formValues,
  handleOnChange,
}: Props) => {
  const { t } = useTranslation()

  const { playerLocale } = useSelector(
    ({ initConfig }: RootState) => initConfig,
  )

  const theme = useTheme()

  const { classes } = useStyles()

  return (
    <Box className={classes.form}>
      <FormControl>
        <RadioGroup
          value={formValues?.reason}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleOnChange('reason', event.target.value)
          }
        >
          {reasons.map((reason, index) => {
            return (
              <FormControlLabel
                key={`optout-option-${reason.option}`}
                value={reason.option}
                label={
                  reason?.translations?.find(
                    (trans) =>
                      trans.language.toUpperCase() ===
                      playerLocale.toUpperCase(),
                  )?.text
                }
                control={<Radio />}
              />
            )
          })}
          <FormControlLabel
            value="other"
            label={t('other')}
            control={<Radio />}
          />
        </RadioGroup>
      </FormControl>
      {formValues.reason === 'other' && (
        <TextField
          variant="filled"
          fullWidth
          margin="normal"
          label={t('writeReason')}
          value={formValues?.comment}
          multiline
          minRows={3}
          onChange={(e) => handleOnChange('comment', e.target.value)}
          error={formValues.comment.trim() === ''}
          helperText={formValues.comment.trim() === '' && t('requiredField')}
          sx={{
            [`& .${filledInputClasses.root}`]: {
              background: 'none !important',
              border: `2px solid ${alpha(theme.palette.text.secondary, 0.5)}`,
              borderRadius: '16px',

              '&.Mui-focused, &.Mui-error, &:hover': {
                borderColor: theme.palette.primary.main,
              },

              '&::after, &::before': {
                display: 'none',
              },
            },
          }}
        />
      )}
    </Box>
  )
}
