import { Box } from '@mui/material'
import { useBreakpoints } from '@/hooks'
import { SidebarMenuItemType } from '@/enums'

import {
  SidebarAvatarComponent,
  SidebarProfileComponent,
  SidebarPointsComponent,
  SidebarProgressBarComponent,
} from './'
import { useHeaderStyles } from './header.style'

type Props = {
  Div: Element
  sidebarClient: string
  sidebarMenuItem: string
  openSidebar: boolean
  handleOpenSidebar?: () => void
}

export const SidebarHeaderComponent = ({
  Div,
  sidebarClient,
  sidebarMenuItem,
  openSidebar,
  handleOpenSidebar,
}: Props) => {
  const { isMobile } = useBreakpoints()

  const { classes } = useHeaderStyles({ openSidebar, sidebarClient, isMobile })

  return (
    <Box className={classes.sidebarHeader}>
      {isMobile && <SidebarProfileComponent Div={Div} />}
      <Box className="sidebarHeaderContent">
        <SidebarAvatarComponent
          Div={Div}
          sidebarClient={sidebarClient}
          handleOpenSidebar={handleOpenSidebar}
          openSidebar={openSidebar}
        />
        <SidebarPointsComponent
          sidebarClient={sidebarClient}
          openSidebar={openSidebar}
        />
      </Box>
      {openSidebar && sidebarMenuItem === SidebarMenuItemType.HOME && (
        <SidebarProgressBarComponent Div={Div} sidebarClient={sidebarClient} />
      )}
    </Box>
  )
}
