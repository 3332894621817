import { useSelector } from 'react-redux'
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material'
import { RootState } from '@/state'

import { ImageChips } from '@/components'
import { ImageDefault } from '@/constants'
import { NameIdType } from '@/state/reward-shop/models'

import { useStyles } from './style'

type Props = {
  item: {
    id: number | string
    title: string
    description: string
    image: string
    ranks?: NameIdType[]
    tags?: NameIdType[]
  }
  contentHeight?: number
  cardContentComponent?: JSX.Element
  cardActionsComponent?: JSX.Element
  tooltip?: React.ReactNode
  onClick?: (param: any) => void
}

export const CardItemComponent = ({
  item,
  contentHeight,
  cardContentComponent,
  cardActionsComponent,
  tooltip,
  onClick,
}: Props) => {
  const { title, description, image } = item

  const { client } = useSelector(({ initConfig }: RootState) => initConfig)

  const { gamificationOpt } = useSelector(({ player }: RootState) => player)

  const { classes } = useStyles({ client })

  const CardArea = (
    <Box position="relative">
      <Box position="relative">
        <Box className="imageDesktop">
          <CardMedia
            component="img"
            image={image || ImageDefault}
            className={classes.cardMedia}
          />
        </Box>

        {(item?.ranks?.length > 0 || item?.tags?.length > 0) && (
          <Box className={classes.imageChips}>
            {item?.ranks?.length > 0 && (
              <ImageChips
                margins="0 2px 2px 0"
                items={item?.ranks}
                type="ranks"
                size="small"
              />
            )}

            {item?.tags?.length > 0 && (
              <ImageChips
                margins="0 2px 2px 0"
                items={item?.tags}
                type="tags"
                size="small"
              />
            )}
          </Box>
        )}
      </Box>
      <CardContent
        sx={{ height: contentHeight ?? 140 }}
        className={classes.cardContent}
      >
        <Box position="relative" maxWidth="95%">
          <Typography className={classes.title} sx={{ width: '90%' }}>
            {title}
          </Typography>

          {tooltip}
        </Box>
        <Typography
          variant="body2"
          color="text.secondary"
          className={classes.description}
        >
          {description}
        </Typography>
        {cardContentComponent}
      </CardContent>
    </Box>
  )

  return (
    <Card className={classes.card}>
      {onClick && gamificationOpt.enable ? (
        <CardActionArea onClick={onClick}>{CardArea}</CardActionArea>
      ) : (
        CardArea
      )}

      {cardActionsComponent && (
        <CardActions>{cardActionsComponent}</CardActions>
      )}
    </Card>
  )
}
