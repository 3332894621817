import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Box, Button, CircularProgress, Grid } from '@mui/material'

import { useMenu } from '@/components/shop-layout'
import { RootState } from '@/state'
import { MenuItemType } from '@/enums'

import { useStyles } from './style'

type Props = {
  children: JSX.Element
}

export function ProductComponent({ children }: Props) {
  const { t } = useTranslation()

  const { isLoadingReward } = useSelector(
    ({ rewardShop }: RootState) => rewardShop,
  )

  const { client } = useSelector(({ initConfig }: RootState) => initConfig)

  const { classes } = useStyles({ client })

  const { handleChangeMenuItem } = useMenu()

  return (
    <Box className={classes.details}>
      {isLoadingReward ? (
        <Box className={classes.loader}>
          <CircularProgress size="4rem" />
        </Box>
      ) : (
        <>
          {client === 'JP' && (
            <Button
              variant="text"
              sx={{ textAlign: 'left', textDecoration: 'underline' }}
              onClick={() => {
                handleChangeMenuItem(MenuItemType.REWARD_SHOP)
              }}
            >
              {t('backToList').toUpperCase()}
            </Button>
          )}
          <Grid container columnSpacing={5} rowSpacing={2}>
            {children}
          </Grid>
        </>
      )}
    </Box>
  )
}
