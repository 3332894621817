import { MutableRefObject } from 'react'
import { Box, BoxProps, colors } from '@mui/material'

type Props = {
  opacityCircleRef: MutableRefObject<undefined>
  bigCircleOneRef: MutableRefObject<undefined>
  bigCircleTwoRef: MutableRefObject<undefined>
  bigBoxRef: MutableRefObject<undefined>
  display: 'inline' | 'none'
}

export function TokensProfileBigAnimationsComponent({
  opacityCircleRef,
  bigCircleOneRef,
  bigCircleTwoRef,
  bigBoxRef,
  display,
}: Props) {
  const bigCirclesProps: BoxProps = {
    position: 'absolute',
    top: 3,
    left: -21.5,
    width: 20,
    height: 20,
    borderRadius: '50%',
    border: 2,
    borderColor: colors.amber[500],
    sx: {
      opacity: 0,
    },
  }

  return (
    <Box display={display}>
      <Box ref={opacityCircleRef} {...bigCirclesProps} />
      <Box ref={bigCircleOneRef} {...bigCirclesProps} />
      <Box ref={bigCircleTwoRef} {...bigCirclesProps} />
      <Box
        ref={bigBoxRef}
        position="absolute"
        top={-4}
        left={-25}
        width="calc(100% + 35px)"
        height="calc(100% + 8px)"
        borderRadius={10}
        bgcolor={colors.amber[500]}
        sx={{ opacity: 0 }}
      />
    </Box>
  )
}
