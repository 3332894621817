import { useTranslation } from 'react-i18next'
import { getData, Country } from 'country-list'
import {
  Autocomplete,
  Box,
  Grid,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material'

import { ProductFormType } from '@/state/reward-shop/models'

type Props = {
  productForm: ProductFormType
  handleOnChange: (field: string, newValue: string | number | Country) => void
}

type CountryType = {
  name: string
  code: string
}

export function JPLocationFormComponent({
  productForm,
  handleOnChange,
}: Props) {
  const { t } = useTranslation()

  const listCountries: Country[] = getData().filter(
    (country) => country.code === 'CH',
  )

  const textFieldsProps: TextFieldProps = {
    fullWidth: true,
    margin: 'normal',
  }

  return (
    <Grid container columnSpacing={2} marginTop="20px" paddingLeft="30px">
      <Grid item xs={12}>
        <Box>
          <Typography>{t('receives')}</Typography>
          <TextField
            {...textFieldsProps}
            value={productForm.receives}
            onChange={(e) => handleOnChange('receives', e.target.value)}
            error={productForm.receives.trim() === ''}
            helperText={
              productForm.receives.trim() === '' && t('requiredField')
            }
          />
        </Box>
      </Grid>
      <Grid item xs={12} xl={6}>
        <Box>
          <Typography>{t('street')}</Typography>
          <TextField
            {...textFieldsProps}
            value={productForm.street}
            onChange={(e) => handleOnChange('street', e.target.value)}
            error={productForm.street.trim() === ''}
            helperText={productForm.street.trim() === '' && t('requiredField')}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} xl={3}>
        <Box>
          <Typography>{t('streetNumber')}</Typography>
          <TextField
            {...textFieldsProps}
            value={productForm.streetNumber}
            onChange={(e) => handleOnChange('streetNumber', e.target.value)}
            error={productForm.streetNumber.trim() === ''}
            helperText={
              productForm.streetNumber.trim() === '' && t('requiredField')
            }
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} xl={3}>
        <Box>
          <Typography>{t('postalCode')}</Typography>
          <TextField
            {...textFieldsProps}
            value={productForm.postalCode}
            onChange={(e) => handleOnChange('postalCode', e.target.value)}
            error={productForm.postalCode.trim() === ''}
            helperText={
              productForm.postalCode.trim() === '' && t('requiredField')
            }
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box>
          <Typography>{t('country')}</Typography>
          <Autocomplete
            disableClearable
            options={listCountries}
            getOptionLabel={(option: CountryType) => option.name}
            value={productForm.country}
            onChange={(_e, value) => handleOnChange('country', value)}
            renderInput={(params) => (
              <TextField
                {...params}
                {...textFieldsProps}
                error={productForm.country === null}
                helperText={productForm.country === null && t('requiredField')}
              />
            )}
            fullWidth
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box>
          <Typography>{t('city')}</Typography>
          <TextField
            {...textFieldsProps}
            value={productForm.city}
            onChange={(e) => handleOnChange('city', e.target.value)}
            error={productForm.city.trim() === ''}
            helperText={productForm.city.trim() === '' && t('requiredField')}
          />
        </Box>
      </Grid>
    </Grid>
  )
}
