import { makeStyles } from 'tss-react/mui'
import { typographyClasses } from '@mui/material/Typography'
import { alpha, darken } from '@mui/material'

import { MikadoFontStyle, SidebarColors } from '@/constants'

interface Props {
  openSidebar?: boolean
  sidebarClient?: string
  isMobile?: boolean
}

export const useAvatarStyles = makeStyles<Props>()((
  theme,
  { openSidebar, sidebarClient, isMobile },
) => {
  return {
    avatarContent: {
      cursor: 'pointer',
      position: 'relative',
      display: 'inline-flex',
      backgroundColor: theme.palette.common.white,
      borderRadius: '50%',
      transform: 'scale(0.4)',
      border:
        openSidebar && !isMobile
          ? `5px solid ${alpha(theme.palette.primary.main, 0.5)}`
          : 'none',
      transformOrigin: '0 0',
      overflow: 'hidden',

      '& .avatar': {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 'auto',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 120,
        height: 120,
        backgroundColor: 'transparent',

        '& svg': {
          backgroundColor: theme.palette.primary.main,
        },
      },

      '& .disableAvatar': {
        '& svg': {
          backgroundColor: theme.palette.grey[200],
        },
      },

      '& .circular': {
        circle: {
          strokeLinecap: 'round',
          strokeWidth: '4px',
        },
      },

      '& .circularTransparent': {
        circle: {
          strokeLinecap: 'round',
          strokeWidth: '4px',
          color: theme.palette.grey[200],
        },
      },
    },

    levelContent: {
      display: 'flex',
      gap: 4,
      alignItems: 'center',
      justifyContent: 'space-around',
      padding: '2px 7px 0px',
      border: `2px solid ${SidebarColors[sidebarClient].HEADER.border1}`,
      borderRadius: 8,
      backgroundColor: SidebarColors[sidebarClient].HEADER.color,
      position: 'absolute',
      bottom: '-10px',
      right: '0',
      left: '0',
      margin: '0 auto',
      width: 'auto',
      boxShadow: `0px 5px 0px -1px ${darken(
        SidebarColors[sidebarClient].HEADER.color,
        0.3,
      )}`,
      cursor: 'pointer',

      [theme.breakpoints.down('sm')]: {
        bottom: '-2px',
      },

      [`& .${typographyClasses.body2}`]: {
        fontSize: 9,
        textAlign: 'center',
        ...MikadoFontStyle,
      },
    },
    accountCircle: {
      width: '130%',
      height: '130%',
    },
  }
})
