import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  SxProps,
  accordionSummaryClasses,
} from '@mui/material'
import { KeyboardArrowDown } from '@mui/icons-material'

type Props = {
  title: React.ReactNode
  children?: React.ReactNode
  sx?: SxProps
  handleOnExpanded?: () => void
  unmountOnExit?: boolean
}

export const AccordionCustomComponent = ({
  title,
  children,
  sx,
  handleOnExpanded,
  unmountOnExit,
}: Props) => {
  return (
    <Box mb={1} borderRadius={2.5}>
      <Accordion
        sx={sx}
        onChange={
          children
            ? (_event: React.SyntheticEvent, exp: boolean) => {
                handleOnExpanded && exp && handleOnExpanded()
              }
            : undefined
        }
        TransitionProps={{
          unmountOnExit: unmountOnExit || false,
        }}
      >
        <AccordionSummary
          expandIcon={children ? <KeyboardArrowDown /> : <></>}
          sx={{
            flexDirection: 'row-reverse',
            gap: 1,
            [`& .${accordionSummaryClasses.content}`]: {
              gap: 1,
              alignItems: 'center',
            },
            [`& .${accordionSummaryClasses.expandIconWrapper}`]: {
              display: children ? 'flex' : 'none',
            },
            [`&.${accordionSummaryClasses.root}`]: {
              cursor: children ? 'pointer' : 'default',
              pointerEvents: children ? 'all' : 'none',
            },
          }}
        >
          {title}
        </AccordionSummary>
        {children && <AccordionDetails>{children}</AccordionDetails>}
      </Accordion>
    </Box>
  )
}
