import {
  Box,
  LinearProgress,
  Typography,
  alpha,
  linearProgressClasses,
  colors,
} from '@mui/material'

export type ProgressProps = {
  state: {
    total: number
    completed: number
    progress: number
  }
  color?: string
}

export function ProgressComponent({ state, color }: ProgressProps) {
  return (
    <>
      <Box display="flex" justifyContent="space-between" pb={1}>
        <Typography variant="body2">{`${state?.completed}/${state?.total}`}</Typography>
        <Typography variant="body2">{`${
          state?.progress ? Number(state?.progress).toFixed(0) : 0
        }%`}</Typography>
      </Box>
      <LinearProgress
        variant="determinate"
        value={Number(state?.progress)}
        sx={{
          height: 8,
          borderRadius: 8,
          [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: alpha(colors.grey[500], 0.7),
          },
          [`& .${linearProgressClasses.bar}`]: {
            backgroundColor: color || colors.green[500],
            borderRadius: 8,
          },
        }}
      />
    </>
  )
}
