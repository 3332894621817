import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Box, Divider, Typography } from '@mui/material'

import { HtmlTooltip } from '@/theme/tooltip.theme'
import { MissionModels, RootState } from '@/state'
import { RewardTypeInformation } from '@/constants'

import { useStyles } from './style'
import { useTooltipRewards } from '../'

type Props = {
  rewards: MissionModels.RewardCategoryType[]
  children: JSX.Element
}

export const TooltipRewardsComponent = ({ rewards, children }: Props) => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  const { client } = useSelector(({ initConfig }: RootState) => initConfig)

  const rewardTypeInfo = RewardTypeInformation(client)

  const { isOpenedTooltip, handleSetOpenedTooltip, getRewardDescription } =
    useTooltipRewards()

  return (
    <HtmlTooltip
      open={isOpenedTooltip}
      onClick={handleSetOpenedTooltip}
      width={154}
      title={
        <Box className={classes.rewardsWrapper}>
          <Box className="rewardsHeader" mb={0.5}>
            <Typography component="p">{t('rewards')}</Typography>
          </Box>
          <Divider />
          {rewards.map((reward, index) => {
            return (
              <Box className="rewardItem" key={`tooltip-reward-${reward.id}`}>
                <Box className="rewardItemIcon">
                  {rewardTypeInfo[reward.rewardType].icon}
                </Box>
                <Box className="rewardItemDescription">
                  <Typography variant="body2">
                    {t(`${rewardTypeInfo[reward.rewardType].label}`)}
                  </Typography>
                  <Typography variant="caption">
                    {getRewardDescription(reward)}
                  </Typography>
                </Box>
              </Box>
            )
          })}
        </Box>
      }
      placement="top-start"
    >
      {children}
    </HtmlTooltip>
  )
}
