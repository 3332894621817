export const WidgetEndpoints = {
  ITEMS: '/reward-shop/v1/ui/items',
  ORDERS: '/reward-shop/v1/ui/orders',
  TOKENS: '/virtual-currency/v1/ui/wallet',
  RANKS: '/ranks/v1/ui/player-card',
  REWARD_PROCESSOR: '/reward-processor/v1/ui/rewards',
  GET_GAMIFICATION_OPT_STATUS: '/api/players-data/v1/player',
  GET_GAMIFICATION_SETTINGS: '/settings/v1/ui',
  SET_GAMIFICATION_OPT_STATUS:
    '/api/players-data/v1/player/gamification-opt-status',
  TOKEN_ACTIVE_BOOSTER:
    '/virtual-currency/v1/ui/token-booster/list-active-boosters',
  LEVEL_ACTIVE_BOOSTER: '/ranks/v1/ui/rewards/list-active-boosters',
  MISSION_ACTIVE_BOOSTER:
    '/missions/v1/ui/missions-booster/list-active-boosters',
  GET_ALL_BUNDLES: '/missions/v1/ui/player-card/bundle-list',
  GET_ONE_BUNDLE: '/missions/v1/ui/player-card/bundle-detail',
  UPDATE_BUNDLE_STATUS: '/missions/v1/ui/player-card/update-bundle-status',
  CLAIM_EASTER_EGG: '/missions/v1/ui/easter-egg/claim',
  GET_ACTIVE_BUNDLES: '/missions/v1/ui/player-card/bundle-active-timeframe',
  GET_ACTIVE_MISSIONS: '/missions/v1/ui/player-card/mission-list-inprogress',
  GET_EXPIRED_MISSIONS: '/missions/v1/ui/player-card/mission-list-expired',
}
