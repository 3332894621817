import { Box } from '@mui/material'
import { SidebarMenuItemType } from '@/enums'
import { SidebarHomeComponent, SidebarMissionComponent } from './'

import { useContentStyles } from './content.style'

type Props = {
  openSidebar: boolean
  sidebarMenuItem: string
  handleChangeMenuItem?: (sidebarMenuItem: string) => void
}

export const SidebarContentComponent = ({
  openSidebar,
  sidebarMenuItem,
  handleChangeMenuItem,
}: Props) => {
  const { classes } = useContentStyles({ openSidebar })

  const content = () => {
    if (sidebarMenuItem === SidebarMenuItemType.HOME || !openSidebar) {
      return (
        <SidebarHomeComponent
          openSidebar={openSidebar}
          handleChangeMenuItem={handleChangeMenuItem}
        />
      )
    }

    if (sidebarMenuItem === SidebarMenuItemType.MISSIONS) {
      return openSidebar && <SidebarMissionComponent />
    }
  }
  return <Box className={classes.sidebarContent}>{content()}</Box>
}
