import { makeStyles } from 'tss-react/mui'
import { typographyClasses, iconButtonClasses } from '@mui/material'
import { MikadoFontStyle } from '@/constants'

export const useProfileStyles = makeStyles()((theme) => {
  return {
    profileContent: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: '1px solid rgba(0, 0, 0, 0.20)',

      [`& .${typographyClasses.caption}`]: {
        ...MikadoFontStyle,
        fontSize: 16,
        textTransform: 'uppercase',
      },

      [`& ${iconButtonClasses.root}`]: {
        cursor: 'pointer',
      },
    },
  }
})
