import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Snackbar, Alert, Box, Typography, Zoom } from '@mui/material'

import { useGlobalUi } from '@/hooks'
import { NotificationTypeEnum } from '@/enums'
import { RootState } from '@/state'

import { ReactComponent as IconLevel } from '@/assets/level-icon.svg'
import { ReactComponent as IconLevelJP } from '@/assets/level-icon-jp.svg'
import { ReactComponent as IconRank } from '@/assets/rank-icon.svg'

import { useStyles } from './styles'

type Props = {
  children: React.ReactNode
}

export function NotificationSnackbarComponent({ children }: Props) {
  const { notificationSnackbar, handleCloseNotificationSnackbar, modal } =
    useGlobalUi()

  const { open } = notificationSnackbar

  const { client } = useSelector(({ initConfig }: RootState) => initConfig)

  const { gamificationPlayer } = useSelector(({ player }: RootState) => player)

  const [isVisible, setIsVisible] = useState(false)

  const { classes } = useStyles()

  const onClose = (_event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    handleCloseNotificationSnackbar()
  }

  useEffect(() => {
    const timerIn = setTimeout(() => {
      setIsVisible(true)
    }, 1000)

    return () => {
      clearTimeout(timerIn)
    }
  }, []) // eslint-disable-line

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      autoHideDuration={60000}
      open={open}
      onClose={onClose}
    >
      <Box className={classes.alertGroup}>
        <Zoom in={true}>
          <Box className={classes.alertIconWrapper}>
            {modal.type === NotificationTypeEnum.RANK_UP ? (
              <IconRank className={classes.alertIcon} />
            ) : (
              <>
                {client === 'JP' ? (
                  <IconLevelJP className={classes.alertIcon} />
                ) : (
                  <IconLevel className={classes.alertIcon} />
                )}
                <Typography className={classes.levelValue}>
                  {gamificationPlayer.level.levelNumber}
                </Typography>
              </>
            )}
          </Box>
        </Zoom>
        {isVisible && (
          <Zoom
            in={isVisible}
            style={{
              transitionDuration: '250ms',
            }}
          >
            <Alert
              icon={false}
              className={classes.alertContainer}
              onClose={onClose}
            >
              {children}
            </Alert>
          </Zoom>
        )}
      </Box>
    </Snackbar>
  )
}
