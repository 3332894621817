import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box, Typography } from '@mui/material'

import { MissionItem } from '@/constants'
import {
  GeneralStatusEnum,
  GeneralStatusExtendedEnum,
  MissionTimeTypeEnum,
} from '@/enums'
import { MissionHistoryModels, RootState } from '@/state'

import { useMissionItemStyles } from './style'
import { MissionItemInProgressComponent } from './mission-item-in-progress.component'

type Props = {
  status: GeneralStatusExtendedEnum | GeneralStatusEnum
  endDate?: string
  timeType?: MissionTimeTypeEnum
  state?: MissionHistoryModels.MissionProgressType
  expanded?: boolean
  handleClick?: () => void
}

export const MissionItemComponent = ({
  status,
  endDate,
  timeType,
  state,
  expanded,
  handleClick,
}: Props) => {
  const { t } = useTranslation()

  const { client } = useSelector(({ initConfig }: RootState) => initConfig)

  const sidebarClient = client && client === 'JP' ? client : 'STANDARD'

  const { classes } = useMissionItemStyles({
    expanded,
    status,
    sidebarClient,
  })

  return (
    <>
      {status === GeneralStatusExtendedEnum.ACTIVE ||
      status === GeneralStatusExtendedEnum.ENDED ? (
        <MissionItemInProgressComponent
          sidebarClient={sidebarClient}
          expanded={expanded}
          endDate={endDate}
          timeType={timeType}
          state={state}
          status={status}
          handleClick={handleClick}
        />
      ) : (
        <Box
          className={classes.missionWrapper}
          onClick={() => handleClick && handleClick()}
        >
          <Box className={classes.missionContent}>
            <Box className="missionIcon">{MissionItem[status].icon}</Box>
            <Typography variant="body2">
              {t(`${MissionItem[status].label}`)}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  )
}
