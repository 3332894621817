import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { format } from 'date-fns'

import { Box, Button, Typography } from '@mui/material'
import { MissionModels, RootState } from '@/state'
import { ReactComponent as IconButtonHistory } from '@/assets/icons/missions/icon-button-history.svg'

import { useMissionItemStyles } from './style'
import { MissionItemComponent } from './mission-item.component'

type Props = {
  mission: MissionModels.MissionsByBundleType
  handleClick?: () => void
}

export const MissionItemHistoryComponent = ({
  mission,
  handleClick,
}: Props) => {
  const { t } = useTranslation()

  const { client } = useSelector(({ initConfig }: RootState) => initConfig)

  const sidebarClient = client && client === 'JP' ? client : 'STANDARD'

  const { classes } = useMissionItemStyles({
    expanded: false,
    status: mission.status,
    sidebarClient,
  })

  return (
    <Box className={classes.missionItemHistory}>
      <Box className="missionItemHistoryIcon">
        <MissionItemComponent
          status={mission.status}
          endDate={mission.endDate}
          state={mission.state}
          timeType={mission.validityTimeState.timeType}
          handleClick={handleClick}
        />
      </Box>
      <Box className="missionItemHistoryText">
        <Typography variant="body2" component="p">
          {format(new Date(mission.endDate), 'dd MMM').toUpperCase()}
        </Typography>
        <Typography variant="body2">{t(`${mission.status}`)}</Typography>
      </Box>
      <Button onClick={() => handleClick()}>
        <IconButtonHistory />
      </Button>
    </Box>
  )
}
