import { MutableRefObject } from 'react'
import { Box, colors } from '@mui/material'

type Props = {
  circleRef: MutableRefObject<undefined>
  display: 'inline' | 'none'
}

export function TokensProfileSmallAnimationsComponent({
  circleRef,
  display,
}: Props) {
  return (
    <Box
      ref={circleRef}
      position="absolute"
      top={0.5}
      left={0}
      width={20}
      height={20}
      borderRadius="50%"
      border={2}
      borderColor={colors.amber[500]}
      sx={{
        opacity: 0,
      }}
      display={display}
    />
  )
}
