import { useTranslation } from 'react-i18next'
import { Button, ButtonProps } from '@mui/material'
import { ContentIconComponent } from '@/components/common'

type Props = {
  handleTryAgain?: () => void
}

export const ErrorPageComponent = ({ handleTryAgain }: Props) => {
  const { t } = useTranslation()

  const buttonProps: ButtonProps = {
    color: 'primary',
    variant: 'outlined',
    sx: { width: '100%', padding: '10px 0' },
  }

  return (
    <ContentIconComponent
      iconType="error"
      title={t('pageError')}
      message={t('pageErrorMessage')}
      buttonContent={
        handleTryAgain ? (
          <Button {...buttonProps} onClick={handleTryAgain}>
            {t('tryAgain')}
          </Button>
        ) : undefined
      }
    />
  )
}
