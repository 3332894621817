import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Country } from 'country-list'

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { CheckBox } from '@mui/icons-material'
import { RootState } from '@/state'

import { ProductFormType } from '@/state/reward-shop/models'
import { IconComponent, TooltipComponent } from '@/components/common'
import { getFormatNumber } from '@/utils'

import { RewardShopTypeEnum } from '@/enums'
import { ReactComponent as BoosterIcon } from '@/assets/boosterFloatingAction.svg'
import { ProductComponent, ProductImageChipsComponent } from '../'

import { useStyles } from '../style'

type Props = {
  disableButton: boolean
  disableByRank: boolean
  productForm: ProductFormType
  quantityOptions: number[]
  handleOnSubmit: () => void
  handleChangeStepForm: (step: number) => void
  handleOnChange: (
    field: string,
    newValue: string | number | boolean | Country,
  ) => void
}

export function JPProductFormStepOneComponent({
  disableButton,
  disableByRank,
  productForm,
  quantityOptions,
  handleOnSubmit,
  handleChangeStepForm,
  handleOnChange,
}: Props) {
  const { t } = useTranslation()

  const { currentProduct } = useSelector(
    ({ rewardShop }: RootState) => rewardShop,
  )

  const { playerLocale, client } = useSelector(
    ({ initConfig }: RootState) => initConfig,
  )

  const { gamificationPlayer } = useSelector(({ player }: RootState) => player)

  const { classes } = useStyles({ client })

  const theme = useTheme()

  const translation =
    currentProduct?.translations?.find(
      (item) => item.language.toUpperCase() === playerLocale.toUpperCase(),
    ) || currentProduct?.translations?.[0]

  return (
    <ProductComponent>
      <>
        <Grid item sm={12} md={8}>
          <ProductImageChipsComponent />
        </Grid>
        <Grid item xs={12} md={4}>
          <Box className="detailsContent">
            <Typography className="title" variant="h5">
              {translation?.name || currentProduct?.name}
            </Typography>
            <Typography className="description">
              {translation?.description || currentProduct?.description}
            </Typography>
            <Box className={classes.jpStepOne}>
              <Box>
                <Box className={classes.tokensBox} mb={1}>
                  <IconComponent type="token" size={30} />
                  <Typography variant="body2">
                    {getFormatNumber(
                      currentProduct?.reward?.price?.virtualCurrencyPrice,
                      playerLocale,
                    )}
                  </Typography>
                </Box>

                <Typography
                  variant="caption"
                  className={classes.remainingTokens}
                >{`${t('remainingTokens')} ${getFormatNumber(
                  gamificationPlayer.tokens,
                  playerLocale,
                )}`}</Typography>
              </Box>
              <form className={classes.form}>
                <TextField
                  className="quantity"
                  label={t('qty')}
                  variant="filled"
                  margin="normal"
                  select
                  value={productForm.quantity}
                  onChange={(e) => handleOnChange('quantity', e.target.value)}
                  sx={{ width: '143px' }}
                >
                  {quantityOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>

                <Box display="flex" justifyContent="flex-start">
                  {currentProduct?.reward?.type ===
                    RewardShopTypeEnum.BOOSTER && (
                    <>
                      <FormControlLabel
                        className="checkBox"
                        control={
                          <Checkbox
                            name="ignoreDevice"
                            checked={productForm.activateBooster}
                            onChange={(e) =>
                              handleOnChange(
                                'activateBooster',
                                e.target.checked,
                              )
                            }
                            checkedIcon={<CheckBox />}
                          />
                        }
                        label={t('activateBooster')}
                      />
                      <Box position="relative">
                        <TooltipComponent
                          title={t('activateBooster')}
                          description={t('activeBoosterDescription')}
                          icon={
                            <Box display="flex" justifyContent="center">
                              <BoosterIcon width={36} height={36} />
                            </Box>
                          }
                          maxWidth={340}
                        />
                      </Box>
                    </>
                  )}
                </Box>

                <Box className={classes.orderButton}>
                  <Button
                    variant="contained"
                    fullWidth
                    disabled={disableButton || disableByRank}
                    onClick={() => {
                      currentProduct?.reward?.type ===
                      RewardShopTypeEnum.EXTERNAL
                        ? handleChangeStepForm(2)
                        : handleOnSubmit()
                    }}
                  >
                    {currentProduct?.reward?.type ===
                    RewardShopTypeEnum.EXTERNAL
                      ? t('orderNow')
                      : t('orderBindingly')}
                  </Button>
                  {(disableButton || disableByRank) && (
                    <Typography
                      color={theme.palette.error.main}
                      sx={{ marginTop: '8px' }}
                    >
                      {disableButton
                        ? t('disabledSubmit')
                        : t('requiredRankMessage')}
                    </Typography>
                  )}
                </Box>
              </form>
            </Box>
          </Box>
        </Grid>
      </>
    </ProductComponent>
  )
}
