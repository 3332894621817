import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ActiveBoosterType, BoosterDataType } from '@/state/my-booster/models'

import { RewardShopBoosterTypeEnum } from '@/enums'
import { ReactComponent as LevelBooster } from '@/assets/levelBooster.svg'
import { ReactComponent as MissionBooster } from '@/assets/missionBooster.svg'

import { ReactComponent as TokenBooster } from '@/assets/tokenBooster.svg'

type Props = {
  timeStart: string
  timeEnd: string
  Div: Element
  booster: ActiveBoosterType
}

export const useActiveBoostersItem = ({
  timeStart,
  timeEnd,
  Div,
  booster,
}: Props) => {
  const { t } = useTranslation()

  const missionColors = {
    baseColor: Div.getAttribute('data-mission-base') || '',
    background: Div.getAttribute('data-mission-background') || '',
    textColor: Div.getAttribute('data-mission-text') || '',
  }
  const levelColors = {
    baseColor: Div.getAttribute('data-level-base') || '',
    background: Div.getAttribute('data-level-background') || '',
    textColor: Div.getAttribute('data-level-text') || '',
  }
  const tokenColors = {
    baseColor: Div.getAttribute('data-token-base') || '',
    background: Div.getAttribute('data-token-background') || '',
    textColor: Div.getAttribute('data-token-text') || '',
  }

  const [progress, setProgress] = useState(0)
  const [timeRemaining, setTimeRemaining] = useState(null)
  const [boosterData, setBoosterData] = useState<BoosterDataType>({
    title: '',
    colors: null,
    image: null,
  })

  const percentageProgress = progress * 100

  const getBoosterPercentageColor = () => {
    if (percentageProgress < 10) {
      return 'alert'
    }
    if (percentageProgress < 25) {
      return 'warning'
    }
    return booster.boosterType
  }

  useEffect(() => {
    switch (booster.boosterType) {
      case RewardShopBoosterTypeEnum.LEVEL:
        setBoosterData({
          title: t('levelBooster'),
          colors: levelColors,
          image: <LevelBooster />,
        })
        break

      case RewardShopBoosterTypeEnum.MISSION:
        setBoosterData({
          title: t('missionBooster'),
          colors: missionColors,
          image: <MissionBooster />,
        })
        break

      case RewardShopBoosterTypeEnum.TOKEN:
        setBoosterData({
          title: t('tokenBooster'),
          colors: tokenColors,
          image: <TokenBooster />,
        })
        break

      default:
        setBoosterData({
          title: t('levelBooster'),
          colors: levelColors,
          image: <LevelBooster />,
        })
        break
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    const startTime = new Date(timeStart).getTime()
    const endTime = new Date(timeEnd).getTime()

    const totalTime = endTime - startTime
    const timerInterval = setInterval(() => {
      const now = new Date().getTime()
      const remaining = Math.max(endTime - now, 0)
      const currentProgress = Math.min((totalTime - remaining) / totalTime, 1)
      setProgress(1 - currentProgress)

      const days = Math.floor(remaining / (1000 * 60 * 60 * 24))
      const hours = Math.floor(
        (remaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
      )
      const minutes = Math.floor((remaining % (1000 * 60 * 60)) / (1000 * 60))

      const seconds = Math.floor((remaining % (1000 * 60)) / 1000)

      setTimeRemaining(
        `${days > 0 ? `${days}d` : ''} ${hours > 0 ? `${hours}h` : ''} ${
          minutes >= 1 ? `${minutes}m` : ''
        } ${`${seconds}s`}`,
      )

      if (remaining === 0) {
        clearInterval(timerInterval)
      }
    }, 1000)

    return () => clearInterval(timerInterval)
  }, []) // eslint-disable-line

  return {
    progress,
    percentageProgress,
    timeRemaining,
    boosterData,
    getBoosterPercentageColor,
  }
}
