import { useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { RootState } from '@/state'

import { ActiveMissionsComponent } from './'
import { useHomeStyles } from './style'

type Props = {
  openSidebar: boolean
  handleChangeMenuItem?: (sidebarMenuItem: string) => void
}

export const SidebarHomeComponent = ({
  openSidebar,
  handleChangeMenuItem,
}: Props) => {
  const { classes } = useHomeStyles({ openSidebar })

  const { missionsEnable } = useSelector(
    ({ globalSettings }: RootState) => globalSettings,
  )

  return (
    <Box className={classes.homeContent}>
      {missionsEnable && (
        <ActiveMissionsComponent
          openSidebar={openSidebar}
          handleChangeMenuItem={handleChangeMenuItem}
        />
      )}
    </Box>
  )
}
