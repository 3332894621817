import { makeStyles } from 'tss-react/mui'

type Props = {
  status?: string
}

export const useMissionStyles = makeStyles<Props>()((theme, props) => {
  return {
    objectiveProgressWrapper: {
      display: 'flex',
      padding: 16,
      marginBottom: 8,
      backgroundColor: theme.palette.common.white,
      borderRadius: 4,
      borderLeft: props.status ? `4.5px solid ${props.status}` : 'none',
    },
    objectiveStatusIcon: {
      backgroundColor: props.status ? props.status : 'inherit',
      borderRadius: '50%',
      minWidth: 28,
      height: 28,
      marginRight: 16,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      '& svg': {
        color: theme.palette.common.white,
      },
    },
    groupIcon: {
      width: 25,
      height: 25,
      padding: 4,
      borderRadius: 8,
      backgroundColor: theme.palette.common.white,
    },
  }
})
