import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import { useGlobalUi } from '@/hooks'

import { MissionDialogsEnum } from '@/enums'
import { MissionDetailsComponent } from './'

export function DialogMission() {
  const { dialog, handleCloseDialog } = useGlobalUi()

  return (
    <>
      {dialog.id === MissionDialogsEnum.MISSION_HISTORY && (
        <Dialog disableEnforceFocus fullWidth open={dialog.open} maxWidth="sm">
          <DialogTitle
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            {dialog.object.name}
            <IconButton
              size="small"
              aria-label="close"
              onClick={handleCloseDialog}
            >
              <Close fontSize="inherit" />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ paddingBottom: 0 }}>
            <MissionDetailsComponent mission={dialog.object} />
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}
