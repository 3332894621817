import { useState } from 'react'
import { useSelector } from 'react-redux'
import { t } from 'i18next'

import { RewardTypeEnum } from '@/enums'
import { RootState, MissionModels } from '@/state'
import { convertTimeFrame } from '@/utils'

export const useTooltipRewards = () => {
  const [isOpenedTooltip, setIsOpenedTooltip] = useState(false)

  const { playerLocale } = useSelector(
    ({ initConfig }: RootState) => initConfig,
  )

  const handleSetOpenedTooltip = () => {
    setIsOpenedTooltip(!isOpenedTooltip)
  }

  const getRewardDescription = (reward: MissionModels.RewardCategoryType) => {
    const languageTranslation =
      reward?.translations?.find(
        (item) => item.language.toUpperCase() === playerLocale.toUpperCase(),
      )?.description || ''

    switch (reward.rewardType) {
      case RewardTypeEnum.BONUS_OFFER:
        return `${
          languageTranslation !== ''
            ? languageTranslation
            : `${t('id')}: ${reward.bonusId}`
        }`

      case RewardTypeEnum.TOKENS:
        return reward.tokens

      case RewardTypeEnum.XP:
        return reward.amount

      default:
        const { boosterRate, timeFrame, timeFrameUnit } =
          reward as MissionModels.BoosterCategoryType

        const timeFrameItem = timeFrameUnit
          ? timeFrame?.replace(/[a-zA-Z]/g, '')
          : convertTimeFrame(timeFrame)

        const timeFrameItemUnit =
          typeof timeFrameItem === 'string' ? timeFrameUnit : timeFrameItem.unit

        const timeFrameUnitText =
          timeFrameItemUnit === 'M' || timeFrameItemUnit === 'm'
            ? timeFrameItemUnit === 'M'
              ? 'Mth'
              : 'Min'
            : timeFrameItemUnit

        return `${boosterRate}X x ${
          typeof timeFrameItem === 'string' ? timeFrameItem : timeFrameItem.time
        } ${t(`duration${timeFrameUnitText}`)}`
    }
  }

  return {
    isOpenedTooltip,
    handleSetOpenedTooltip,
    getRewardDescription,
  }
}
