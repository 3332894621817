import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import { RewardShopModels, RewardShopThunks } from '@/state'

export const getOneProductExtraReducer = (
  builder: ActionReducerMapBuilder<RewardShopModels.RewardShopState>,
) => {
  builder.addCase(RewardShopThunks.getOneProductThunk.pending, (state) => {
    state.isLoadingReward = true
  })
  builder.addCase(
    RewardShopThunks.getOneProductThunk.fulfilled,
    (state, { payload }) => {
      state.isLoadingReward = false
      state.currentProduct = payload
    },
  )
  builder.addCase(RewardShopThunks.getOneProductThunk.rejected, (state) => {
    state.isLoadingReward = false
  })
}
