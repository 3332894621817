import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import {
  MissionEventTypeEnum,
  MissionNextLevelTypeEnum,
  MissionTimeTypeEnum,
  TimeZoneEnum,
  TimeZoneUTCEnum,
} from '@/enums'

import { MissionModels } from '@/state'
import { getDateTimeFromIsoUTC } from '@/utils/date.util'
import { dateTableFormat } from '@/constants/date'

export const useMissionItem = () => {
  const { t } = useTranslation()

  const getPaymentMethods = (paymentMethods: string[]) => {
    return paymentMethods.length > 0
      ? ` - ${t('missionHistory.paymentMethods')}: ${paymentMethods.join(', ')}`
      : ''
  }

  const getMinimumAmount = (
    minimumAmounts: { minimumAmount: number; currency: string }[],
  ) => {
    return minimumAmounts.length > 0
      ? minimumAmounts.map(
          (item) =>
            ` - ${t('missionHistory.minimumAmount')}: ${item.minimumAmount} ${
              item.currency
            }`,
        )
      : ''
  }

  const getObjectiveName = (objective: MissionModels.ObjectiveMissionTypes) => {
    let objectiveType
    let objectiveMessage = `${t(
      `missionHistory.missionEventOptions.${objective.missionEventType}`,
    )} `

    switch (objective.missionEventType) {
      case MissionEventTypeEnum.ACCOUNT_VERIFICATION:
        objectiveType =
          objective as MissionModels.ObjectiveMissionOptInAndAccountVerificationType
        objectiveMessage += `${t('missionHistory.channels')} - ${t(
          'missionHistory.channelsOptions.email',
        )}: ${objectiveType.channelEmail} -  ${t(
          'missionHistory.channelsOptions.sms',
        )}: ${objectiveType.channelSms}`
        break

      case MissionEventTypeEnum.COMPLETED_DEPOSIT:
      case MissionEventTypeEnum.COMPLETED_WITHDRAWAL:
        objectiveType =
          objective as MissionModels.ObjectiveMissionCompletedTransactionType
        objectiveMessage += `${t(
          `missionHistory.missionConditionOptions.${objectiveType.conditionType}`,
        )}: ${objectiveType.conditionValue} ${getPaymentMethods(
          objectiveType.paymentMethods,
        )} ${getMinimumAmount(objectiveType.minimumAmount)}`
        break

      case MissionEventTypeEnum.LEVEL_UP:
        objectiveType = objective as MissionModels.ObjectiveMissionLevelUpType
        objectiveMessage += `- ${t(
          `missionHistory.missionLevelOptions.${objectiveType.nextLevelType}`,
        )} ${
          objectiveType.nextLevelType !== MissionNextLevelTypeEnum.ANY
            ? `- ${t('missionHistory.value')}: ${objectiveType.nextLevelValue}`
            : ''
        }`
        break

      case MissionEventTypeEnum.OPT_IN:
        objectiveType =
          objective as MissionModels.ObjectiveMissionOptInAndAccountVerificationType
        objectiveMessage += `${t('missionHistory.channels')} - ${t(
          'missionHistory.channelsOptions.email',
        )}: ${objectiveType.channelEmail} - ${t(
          'missionHistory.channelsOptions.sms',
        )}: ${objectiveType.channelSms} - ${t(
          'missionHistory.channelsOptions.phone',
        )}: ${objectiveType.channelPhone} - ${t(
          'missionHistory.channelsOptions.post',
        )}: ${objectiveType.channelPost}`
        break

      case MissionEventTypeEnum.REFER_A_FRIEND:
        objectiveType =
          objective as MissionModels.ObjectiveMissionReferAFriendType
        objectiveMessage += `- ${t(
          `missionHistory.missionConditionOptions.${objectiveType.conditionType}`,
        )}: ${objectiveType.conditionValue} - ${t(
          'missionHistory.godsonAccountStatus',
        )}: ${t(
          `missionHistory.accountStatusOptions.${objectiveType.godsonAccountStatusType.toUpperCase()}`,
        )}`
        break

      case MissionEventTypeEnum.WAGER:
      case MissionEventTypeEnum.WIN:
        objectiveType =
          objective as MissionModels.ObjectiveMissionWagerAndWinType
        objectiveMessage += `- ${t(
          `missionHistory.missionConditionOptions.${objectiveType.conditionType}`,
        )}: ${objectiveType.conditionValue} ${getMinimumAmount(
          objectiveType.minimumAmount,
        )}`
        break

      default:
        // eslint-disable-next-line
        objectiveMessage
        break
    }

    return objectiveMessage
  }

  const getValidityTime = (
    missionValidityTime:
      | MissionModels.ValidatyTimeType
      | MissionModels.ValidatyTimePeriodType
      | MissionModels.ValidatyTimeSpecificDateType,
  ) => {
    let validityType
    switch (missionValidityTime.timeType) {
      case MissionTimeTypeEnum.ANY_TIME:
        return t(
          `missionHistory.missionTimeOptions.${MissionTimeTypeEnum.ANY_TIME}`,
        )

      case MissionTimeTypeEnum.PERIOD:
        validityType =
          missionValidityTime as MissionModels.ValidatyTimePeriodType
        return `${validityType.periodValue} ${validityType.periodType}`

      case MissionTimeTypeEnum.SPECIFIC_DATE:
        validityType =
          missionValidityTime as MissionModels.ValidatyTimeSpecificDateType
        return format(
          getDateTimeFromIsoUTC(
            validityType.date,
            TimeZoneUTCEnum[TimeZoneEnum.GMT_0],
          ),
          dateTableFormat,
        )
    }
  }

  return {
    getObjectiveName,
    getValidityTime,
  }
}
