import React from 'react'
import { Box, Divider, Typography, alpha, colors } from '@mui/material'

type Props = {
  title: string
  subtitle?: string
  children: React.ReactNode
  groupIcon: React.ReactNode
}

export function MissionGroupDetailsComponent({
  title,
  subtitle,
  children,
  groupIcon,
}: Props) {
  return (
    <Box bgcolor={alpha(colors.grey[300], 0.5)} borderRadius={2} mb={3} p={2}>
      <Box
        display="flex"
        justifyContent="left"
        alignItems="center"
        mb={2}
        gap={1}
      >
        {groupIcon}
        <Typography fontWeight={700}>{title}</Typography>
        {subtitle && <Typography>{subtitle}</Typography>}
      </Box>
      <Divider light sx={{ marginBottom: 2 }} />
      {children}
    </Box>
  )
}
