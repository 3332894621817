import { makeStyles } from 'tss-react/mui'
import { alpha } from '@mui/material'
import { tableCellClasses } from '@mui/material/TableCell'

import { chipClasses } from '@mui/material/Chip'

export const useStyles = makeStyles()((theme) => {
  return {
    row: {
      borderBottom: `2px solid ${alpha(theme.palette.text.primary, 0.1)}`,
      [`& .${tableCellClasses.head}`]: {
        color: theme.palette.text.secondary,
        fontWeight: 700,
      },
      [`& .${tableCellClasses.body}`]: {
        color: theme.palette.text.secondary,
      },
    },
    img: {
      borderRadius: '15%',
      maxWidth: 50,
    },
    chip: {
      padding: 8,
      width: '140px',
      fontWeight: 600,

      svg: {
        fontSize: '14px !important',
        color: 'inherit !important',
      },

      [`& .${chipClasses.label}`]: {
        fontSize: '10px',
      },
    },
  }
})
