import io, { Socket } from 'socket.io-client'

export interface SocketSubscriptionOptions {
  namespace?: string
  token?: string
  host?: string
}

/**
 * Creates a new connection base in the namespace provided
 * @class SocketSubscriptionService
 * @service
 */

class SocketSubscriptionService {
  private socket: Socket
  private path: string = ''
  private namespace: string
  private host: string
  private token: string

  constructor({ host, namespace, token }: SocketSubscriptionOptions) {
    this.namespace = namespace ? `/${namespace}` : ''
    this.host =
      host || JSON.parse(localStorage.getItem('gamanzaengage_serviceUrl'))
    this.path = `${this.host}${this.namespace}`
    this.token =
      token || JSON.parse(localStorage.getItem('gamanzaengage_token'))
    this.connect()
  }

  public connect = () => {
    this.setSocket()
  }

  public getSocket = () => {
    return this.socket
  }

  public disconnect = async () => {
    if (!this.socket) {
      throw new Error(`ws:missing_socket`)
    }
    this.socket.disconnect()
  }

  /**
   * @description set the Socket instance
   * @memberof SocketIoService
   */
  private setSocket = async () => {
    this.socket = io(this.path, {
      transports: ['websocket'],
      query: {
        authorization: `Bearer ${this.token}`,
      },
      autoConnect: true,
      reconnectionDelay: 1000,
    })
  }
}

export default SocketSubscriptionService
