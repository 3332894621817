import { createAsyncThunk } from '@reduxjs/toolkit'
import Container from 'typedi'
import { Rest } from '@/utils/api.utils'

import { PlayerModels, WidgetEndpoints } from '@/state'
import { PlayerActionTypes } from '../constants'

const apiService: Rest = Container.get(Rest)

export const getGamificationOptStatusThunk = createAsyncThunk(
  PlayerActionTypes.GET_GAMIFICATION_OPT_STATUS,
  async (_) => {
    try {
      const response: PlayerModels.GetGamificationOptStatusResponse =
        await apiService.sendData({
          method: 'GET',
          endpoint: WidgetEndpoints.GET_GAMIFICATION_OPT_STATUS,
        })

      return response
    } catch (error) {
      throw error
    }
  },
)
