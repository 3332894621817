import { ActiveBoosterType } from '@/state/my-booster/models'
import { RewardShopBoosterTypeEnum } from '@/enums'
import { ActiveBoostersColors } from '@/constants'

type Props = {
  Div: Element
  activeBoosters: ActiveBoosterType[]
}

export const useHideBoostersItem = ({ Div, activeBoosters }: Props) => {
  const missionColor =
    Div.getAttribute('data-mission-base') ||
    ActiveBoostersColors[RewardShopBoosterTypeEnum.MISSION].baseColor
  const levelColor =
    Div.getAttribute('data-level-base') ||
    ActiveBoostersColors[RewardShopBoosterTypeEnum.LEVEL].baseColor
  const tokenColor =
    Div.getAttribute('data-token-base') ||
    ActiveBoostersColors[RewardShopBoosterTypeEnum.TOKEN].baseColor

  const getBoosterColor = (boosterType: string) => {
    switch (boosterType) {
      case RewardShopBoosterTypeEnum.MISSION:
        return missionColor
      case RewardShopBoosterTypeEnum.LEVEL:
        return levelColor
      case RewardShopBoosterTypeEnum.TOKEN:
        return tokenColor
      default:
        return levelColor
    }
  }

  const countByType = activeBoosters.reduce((count, item) => {
    if (!count[item.boosterType]) {
      count[item.boosterType] = 1
    } else {
      count[item.boosterType]++
    }
    return count
  }, {})

  const percentagesByBoosterType = Object.keys(countByType).map(
    (boosterType) => ({
      color: getBoosterColor(boosterType),
      percentage: (countByType[boosterType] / activeBoosters.length) * 100,
    }),
  )

  const DEFAULT_POSITION = 90

  return {
    DEFAULT_POSITION,
    percentagesByBoosterType,
  }
}
