export enum SocketEventsEnum {
  ACK = 'send:ack',
  CONNECTED = 'on:connected',
  ERROR = 'on:error',
  RANKS_LEVELUP = 'ranks:on:levelUp',
  RANKS_RANKUP = 'ranks:on:rankUp',
  RANKS_XPUP = 'ranks:on:xpUp',
  WALLET_UPDATE = 'virtualCurrency:on:walletUpdate',
  TOKEN_BOOSTER_ACTIVATED = 'virtualCurrency:on:tokenBoosterActivated',
  TOKEN_BOOSTER_END = 'virtualCurrency:on:tokenBoosterEnd',
  LEVEL_BOOSTER_ACTIVATED = 'ranks:on:levelBoosterActivated',
  LEVEL_BOOSTER_END = 'ranks:on:levelBoosterEnd',
  ONSITE_NOTIFICATION = 'messageGateway:on:sendOnSiteNotification',
  ONSITE_SEND_EVENT = 'send:analytics:event',
  OPT_IN = 'ranks:on:optIn',
  OPT_OUT = 'ranks:on:optOut',
  MISSION_OBJECTIVE_UPDATE = 'missions:on:objectiveUpdate',
  MISSION_OBJECTIVE_COMPLETE = 'missions:on:objectiveComplete',
  MISSION_UPDATE = 'missions:on:missionUpdate',
  MISSION_COMPLETE = 'missions:on:missionComplete',
  MISSION_BUNDLE_COMPLETE = 'missions:on:missionBundleComplete',
}
