import { useTranslation } from 'react-i18next'
import { Box, Button, ButtonProps, Typography } from '@mui/material'
import { ContentIconComponent } from '@/components/common'

import { useRewardShop } from '@/components/reward-shop'

export const OrderResponseComponent = () => {
  const { t } = useTranslation()

  const {
    orderProduct,
    gamificationPlayer,
    handleContinueShopping,
    handleTryAgain,
  } = useRewardShop()

  const buttonProps: ButtonProps = {
    color: 'primary',
    variant: 'outlined',
    sx: { width: '100%', padding: '10px 0' },
  }

  return (
    <ContentIconComponent
      iconType={orderProduct.message ? 'error' : 'success'}
      title={orderProduct.message ? t('paymentError') : t('paymentSuccess')}
      message={
        orderProduct.message ? orderProduct.message : t('paymentMessageSuccess')
      }
      buttonContent={
        orderProduct.message ? (
          <Button {...buttonProps} onClick={handleTryAgain}>
            {t('tryAgain')}
          </Button>
        ) : (
          <>
            <Button {...buttonProps} onClick={handleContinueShopping}>
              {t('continueShopping')}
            </Button>
            <Box
              sx={{
                textAlign: 'center',
                color: 'primary.main',
                marginTop: '30px',
              }}
            >
              <Typography variant="body2">{t('remainingTokens')}</Typography>
              <Typography fontWeight={700} sx={{ paddingTop: '5px' }}>
                {gamificationPlayer.tokens}
              </Typography>
            </Box>
          </>
        )
      }
    />
  )
}
