import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import {
  MissionHistoryThunks,
  MissionHistoryModels,
} from '@/state/mission-history'

export const getActiveBundlesExtraReducer = (
  builder: ActionReducerMapBuilder<MissionHistoryModels.MissionHistoryState>,
) => {
  builder.addCase(
    MissionHistoryThunks.getActiveBundlesThunk.pending,
    (state) => {
      state.isLoading = true
    },
  )
  builder.addCase(
    MissionHistoryThunks.getActiveBundlesThunk.fulfilled,
    (state, { payload }) => {
      const { statusCode, ...bundles } = payload
      const activeBundles = []
      Object.keys(bundles).forEach((key) => {
        if (!bundles[key]._id) {
          delete bundles[key]
        } else {
          activeBundles.push(bundles[key])
        }
      })
      state.missionActiveBundles = activeBundles
      state.isLoading = false
    },
  )
  builder.addCase(
    MissionHistoryThunks.getActiveBundlesThunk.rejected,
    (state) => {
      state.isLoading = false
    },
  )
}
