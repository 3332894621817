import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { OnSiteModels } from '../'

const initialState: OnSiteModels.OnSiteNotificationState = {
  onsiteNotifications: [],
}

export const onSiteSlice = createSlice({
  name: 'onSite',
  initialState,
  reducers: {
    addOnSiteNotification(
      state,
      action: PayloadAction<OnSiteModels.SocketOnSiteNotificationResponse>,
    ) {
      state.onsiteNotifications = [...state.onsiteNotifications, action.payload]
    },
    removeOnSiteNotification(state, action: PayloadAction<string>) {
      state.onsiteNotifications = state.onsiteNotifications.filter(
        (notification) => notification.messageId !== action.payload,
      )
    },
  },
})

export const OnSiteActions = onSiteSlice.actions
