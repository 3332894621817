import { createAsyncThunk } from '@reduxjs/toolkit'
import Container from 'typedi'
import { Rest } from '@/utils/api.utils'

import { MyBoosterModels, WidgetEndpoints } from '@/state'
import { MyBoosterActionTypes } from '../constants'

const apiService: Rest = Container.get(Rest)

export const getAllMyBoostersThunk = createAsyncThunk(
  MyBoosterActionTypes.GET_MY_BOOSTERS,
  async (payload: MyBoosterModels.GetAllBoostersPayload, thunkAPI) => {
    try {
      const response: MyBoosterModels.GetAllBoostersResponse =
        await apiService.sendData({
          method: 'GET',
          endpoint: WidgetEndpoints.REWARD_PROCESSOR,
          queries: payload,
        })

      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
