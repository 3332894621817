import { createAsyncThunk } from '@reduxjs/toolkit'
import Container from 'typedi'
import { Rest } from '@/utils/api.utils'

import { MyBoosterModels, WidgetEndpoints } from '@/state'
import { MyBoosterActionTypes } from '../constants'

const apiService: Rest = Container.get(Rest)

export const getLevelActiveBoostersThunk = createAsyncThunk(
  MyBoosterActionTypes.GET_LEVEL_ACTIVE_BOOSTERS,
  async (_, thunkAPI) => {
    try {
      const response: MyBoosterModels.GetActiveBoostersResponse =
        await apiService.sendData({
          method: 'GET',
          endpoint: WidgetEndpoints.LEVEL_ACTIVE_BOOSTER,
        })

      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
