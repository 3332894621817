import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Lottie from 'lottie-react'

import { Box, Button, Slide, Typography } from '@mui/material'
import { NotificationTypeEnum } from '@/enums'
import { GeneralModels, RootState } from '@/state'

import { useBreakpoints, useGlobalUi, usePlayerData } from '@/hooks'
import { RewardType } from '@/state/player/models'
import { ModalCounterComponent, ModalRewardComponent } from './'

import { useNotificationFinal } from '../hooks'
import { useStyles } from './styles'

type Props = {
  finalAnimationData: GeneralModels.LottieAnimationData
}

export const ModalStatusFinalComponent = ({ finalAnimationData }: Props) => {
  const { t } = useTranslation()

  const { modal, handleCloseModal } = useGlobalUi()

  const { gamificationPlayer, earnedRewards } = useSelector(
    ({ player }: RootState) => player,
  )

  const { assetsRoute } = useSelector(
    ({ globalSettings }: RootState) => globalSettings,
  )

  const {
    containerRef,
    iconList,
    isVisibleRewards,
    isVisibleButton,
    level,
    tokens,
    rankName,
    xpBalance,
  } = useNotificationFinal()

  const { playerRankName } = usePlayerData()

  const { classes } = useStyles()

  const { isMobile } = useBreakpoints()

  return (
    <>
      <Box className={classes.finalContainerTop}>
        <Slide
          direction="down"
          in={true}
          container={containerRef.current}
          timeout={750}
        >
          <Box className={classes.finalBoxBackground}>
            <Box className={classes.finalBoxBackgroundImage}>
              <img
                src={`${assetsRoute}/casino-ui-widgets/assets/images/modal-background.svg`}
                alt="modal background"
              />
            </Box>
          </Box>
        </Slide>
        <Box display="flex" flexDirection="column" width="100%">
          <Box
            width={
              isMobile
                ? 140
                : modal.type === NotificationTypeEnum.RANK_UP
                  ? 240
                  : 190
            }
            className={classes.finalAnimation}
          >
            <Lottie animationData={finalAnimationData} loop />
            {modal.type === NotificationTypeEnum.LEVEL_UP && (
              <Box className={classes.finalLevelAnimation}>
                <Typography className={classes.finalLevelValue}>
                  {gamificationPlayer.level.levelNumber}
                </Typography>
              </Box>
            )}
          </Box>
          <Typography
            marginTop={
              modal.type === NotificationTypeEnum.RANK_UP && !isMobile ? -5 : 0
            }
            className={classes.finalTitle}
          >
            {modal.type === NotificationTypeEnum.RANK_UP
              ? playerRankName
              : t('levelUp').toUpperCase()}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.finalContainerBottom}>
        <Box mt={2}>
          <Box className={classes.counters}>
            <ModalCounterComponent
              counterTitle={t('xpPoints')}
              counterValue={xpBalance}
              icon={iconList.xpPoints}
              appears={100}
            />

            {modal.type === NotificationTypeEnum.RANK_UP ? (
              <ModalCounterComponent
                counterTitle={t('rank')}
                counterValue={rankName}
                icon={iconList.ranks}
                appears={300}
              />
            ) : (
              <ModalCounterComponent
                counterTitle={t('level')}
                counterValue={level}
                icon={iconList.levels}
                appears={300}
              />
            )}

            <ModalCounterComponent
              counterTitle={t('tokens')}
              counterValue={tokens}
              icon={iconList.tokens}
              appears={500}
            />
          </Box>
          <Box className={classes.rewardsAndButtonContainer}>
            <Slide
              direction="up"
              in={isVisibleRewards}
              container={containerRef.current}
              timeout={1000}
            >
              <Box>
                {earnedRewards?.length > 0 ? (
                  <>
                    <Typography className={classes.finalCongratulations}>
                      {t('congratulationsRewards', {
                        type:
                          modal.type === NotificationTypeEnum.RANK_UP
                            ? playerRankName
                            : `${t('level')} ${
                                gamificationPlayer.level.levelNumber
                              }`,
                      })}
                    </Typography>
                    <Box className={classes.rewardList}>
                      {earnedRewards.map((reward: RewardType) => (
                        <ModalRewardComponent
                          reward={reward}
                          key={reward.type}
                        />
                      ))}
                    </Box>
                  </>
                ) : (
                  <Box className={classes.finalCongratulationsEmpty}>
                    <Typography className={classes.finalCongratulations}>
                      {t('congratulationsWithoutRewards', {
                        type:
                          modal.type === NotificationTypeEnum.RANK_UP
                            ? playerRankName
                            : `${t('level')} ${
                                gamificationPlayer.level.levelNumber
                              }`,
                      })}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Slide>
            <Slide
              direction="up"
              in={isVisibleButton}
              container={containerRef.current}
              timeout={1000}
            >
              <Box className={classes.continueLink}>
                <Button
                  className={classes.continueLinkButton}
                  variant="outlined"
                  onClick={handleCloseModal}
                >
                  {t('continuePlaying')}
                </Button>
              </Box>
            </Slide>
          </Box>
        </Box>
      </Box>
    </>
  )
}
