import { useSelector } from 'react-redux'
import { Box, CircularProgress, Divider, Typography } from '@mui/material'
import { RootState } from '@/state'

import { ActiveBundleComponent } from './active-bundle.component'

export const MissionBundlesComponent = () => {
  const { missionActiveBundles, isLoading } = useSelector(
    ({ missionHistory }: RootState) => missionHistory,
  )

  const filteredMissionBundles = missionActiveBundles?.filter(
    (bundle) => bundle.endDate >= new Date().toISOString(),
  )

  return (
    <>
      {filteredMissionBundles.length > 0 && !isLoading ? (
        <Box>
          {filteredMissionBundles.map((bundle, index) => {
            return (
              <Box mb={2} key={bundle._id}>
                <ActiveBundleComponent bundle={bundle} />
                {index < missionActiveBundles.length - 1 && (
                  <Divider light={false} sx={{ marginTop: 3 }} />
                )}
              </Box>
            )
          })}
        </Box>
      ) : (
        <>
          {isLoading ? (
            /* TODO: In the future we will add a loading state, when we have the design */
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                minHeight: 300,
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            /* TODO: Replace in the future when we have the design to the empty state */
            <Typography>
              {'There are no active missions bundles right now'}
            </Typography>
          )}
        </>
      )}
    </>
  )
}
