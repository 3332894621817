import { useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { RootState } from '@/state'

import { SidebarHeaderComponent } from './header'
import { useGlobalStyles } from './global.style'
import { SidebarMenuComponent } from './menu'

import { useSidebarActions } from '../hooks'
import { SidebarContentComponent } from './content'

type Props = {
  Div: Element
}

export const SidebarComponent = ({ Div }: Props) => {
  const { client } = useSelector(({ initConfig }: RootState) => initConfig)
  const { images } = useSelector(({ assets }: RootState) => assets)

  const sidebarClient = client && client === 'JP' ? client : 'STANDARD'

  const {
    openSidebar,
    sidebarMenuItem,
    handleOpenSidebar,
    handleChangeMenuItem,
  } = useSidebarActions()

  const { classes } = useGlobalStyles({
    openSidebar,
    backgroundImage: images.sidebar_image,
  })

  return (
    <Box className={classes.sidebarWrapper}>
      <SidebarHeaderComponent
        Div={Div}
        sidebarClient={sidebarClient}
        sidebarMenuItem={sidebarMenuItem}
        handleOpenSidebar={handleOpenSidebar}
        openSidebar={openSidebar}
      />
      <SidebarContentComponent
        sidebarMenuItem={sidebarMenuItem}
        openSidebar={openSidebar}
        handleChangeMenuItem={handleChangeMenuItem}
      />
      {openSidebar && (
        <SidebarMenuComponent
          sidebarClient={sidebarClient}
          sidebarMenuItem={sidebarMenuItem}
          handleChangeMenuItem={handleChangeMenuItem}
        />
      )}
    </Box>
  )
}
