import { makeStyles } from 'tss-react/mui'
import { alpha, linearProgressClasses, typographyClasses } from '@mui/material'
import {
  MikadoBlackFontStyle,
  MikadoFontStyle,
  MissionColors,
} from '@/constants'

interface Props {
  client?: string
}

export const useDialogObjectivesStyles = makeStyles<Props>()((
  theme,
  { client },
) => {
  return {
    dialogObjetivesWrapper: {
      marginTop: 10,
    },

    dialogObjectivesContent: {
      display: 'flex',
      flexDirection: 'column',
      gap: 13,
      maxHeight: 260,
      height: '100%',
      overflow: 'scroll',
      marginTop: 10,
      paddingBottom: 10,
    },

    dialogObjetive: {
      backgroundColor:
        MissionColors[client].DIALOG_MISSION.backgroundObjectives,
      borderRadius: 8,
      padding: '8px 16px',
      display: 'flex',
      gap: 20,
      boxShadow: `0px 5px 0px 0px ${alpha(theme.palette.common.black, 0.2)}`,

      '& .objectiveDescription': {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      },

      [`& .${typographyClasses.body1}`]: {
        fontSize: 12,
        ...MikadoBlackFontStyle,
      },

      '& .objectiveProgressBar': {
        width: 'calc(100% - 5px)',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        padding: '2px',
        borderRadius: 16,
        marginTop: 5,
        background:
          MissionColors[client].DIALOG_MISSION.backgroundLinearObjective,

        '& .linearBorder': {
          width: 'calc(100% - 6px)',
          position: 'absolute',
          top: 3,
          left: 0,
          right: 0,
          margin: '0px auto',
          borderTop: `6.5px solid ${alpha(theme.palette.common.white, 0.26)}`,
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          zIndex: 1,
        },

        '& .linearBars': {
          display: 'flex',
          width: '100%',
          position: 'absolute',
          height: 13,
          zIndex: 1,

          '& .linearBar': {
            width: 'calc(100% / 6)',
            borderRight: `0.44px solid ${alpha(
              theme.palette.common.black,
              0.15,
            )}`,
          },

          '& .linearBar:last-child': {
            border: 'none',
          },
        },

        [`& .${linearProgressClasses.root}`]: {
          width: '100%',
          height: 13,
          borderRadius: 13,
          backgroundColor:
            MissionColors[client].DIALOG_MISSION.backgroundObjectives,
          border: `2px solid ${MissionColors[client].DIALOG_MISSION.backgroundObjectives}`,

          [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 12,
            backgroundColor: MissionColors[client].DIALOG_MISSION.progressValue,
          },
          [`&.inactive .${linearProgressClasses.bar}`]: {
            backgroundColor:
              MissionColors[client].DIALOG_MISSION.progressValueInactive,
          },
        },

        '& .linearBalance': {
          fontSize: 10,
          position: 'absolute',
          textAlign: 'center',
          zIndex: 2,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 3,
          ...MikadoFontStyle,
        },
      },
    },
  }
})
