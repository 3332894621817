import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { SliderMissionsComponent } from '@/components/common'

import { SidebarMenuItemType } from '@/enums'
import { useMissionsActions } from '@/components/sidebar/hooks'
import { RootState } from '@/state'

type Props = {
  openSidebar: boolean
  handleChangeMenuItem?: (sidebarMenuItem: string) => void
}

export const ActiveMissionsComponent = ({
  openSidebar,
  handleChangeMenuItem,
}: Props) => {
  const { getActiveMissions } = useMissionsActions()

  const { missionsActive } = useSelector(
    ({ missionHistory }: RootState) => missionHistory,
  )

  useEffect(() => {
    getActiveMissions()
  }, []) // eslint-disable-line

  const handleSeeAll = () => {
    handleChangeMenuItem(SidebarMenuItemType.MISSIONS)
  }

  return (
    <>
      {missionsActive.length > 0 && (
        <SliderMissionsComponent
          listMissions={
            openSidebar ? missionsActive : missionsActive.slice(0, 3)
          }
          expanded={openSidebar}
          title={openSidebar ? 'activeMissions' : 'missions'}
          handleSeeAll={handleSeeAll}
        />
      )}
    </>
  )
}
