import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { NotificationTypeEnum } from '@/enums'

import { useGlobalUi } from '@/hooks'
import { GeneralModels, RootState } from '@/state'
import { getAssetsUrl, getLottieAnimationData } from '@/utils'

export const useNotificationAnimations = () => {
  const [initialAnimationData, setInitialAnimationData] =
    useState<GeneralModels.LottieAnimationData | null>(null)

  const [finalAnimationData, setFinalAnimationData] =
    useState<GeneralModels.LottieAnimationData | null>(null)

  const { modal, notificationSnackbar } = useGlobalUi()

  const { assetsRoute } = useSelector(
    ({ globalSettings }: RootState) => globalSettings,
  )

  const { client } = useSelector(({ initConfig }: RootState) => initConfig)

  const getInitialAnimationUrl = () => {
    if (modal.type === NotificationTypeEnum.RANK_UP) {
      return getAssetsUrl(
        assetsRoute,
        'assets/animations/notifications/rank.json',
      )
    }

    if (client === 'JP') {
      return getAssetsUrl(
        assetsRoute,
        'assets/animations/notifications/level-jp.json',
      )
    }

    return getAssetsUrl(
      assetsRoute,
      'assets/animations/notifications/level.json',
    )
  }

  const getFinalAnimationUrl = () => {
    if (modal.type === NotificationTypeEnum.RANK_UP) {
      return getAssetsUrl(
        assetsRoute,
        'assets/animations/notifications/rank-loop.json',
      )
    }

    if (client === 'JP') {
      return getAssetsUrl(
        assetsRoute,
        'assets/animations/notifications/level-loop-jp.json',
      )
    }

    return getAssetsUrl(
      assetsRoute,
      'assets/animations/notifications/level-loop.json',
    )
  }

  useEffect(() => {
    if (modal.type && notificationSnackbar.open) {
      getLottieAnimationData(getInitialAnimationUrl(), setInitialAnimationData)
    }

    if (modal.type && notificationSnackbar.open) {
      getLottieAnimationData(getFinalAnimationUrl(), setFinalAnimationData)
    }
  }, [notificationSnackbar, modal]) // eslint-disable-line

  return {
    initialAnimationData,
    finalAnimationData,
  }
}
