import { createAsyncThunk } from '@reduxjs/toolkit'
import Container from 'typedi'
import { Rest } from '@/utils/api.utils'

import { GlobalSettingsModels, WidgetEndpoints, store } from '@/state'
import { insertMikadoFont } from '@/utils'
import { GlobalSettingsEnum } from '@/enums'

import { GlobalSettingsActionTypes } from '../constants'

const apiService: Rest = Container.get(Rest)

const setAssetsRoute = (assetsRoute: string) => {
  if (assetsRoute) {
    insertMikadoFont(assetsRoute)
  }
}

export const getGlobalSettingsThunk = createAsyncThunk(
  GlobalSettingsActionTypes.GET_GLOBAL_SETTINGS,
  async () => {
    try {
      const { assetsRoute } = store.getState().globalSettings

      setAssetsRoute(assetsRoute)

      const response: GlobalSettingsModels.GetGamificationSettingsResponse[] =
        await apiService.sendData({
          method: 'GET',
          endpoint: WidgetEndpoints.GET_GAMIFICATION_SETTINGS,
        })

      if (response && !assetsRoute) {
        const responseRoute =
          Object.values(response).find(
            (item) => item?.key === GlobalSettingsEnum.ASSETS_ROUTE,
          )?.value || ''

        setAssetsRoute(responseRoute as string)
      }

      return response
    } catch (error) {
      throw error
    }
  },
)
