import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  DateFilterEnum,
  GeneralStatusExtendedEnum,
  MissionStatusEnum,
} from '@/enums'
import { dateFilters } from '@/constants'

import {
  claimEasterEggExtraReducer,
  getAllMissionBundlesExtraReducer,
  getOneMissionBundleExtraReducer,
  updateBundleStatusExtraReducer,
  getActiveBundlesExtraReducer,
  getActiveMissionsExtraReducer,
  getExpiredMissionsExtraReducer,
} from './'
import { MissionHistoryModels, MissionModels } from '../'

export const missionHistoryInitialState: MissionHistoryModels.MissionHistoryState =
  {
    isLoading: false,
    pagination: { total: 0, limit: 25, page: 1, pages: 0 },
    filters: {
      limit: 5,
      page: 1,
      date: DateFilterEnum.LIFETIME,
      startDate: dateFilters[DateFilterEnum.LIFETIME].dateStart,
      endDate: dateFilters[DateFilterEnum.LIFETIME].dateEnd,
      status: [
        GeneralStatusExtendedEnum.ACTIVE,
        GeneralStatusExtendedEnum.PAUSED,
      ],
    },
    missionActiveBundles: [],
    missionsActive: [],
    missionsExpired: [],
    missionHistoryBundles: [],
    currentHistoryBundles: [],
  }

const getUpdatedMissionObjectives = (
  stateMissions:
    | MissionModels.MissionsByBundleType[]
    | MissionModels.MissionType[],
  objective: MissionHistoryModels.SocketObjectiveType,
) => {
  return stateMissions.map(
    (
      mission: MissionModels.MissionsByBundleType | MissionModels.MissionType,
    ) => {
      const objectives = mission.objectives.map((itemObjetive) =>
        itemObjetive.originalId === objective.objectiveId &&
        itemObjetive.missionId === objective.missionId
          ? {
              ...itemObjetive,
              state: objective.objectiveSummary.state,
              value: objective.objectiveSummary.value,
              completed: objective.objectiveSummary.completed,
            }
          : itemObjetive,
      )

      return {
        ...mission,
        objectives,
      }
    },
  )
}

const getUpdatedMission = (
  stateMissions:
    | MissionModels.MissionsByBundleType[]
    | MissionModels.MissionType[],
  mission: MissionHistoryModels.SocketMissionType,
) => {
  return stateMissions.map(
    (
      itemMission:
        | MissionModels.MissionsByBundleType
        | MissionModels.MissionType,
    ) =>
      itemMission._id === mission._id
        ? {
            ...itemMission,
            state: mission.state,
            status: mission.status,
          }
        : itemMission,
  )
}

export const missionHistorySlice = createSlice({
  name: 'missionHistory',
  initialState: missionHistoryInitialState,
  reducers: {
    setFilters(
      state,
      action: PayloadAction<MissionHistoryModels.MissionHistoryFiltersType>,
    ) {
      state.filters = action.payload
    },
    updateMissionObjective(
      state,
      action: PayloadAction<MissionHistoryModels.SocketUpdateObjectiveResponse>,
    ) {
      const objective = action.payload.payload

      const newMissionsActive = getUpdatedMissionObjectives(
        state.missionsActive,
        objective,
      )

      const newMissionActiveBundles = state.missionActiveBundles.map(
        (bundle) => {
          const bundleMissionsUpdated = getUpdatedMissionObjectives(
            bundle.missionIds,
            objective,
          )
          return { ...bundle, missionIds: bundleMissionsUpdated }
        },
      )

      state.missionsActive =
        newMissionsActive as MissionModels.MissionsByBundleType[]
      state.missionActiveBundles = newMissionActiveBundles
    },
    updateMission(
      state,
      action: PayloadAction<MissionHistoryModels.SocketUpdateMissionResponse>,
    ) {
      const mission = action.payload.payload

      state.missionActiveBundles = (
        mission.status !== MissionStatusEnum.ENDED
          ? state.missionActiveBundles.map((bundle) => {
              if (bundle._id === mission.missionBundleId) {
                const bundleMissionsUpdated = getUpdatedMission(
                  bundle.missionIds,
                  mission,
                )
                return { ...bundle, missionIds: bundleMissionsUpdated }
              }

              return bundle
            })
          : state.missionActiveBundles.filter(
              (itemMission) => itemMission._id !== mission.missionId,
            )
      ) as MissionHistoryModels.MissionHistoryBundleDetailsType[]

      state.missionsActive = (
        getUpdatedMission(
          state.missionsActive,
          mission,
        ) as MissionModels.MissionsByBundleType[]
      ).filter(
        (mission) => mission.status !== GeneralStatusExtendedEnum.COMPLETED,
      )
    },
  },
  extraReducers: (builder) => {
    getAllMissionBundlesExtraReducer(builder)
    getOneMissionBundleExtraReducer(builder)
    updateBundleStatusExtraReducer(builder)
    claimEasterEggExtraReducer(builder)
    getActiveBundlesExtraReducer(builder)
    getActiveMissionsExtraReducer(builder)
    getExpiredMissionsExtraReducer(builder)
  },
})

export const MissionHistoryActions = missionHistorySlice.actions
