import { makeStyles } from 'tss-react/mui'
import { darken, dialogClasses, typographyClasses } from '@mui/material'
import { MikadoFontStyle } from '@/constants'

export const useDialogMissionStyles = makeStyles()((theme) => {
  return {
    dialogMission: {
      [`& .${dialogClasses.paper}`]: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        padding: '0 50px',
        overflow: 'hidden',

        [theme.breakpoints.down('sm')]: {
          padding: '0 30px',
        },

        '@media only screen and (max-width: 430px)': {
          padding: '0 10px',
        },
      },
    },
    dialogMissionWrapper: {
      width: 350,
      height: 'auto',
      paddingBottom: 20,
      paddingTop: 10,

      [theme.breakpoints.down('sm')]: {
        width: 300,
      },

      [theme.breakpoints.down('xs')]: {
        width: 'calc(100% - 10px)',
      },
    },
    dialogMissionHeader: {
      position: 'relative',

      [theme.breakpoints.down('xs')]: {
        width: 'calc(100% + 10px)',
      },

      '& .imgDialogBorder': {
        position: 'absolute',
        top: 10,

        [theme.breakpoints.down('sm')]: {
          svg: {
            width: 302,
          },
        },

        [theme.breakpoints.down('xs')]: {
          width: '100%',

          svg: {
            width: '100%',
          },
        },
      },
      '& .imgDialogHeader': {
        position: 'absolute',
        top: 15,

        [theme.breakpoints.down('sm')]: {
          svg: {
            width: 302,
          },
        },

        [theme.breakpoints.down('xs')]: {
          width: '100%',

          svg: {
            width: '100%',
          },
        },
      },
      '& .imgDialogDecorationLeft': {
        position: 'absolute',
        top: 35,
        left: -41,
        zIndex: -1,

        [theme.breakpoints.down('sm')]: {
          left: -30,
        },

        '@media only screen and (max-width: 430px)': {
          display: 'none',
        },
      },
      '& .imgDialogDecorationRight': {
        position: 'absolute',
        top: 35,
        right: -44,
        zIndex: -1,

        [theme.breakpoints.down('sm')]: {
          right: -33,
        },

        '@media only screen and (max-width: 430px)': {
          display: 'none',
        },
      },
      '& .btnCloseDialog': {
        position: 'absolute',
        top: 0,
        right: -15,
        padding: 0,
      },
      [`& .${typographyClasses.body1}`]: {
        position: 'absolute',
        width: '100%',
        height: 80,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 26,
        textTransform: 'uppercase',
        paddingTop: 10,
        ...MikadoFontStyle,
      },
    },
    dialogMissionContent: {
      backgroundColor: theme.palette.common.white,
      width: 318,
      height: '100%',
      margin: '30px auto 0',
      padding: '80px 16px 16px',
      borderRadius: 13,
      boxShadow: `0px 10px 0px 0px ${darken(theme.palette.common.white, 0.25)}`,

      [theme.breakpoints.down('sm')]: {
        width: 270,
      },

      [theme.breakpoints.down('xs')]: {
        padding: '80px 5px 0px',
        width: '100%',
      },
    },
  }
})
