import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Box, CircularProgress, Typography } from '@mui/material'

import { RootState } from '@/state'
import { DialogTypeEnum, UIDialogsEnum } from '@/enums'
import { MissionItemHistoryComponent } from '@/components/common'

import { useGlobalUi } from '@/hooks'
import { useMissionStyles } from './style'

export const MissionHistoryComponent = () => {
  const { t } = useTranslation()
  const { isLoading, missionsExpired } = useSelector(
    ({ missionHistory }: RootState) => missionHistory,
  )

  const { handleOpenDialog } = useGlobalUi()

  const { classes } = useMissionStyles()

  const filteredMissions = missionsExpired?.filter(
    (mission) => mission.endDate < new Date().toISOString(),
  )

  return (
    <>
      {filteredMissions.length > 0 && !isLoading ? (
        <Box className={classes.listMissionsHistory}>
          {filteredMissions.map((mission) => {
            return (
              <MissionItemHistoryComponent
                key={mission._id}
                mission={mission}
                handleClick={() => {
                  handleOpenDialog({
                    id: UIDialogsEnum.MISSION_DETAILS,
                    object: {
                      missionId: mission._id,
                      title: t(
                        `${mission.missionBundleSettings.timeFrame.timeFrameType}Mission`,
                      ),
                      type: DialogTypeEnum.MISSION_HISTORY,
                    },
                  })
                }}
              />
            )
          })}
        </Box>
      ) : (
        <>
          {isLoading ? (
            /* TODO: In the future we will add a loading state, when we have the design */
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                minHeight: 300,
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            /* TODO: Replace in the future when we have the design to the empty state */
            <Typography>{'There are no expired missions right now'}</Typography>
          )}
        </>
      )}
    </>
  )
}
