import { useSelector } from 'react-redux'
import { RootState } from '@/state'
import { DateFilterEnum } from '@/enums'

import { useTableFilter } from '@/hooks'
import { useMissionsHistoryActions } from './'

export const useMissionsHistoryFilters = () => {
  const { filters, pagination } = useSelector(
    ({ missionHistory }: RootState) => missionHistory,
  )

  const { setMissionsHistoryFilters, getAllMissionBundles } =
    useMissionsHistoryActions()

  const {
    handleChangeFilter,
    handleChangePage,
    handleChangeLimit,
    handleChangeSort,
    handleClearFilters,
  } = useTableFilter({
    setFilters: setMissionsHistoryFilters,
    getAll: getAllMissionBundles,
    filters,
    pagination,
  })

  const dateOptions = [
    DateFilterEnum.CURRENT_MONTH,
    DateFilterEnum.LAST_MONTH,
    DateFilterEnum.CURRENT_YEAR,
    DateFilterEnum.LAST_YEAR,
    DateFilterEnum.DATE_RANGE,
  ]

  const handleChangeNextPage = () => {
    handleChangePage(pagination.page + 1)
  }

  const handleChangePrevPage = () => {
    handleChangePage(pagination.page - 1)
  }

  return {
    filters,
    pagination,
    dateOptions,
    handleChangeNextPage,
    handleChangePrevPage,
    handleChangeFilter,
    handleChangeLimit,
    handleChangeSort,
    handleClearFilters,
  }
}
