import { makeStyles } from 'tss-react/mui'
import { SidebarColors } from '@/constants'

interface Props {
  openSidebar?: boolean
  sidebarClient?: string
  isMobile?: boolean
}

export const useHeaderStyles = makeStyles<Props>()((
  theme,
  { openSidebar, sidebarClient, isMobile },
) => {
  return {
    sidebarHeader: {
      background:
        openSidebar && !isMobile
          ? SidebarColors[sidebarClient].HEADER.primaryGradient
          : 'transparent',
      padding: openSidebar ? '16px 16px 58px' : '16px 5px',
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      display: 'flex',
      flexDirection: 'column',
      gap: 12,
      position: 'relative',

      [theme.breakpoints.down('sm')]: {
        padding: '10px 16px',
      },

      '& .sidebarHeaderContent': {
        display: 'flex',
        flexDirection: openSidebar || isMobile ? 'row' : 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: openSidebar || isMobile ? 12 : 24,

        [theme.breakpoints.down('sm')]: {
          justifyContent: 'left',
        },
      },
    },
  }
})
