import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { Box } from '@mui/material'
import { FullPageLoader } from '@/components/common'
import { RootState } from '@/state'

import { useMissionsHistoryActions } from '../hooks'
import { DialogMission, MissionsHistoryPanelComponent } from './'

export const MissionsHistoryComponent = () => {
  const { getAllMissionBundles, setMissionsHistoryFilters } =
    useMissionsHistoryActions()

  const { filters } = useSelector(
    ({ missionHistory }: RootState) => missionHistory,
  )

  const { playerId } = useSelector(({ initConfig }: RootState) => initConfig)

  const { isLoadingFull } = useSelector(({ globalUI }: RootState) => globalUI)

  useEffect(() => {
    getAllMissionBundles({
      ...filters,
      playerId,
    })
    setMissionsHistoryFilters({
      ...filters,
      playerId,
    })
  }, [playerId]) // eslint-disable-line

  return (
    <Box maxWidth={800}>
      {/* TODO: This component will be added in the next PR
      <Box mb={2}>
        <MissionsHistoryFiltersComponent />
      </Box> */}
      <FullPageLoader open={isLoadingFull} />
      <MissionsHistoryPanelComponent />
      <DialogMission />
    </Box>
  )
}
