import { useSelector } from 'react-redux'
import { ReactComponent as IconToken } from '@/assets/icons/icon-token.svg'
import { ReactComponent as IconTokenJP } from '@/assets/icons/icon-token-jp.svg'

import { ReactComponent as IconRank } from '@/assets/icons/icon-rank.svg'
import { ReactComponent as IconRankJP } from '@/assets/icons/icon-rank-jp.svg'
import { ReactComponent as IconLevel } from '@/assets/icons/icon-level.svg'

import { ReactComponent as IconLevelJP } from '@/assets/icons/icon-level-jp.svg'
import { ReactComponent as IconXp } from '@/assets/icons/icon-xp.svg'
import { ReactComponent as IconXpJP } from '@/assets/icons/icon-xp-jp.svg'

import { RootState } from '@/state'

type Props = {
  size?: number
  type: 'token' | 'rank' | 'level' | 'xp'
}

export function IconComponent({ size, type }: Props) {
  const { client } = useSelector(({ initConfig }: RootState) => initConfig)

  const dimension = size ?? 22

  return (
    <>
      {client === 'JP' ? (
        <>
          {type === 'level' ? (
            <IconLevelJP height={dimension} width={dimension} />
          ) : type === 'rank' ? (
            <IconRankJP height={dimension} width={dimension} />
          ) : type === 'token' ? (
            <IconTokenJP height={dimension} width={dimension} />
          ) : (
            type === 'xp' && <IconXpJP height={dimension} width={dimension} />
          )}
        </>
      ) : (
        <>
          {type === 'level' ? (
            <IconLevel height={dimension} width={dimension} />
          ) : type === 'rank' ? (
            <IconRank height={dimension} width={dimension} />
          ) : type === 'token' ? (
            <IconToken height={dimension} width={dimension} />
          ) : (
            type === 'xp' && <IconXp height={dimension} width={dimension} />
          )}
        </>
      )}
    </>
  )
}
