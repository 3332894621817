import { useSelector } from 'react-redux'
import { Box, Typography } from '@mui/material'
import { RootState } from '@/state'

import { getFormatNumber } from '@/utils'
import { useBreakpoints, usePlayerData } from '@/hooks'
import { IconComponent } from '@/components/common'

import { usePointsStyles } from './style'

type Props = {
  openSidebar: boolean
  sidebarClient: string
}

export function SidebarPointsComponent({ openSidebar, sidebarClient }: Props) {
  const { gamificationPlayer, gamificationOpt } = useSelector(
    ({ player }: RootState) => player,
  )

  const { playerLocale } = useSelector(
    ({ initConfig }: RootState) => initConfig,
  )

  const { playerRankName } = usePlayerData()

  const { isMobile } = useBreakpoints()

  const { classes } = usePointsStyles({ openSidebar, sidebarClient, isMobile })

  type PointCardProps = {
    props: {
      icon: React.ReactNode
      value: string | number
      progress?: React.ReactNode
    }
  }

  function PointCardComponent({
    props: { icon, value, progress },
  }: PointCardProps) {
    const imageIcon = (
      <Box display="flex" justifyContent="center" height={30}>
        {icon}
      </Box>
    )

    return (
      <Box className={classes.pointCardContent}>
        <Box className={classes.pointTopBorder}></Box>
        {imageIcon}
        <Typography component="p" variant="caption">
          {gamificationOpt?.enable ? value : '--'}
        </Typography>
        {progress}
      </Box>
    )
  }

  return (
    <Box display="flex" flexDirection="column">
      <Box className={classes.pointsCardsContent}>
        {((isMobile && openSidebar) || !isMobile) && (
          <>
            <PointCardComponent
              props={{
                icon: <IconComponent type="rank" size={32} />,
                value: playerRankName,
              }}
            />
            <PointCardComponent
              props={{
                icon: <IconComponent type="xp" size={32} />,
                value: getFormatNumber(
                  gamificationPlayer.xpBalance,
                  playerLocale,
                ),
              }}
            />
          </>
        )}
        <PointCardComponent
          props={{
            icon: <IconComponent type="token" size={32} />,
            value: getFormatNumber(gamificationPlayer.tokens, playerLocale),
          }}
        />
      </Box>
    </Box>
  )
}
