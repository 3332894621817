import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import { MissionModels } from '@/state'

import { ReactComponent as IconBagRewards } from '@/assets/icons/icon-bag-normal.svg'
import { ReactComponent as IconInfoRewards } from '@/assets/icons/icon-info-rewards.svg'
import { TooltipRewardsComponent } from '@/components/common/tooltip-rewards'

import { useDialogProgressStyles } from './progress.style'

type Props = {
  rewards: MissionModels.RewardCategoryType[]
  client: string
}

export const DialogMissionRewardsComponent = ({ rewards, client }: Props) => {
  const { t } = useTranslation()

  const { classes } = useDialogProgressStyles({ client })

  return (
    <>
      {rewards.length > 0 && (
        <Box className={classes.dialogMissionRewards}>
          <Typography>{`${t('rewards')}:`}</Typography>
          <TooltipRewardsComponent rewards={rewards}>
            <Box className="bagRewards">
              <Box className="iconBagRewards">
                <IconBagRewards />
              </Box>
              <Box className="iconBagInfo">
                <IconInfoRewards />
              </Box>
            </Box>
          </TooltipRewardsComponent>
        </Box>
      )}
    </>
  )
}
