import { makeStyles } from 'tss-react/mui'
import { alpha, linkClasses } from '@mui/material'
import { iconClasses } from '@mui/material/Icon'

import { svgIconClasses } from '@mui/material/SvgIcon'
import { tabClasses } from '@mui/material/Tab'

export const useStyles = makeStyles()((theme) => {
  return {
    disabledButton: {
      color: `${theme.palette.primary.main} !important`,
      border: `1px solid ${theme.palette.primary.main} !important`,
      opacity: 0.5,
    },

    breadcrumbsContent: {
      marginTop: '16px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: '15px',

      '& button': {
        backgroundColor: `${theme.palette.background.paper} !important`,
        color: theme.palette.text.primary,
        width: '36px',
        height: '36px',
        borderRadius: '12px',
        minWidth: 'auto',

        [theme.breakpoints.up('md')]: {
          width: '56px',
          height: '56px',
        },

        '& svg': {
          width: '20px',
          height: '20px',
        },
      },

      '& .breadcrumbLink': {
        color: alpha(theme.palette.text.primary, 0.5),
        letterSpacing: '0.75px',

        [`& .${linkClasses.root}`]: {
          color: alpha(theme.palette.text.primary, 0.5),
          textDecorationColor: 'initial',
          cursor: 'pointer',
        },
      },
    },
    tabContent: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

      [`& .${iconClasses.root} img`]: {
        width: '100%',
        height: '100%',
      },
      [`& .${svgIconClasses.root}`]: {
        width: '40px',
        height: '40px',
        color: theme.palette.primary.main,
        borderRadius: '12px',
        padding: '7px',
        background: alpha(theme.palette.primary.main, 0.1),
      },
      [`& .${tabClasses.iconWrapper}`]: {
        margin: 0,
      },
      '& .tabLabel': {
        marginLeft: 0,
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'row',
        textTransform: 'capitalize',

        [theme.breakpoints.up('md')]: {
          marginLeft: '10px',
        },
      },
      '& .boostersCount': {
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        borderRadius: '8px',
        marginLeft: '5px',
        width: '25px',
        height: '25px',
      },
    },
  }
})
