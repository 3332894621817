import { useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '@/state'

import { useGamificationMechanicsActions } from './use-gamification-mechanics-actions.hook'

export type FormValues = {
  reason: string
  comment: string
}

export const useGamificationMechanicsModal = () => {
  const { setGamificationMechanicsAction } = useGamificationMechanicsActions()

  const { playerLocale } = useSelector(
    ({ initConfig }: RootState) => initConfig,
  )

  const { isLoading, gamificationOpt } = useSelector(
    ({ player }: RootState) => player,
  )
  const { gamificationReasons } = useSelector(
    ({ globalSettings }: RootState) => globalSettings,
  )

  const [formValues, setFormValues] = useState<FormValues>({
    reason: '',
    comment: '',
  })

  const handleOnChange = (field: string, newValue: string) => {
    setFormValues({ ...formValues, [field]: newValue })
  }

  const handleSubmit = () => {
    setGamificationMechanicsAction({
      enable: !gamificationOpt?.enable,
      reason: gamificationOpt?.enable
        ? formValues.comment !== ''
          ? [
              {
                option: formValues.reason,
                translations: [
                  { language: playerLocale, text: formValues.comment },
                ],
              },
            ]
          : [
              {
                option: formValues.reason,
                translations: [
                  gamificationReasons
                    ?.find((reason) => reason.option === formValues.reason)
                    .translations.find(
                      (trans) =>
                        trans.language.toUpperCase() ===
                        playerLocale.toUpperCase(),
                    ),
                ],
              },
            ]
        : undefined,
    })
    setFormValues({ reason: '', comment: '' })
  }

  return {
    formValues,
    isLoading,
    gamificationOpt,
    gamificationReasons,
    handleOnChange,
    handleSubmit,
  }
}
