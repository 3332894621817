import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { format } from 'date-fns'
import { Box } from '@mui/material'
import { RootState } from '@/state'

import { TimeZoneEnum, TimeZoneUTCEnum } from '@/enums'
import {
  MissionBundlesByGroupComponent,
  useMissionsHistoryFilters,
} from '@/components/mission-history'
import {
  ContentIconComponent,
  PaginationComponent,
  SkeletonLoader,
} from '@/components/common'

import { getDateTimeFromIsoUTC } from '@/utils/date.util'

export const MissionsHistoryPanelComponent = () => {
  const { t } = useTranslation()
  const { isLoading, missionHistoryBundles, pagination } = useSelector(
    ({ missionHistory }: RootState) => missionHistory,
  )

  const [dataFiltered, setDataFiltered] = useState([])

  const { handleChangeNextPage, handleChangePrevPage } =
    useMissionsHistoryFilters()

  useEffect(() => {
    const dateFromTime = 'T00:00:00.000'

    if (!isLoading && missionHistoryBundles) {
      const groupsByPlayer = missionHistoryBundles.reduce((groups, event) => {
        const date = `${format(
          getDateTimeFromIsoUTC(
            event.startDate,
            TimeZoneUTCEnum[TimeZoneEnum.GMT_0],
          ),
          'yyyy-MM-dd',
        )}${dateFromTime}`
          .split('T')[0]
          .split('-')

        date.pop()

        if (!groups[date.join('-')]) {
          groups[date.join('-')] = [] // eslint-disable-line
        }
        groups[date.join('-')].push(event)
        return groups
      }, {})

      const groupArrays = Object.keys(groupsByPlayer).map((date) => {
        return {
          date,
          missionsBundles: groupsByPlayer[date],
        }
      })

      setDataFiltered(groupArrays)
    }
  }, [isLoading, missionHistoryBundles]) // eslint-disable-line

  return (
    <Box mt={4}>
      <>
        {isLoading ? (
          <SkeletonLoader element="table" />
        ) : dataFiltered.length > 0 ? (
          <>
            {dataFiltered.map((mission, index) => (
              <MissionBundlesByGroupComponent
                {...mission}
                key={mission.date}
                last={dataFiltered.length === index + 1}
              />
            ))}
          </>
        ) : (
          <ContentIconComponent
            title={t('missionHistory.noResultsFound')}
            message={t('missionHistory.noResultsFoundCaption')}
            hideButtonContent
            hideIcon
          />
        )}
      </>
      {!isLoading && missionHistoryBundles.length > 0 && (
        <PaginationComponent
          pagination={pagination}
          handleNextPage={handleChangeNextPage}
          handlePrevPage={handleChangePrevPage}
        />
      )}
    </Box>
  )
}
