import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import { uniqBy } from 'lodash'
import { MyBoosterThunks, MyBoosterModels } from '@/state'

import { RewardShopBoosterTypeEnum } from '@/enums'

export const getMissionActiveBoostersExtraReducer = (
  builder: ActionReducerMapBuilder<MyBoosterModels.MyBoosterState>,
) => {
  builder.addCase(
    MyBoosterThunks.getMissionActiveBoostersThunk.fulfilled,
    (state, { payload }) => {
      const missionDocs =
        payload.docs?.map((mission) => {
          return { ...mission, boosterType: RewardShopBoosterTypeEnum.MISSION }
        }) || []
      state.activeBoosters = uniqBy(
        [...state.activeBoosters, ...missionDocs],
        '_id',
      )
    },
  )
}
