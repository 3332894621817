import { useTranslation } from 'react-i18next'
import { Box, CircularProgress, Typography } from '@mui/material'
import { MissionItem } from '@/constants'

import {
  GeneralStatusEnum,
  GeneralStatusExtendedEnum,
  MissionTimeTypeEnum,
} from '@/enums'
import { MissionHistoryModels } from '@/state'
import { ReactComponent as IconGoingArrow } from '@/assets/icons/missions/icon-mission-going-arrow.svg'

import { useRemainingTime } from '@/hooks'
import { useMissionItemStyles } from './style'

type Props = {
  sidebarClient: string
  endDate?: string
  timeType?: MissionTimeTypeEnum
  state?: MissionHistoryModels.MissionProgressType
  expanded?: boolean
  status: GeneralStatusExtendedEnum | GeneralStatusEnum
  handleClick?: () => void
}

export const MissionItemInProgressComponent = ({
  sidebarClient,
  endDate,
  timeType,
  state,
  expanded,
  status,
  handleClick,
}: Props) => {
  const { t } = useTranslation()

  const { classes } = useMissionItemStyles({
    expanded,
    status,
    sidebarClient,
  })

  const { timeRemaining } = useRemainingTime({ endDate })

  return (
    <Box
      className={classes.missionWrapper}
      onClick={() => handleClick && handleClick()}
    >
      <Box className={classes.missionContent}>
        <Box className={`${classes.missionProgress} missionProgress`}>
          <Box className="missionIcon">{MissionItem[status].icon}</Box>
          <CircularProgress
            variant="determinate"
            value={Number(state.progress)}
            size={47}
            thickness={4}
            className="missionCircularProgress"
          />
          <Typography>{`${state.completed}/${state.total}`}</Typography>
          <Box className="missionIconArrow">
            <IconGoingArrow />
          </Box>
        </Box>
        <>
          {expanded && (
            <Typography variant="body2">
              {t(`${MissionItem[status].label}`)}
            </Typography>
          )}
          {timeType !== MissionTimeTypeEnum.ANY_TIME && (
            <Typography variant="caption">{timeRemaining}</Typography>
          )}
        </>
      </Box>
    </Box>
  )
}
