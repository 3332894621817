import {
  Send,
  ShoppingBasket,
  OtherHouses,
  Cancel,
  Error,
} from '@mui/icons-material'
import { colors } from '@mui/material'
import { OrderStatusEnum } from '@/enums'

export const OrderStatusStyles = {
  [OrderStatusEnum.SHIPPED]: {
    icon: <Send />,
    color: colors.purple[800],
  },
  [OrderStatusEnum.PURCHASED]: {
    icon: <ShoppingBasket />,
    color: colors.green[800],
  },
  [OrderStatusEnum.OUT_OF_STOCK]: {
    icon: <Error />,
    color: colors.red[800],
  },
  [OrderStatusEnum.THIRD_PARTY]: {
    icon: <OtherHouses />,
    color: colors.yellow[800],
  },
  [OrderStatusEnum.CANCELLED]: {
    icon: <Cancel />,
    color: colors.grey[800],
  },
}
