import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()((theme) => {
  return {
    jpTabs: {
      border: `1px solid #D9D9D9`,
      padding: '16px 0',
      borderRadius: '8px',
      backgroundColor: '#F0F0F0',

      [theme.breakpoints.down('sm')]: {
        backgroundColor: 'transparent',
        border: 'none',
        padding: '0px',
      },

      '& .GamanzaTabs-flexContainer': {
        justifyContent: 'space-around',

        [theme.breakpoints.down('sm')]: {
          justifyContent: 'center',
          flexWrap: 'wrap',
          gap: '10px',
        },
      },
    },
    jpTabContent: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '&.Mui-selected': {
        '& .tabLabel': {
          color: theme.palette.primary.main,
        },
      },
      '& .tabLabel': {
        marginLeft: 0,
        color: theme.palette.common.black,
        display: 'flex',
        flexDirection: 'row',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: '18px',
        letterSpacing: '-0.5px',

        [theme.breakpoints.up('md')]: {
          marginLeft: '10px',
        },

        [theme.breakpoints.down('md')]: {
          fontSize: '15px',
        },

        [theme.breakpoints.down('sm')]: {
          fontSize: '12px',
        },
      },
      '& .boostersCount': {
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        borderRadius: '8px',
        marginLeft: '5px',
        width: '25px',
        height: '25px',
      },

      [theme.breakpoints.down('sm')]: {
        minWidth: '165px',
        border: `1px solid #D9D9D9`,
        borderRadius: '8px',
        backgroundColor: '#F0F0F0',
      },
    },
    rewardShopDescription: {
      padding: '72px 40px',
      fontSize: '18px',
      textAlign: 'center',

      [theme.breakpoints.down('sm')]: {
        padding: '36px 20px',
        fontSize: '13px',
      },
    },
  }
})
