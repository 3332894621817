import { Chip } from '@mui/material'
import { green, red } from '@mui/material/colors'
import { Lock } from '@mui/icons-material'

import { NameIdType } from '@/state/reward-shop/models'

type Props = {
  items: NameIdType[]
  type: 'ranks' | 'tags'
  size: 'small' | 'medium'
  margins?: string
}

export const ImageChips = ({ items, type, size, margins }: Props) => {
  const color = type === 'ranks' ? red[800] : green[600]

  const backgroundColor = type === 'ranks' ? red[50] : green[50]

  return (
    <>
      {items.map((item: NameIdType) => {
        return (
          <Chip
            key={item.name}
            variant="outlined"
            size={size}
            label={item.name}
            icon={
              type === 'ranks' ? (
                <Lock sx={{ color: `${color}!important` }} />
              ) : undefined
            }
            sx={{
              fontSize: size === 'small' ? '10px' : '13px',
              margin: margins,
              textTransform: 'capitalize',
              fontWeight: 700,
              color,
              backgroundColor,
              border: `1px solid ${color}`,
            }}
          />
        )
      })}
    </>
  )
}
