import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import { PlayerModels, PlayerThunks } from '@/state'

export const getGamificationOptStatusExtraReducer = (
  builder: ActionReducerMapBuilder<PlayerModels.PlayerState>,
) => {
  builder.addCase(
    PlayerThunks.getGamificationOptStatusThunk.fulfilled,
    (state, { payload }) => {
      state.gamificationOpt.enable = payload?.gamificationOpt?.enable
    },
  )
}
