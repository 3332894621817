export const MissionColors = {
  // TODO: At the moment the STANDARD style is equals to JP
  STANDARD: {
    STATUS: {
      CLAIM: {
        backgroundContent: '#ffe36e',
        backgroundWrapper: '#fdc30b',
        shadow: '#cb5705',
      },
      COMPLETED: {
        backgroundContent: '#e0ffbb',
        backgroundWrapper: '#70b202',
        shadow: '#4f7a2a',
      },
      ACTIVE: {
        backgroundContent: '#007dc9',
        backgroundWrapper: '#3babff',
        shadow: '#1f4d91',
        progress: '#fdd160',
      },
      PENDING: {
        backgroundContent: '#a0abc0',
        backgroundWrapper: '#717d96',
        shadow: '#4a5468',
      },
      ENDED: {
        backgroundContent: '#a0abc0',
        backgroundWrapper: '#717d96',
        shadow: '#4a5468',
      },
    },
    DIALOG_MISSION: {
      backgroundProgress: '#D90036',
      backgrounBar: '#a30529',
      progressValue: '#70b200',
      progressValueInactive: '#717d96',
      progressRoot: '#aa143b',
      borderProgress: '#FCCD0A',
      backgroundObjectives: '#f0f0f0',
      backgroundLinearObjective:
        'linear-gradient(180deg, #FFCC03 0%, #E8AC11 100%)',
    },
  },
  JP: {
    STATUS: {
      CLAIM: {
        backgroundContent: '#ffe36e',
        backgroundWrapper: '#fdc30b',
        shadow: '#cb5705',
      },
      COMPLETED: {
        backgroundContent: '#e0ffbb',
        backgroundWrapper: '#70b202',
        shadow: '#4f7a2a',
      },
      ACTIVE: {
        backgroundContent: '#007dc9',
        backgroundWrapper: '#3babff',
        shadow: '#1f4d91',
        progress: '#fdd160',
      },
      PENDING: {
        backgroundContent: '#a0abc0',
        backgroundWrapper: '#717d96',
        shadow: '#4a5468',
      },
      ENDED: {
        backgroundContent: '#a0abc0',
        backgroundWrapper: '#717d96',
        shadow: '#4a5468',
      },
    },
    DIALOG_MISSION: {
      backgroundProgress: '#D90036',
      backgrounBar: '#a30529',
      progressValue: '#70b200',
      progressValueInactive: '#717d96',
      progressRoot: '#aa143b',
      borderProgress: '#FCCD0A',
      backgroundObjectives: '#f0f0f0',
      backgroundLinearObjective:
        'linear-gradient(180deg, #FFCC03 0%, #E8AC11 100%)',
    },
  },
}
