import { createAsyncThunk } from '@reduxjs/toolkit'
import Container from 'typedi'
import { t } from 'i18next'

import { Rest } from '@/utils/api.utils'
import {
  MyBoosterActions,
  MyBoosterModels,
  MyBoosterThunks,
  WidgetEndpoints,
  globalUiActions,
  store,
} from '@/state'
import {
  RewardProcessorStatusEnum,
  RewardShopBoosterTypeEnum,
  SnackbarSeverityEnum,
  StatusCodeEnum,
} from '@/enums'

import { LIMIT } from '@/constants'
import { MyBoosterActionTypes } from '../constants'

const apiService: Rest = Container.get(Rest)

export const activateBoosterThunk = createAsyncThunk(
  MyBoosterActionTypes.ACTIVATE_BOOSTER,
  async (payload: MyBoosterModels.ActivateBoosterPaylaod, thunkAPI) => {
    try {
      const { id, ...bodyPayload } = payload
      const response: MyBoosterModels.ActivateBoosterResponse =
        await apiService.sendData({
          method: 'PATCH',
          endpoint: `${WidgetEndpoints.REWARD_PROCESSOR}/${id}`,
          body: bodyPayload,
        })

      const success = response.statusCode.toString() === StatusCodeEnum.OK

      if (
        success &&
        response.status === RewardProcessorStatusEnum.IN_PROGRESS
      ) {
        const state = store.getState()

        thunkAPI.dispatch(
          globalUiActions.setSnackbar({
            open: true,
            severity: SnackbarSeverityEnum.SUCCESS,
            message: t('activatedSuccessfully'),
          }),
        )

        thunkAPI.dispatch(
          MyBoosterThunks.getAllMyBoostersThunk({
            playerId: state.initConfig.playerId,
            rewardType: [
              RewardShopBoosterTypeEnum.LEVEL,
              RewardShopBoosterTypeEnum.TOKEN,
            ],
            status: RewardProcessorStatusEnum.PENDING,
            limit: LIMIT,
            page: 1,
          }),
        )
        thunkAPI.dispatch(MyBoosterActions.setLoadingFalse())
      }
      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
