import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Country, getData } from 'country-list'

import { RootState, RewardShopModels } from '@/state'
import { DeliveryTypeEnum, RewardShopTypeEnum } from '@/enums'
import { useRewardShopActions } from './'

export const useProductForm = () => {
  const { currentProduct } = useSelector(
    ({ rewardShop }: RootState) => rewardShop,
  )

  const { player } = useSelector(({ player }: RootState) => player)

  const { playerId, playerLocale, client } = useSelector(
    ({ initConfig }: RootState) => initConfig,
  )

  const { orderSubmit } = useRewardShopActions()

  const [productForm, setProductForm] =
    useState<RewardShopModels.ProductFormType>({
      quantity: 1,
      deliveryType: DeliveryTypeEnum.CUSTOM,
      country: getData()?.find(
        (country) =>
          country.code ===
          (client === 'JP' ? 'CH' : player?.shippingAddress?.country),
      ),
      city: player?.shippingAddress?.city || '-',
      street: player?.shippingAddress?.street || '-',
      streetNumber: player?.shippingAddress?.streetNumber || '-',
      postalCode: player?.shippingAddress?.postalCode || '-',
      receives: player?.name || '-',
      activateBooster: false,
    })

  const [editLocation, setEditLocation] = useState<boolean>(false)

  const handleOnChange = (
    field: string,
    newValue: string | number | boolean | Country,
  ) => {
    setProductForm({
      ...productForm,
      [field]: newValue,
    })

    if (newValue === DeliveryTypeEnum.CASINO && editLocation) {
      handleEditLocation()
    }
  }

  const handleOnSubmit = () => {
    orderSubmit({
      playerId: playerId,
      recipientName: productForm.receives,
      playerLanguage: playerLocale,
      shippingAddress: {
        street: productForm.street,
        streetNumber: productForm.streetNumber,
        city: productForm.city,
        country: productForm.country.code,
        deliverInCasino: productForm.deliveryType === DeliveryTypeEnum.CASINO,
        zipCode: productForm.postalCode,
      },
      dateOfPurchase: new Date().toISOString(),
      itemId: currentProduct._id,
      quantity: productForm.quantity,
      comment: {
        comment: 'Reward purchased from widgets.',
        createdBy: 'By Player',
      },
      activation:
        currentProduct?.reward?.type === RewardShopTypeEnum.BOOSTER
          ? productForm.activateBooster
          : true,
    })
  }

  const handleEditLocation = () => {
    setEditLocation(!editLocation)
  }

  // OPTIONS
  const quantityOptions = [1, 2, 3, 4, 5]

  return {
    currentProduct,
    productForm,
    quantityOptions,
    editLocation,
    handleOnChange,
    handleOnSubmit,
    handleEditLocation,
  }
}
