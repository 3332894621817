import { makeStyles } from 'tss-react/mui'
import { amber } from '@mui/material/colors'
import { typographyClasses } from '@mui/material/Typography'

interface Props {
  transform: number
  largeSize: boolean
  client?: string
}

export const useStyles = makeStyles<Props>()((
  theme,
  { transform, largeSize, client },
) => {
  return {
    avatarContent: {
      position: 'relative',
      display: 'inline-flex',
      backgroundColor: 'transparent',
      borderRadius: '50%',
      transform: `scale(${transform})`,
      transformOrigin: '0 0',
      overflow: 'hidden',
      '& .avatar': {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 'auto',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 110,
        height: 110,
        backgroundColor: 'transparent',

        '& svg': {
          backgroundColor: theme.palette.primary.main,
        },
      },

      '& .disableAvatar': {
        '& svg': {
          backgroundColor: theme.palette.grey[200],
        },
      },

      '& .circular': {
        circle: {
          strokeLinecap: 'round',
          strokeWidth: client === 'JP' ? '2px' : '3.5px',
        },
      },

      '& .circularTransparent': {
        circle: {
          strokeLinecap: 'round',
          strokeWidth: client === 'JP' ? '2px' : '3.5px',
          color: theme.palette.grey[200],
        },
      },
    },
    levelContent: {
      display: 'flex',
      gap: 4,
      alignItems: 'center',
      justifyContent: 'space-around',
      padding: '2px 5px',
      border: `2px solid ${amber[400]}`,
      borderRadius: '35px',
      backgroundColor: theme.palette.background.default,
      position: 'absolute',
      bottom: largeSize ? '5px' : '-10px',
      right: largeSize ? '-25px' : '-10px',
      '& img': {
        width: largeSize ? '21px' : '16px',
      },
      [`& .${typographyClasses.body2}`]: {
        fontSize: largeSize ? '15px' : '12px',
      },
    },
    levelNumber: {
      zIndex: 10,
      position: 'absolute',
      top: '53%',
      left: '49%',
      transform: 'translate(-53%, -49%)',
    },
    levelImage: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
    accountCircle: {
      width: '130%',
      height: '130%',
    },
  }
})
