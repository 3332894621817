import { useSelector } from 'react-redux'
import Lottie from 'lottie-react'
import {
  Box,
  Avatar,
  CircularProgress,
  Typography,
  SxProps,
  Theme,
} from '@mui/material'

import { AccountCircle } from '@mui/icons-material'
import { RootState } from '@/state'
import { IconComponent } from '@/components/common'
import { getAssetsUrl, getLottieAnimationData } from '@/utils'

import { useStyles } from './style'
import { useAnimationAvatarActions } from '../hooks'
import { useEffect, useState } from 'react'

type Props = {
  Div: Element
  sx?: SxProps<Theme>
}

export function AvatarComponent({ Div, sx }: Props) {
  const { images } = useSelector(({ assets }: RootState) => assets)
  const { assetsRoute } = useSelector(
    ({ globalSettings }: RootState) => globalSettings,
  )
  const { gamificationPlayer, gamificationOpt } = useSelector(
    ({ player }: RootState) => player,
  )

  const [animationDataLevelUp, setAnimationDataLevelUp] = useState(null)
  const [animationDataLevelUpBadge, setAnimationDataLevelUpBadge] =
    useState(null)
  const [animationDataRankUp, setAnimationDataRankUp] = useState(null)

  const avatarLevelUpJPUrl = getAssetsUrl(
    assetsRoute,
    'assets/animations/avatar/jackpots/avatar-level-up.json',
  )
  const avatarLevelUpBadgeJPUrl = getAssetsUrl(
    assetsRoute,
    'assets/animations/avatar/jackpots/avatar-level-up-badge.json',
  )
  const avatarRankUpJPUrl = getAssetsUrl(
    assetsRoute,
    'assets/animations/avatar/jackpots/avatar-rank-up.json',
  )

  const avatarLevelUpUrl = getAssetsUrl(
    assetsRoute,
    'assets/animations/avatar/standard/level-up-big-star.json',
  )
  const avatarRankUpUrl = getAssetsUrl(
    assetsRoute,
    'assets/animations/avatar/standard/avatar-rank-up.json',
  )
  const avatarLevelUpBadgeUrl = getAssetsUrl(
    assetsRoute,
    'assets/animations/avatar/standard/avatar-level-up-badge.json',
  )

  // Get Data Attributes
  const dataSize =
    Div.getAttribute('data-size') !== undefined
      ? Number(Div.getAttribute('data-size'))
      : 110
  const showLevel = Div.getAttribute('data-show-level') || 'false'
  const progressType = Div.getAttribute('data-progress-type') || 'rank'

  const {
    getDataSize,
    levelUpAvatarAnimationContainerRef,
    levelUpBadgeAnimationContainerRef,
    rankUpAnimationContainerRef,
    numberAnimationRef,
    avatarRef,
    avatarRankImage,
    hiddenProgressBar,
    showBadge,
    showLevelUp,
    showRankUp,
  } = useAnimationAvatarActions({
    dataSize,
    showLevel,
    gamificationOpt,
    gamificationPlayer,
    images,
  })

  const dimensionsData = getDataSize()
  const transform = (dimensionsData.size || 1) / 120
  const largeSize =
    dimensionsData.size && dimensionsData.size > 70 ? true : false

  const { client } = useSelector(({ initConfig }: RootState) => initConfig)
  const { classes } = useStyles({ transform, largeSize, client })

  useEffect(() => {
    getLottieAnimationData(
      client === 'JP' ? avatarLevelUpJPUrl : avatarLevelUpUrl,
      setAnimationDataLevelUp,
    )
    getLottieAnimationData(
      client === 'JP' ? avatarLevelUpBadgeJPUrl : avatarLevelUpBadgeUrl,
      setAnimationDataLevelUpBadge,
    )
    getLottieAnimationData(
      client === 'JP' ? avatarRankUpJPUrl : avatarRankUpUrl,
      setAnimationDataRankUp,
    )
  }, []) // eslint-disable-line

  return (
    <Box
      width={dimensionsData.size}
      height={dimensionsData.size}
      sx={{ ...sx, position: 'relative' }}
    >
      <Box className={classes.avatarContent}>
        <Avatar
          ref={avatarRef}
          className={`avatar ${!gamificationOpt.enable && 'disableAvatar'}`}
          alt="Remy Sharp"
          src={avatarRankImage}
        >
          <AccountCircle className={classes.accountCircle} />
        </Avatar>
        <CircularProgress
          className="circularTransparent"
          variant="determinate"
          value={100}
          size={120}
          sx={{
            position: gamificationOpt.enable ? 'absolute' : 'relative',
          }}
        />
        {gamificationOpt.enable && (
          <CircularProgress
            id="progressBar"
            className="circular"
            variant="determinate"
            value={
              gamificationOpt?.enable
                ? progressType === 'rank'
                  ? gamificationPlayer.rank.progress
                  : gamificationPlayer.level.progress
                : 0
            }
            size={120}
            sx={{
              overflow: 'hidden',
              visibility: hiddenProgressBar ? 'hidden' : 'visible',
              opacity: hiddenProgressBar ? 0 : 1,
              transition: 'visibility 0s, opacity 0.3s ease',
            }}
          />
        )}
      </Box>

      <Lottie
        animationData={animationDataLevelUp}
        hidden={!showLevelUp}
        autoplay={false}
        loop={false}
        lottieRef={levelUpAvatarAnimationContainerRef}
        style={{
          left: dimensionsData.avatarLevelSize.left,
          top: dimensionsData.avatarLevelSize.top,
          position: 'absolute',
          width: dimensionsData.avatarLevelSize.size,
          height: dimensionsData.avatarLevelSize.size,
        }}
      />
      <Lottie
        animationData={animationDataRankUp}
        hidden={!showRankUp}
        autoplay={false}
        loop={false}
        lottieRef={rankUpAnimationContainerRef}
        style={{
          left: dimensionsData.avatarRankSize.left,
          top: dimensionsData.avatarRankSize.top,
          position: 'absolute',
          width: dimensionsData.avatarRankSize.size,
          height: dimensionsData.avatarRankSize.size,
        }}
        rendererSettings={{
          progressiveLoad: false,
          preserveAspectRatio: 'xMidYMid slice',
        }}
      />

      {showLevel === 'true' && gamificationOpt?.enable && (
        <>
          <Box className={classes.levelContent}>
            <IconComponent
              type="level"
              size={dimensionsData.size === 50 ? 15 : 21}
            />

            <Box
              sx={{
                width: dimensionsData.size === 50 ? 15 : 21,
                height: dimensionsData.size === 50 ? 15 : 21,
                position: 'relative',
                top: 'auto!important',
                left: 'auto!important',
                right: 'auto!important',
                bottom: 'auto!important',
              }}
            >
              <Typography
                ref={numberAnimationRef}
                variant="body2"
                className={classes.levelNumber}
              >
                {gamificationPlayer.level.levelNumber}
              </Typography>
            </Box>
          </Box>
          <Lottie
            animationData={animationDataLevelUpBadge}
            hidden={!showBadge}
            autoplay={false}
            loop={false}
            lottieRef={levelUpBadgeAnimationContainerRef}
            style={{
              left: dimensionsData.badgeSize.left,
              top: dimensionsData.badgeSize.top,
              position: 'absolute',
              width: dimensionsData.badgeSize.size,
              height: dimensionsData.badgeSize.size,
            }}
          />
        </>
      )}
    </Box>
  )
}
