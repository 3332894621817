export const removeEmptyProp = <T>(data: T) => {
  const formatData = { ...data }
  Object.keys(formatData).forEach((key) => {
    const value: any = formatData[key]
    return (
      (value === null ||
        value === '' ||
        (value && value.length === 0) ||
        value === undefined) &&
      delete formatData[key]
    )
  })
  return formatData as T
}
