import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { GlobalUIModels, RootState, globalUiActions } from '@/state'

export const useGlobalUi = () => {
  const dispatch = useDispatch()

  const { snackbar, dialog, dialogOnSite, notificationSnackbar, modal } =
    useSelector(({ globalUI }: RootState) => globalUI)

  const handleCloseSnackbar = useCallback(() => {
    dispatch(
      globalUiActions.setSnackbar({
        ...snackbar,
        open: false,
      }),
    )
  }, [dispatch, snackbar])

  const handleCloseNotificationSnackbar = useCallback(() => {
    dispatch(
      globalUiActions.setNotificationSnackbar({
        ...notificationSnackbar,
        open: false,
      }),
    )
  }, [dispatch, notificationSnackbar])

  const handleCloseModal = useCallback(() => {
    dispatch(
      globalUiActions.setModal({
        open: false,
      }),
    )
    dispatch(
      globalUiActions.setNotificationSnackbar({
        ...notificationSnackbar,
        open: false,
      }),
    )
  }, [dispatch, notificationSnackbar])

  const handleOpenModal = useCallback(() => {
    dispatch(
      globalUiActions.setNotificationSnackbar({
        open: false,
      }),
    )

    dispatch(
      globalUiActions.setModal({
        ...modal,
        open: true,
      }),
    )
  }, [dispatch, modal])

  const handleOpenSnackbar = useCallback(
    (props: GlobalUIModels.SnackbarType) => {
      dispatch(globalUiActions.setSnackbar({ ...props, open: true }))
    },
    [dispatch],
  )

  const handleOpenDialog = useCallback(
    (payload: Omit<GlobalUIModels.DialogType, 'open'>) => {
      dispatch(
        globalUiActions.setDialog({
          open: true,
          ...payload,
        }),
      )
    },
    [dispatch],
  )

  const handleCloseDialog = useCallback(() => {
    dispatch(
      globalUiActions.setDialog({
        open: false,
      }),
    )
  }, [dispatch])

  const handleOpenOnSiteDialog = useCallback(
    (payload: Omit<GlobalUIModels.DialogType, 'open'>) => {
      dispatch(
        globalUiActions.setDialogOnSite({
          open: true,
          ...payload,
        }),
      )
    },
    [dispatch],
  )

  const handleCloseOnSiteDialog = useCallback(() => {
    dispatch(
      globalUiActions.setDialogOnSite({
        open: false,
      }),
    )
  }, [dispatch])

  const handleWaitCloseDialog = useCallback(() => {
    dispatch(
      globalUiActions.setDialog({
        ...dialog,
        open: false,
      }),
    )
    // Clean the entire object
    setTimeout(() => {
      dispatch(globalUiActions.setDialog({ open: false }))
    }, 500)
  }, [dispatch, dialog])

  return {
    dialog,
    dialogOnSite,
    snackbar,
    notificationSnackbar,
    modal,
    handleOpenDialog,
    handleOpenSnackbar,
    handleCloseDialog,
    handleOpenOnSiteDialog,
    handleCloseOnSiteDialog,
    handleWaitCloseDialog,
    handleCloseSnackbar,
    handleOpenModal,
    handleCloseModal,
    handleCloseNotificationSnackbar,
  }
}
