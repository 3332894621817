import { ReactComponent as IconSidebarProfile } from '@/assets/icons/icon-sidebar-profile.svg'
import { ReactComponent as IconSidebarShop } from '@/assets/icons/icon-sidebar-shop.svg'
import { ReactComponent as IconSidebarHome } from '@/assets/icons/icon-sidebar-home.svg'

import { ReactComponent as IconSidebarTournaments } from '@/assets/icons/icon-sidebar-tournaments.svg'
import { ReactComponent as IconSidebarMissions } from '@/assets/icons/icon-sidebar-missions.svg'
import { SidebarMenuItemType } from '@/enums'

export const SidebarMenuOptions = {
  [SidebarMenuItemType.PROFILE]: {
    id: SidebarMenuItemType.PROFILE,
    label: 'profile',
    icon: <IconSidebarProfile />,
  },
  [SidebarMenuItemType.REWARD_SHOP]: {
    id: SidebarMenuItemType.REWARD_SHOP,
    label: 'shop',
    icon: <IconSidebarShop />,
  },

  [SidebarMenuItemType.HOME]: {
    id: SidebarMenuItemType.HOME,
    label: 'home',
    icon: <IconSidebarHome />,
  },

  [SidebarMenuItemType.TOURNAMENTS]: {
    id: SidebarMenuItemType.TOURNAMENTS,
    label: 'tournaments',
    icon: <IconSidebarTournaments />,
  },
  [SidebarMenuItemType.MISSIONS]: {
    id: SidebarMenuItemType.MISSIONS,
    label: 'missions',
    icon: <IconSidebarMissions />,
  },
}
