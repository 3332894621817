import MikadoUltra from './mikado/MikadoUltra.otf'
import MikadoBlack from './mikado/MikadoBlack.otf'

export const fontFallbacks = (assetsRoute: string) => {
  return [
    {
      fontFamily: 'Mikado',
      fontWeight: 900,
      fontStyle: 'normal',
      src: `url(${assetsRoute}/casino-ui-widgets${MikadoUltra}) format('opentype')`,
    },
    {
      fontFamily: 'Mikado',
      fontWeight: 700,
      fontStyle: 'normal',
      src: `url(${assetsRoute}/casino-ui-widgets${MikadoBlack}) format('opentype')`,
    },
  ]
}
