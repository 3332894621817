import { ScopedCssBaseline } from '@mui/material'
import './init'
import {
  ActiveBoosterComponent,
  AvatarComponent,
  MechanicsStatusComponent,
  PointsComponent,
  ShopLayoutComponent,
  TokensProfileAnimationComponent,
  MyBoostersComponent,
  MissionsHistoryComponent,
  SidebarComponent,
} from './components'

import { WidgetTypeEnum } from './enums'

type Props = {
  Div: Element
  /* 
  TODO: Maybe in the future we will use this prop
  callbacks?: {
    rewardShop?: {
      next_btn?: (props: any) => void
    }
  } */
}

export function App({ Div }: Props) {
  const type = Div.getAttribute('data-type') as WidgetTypeEnum

  if (
    !Div.hasChildNodes() &&
    Object.values(WidgetTypeEnum).includes(type as WidgetTypeEnum)
  ) {
    return (
      <ScopedCssBaseline
        enableColorScheme
        className="scopedContainer"
        sx={{ backgroundColor: 'transparent' }}
      >
        {type === WidgetTypeEnum.AVATAR && <AvatarComponent Div={Div} />}
        {type === WidgetTypeEnum.POINTS && <PointsComponent />}
        {type === WidgetTypeEnum.REWARD_SHOP && (
          <ShopLayoutComponent Div={Div} />
        )}
        {type === WidgetTypeEnum.TOKENS && (
          <TokensProfileAnimationComponent Div={Div} />
        )}
        {type === WidgetTypeEnum.ACTIVE_BOOSTERS && (
          <ActiveBoosterComponent Div={Div} />
        )}
        {type === WidgetTypeEnum.GAMIFICATION_MECHANICS && (
          <MechanicsStatusComponent Div={Div} />
        )}
        {type === WidgetTypeEnum.MY_BOOSTERS && (
          <MyBoostersComponent isStandalone />
        )}
        {type === WidgetTypeEnum.MISSION_HISTORY && (
          <MissionsHistoryComponent />
        )}
        {type === WidgetTypeEnum.SIDEBAR && <SidebarComponent Div={Div} />}
      </ScopedCssBaseline>
    )
  }
}
