import { keyframes } from 'tss-react'
import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()((theme) => {
  const flagAnimation = keyframes`
  from, to {
    transform: scale(1, 1); 
  }
  25% {
    transform: scale(0.95, 1.05); 
  }
  50% { 
    transform: scale(1.05, 0.95); 
  }
  75% { 
    transform: scale(0.95, 1.05); 
  }
`

  return {
    modalContent: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      maxWidth: '100%',
      [theme.breakpoints.up('sm')]: {
        width: 450,
      },
      [theme.breakpoints.down('sm')]: {
        width: 320,
      },
    },
    modalContentInitial: {
      [theme.breakpoints.up('sm')]: {
        height: 800,
      },
      [theme.breakpoints.down('sm')]: {
        height: 610,
      },
    },
    notificationContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: 16,
      alignItems: 'center',
      paddingLeft: 65,
      [theme.breakpoints.up('sm')]: {
        paddingLeft: 60,
        minWidth: 440,
      },
      [theme.breakpoints.up('sm')]: {
        minWidth: 540,
      },
    },
    notificationButton: {
      color: theme.palette.common.white,
      borderColor: theme.palette.common.white,
      padding: '10px',
      flexShrink: 0,
      display: 'flex',
      gap: 16,
    },
    notificationButtonIcon: {
      fontSize: 16,
      color: theme.palette.common.white,
      [theme.breakpoints.up('sm')]: {
        fontSize: 24,
      },
    },
    initialContainerTop: {
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      margin: '0 auto',
      position: 'relative',
      height: 500,
    },
    initialContainerBottom: {
      height: 300,
      position: 'relative',
      overflow: 'hidden',
      borderRadius: '0 0 16px 16px',
    },
    initialLevel: {
      position: 'absolute',
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        bottom: 100,
      },
      [theme.breakpoints.down('sm')]: {
        bottom: 70,
      },
    },
    initialLevelValue: {
      color: theme.palette.common.white,
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        fontSize: '80px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '64px',
      },
    },
    initialBoxBackground: {
      borderRadius: '50% 50% 0 0',
      height: 350,
      width: 600,
      position: 'absolute',
      top: 0,
      zIndex: -10,
      backgroundColor: theme.palette.primary.main,
      [theme.breakpoints.up('sm')]: {
        left: -75,
      },
      [theme.breakpoints.down('sm')]: {
        left: -140,
      },
    },
    initialBoxBackgroundImage: {
      position: 'absolute',
      top: 0,
      left: 96,
    },
    initialCongratulationsTitle: {
      color: theme.palette.common.white,
      fontSize: '32px',
      textAlign: 'center',
      mb: 2,
    },
    initialCongratulationsDescription: {
      color: theme.palette.common.white,
      fontSize: '20px',
      textAlign: 'center',
    },
    finalContainerTop: {
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      borderRadius: '16px',
      [theme.breakpoints.up('sm')]: {
        width: 386,
        height: 280,
        padding: '48px 32px 0 32px',
      },
      [theme.breakpoints.down('sm')]: {
        width: 320,
        height: 180,
      },
    },
    finalContainerBottom: {
      position: 'relative',
      overflow: 'hidden',
      borderRadius: '0 0 16px 16px',
      [theme.breakpoints.up('sm')]: {
        padding: '0 32px 32px 32px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '0 8px 4px 8px',
      },
    },
    finalBoxBackground: {
      borderRadius: '0 0 50% 50%',
      height: 600,
      width: 600,
      position: 'absolute',
      overflow: 'hidden',
      backgroundColor: theme.palette.primary.main,
      [theme.breakpoints.up('sm')]: {
        top: -450,
        left: -75,
      },
      [theme.breakpoints.down('sm')]: {
        top: -500,
        left: -140,
      },
    },
    finalBoxBackgroundImage: {
      position: 'absolute',
      bottom: 24,
      left: 96,
    },
    finalAnimation: {
      margin: '0 auto',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
    },
    finalTitle: {
      color: theme.palette.primary.main,
      textAlign: 'center',
      fontWeight: 700,
      [theme.breakpoints.up('sm')]: {
        fontSize: '40px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '24px',
      },
    },
    finalLevelValue: {
      color: theme.palette.common.white,
      fontSize: '52px',
      textAlign: 'center',
    },
    finalLevelAnimation: {
      animation: `${flagAnimation} 3s infinite ease`,
      position: 'absolute',
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        top: 40,
      },
      [theme.breakpoints.down('sm')]: {
        top: 20,
      },
    },
    finalCongratulations: {
      textAlign: 'center',
      color: theme.palette.grey[600],
      [theme.breakpoints.up('sm')]: {
        fontSize: '14px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
      },
    },
    finalCongratulationsEmpty: {
      minHeight: 100,
      display: 'flex',
      alignItems: 'center',
    },
    counters: {
      display: 'flex',
      overflow: 'hidden',
      justifyContent: 'center',
      [theme.breakpoints.up('sm')]: {
        gap: 16,
        margin: '0 auto 16px auto',
      },
      [theme.breakpoints.down('sm')]: {
        gap: 8,
        margin: '8px 4px',
      },
    },
    counterContainer: {
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '24px',
      paddingTop: '4px',
      paddingBottom: '4px',
      backgroundColor: theme.palette.common.white,
    },
    counterIconContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      marginX: 'auto',
      position: 'relative',
    },
    counterIcon: {
      display: 'flex',
      height: 60,
      paddingTop: '4px',
      alignItems: 'center',
      [theme.breakpoints.up('sm')]: {
        width: 118,
      },
      [theme.breakpoints.down('sm')]: {
        width: 90,
      },
    },
    counterValue: {
      fontWeight: 700,
      width: '100%',
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        fontSize: '20px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
      },
    },
    counterTitle: {
      width: '100%',
      textAlign: 'center',
      color: theme.palette.grey[500],
      [theme.breakpoints.up('sm')]: {
        fontSize: '14px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
      },
    },
    counterLevelIconContainer: {
      position: 'absolute',
      width: '100%',
      top: 2,
    },
    counterLevelIcon: {
      color: theme.palette.common.white,
      fontSize: '20px',
      textAlign: 'center',
    },
    rewardsAndButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    rewardList: {
      display: 'flex',
      flexDirection: 'column',
      margin: '8px auto',
      overflow: 'scroll',
      borderRadius: '16px',
      border: `1px solid ${theme.palette.grey[300]}`,
      padding: '4px',
      backgroundColor: theme.palette.grey[200],
      [theme.breakpoints.up('sm')]: {
        maxHeight: 180,
      },
      [theme.breakpoints.down('sm')]: {
        maxHeight: 155,
      },
    },
    continueLink: {
      display: 'flex',
      justifyContent: 'center',
      margin: '8px auto',
      [theme.breakpoints.up('sm')]: {
        width: '100%',
      },
      [theme.breakpoints.down('sm')]: {
        width: 300,
      },
    },
    continueLinkButton: {
      width: '100%',
      padding: '10px 0',
      fontSize: '18px',
    },
    rewardName: {
      paddingLeft: '8px',
      fontSize: '12px',
      color: theme.palette.grey[700],
    },
    rewardContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: theme.palette.grey[100],
      [theme.breakpoints.up('sm')]: {
        borderRadius: '36px',
        margin: '4px',
        padding: '4px',
      },
      [theme.breakpoints.down('sm')]: {
        borderRadius: '16px',
        margin: '2px',
        padding: '2px',
      },
    },
    rewardIcons: {
      width: '35px',
      height: '35px',
    },
  }
})
