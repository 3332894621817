import { makeStyles } from 'tss-react/mui'

interface Props {
  openSidebar?: boolean
}

export const useContentStyles = makeStyles<Props>()((
  theme,
  { openSidebar },
) => {
  return {
    sidebarContent: {
      padding: openSidebar ? '16px 16px 58px' : '16px 5px 20px',

      [theme.breakpoints.down('sm')]: {
        padding: '10px 16px',
        position: openSidebar ? 'relative' : 'absolute',
        top: openSidebar ? 0 : 22,
        left: openSidebar ? 0 : 160,
      },
    },
  }
})
