import { useSelector } from 'react-redux'
import { Box, Button, Typography } from '@mui/material'
import { ArrowBackIosNew } from '@mui/icons-material'

import { RootState } from '@/state'
import { ReactComponent as BoosterClose } from '@/assets/boosterCloseAction.svg'
import { ReactComponent as BoosterFloating } from '@/assets/boosterFloatingAction.svg'

import { ActiveBoosterItemComponent, HiddenPercentagesBoosterType } from './'
import { useStyles } from './style'
import { useAnimations } from './animations'

import { useActiveBoosters } from '../hooks'

type Props = {
  Div: Element
}

export function ActiveBoosterComponent({ Div }: Props) {
  const {
    showAllItems,
    hideAllItems,
    orderBoosters: activeBoosters,
    handleHideAllItems,
    handleShowAllItems,
  } = useActiveBoosters()

  const { gamificationOpt } = useSelector(({ player }: RootState) => player)

  const { classes: classesStyle } = useStyles({})

  const { classes: classesAnimation } = useAnimations()

  return (
    <>
      {gamificationOpt.enable && (
        <Box
          py={1}
          sx={{
            display: activeBoosters.length > 0 ? 'flex' : 'none',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            position: 'relative',
            overflow: 'hidden',
          }}
          className={classesAnimation.animations}
        >
          {activeBoosters.length > 0 && (
            <Button
              onClick={handleHideAllItems}
              className={classesStyle.defaultActions}
            >
              {hideAllItems ? (
                <Box className="floatingAction">
                  <BoosterFloating />
                  <Box className="lengthBoosters">{activeBoosters.length}</Box>
                </Box>
              ) : (
                <BoosterClose />
              )}
            </Button>
          )}
          <Box
            className={`${classesStyle.boostersContainer} boostersAnimation ${
              hideAllItems ? 'hide' : 'show'
            }`}
          >
            {activeBoosters.map((booster, index) => {
              return (
                <ActiveBoosterItemComponent
                  showAllItems={showAllItems}
                  index={index}
                  Div={Div}
                  booster={booster}
                  key={`active-booster-${booster._id}`}
                />
              )
            })}
            {activeBoosters.length > 3 && !showAllItems && (
              <Button
                className={`${classesStyle.customActions} showAllItems`}
                onClick={handleShowAllItems}
              >
                <Typography>{`${activeBoosters.length - 3}+`}</Typography>
                <HiddenPercentagesBoosterType
                  activeBoosters={activeBoosters.slice(3)}
                  Div={Div}
                />
              </Button>
            )}
            {activeBoosters.length > 3 && showAllItems && (
              <Button
                className={`${classesStyle.customActions} hideMoreThan3Items`}
                onClick={handleShowAllItems}
              >
                <ArrowBackIosNew />
              </Button>
            )}
          </Box>
        </Box>
      )}
    </>
  )
}
