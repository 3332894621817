import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '@/state'

export const useTokensAnimations = () => {
  const { gamificationPlayer, gamificationOpt } = useSelector(
    ({ player }: RootState) => player,
  )

  const { client, playerLocale } = useSelector(
    ({ initConfig }: RootState) => initConfig,
  )

  const { oldTokens, tokens } = gamificationPlayer

  const bigWin = false // TODO: When a player earns Tokens due to a Reward (Level Up, Rank Up, Mission, Tournament), this animation will be triggered.

  const [showAnimation, setShowAnimation] = useState(false)
  const [realTokens, setRealTokens] = useState(tokens)

  useEffect(() => {
    if (
      tokens !== undefined &&
      oldTokens !== undefined &&
      oldTokens !== tokens
    ) {
      setShowAnimation(true)
      setRealTokens(tokens)
    }
  }, [tokens]) // eslint-disable-line

  return {
    client,
    gamificationOpt,
    playerLocale,
    tokens,
    bigWin,
    showAnimation,
    realTokens,
    setShowAnimation,
  }
}
