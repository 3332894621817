import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Typography, useTheme } from '@mui/material'

import { IconComponent } from '@/components/common'
import { getFormatNumber } from '@/utils'
import { getAssetsUrl, getLottieAnimationData } from '@/utils'

import { RootState } from '@/state'
import { TokensLottieAnimationComponent } from './'
import { useTokensAnimations } from '../../hooks'

type Props = {
  Div: Element
}

export function TokensProfileAnimationComponent({ Div }: Props) {
  const { assetsRoute } = useSelector(
    ({ globalSettings }: RootState) => globalSettings,
  )

  const {
    client,
    gamificationOpt,
    playerLocale,
    realTokens,
    bigWin,
    showAnimation,
    setShowAnimation,
  } = useTokensAnimations()

  const theme = useTheme()

  // Get Data Attributes
  const justifyContent = Div.getAttribute('data-justify-content')
    ? Div.getAttribute('data-justify-content')
    : client === 'JP'
      ? 'center'
      : 'flex-end'

  const textColor = Div.getAttribute('data-text-color')
    ? Div.getAttribute('data-text-color')
    : theme.palette.primary.main

  const [animationData, setAnimationData] = useState(null)

  const getAnimationUrl = () => {
    return getAssetsUrl(
      assetsRoute,
      client === 'JP'
        ? 'assets/animations/small-big-win/jackpots/small-win.json'
        : 'assets/animations/small-big-win/standard/small-win.json',
    )
  }

  useEffect(() => {
    if (animationData === null) {
      getLottieAnimationData(getAnimationUrl(), setAnimationData)
    }
  }, []) // eslint-disable-line

  return (
    <Box>
      {gamificationOpt?.enable && (
        <Box
          display="inline-flex"
          alignItems="center"
          justifyContent={justifyContent}
          gap={0.5}
          position="relative"
        >
          {showAnimation ? (
            <TokensLottieAnimationComponent
              textColor={textColor}
              bigWin={bigWin}
              showAnimation={showAnimation}
              setShowAnimation={setShowAnimation}
              animationData={animationData}
            />
          ) : (
            <>
              <Box position="relative" display="flex">
                <IconComponent type="token" size={19} />
              </Box>
              <Typography
                variant="body2"
                fontWeight={900}
                fontSize={14}
                color={textColor}
                lineHeight={1}
                zIndex={20}
              >
                {getFormatNumber(realTokens, playerLocale)}
              </Typography>
            </>
          )}
        </Box>
      )}
    </Box>
  )
}
