import { Box, Modal, useTheme } from '@mui/material'
import { useGlobalUi } from '@/hooks'

type Props = {
  children: React.ReactNode
}

export const ModalComponent = ({ children }: Props) => {
  const { modal, handleCloseModal } = useGlobalUi()

  const { open } = modal

  const theme = useTheme()

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: theme.palette.grey[100],
    borderRadius: 4,
  }

  const handleClose = (event, reason: string) => {
    if (reason && reason === 'backdropClick') return

    handleCloseModal()
  }

  return (
    <Modal open={open} onClose={handleClose} disableAutoFocus={true}>
      <Box sx={style}>{children}</Box>
    </Modal>
  )
}
