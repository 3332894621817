import { makeStyles } from 'tss-react/mui'
import MissionBackground from '@/assets/mission-background.png'

interface Props {
  openSidebar: boolean
  backgroundImage?: string
}

export const useGlobalStyles = makeStyles<Props>()((
  theme,
  { openSidebar, backgroundImage },
) => {
  return {
    sidebarWrapper: {
      borderRadius: 8,
      minHeight: 400,
      width: openSidebar ? 320 : 90,
      transition: 'width 0.3s ease',
      backgroundImage: !!backgroundImage
        ? `url(${backgroundImage})`
        : `url(${MissionBackground})`,
      position: 'relative',
      overflow: 'hidden',
      paddingBottom: openSidebar ? '40px' : 'auto',

      [theme.breakpoints.down('sm')]: {
        borderRadius: 0,
        width: '100%',
        transition: 'height 0.3s ease',
        height: openSidebar ? 'auto' : 128,
        minHeight: 128,
        paddingBottom: openSidebar ? '80px' : 'auto',
      },
    },
  }
})
