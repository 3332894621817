import { useTranslation } from 'react-i18next'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import {
  Box,
  Button,
  Typography,
  TypographyProps,
  alpha,
  useTheme,
} from '@mui/material'

import { PaginationType } from '@/state'
import { useBreakpoints } from '@/hooks'

type Props = {
  pagination: PaginationType
  handlePrevPage: () => void
  handleNextPage: () => void
}

export function PaginationComponent({
  pagination,
  handlePrevPage,
  handleNextPage,
}: Props) {
  const { t } = useTranslation()

  const typographyProps: TypographyProps = {
    variant: 'body2',
    letterSpacing: 3,
  }

  const { page, pages } = pagination

  const isPrevPage = () => page > 1

  const isNextPage = () => page < pages

  const { isMobile } = useBreakpoints()

  const theme = useTheme()

  return (
    <Box
      display="flex"
      gap={5}
      alignItems="center"
      sx={{ justifyContent: isMobile ? 'center' : 'flex-end' }}
    >
      <Button
        size="small"
        disabled={!isPrevPage()}
        onClick={() => handlePrevPage()}
        startIcon={
          <KeyboardArrowLeft
            fontSize="inherit"
            sx={{
              marginRight: 4,
              color: !isPrevPage()
                ? alpha(theme.palette.text.primary, 0.5)
                : theme.palette.text.primary,
            }}
          />
        }
      >
        <Typography
          {...typographyProps}
          color={
            !isPrevPage()
              ? alpha(theme.palette.text.primary, 0.5)
              : theme.palette.text.primary
          }
        >
          {t('prev')}
        </Typography>
      </Button>
      <Typography {...typographyProps}>{`${page}/${pages}`}</Typography>
      <Button
        size="small"
        disabled={!isNextPage()}
        onClick={() => handleNextPage()}
        endIcon={
          <KeyboardArrowRight
            fontSize="inherit"
            sx={{
              marginLeft: 4,
              color: !isNextPage()
                ? alpha(theme.palette.text.primary, 0.5)
                : theme.palette.text.primary,
            }}
          />
        }
      >
        <Typography
          {...typographyProps}
          color={
            !isNextPage()
              ? alpha(theme.palette.text.primary, 0.5)
              : theme.palette.text.primary
          }
        >
          {t('next')}
        </Typography>
      </Button>
    </Box>
  )
}
