import { useSelector } from 'react-redux'
import { t } from 'i18next'
import { Box, Avatar, CircularProgress, Typography } from '@mui/material'

import { AccountCircle } from '@mui/icons-material'
import { RootState } from '@/state'
import { useSidebarAvatarActions } from '@/components/sidebar'

import { useBreakpoints } from '@/hooks'
import { useAvatarStyles } from './style'

type Props = {
  Div: Element
  sidebarClient: string
  openSidebar: boolean
  handleOpenSidebar?: () => void
}

export function SidebarAvatarComponent({
  Div,
  sidebarClient,
  openSidebar,
  handleOpenSidebar,
}: Props) {
  const { images } = useSelector(({ assets }: RootState) => assets)
  const { gamificationPlayer, gamificationOpt } = useSelector(
    ({ player }: RootState) => player,
  )

  const progressType = Div.getAttribute('data-avatar-progress-type') || 'rank'

  const { avatarRef, avatarRankImage } = useSidebarAvatarActions({
    gamificationOpt,
    gamificationPlayer,
    images,
  })

  const dimensionsData = 50

  const { isMobile } = useBreakpoints()

  const { classes } = useAvatarStyles({ openSidebar, sidebarClient, isMobile })

  return (
    <Box
      width={dimensionsData}
      height={dimensionsData}
      position="relative"
      onClick={() => handleOpenSidebar && handleOpenSidebar()}
    >
      <Box className={classes.avatarContent}>
        <Avatar
          ref={avatarRef}
          className={`avatar ${!gamificationOpt.enable && 'disableAvatar'}`}
          alt="Remy Sharp"
          src={avatarRankImage}
        >
          <AccountCircle className={classes.accountCircle} />
        </Avatar>
        <CircularProgress
          className="circularTransparent"
          variant="determinate"
          value={100}
          size={120}
          sx={{
            position: gamificationOpt.enable ? 'absolute' : 'relative',
          }}
        />
        {gamificationOpt.enable && (
          <CircularProgress
            className="circular"
            variant="determinate"
            value={
              gamificationOpt?.enable
                ? progressType === 'rank'
                  ? gamificationPlayer.rank.progress
                  : gamificationPlayer.level.progress
                : 0
            }
            size={120}
          />
        )}
      </Box>
      {gamificationOpt?.enable && (
        <Box className={classes.levelContent}>
          <Typography variant="body2">
            {`${t('lvl')} ${gamificationPlayer.level.levelNumber}`}
          </Typography>
        </Box>
      )}
    </Box>
  )
}
