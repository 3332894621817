import { makeStyles } from 'tss-react/mui'
import { alpha, darken, typographyClasses } from '@mui/material'
import { MikadoFontStyle } from '@/constants'

interface Props {
  expanded?: boolean
}

export const useSliderMissionStyles = makeStyles<Props>()((
  theme,
  { expanded },
) => {
  return {
    sliderMissionWrapper: {
      background: expanded ? theme.palette.common.white : 'none',
      borderRadius: expanded ? 12 : 0,
      padding: expanded ? '8px 10px' : 0,
      boxShadow: expanded
        ? `0px 4px 0px 0px ${alpha(theme.palette.common.black, 0.2)}`
        : 'none',
      minWidth: '100%',
      position: 'relative',
      animation: 'flexAnimationOpacity 0.35s ease-in forwards',

      '@keyframes flexAnimationOpacity': {
        '0%': {
          opacity: 0,
        },

        '100%': {
          opacity: 1,
        },
      },
    },

    sliderMissionHeader: {
      display: 'flex',
      justifyContent: expanded ? 'space-between' : 'center',
      alignItems: 'center',

      [theme.breakpoints.down('sm')]: {
        display: expanded ? 'flex' : 'none',
      },

      [`& .${typographyClasses.body1}`]: {
        fontSize: 14,
        textTransform: 'uppercase',
        ...MikadoFontStyle,
      },

      [`& .${typographyClasses.caption}`]: {
        fontSize: 14,
        textTransform: 'uppercase',
        ...MikadoFontStyle,
      },
    },

    sliderMissionItems: {
      display: 'flex',
      justifyContent: 'left',
      flexDirection: expanded ? 'row' : 'column',
      gap: expanded ? 16 : 0,

      // carousel
      overflowX: 'auto',
      scrollSnapType: 'x mandatory',
      scrollBehavior: 'smooth',
      width: '100%',
      margin: '0px auto',
      padding: '15px 0',
      flexWrap: 'nowrap',
      boxSizing: 'border-box',

      '& > *': {
        scrollSnapAlign: 'start',
      },

      [theme.breakpoints.down('sm')]: {
        maxWidth: expanded ? 267 : 130,
        flexDirection: 'row',
        overflowX: expanded ? 'auto' : 'inherit',
      },
    },

    sliderMissionBullets: {
      display: 'flex',
      justifyContent: 'center',
      gap: 16,

      '& .sliderMissionBullet': {
        cursor: 'pointer',
        width: 12,
        height: 12,
        backgroundColor: '#E3E6EC',
        border: '2px solid #D4D8DF',
        borderRadius: '50%',
        boxShadow: `0px 1.2px 1.2px 0px ${alpha(
          theme.palette.common.black,
          0.25,
        )}`,

        '&.active': {
          backgroundColor: theme.palette.primary.main,
          borderColor: darken(theme.palette.primary.main, 0.25),
        },
      },
    },

    cardArrow: {
      width: 30,
      height: 30,
      minWidth: 30,
      padding: 0,
      margin: 0,
      position: 'absolute',
      top: '45%',
      zIndex: 3,
      borderRadius: 21,
      border: ` 1px solid ${theme.palette.common.black}`,
      background: theme.palette.primary.main,
      boxShadow: `0px 1.5px 1.5px 0px ${alpha(
        theme.palette.common.black,
        0.5,
      )}`,

      '&.right': {
        right: -10,

        [theme.breakpoints.down('sm')]: {
          right: '5%',
        },

        [theme.breakpoints.down('xs')]: {
          right: -10,
        },

        '& svg': {
          width: 13,
        },
      },

      '&.left': {
        left: -10,

        [theme.breakpoints.down('sm')]: {
          left: '5%',
        },

        [theme.breakpoints.down('xs')]: {
          left: -10,
        },

        '& svg': {
          width: 18,
        },
      },

      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  }
})
