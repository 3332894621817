import { createAsyncThunk } from '@reduxjs/toolkit'
import Container from 'typedi'
import { Rest } from '@/utils/api.utils'

import { MissionHistoryModels, WidgetEndpoints, globalUiActions } from '@/state'
import { MissionHistoryActionTypes } from '../constants'

const apiService: Rest = Container.get(Rest)

export const getOneMissionBundleThunk = createAsyncThunk(
  MissionHistoryActionTypes.GET_ONE_BUNDLE,
  async (
    payload: MissionHistoryModels.GetOneMissionBundlePayload,
    thunkAPI,
  ) => {
    try {
      thunkAPI.dispatch(globalUiActions.setLoadingFull(true))
      const response: MissionHistoryModels.GetOneMissionBundleResponse =
        await apiService.sendData({
          method: 'GET',
          endpoint: `${WidgetEndpoints.GET_ONE_BUNDLE}/${payload.bundleId}`,
        })

      thunkAPI.dispatch(globalUiActions.setLoadingFull(false))

      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
