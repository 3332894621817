import { useTranslation } from 'react-i18next'
import { MissionEventTypeEnum, MissionNextLevelTypeEnum } from '@/enums'
import { MissionModels } from '@/state'

export const useDialogMissionObjectives = () => {
  const { t } = useTranslation()

  const getPaymentMethods = (paymentMethods: string[]) => {
    return paymentMethods.length > 0
      ? ` - ${t('paymentMethods')}: ${paymentMethods.join(' ')}`
      : ''
  }

  const getMinimumAmount = (
    minimumAmounts: { minimumAmount: number; currency: string }[],
  ) => {
    return minimumAmounts.length > 0
      ? minimumAmounts
          .map(
            (item) =>
              `- ${t('minimumAmount')}: ${item.minimumAmount} ${item.currency}`,
          )
          .join(' ')
      : ''
  }

  const getObjectiveName = (objective: MissionModels.ObjectiveMissionTypes) => {
    let objectiveType
    let objectiveMessage = `${t(`${objective.missionEventType}`)} `

    switch (objective.missionEventType) {
      case MissionEventTypeEnum.ACCOUNT_VERIFICATION:
        objectiveType =
          objective as MissionModels.ObjectiveMissionOptInAndAccountVerificationType
        objectiveMessage += `${t('channels')} - ${t('email')}: ${
          objectiveType.channelEmail
        } -  ${t('sms')}: ${objectiveType.channelSms}`
        break

      case MissionEventTypeEnum.COMPLETED_DEPOSIT:
      case MissionEventTypeEnum.COMPLETED_WITHDRAWAL:
        objectiveType =
          objective as MissionModels.ObjectiveMissionCompletedTransactionType

        objectiveMessage += `${t(`${objectiveType.conditionType}`)}: ${
          objectiveType.conditionValue
        } ${getPaymentMethods(objectiveType.paymentMethods)} ${getMinimumAmount(
          objectiveType.minimumAmount,
        )}`
        break

      case MissionEventTypeEnum.LEVEL_UP:
        objectiveType = objective as MissionModels.ObjectiveMissionLevelUpType
        objectiveMessage += `- ${t(`${objectiveType.nextLevelType}`)} ${
          objectiveType.nextLevelType !== MissionNextLevelTypeEnum.ANY
            ? `- ${t('value')}: ${objectiveType.nextLevelValue}`
            : ''
        }`
        break

      case MissionEventTypeEnum.OPT_IN:
        objectiveType =
          objective as MissionModels.ObjectiveMissionOptInAndAccountVerificationType
        objectiveMessage += `${t('channels')} - ${t('email')}: ${
          objectiveType.channelEmail
        } - ${t('sms')}: ${objectiveType.channelSms} - ${t('phone')}: ${
          objectiveType.channelPhone
        } - ${t('post')}: ${objectiveType.channelPost}`
        break

      case MissionEventTypeEnum.REFER_A_FRIEND:
        objectiveType =
          objective as MissionModels.ObjectiveMissionReferAFriendType
        objectiveMessage += `- ${t(`${objectiveType.conditionType}`)}: ${
          objectiveType.conditionValue
        } - ${t('godsonAccountStatus')}: ${t(
          `${objectiveType.godsonAccountStatusType.toUpperCase()}`,
        )}`
        break

      case MissionEventTypeEnum.WAGER:
      case MissionEventTypeEnum.WIN:
        objectiveType =
          objective as MissionModels.ObjectiveMissionWagerAndWinType
        objectiveMessage += `- ${t(`${objectiveType.conditionType}`)}: ${
          objectiveType.conditionValue
        } ${getMinimumAmount(objectiveType.minimumAmount)}`
        break

      default:
        // eslint-disable-next-line
        objectiveMessage
        break
    }

    return objectiveMessage
  }

  return {
    getObjectiveName,
  }
}
