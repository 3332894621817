export const translation = {
  activate: 'Activate',
  activateBooster: 'Activate Booster',
  activatedSuccessfully: 'Activated successfully.',
  activeBooster: 'Active Booster',
  activeBoosterDescription:
    'If you activate this Booster, it will show when launching a game. Otherwise, it will show as Pending under My Boosters. You may re-position the booster by holding and dragging it.',
  addressOrder: 'Address and order details',
  back: 'Back',
  backToList: 'Back to list',
  backToProduct: 'Back to product',
  bonusOffer: 'Bonus Offer',
  buyNow: 'Buy Now',
  cancel: 'Cancel',
  casinoLocation: 'Deliver to casino for self collection',
  casinoLocationCustom:
    'I would like to order the Product to a specific address:',
  casinoLocationJPOne:
    'I would like to order the Product to the Grand Casino Baden and pick it up',
  casinoLocationJPTwo: `(you'll get a notification once the Product arrives):`,
  casinoLocationJPThree: 'Grand Casino Baden, Haselstrasse 2, 5400 Baden',
  casinoLocationMessage:
    'You will be informed by e-mail as soon as the package is ready for collection.',
  city: 'City',
  congratulations: 'Congratulations!',
  congratulationsRankUp: `You’ve reached rank {{rank}} now`,
  congratulationsLevelUp: `You’ve reached level {{level}} now`,
  congratulationsRewards: `Congratulations! You've leveled up to {{type}}! You earned the following rewards:`,
  congratulationsWithoutRewards: `Congratulations! You've leveled up to {{type}}!`,
  continueShopping: 'Continue Shopping',
  continuePlaying: 'Continue Playing',
  country: 'Country',
  day_one: 'Day',
  day_other: 'Days',
  disabledSubmit: "Note: You don't have enough tokens to buy this product",
  edit: 'Edit',
  errorLoading: 'Connection problems.',
  errorOccurred: 'An error has occurred. Please try again.',
  goBack: 'Go Back',
  goShopping: 'Go Shopping',
  history: 'Shop History',
  hour_one: 'Hour',
  hour_other: 'Hours',
  important: 'Important',
  infoRank:
    'Lorem ipsum dolor sit amet consectetur. Turpis non ut id a sagittis.',
  infoLevel:
    'Lorem ipsum dolor sit amet consectetur. Turpis non ut id a sagittis.',
  infoTokens:
    'Lorem ipsum dolor sit amet consectetur. Turpis non ut id a sagittis.',
  infoXPPoints:
    'Lorem ipsum dolor sit amet consectetur. Turpis non ut id a sagittis.',
  levelBooster: 'Level Booster',
  linkNoAvailable: 'Sorry the link is no longer available',
  level: 'Level',
  levelUp: 'Level up',
  loyaltyProgram: 'Loyalty Program',
  mechanicsDescriptionOptOut:
    'You can deactivate all loyalty features by choosing to opt out. You will no longer accumulate XP Points, Tokens or progress through levels. You will not be able to access the Reward Shop or buy any products, and any purchased item(s) pending activation will be lost with no return.',
  mechanicsDescriptionOptIn:
    'If you want to voluntarily reactivate the gamification functions, you can request it here',
  mechanicsDisableState:
    'Not available because you opted out of the Loyalty Program. Please contact customer support for more information.',
  mechanicsCTAOptIn: 'Opt-In',
  mechanicsCTAOptOut: 'Opt-Out',
  mechanicsModalCTAOptIn: 'Reactivate',
  mechanicsModalCTAOptOut: 'Deactivate',
  mechanicsModalConfirmOptIn:
    'I confirm I want to reactivate loyalty features and start receiving benefits again.',
  mechanicsModalConfirmOptOut:
    'I confirm that I wish to opt out of all loyalty features, including access to the rewards store. I will not accumulate XP Points or Tokens.',
  mechanicsModalTitleOptIn: 'Reactivate Loyalty Features',
  mechanicsModalTitleOptOut: 'Deactivate Loyalty Features',
  mechanicsModalSubtitleOptIn:
    'Please confirm that you want to reactivate all loyalty features.',
  mechanicsModalSubtitleOptOut:
    'Please confirm that you want to deactivate all loyalty features.',
  mechanicsSuccessOptIn:
    'The process of reactivating the gamification functions was carried out successfully.',
  mechanicsSuccessOptOut:
    'The process of excluding the gamification functions was carried out successfully.',
  mechanicsSuccessOptInExternal:
    'You have been opted in to all gamification features.',
  mechanicsSuccessOptOutExternal:
    'You have been opted out of all gamification features.',
  mechanicsWarning:
    'Upon deactivation, all accumulated XP Points and Tokens, purchased items, as well as your current level, will be automatically lost and cannot be recovered.',
  minute_one: 'Minute',
  minute_other: 'Minutes',
  missionBooster: 'Mission Booster',
  month_one: 'Month',
  month_other: 'Months',
  myBoosters: 'My Boosters',
  myProduct: 'My Product',
  name: 'Name',
  next: 'Next',
  noBoostersYet: 'No Boosters Yet',
  noBoostersHaveBeenBought: 'You haven’t bought/earned any boosters yet.',
  noPurchasesYet: 'No Purchases Yet',
  noProductsHaveBeenBought: 'You haven’t bought any products yet.',
  noRewardsYet: 'No Rewards Yet',
  note: 'Note',
  orderNow: 'Order Now',
  orderBindingly: 'Order Bindingly',
  other: 'Other',
  pageError: 'Page Error',
  pageErrorMessage: 'Sorry there was a connection problem',
  paymentError: 'Payment Error',
  paymentSuccess: 'Payment Success',
  paymentMessageSuccess: 'The payment process was carried out successfully.',
  pending: 'Pending',
  postalCode: 'Postal Code',
  prev: 'Prev',
  price: 'Price',
  productDetails: 'Product Details',
  purchased: 'Purchased',
  purchasedDate: 'Purchased Date',
  qty: 'QTY',
  quantity: 'Quantity',
  rank: 'Rank',
  reactivate: 'Reactivate',
  receives: 'Who receives the item?',
  remainingTokens: 'Remaining Tokens:',
  remainingTokensAfter: 'Remaining Tokens after the order:',
  requiredField: 'This field is required.',
  requiredRankMessage: `Note: You don't have the necessary Rank to buy this product.`,
  requiredFieldMessage: 'All fields are required.',
  rewardShop: 'Reward Shop',
  rewardShopDescription:
    'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.',
  rewardCategorybonus: 'Bonus',
  rewardCategorybooster: 'Booster',
  rewardCategoryexternal_product: 'Merchandise',
  rewardCategoryxp: 'XP Points',
  seeAll: 'See All',
  seeProgress: 'See Progress',
  selectDelivery: 'Select delivery location',
  shipped: 'Shipped',
  status: 'Status',
  street: 'Street',
  streetNumber: 'Street Number',
  tokenBooster: 'Token Booster',
  tokens: 'Tokens',
  tryAgain: 'Try Again',
  week_one: 'Week',
  week_other: 'Weeks',
  writeReason: 'Write a reason here',
  xpPoints: 'XP Points',
  year_one: 'Year',
  year_other: 'Years',
  // new
  out_of_stock: 'Out of Stock',
  third_party: '3rd Party Ordered',
  cancelled: 'Cancelled',
  forNextRank: 'for the next rank!!',
  /** -- NEW MISSIONS -- */
  active: 'Active',
  completed: 'Completed',
  locked: 'Locked',
  claim: 'Claim',
  going: 'Going...',
  incomplete: 'Incomplete',
  ended: 'Ended',
  activeMissions: 'Active Missions',
  lvl: 'LVL',
  missions: 'Missions',
  dailyMission: 'Daily Mission',
  weeklyMission: 'Weekly Mission',
  lifetimeMission: 'Lifetime Mission',
  rangeMission: 'Range Mission',
  monthlyMission: 'Monthly Mission',
  missionProgress: 'Mission Progress',
  missionCompleted: 'Mission Completed',
  missionEnded: 'Mission Ended',
  missionLocked: 'Mission Locked',
  messageMissionCompleted: 'The mission was completed successfully',
  messageMissionBundleCompleted:
    'The mission bundle was completed successfully',
  rewards: 'Rewards',
  id: 'Id',
  durationY: 'Years',
  durationW: 'Weeks',
  durationMth: 'Months',
  durationD: 'Days',
  durationH: 'Hours',
  durationMin: 'Minutes',
  durationS: 'Seconds',
  paymentMethods: 'Payment Methods',
  minimumAmount: 'Minimum Amount',
  channels: 'Channels',
  value: 'Value',
  godsonAccountStatus: 'Godson Account Status',
  missionsHistory: 'History',
  // Channel options from BE
  email: 'Email',
  sms: 'SMS',
  phone: 'Phone',
  post: 'Post',
  // Event options from BE
  completed_deposit: 'Completed Deposit',
  completed_withdrawal: 'Completed Withdrawal',
  wager: 'Wager',
  win: 'Win',
  refer_a_friend: 'Refer a friend',
  login: 'Login',
  activate_account: 'Activate Account (KYC)',
  account_verification: 'Account Verification',
  self_assessment_test: 'Self Assessment Test',
  opt_in: 'Marketing Preferences',
  level_up: 'Level Up',
  // Condition options from BE
  total_bet_amount: 'Total Bet Amount',
  successive_quantity: 'Successive Quantity',
  total_win_amount: 'Total Win Amount',
  // Level options from BE
  any: 'Any',
  specific_level: 'Specific Level',
  // Account status options from BE
  INITIAL: 'Initial',
  PROVISIONAL: 'Provisional',
  ACTIVE: 'Active',
  FULLY_IDENTIFIED: 'Fully Identified',
  BLOCKED_FULL: 'Blocked Full',
  BLOCKED_PARTIAL: 'Blocked Partial',
  CLOSED_PROVISIONAL: 'Closed Provisional',
  CLOSED_ACTIVE: 'Closed Active',
  INACTIVE: 'Inactive',
}
