import { createAsyncThunk } from '@reduxjs/toolkit'
import { t } from 'i18next'
import Container from 'typedi'

import { RewardShopModels, WidgetEndpoints, globalUiActions } from '@/state'
import { Rest } from '@/utils/api.utils'
import { SnackbarSeverityEnum } from '@/enums'

import { RewardShopActionTypes } from '../constants'

const apiService: Rest = Container.get(Rest)

export const orderProductThunk = createAsyncThunk(
  RewardShopActionTypes.ORDER_PRODUCT,
  async (payload: RewardShopModels.OrderProductPayload, thunkAPI) => {
    try {
      const response: RewardShopModels.OrderProductResponse =
        await apiService.sendData({
          method: 'POST',
          endpoint: WidgetEndpoints.ORDERS,
          body: payload,
        })

      if (response.itemId && response.activation) {
        thunkAPI.dispatch(
          globalUiActions.setSnackbar({
            open: true,
            severity: SnackbarSeverityEnum.SUCCESS,
            message: t('activatedSuccessfully'),
          }),
        )
      }

      return response
    } catch (error) {
      thunkAPI.rejectWithValue(error)
    }
  },
)
