import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Box, Button, Chip, Grid, Typography, useTheme } from '@mui/material'

import { IconColorByStatus } from '@/constants'
import { GeneralStatusExtendedEnum } from '@/enums'
import { MissionHistoryModels, RootState } from '@/state'

import { MissionProgressComponent } from './'

type Props = {
  missionStatus: GeneralStatusExtendedEnum
  currentMissionBundle?: MissionHistoryModels.MissionHistoryBundleDetailsType
  handleClaimEasterEgg: (
    originalId: string,
    bundleId: string,
    easterEggId: string,
  ) => void
}

export const MissionBundleDetailsComponent = ({
  missionStatus,
  currentMissionBundle,
  handleClaimEasterEgg,
}: Props) => {
  const { t } = useTranslation()

  const { gamificationPlayer } = useSelector(({ player }: RootState) => player)

  const totalMissions = currentMissionBundle?.missions.length || 0
  const mediaMissions = totalMissions / 2

  const chipColor = IconColorByStatus[missionStatus].color

  const theme = useTheme()

  return (
    <>
      <Box display="flex">
        <Box minWidth="120px">
          <Typography fontWeight={700}>{`${t(
            'missionHistory.status',
          )}:`}</Typography>
          <Chip
            size="small"
            label={t(
              `missionHistory.generalStatusTypeOptions.${missionStatus}`,
            )}
            sx={{
              m: 0.5,
              color: theme.palette.common.white,
              backgroundColor: chipColor,
            }}
          />
        </Box>
        <Box display="flex" gap={1}>
          {currentMissionBundle?.easterEggs.map((easterEgg, index) => {
            if (
              easterEgg.categories.findIndex(
                (category) =>
                  typeof category.playerCategoryId !== 'string' &&
                  category.playerCategoryId?._id ===
                    gamificationPlayer.category.id,
              ) !== -1 &&
              easterEgg.condition.status === GeneralStatusExtendedEnum.PENDING
            ) {
              return (
                <Button
                  key={`easterEgg-${easterEgg.condition.easterEggId}`}
                  variant="outlined"
                  onClick={() =>
                    handleClaimEasterEgg(
                      currentMissionBundle.originalId,
                      currentMissionBundle?._id,
                      easterEgg.condition.easterEggId,
                    )
                  }
                  sx={{
                    height: 30,
                    fontSize: 12,
                    marginTop: 2.5,
                  }}
                >
                  {`${t('missionHistory.easterEgg')} ${index + 1}`}
                </Button>
              )
            } else {
              return ''
            }
          })}
        </Box>
      </Box>

      <Box display="flex" alignItems="center" gap={1} mt={3} mb={0.5}>
        <Typography fontWeight={700}>{`${t(
          'missionHistory.missions',
        )}:`}</Typography>
        <Typography variant="body2">
          {t(
            `missionHistory.${
              currentMissionBundle?.missionsInOrder ? 'thisOrder' : 'anyOrder'
            }`,
            {
              what: `(${currentMissionBundle?.state.completed}/${currentMissionBundle?.state.total})`,
            },
          )}
        </Typography>
      </Box>
      {totalMissions > 0 && (
        <>
          {currentMissionBundle?.missionsInOrder ? (
            <Box
              sx={{
                display: 'grid',
                gridTemplatColumns: 'repeat(2, 1fr)',
                gridTemplateRows: `repeat(${Math.round(mediaMissions)}, 2fr)`,
                gap: 4,
              }}
            >
              {currentMissionBundle?.missions.map((mission, index) => {
                return (
                  <Box
                    key={mission?._id}
                    sx={{
                      gridColumn: Math.round(mediaMissions) > index ? '1' : '2',
                      gridRow:
                        Math.round(mediaMissions) > index
                          ? index + 1
                          : index - mediaMissions + 1,
                      maxWidth:
                        currentMissionBundle.missions.length === 1
                          ? 'calc(50% - 16px)'
                          : 'inherit',
                    }}
                  >
                    <MissionProgressComponent mission={mission} index={index} />
                  </Box>
                )
              })}
            </Box>
          ) : (
            <Grid container spacing={4}>
              {currentMissionBundle?.missions.map((mission) => (
                <Grid item xs={6} key={mission?._id}>
                  <MissionProgressComponent mission={mission} />
                </Grid>
              ))}
            </Grid>
          )}
        </>
      )}
    </>
  )
}
