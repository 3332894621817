import { makeStyles } from 'tss-react/mui'
import { dialogClasses } from '@mui/material'

export const useDialogOnSiteStyles = makeStyles()((theme) => {
  return {
    dialog: {
      borderRadius: 20,
      [`& .${dialogClasses.paper}`]: {
        borderRadius: 16,
        maxWidth: 600,
        width: '100%',
      },
    },
    onsiteDialogContainer: {
      position: 'relative',
      width: '100%',
    },
    htmlContent: {
      display: 'flex',
      justifyContent: 'center',
      padding: '20px 0 0',
    },
    buttonClose: {
      position: 'absolute',
      top: 15,
      [theme.breakpoints.up('md')]: {
        right: 15,
      },
      [theme.breakpoints.down('md')]: {
        right: 8,
      },
    },
  }
})
