import { useSelector } from 'react-redux'
import { Box, Grid, Typography } from '@mui/material'
import { CardItemComponent, IconComponent } from '@/components/common'

import { RewardShopModels, RootState } from '@/state'
import { getFormatNumber } from '@/utils'
import { useStyles } from './style'

type Props = {
  handleOpenProductDetail: (productId: string) => void
  product: RewardShopModels.ProductType
  fullList?: boolean
}

export function ProductCardComponent({
  product,
  fullList,
  handleOpenProductDetail,
}: Props) {
  const { playerLocale, client } = useSelector(
    ({ initConfig }: RootState) => initConfig,
  )

  const { classes } = useStyles({ client })

  const translation =
    product.translations?.find(
      (item) => item.language.toUpperCase() === playerLocale.toUpperCase(),
    ) || product.translations?.[0]

  return (
    <Grid
      item
      key={product._id}
      xs={12}
      sm={6}
      md={4}
      lg={3}
      xl={2}
      width="100%"
      sx={
        fullList
          ? { flex: '0 0 280px!important', maxWidth: ' 280px!important' }
          : {}
      }
    >
      <CardItemComponent
        key={product._id}
        item={{
          id: product._id,
          title: translation?.name || product.name,
          description: translation?.description || product.description,
          image: product.mobileImage,
          ranks: product.ranks?.map((item) => ({
            name:
              item?.translations?.find(
                (trans) =>
                  trans.language.toUpperCase() === playerLocale.toUpperCase(),
              )?.name ||
              item?.translations?.[0]?.name ||
              item?.name,
            id: item._id,
          })),
          tags: product.tags,
        }}
        cardContentComponent={
          <Box className={classes.tokens}>
            <IconComponent type="token" size={25} />
            <Typography variant="body2">
              {getFormatNumber(
                product.reward.price.virtualCurrencyPrice,
                playerLocale,
              )}
            </Typography>
          </Box>
        }
        onClick={() => handleOpenProductDetail(product._id)}
      />
    </Grid>
  )
}
