import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NotificationTypeEnum, SnackbarSeverityEnum } from '@/enums'
import { GlobalUIModels } from './'

const initialState: GlobalUIModels.GlobalUiState = {
  snackbar: {
    message: '',
    open: false,
    severity: SnackbarSeverityEnum.SUCCESS,
  },
  dialog: {
    open: false,
  },
  dialogOnSite: {
    open: false,
  },
  notificationSnackbar: {
    open: false,
  },
  modal: {
    open: false,
  },
  hasSnackbarComponent: false,
  isLoadingFull: false,
}

export const globalUiSlice = createSlice({
  name: 'globalUi',
  initialState,
  reducers: {
    setSnackbar(state, action: PayloadAction<GlobalUIModels.SnackbarType>) {
      state.snackbar = action.payload
    },
    setHasSnackbar(state) {
      state.hasSnackbarComponent = true
    },
    setDialog(state, action: PayloadAction<GlobalUIModels.DialogType>) {
      state.dialog = action.payload
    },
    setDialogOnSite(state, action: PayloadAction<GlobalUIModels.DialogType>) {
      state.dialogOnSite = action.payload
    },
    setNotificationSnackbar(
      state,
      action: PayloadAction<GlobalUIModels.NotificationSnackbarType>,
    ) {
      state.notificationSnackbar = action.payload
    },
    setModal(state, action: PayloadAction<GlobalUIModels.ModalType>) {
      state.modal = action.payload
    },
    setModalType(state, action: PayloadAction<NotificationTypeEnum>) {
      state.modal = {
        ...state.modal,
        type: action.payload,
      }
    },
    setLoadingFull(state, action: PayloadAction<boolean>) {
      state.isLoadingFull = action.payload
    },
  },
})

export const globalUiActions = globalUiSlice.actions
export * from './models'
