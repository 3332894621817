import { makeStyles } from 'tss-react/mui'
import { cardActionAreaClasses } from '@mui/material'

interface Props {
  client: string
}

export const useStyles = makeStyles<Props>()((theme, { client }) => {
  return {
    card: {
      borderRadius: 8,
      [`& .${cardActionAreaClasses.root}`]: {
        height: 'auto',
      },
    },
    cardMedia:
      client === 'JP'
        ? {
            height: '169px',
          }
        : {
            height: '150px',
            [theme.breakpoints.down('sm')]: {
              height: '120px',
            },
          },
    cardContent: {
      padding: '12px',
    },
    title: {
      fontWeight: client === 'JP' ? 400 : 700,
      fontSize: client === 'JP' ? '16px' : '14px',
      lineHeight: client === 'JP' ? 1.2 : 'inherit',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: '2',
      WebkitBoxOrient: 'vertical',
    },
    description: {
      fontSize: '12px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: '2',
      WebkitBoxOrient: 'vertical',
      minHeight: '35px',
    },
    imageChips: {
      position: 'absolute',
      bottom: '4px',
      left: '4px',
    },
  }
})
