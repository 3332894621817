import { useCallback } from 'react'
import { MissionHistoryThunks, useAppDispatch } from '@/state'

export const useMissionsActions = () => {
  const dispatch = useAppDispatch()

  const getActiveBundles = useCallback(() => {
    dispatch(MissionHistoryThunks.getActiveBundlesThunk())
  }, [dispatch])

  const getActiveMissions = useCallback(() => {
    dispatch(MissionHistoryThunks.getActiveMissionsThunk())
  }, [dispatch])

  const getExpiredMissions = useCallback(() => {
    dispatch(MissionHistoryThunks.getExpiredMissionsThunk())
  }, [dispatch])

  return {
    getActiveBundles,
    getActiveMissions,
    getExpiredMissions,
  }
}
