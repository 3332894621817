import { makeStyles } from 'tss-react/mui'
import { typographyClasses } from '@mui/material/Typography'
import { alpha, linearProgressClasses } from '@mui/material'

import { MikadoFontStyle, SidebarColors } from '@/constants'

interface Props {
  sidebarClient?: string
}

export const useLevelStyles = makeStyles<Props>()((
  theme,
  { sidebarClient },
) => {
  return {
    level: {
      display: 'flex',
      position: 'absolute',
      left: 0,
      right: 0,
      width: 'calc(100% - 32px)',
      margin: '0px auto',
      bottom: -32,
      background: SidebarColors[sidebarClient].LEVEL.background,
      border: `6px solid ${SidebarColors[sidebarClient].LEVEL.border}`,
      padding: 15,
      borderRadius: 16,
      alignItems: 'center',
      flexDirection: 'column',
      boxShadow: `0px 10px 0px -1px ${SidebarColors[sidebarClient].LEVEL.shadow}`,
      animation: 'flexAnimationOpacity 0.35s ease-in forwards',

      '@keyframes flexAnimationOpacity': {
        '0%': {
          opacity: 0,
        },

        '100%': {
          opacity: 1,
        },
      },

      [`& .${typographyClasses.body2}`]: {
        fontSize: 16,
        textTransform: 'uppercase',
        position: 'absolute',
        top: -15,
        ...MikadoFontStyle,
      },

      [theme.breakpoints.down('sm')]: {
        bottom: 0,
        marginTop: 15,
        width: '100%',
        position: 'relative',
      },
    },

    progressWrapper: {
      height: 16,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      padding: '11px 3px',
      borderRadius: 16,
      backgroundColor: SidebarColors[sidebarClient].LEVEL.progress,

      '& .linearBorder': {
        width: 'calc(100% - 6px)',
        position: 'absolute',
        top: 3,
        left: 0,
        right: 0,
        margin: '0px auto',
        borderTop: `8px solid ${alpha(theme.palette.common.white, 0.26)}`,
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
        zIndex: 1,
      },

      '& .linearBars': {
        display: 'flex',
        width: '100%',
        position: 'absolute',
        height: 16,
        zIndex: 1,

        '& .linearBar': {
          width: 'calc(100% / 6)',
          boxShadow: `-0.44px 0px 0.44px 0px ${alpha(
            theme.palette.common.white,
            0.15,
          )}`,
        },
      },

      '& .linearNumber': {
        backgroundColor: SidebarColors[sidebarClient].LEVEL.progress,
        position: 'absolute',
        top: -5,
        left: -10,
        width: 32,
        height: 32,
        borderRadius: '50%',
        zIndex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        [`& .${typographyClasses.caption}`]: {
          fontSize: 14,
          marginLeft: -2,
          ...MikadoFontStyle,
        },
      },

      [`& .${linearProgressClasses.root}`]: {
        width: '100%',
        height: 16,
        borderRadius: 16,
        backgroundColor: 'transparent',

        [`& .${linearProgressClasses.bar}`]: {
          borderRadius: 16,
        },
      },

      '& .linearBalance': {
        fontSize: 14,
        position: 'absolute',
        textAlign: 'center',
        zIndex: 2,
        width: '100%',
        ...MikadoFontStyle,
      },

      '& .linearChest': {
        position: 'absolute',
        top: -10,
        right: -10,
        zIndex: 1,
      },
    },
  }
})
