import { useCallback } from 'react'
import { PlayerModels, PlayerThunks, useAppDispatch } from '@/state'

export const useGamificationMechanicsActions = () => {
  const dispatch = useAppDispatch()

  const setGamificationMechanicsAction = useCallback(
    (filters: PlayerModels.SetGamificationOptStatusPayload) => {
      dispatch(PlayerThunks.setGamificationOptStatusThunk(filters))
    },
    [dispatch],
  )

  return {
    setGamificationMechanicsAction,
  }
}
