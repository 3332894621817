import { makeStyles } from 'tss-react/mui'
import { alpha, linearProgressClasses, typographyClasses } from '@mui/material'
import { MikadoFontStyle, MissionColors } from '@/constants'

interface Props {
  client?: string
}

export const useDialogProgressStyles = makeStyles<Props>()((
  theme,
  { client },
) => {
  return {
    dialogMissionProgress: {
      backgroundColor: MissionColors[client].DIALOG_MISSION.backgroundProgress,
      border: `3px solid ${MissionColors[client].DIALOG_MISSION.borderProgress}`,
      borderRadius: 8,
      padding: '8px 16px',

      '& .missionProgressTitle': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

        [`& .${typographyClasses.body1}`]: {
          fontSize: 16,
          ...MikadoFontStyle,
        },
        [`& .${typographyClasses.body2}`]: {
          fontSize: 13,
          paddingLeft: 5,
          ...MikadoFontStyle,
        },
      },

      '& .missionProgressBar': {
        width: 'calc(100% - 5px)',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        padding: '3px',
        borderRadius: 16,
        marginTop: 12,
        marginBottom: 12,
        backgroundColor: MissionColors[client].DIALOG_MISSION.backgrounBar,

        '& .linearBorder': {
          width: 'calc(100% - 6px)',
          position: 'absolute',
          top: 3,
          left: 0,
          right: 0,
          margin: '0px auto',
          borderTop: `12px solid ${alpha(theme.palette.common.white, 0.26)}`,
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          zIndex: 1,
        },

        '& .linearBars': {
          display: 'flex',
          width: '100%',
          position: 'absolute',
          height: 24,
          zIndex: 1,

          '& .linearBar': {
            width: 'calc(100% / 6)',
            borderRight: `0.44px solid ${alpha(
              theme.palette.common.black,
              0.15,
            )}`,
          },

          '& .linearBar:last-child': {
            border: 'none',
          },
        },

        [`& .${linearProgressClasses.root}`]: {
          width: '100%',
          height: 24,
          borderRadius: 24,
          backgroundColor: MissionColors[client].DIALOG_MISSION.progressRoot,

          [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 24,
            backgroundColor: MissionColors[client].DIALOG_MISSION.progressValue,
          },
        },

        '& .linearBalance': {
          fontSize: 14,
          position: 'absolute',
          textAlign: 'center',
          zIndex: 2,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 3,
          ...MikadoFontStyle,
        },

        '& .linearChest': {
          position: 'absolute',
          zIndex: 1,

          '&.opened': {
            top: -28,
            right: -18,
            '& svg': {
              width: 82,
              height: '100%',
            },
          },

          '&.closed': {
            top: -25,
            right: -14,
            '& svg': {
              width: 75,
              height: '100%',
            },
          },
        },
      },
    },

    // Rewards
    dialogMissionRewards: {
      display: 'flex',
      gap: 10,
      alignItems: 'center',

      [`& .${typographyClasses.body1}`]: {
        fontSize: 12,
        ...MikadoFontStyle,
      },

      '& .bagRewards': {
        padding: 0,
        minWidth: 'auto',
        position: 'relative',
        cursor: 'pointer',
        width: 40,

        '& .iconBagRewards': {
          height: 40,
          '& svg': {
            width: 40,
            height: 40,
          },
        },

        '& .iconBagInfo': {
          position: 'absolute',
          top: 0,
          right: -5,

          '& svg': {
            width: 20,
            height: '100%',
          },
        },
      },
    },
  }
})
