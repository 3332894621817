import { useEffect, useRef, useState } from 'react'
import { gsap, Power1 } from 'gsap'
import { LottieRefCurrentProps } from 'lottie-react'

import { NotificationTypeEnum } from '@/enums'
import { useGlobalUi } from '@/hooks'
import { AssetsModels, PlayerModels } from '@/state'

type Props = {
  dataSize: number
  showLevel: string
  gamificationOpt: PlayerModels.GamificationOptType
  gamificationPlayer: PlayerModels.GamificationPlayerType
  images: AssetsModels.AssetsType
}

export const useAnimationAvatarActions = ({
  dataSize,
  showLevel,
  gamificationOpt,
  gamificationPlayer,
  images,
}: Props) => {
  const [hiddenProgressBar, setHiddenProgressBar] = useState(false)
  const [showLevelUp, setShowLevelUp] = useState(false)
  const [showRankUp, setShowRankUp] = useState(false)
  const [showBadge, setShowBadge] = useState(false)
  const [avatarRankImage, setAvatarRankImage] = useState(
    gamificationPlayer?.rank?.imageUrl,
  )
  const { notificationSnackbar, modal } = useGlobalUi()

  const levelUpAvatarAnimationContainerRef = useRef<LottieRefCurrentProps>()
  const levelUpBadgeAnimationContainerRef = useRef<LottieRefCurrentProps>()

  const numberAnimationRef = useRef(null)
  const rankUpAnimationContainerRef = useRef<LottieRefCurrentProps>()

  const avatarRef = useRef<HTMLDivElement>(null)

  const getDataSize = () => {
    switch (true) {
      case dataSize < 110:
        return {
          size: 50,
          avatarLevelSize: { size: 96, top: -17, left: -22 },
          avatarRankSize: { size: 105, top: -26, left: -23 },
          badgeSize: {
            size: 116,
            top: -25,
            left: -33,
          },
        }
      case dataSize >= 140:
        return {
          size: 140,
          avatarLevelSize: { size: 270, top: -48, left: -62 },
          avatarRankSize: { size: 293, top: -72, left: -65.5 },
          badgeSize: {
            size: 147,
            top: 28,
            left: 47,
          },
        }
      default:
        return {
          size: 110,
          avatarLevelSize: { size: 211, top: -37, left: -48 },
          avatarRankSize: { size: 237, top: -60.5, left: -55 },
          badgeSize: {
            size: 142,
            top: 1,
            left: 21,
          },
        }
    }
  }

  const levelUpNumberAnimation = () => {
    if (levelUpBadgeAnimationContainerRef.current) {
      levelUpBadgeAnimationContainerRef.current.play()
      setShowBadge(true)

      setTimeout(() => {
        levelUpBadgeAnimationContainerRef.current.stop()
        setShowBadge(false)
      }, 1500)
    }

    gsap.to(numberAnimationRef.current, {
      y: dataSize === 50 ? -19 : -23,
      ease: 'power4.out',
      duration: 0.1,
      delay: 0.55,
      onComplete: () => {
        gsap.to(numberAnimationRef.current, {
          y: dataSize === 50 ? -8 : 0,
          ease: 'power4.out',
          duration: 0.1,
        })
      },
    })
  }

  const levelUpAvatarAnimation = () => {
    if (levelUpAvatarAnimationContainerRef.current) {
      levelUpAvatarAnimationContainerRef.current.play()
      setShowLevelUp(true)

      setTimeout(() => {
        levelUpAvatarAnimationContainerRef.current.stop()

        setShowLevelUp(false)

        if (modal.type === NotificationTypeEnum.RANK_UP) {
          rankUpAnimation()
        }
      }, 2000)
    }
  }

  const rankUpAnimation = () => {
    const avatar = avatarRef.current

    if (rankUpAnimationContainerRef.current) {
      rankUpAnimationContainerRef.current.play()

      setShowRankUp(true)

      setHiddenProgressBar(true)

      setTimeout(() => {
        rankUpAnimationContainerRef.current.stop()
        setHiddenProgressBar(false)

        setShowRankUp(false)
      }, 3000)
    }

    const tl = gsap.timeline()
    tl.to(avatar, { y: '7.25rem', duration: 0.5, ease: Power1.easeInOut })
      .call(
        () =>
          gamificationPlayer?.rank?.imageUrl !== avatarRankImage &&
          setAvatarRankImage(gamificationPlayer?.rank?.imageUrl),
      )
      .to(avatar, { duration: 0.5, delay: 0.8 })
      .to(avatar, { y: '0 ', duration: 0.5, ease: Power1.easeInOut })
  }

  const playAnimation = () => {
    levelUpAvatarAnimation()
    if (showLevel === 'true' && gamificationOpt?.enable) {
      levelUpNumberAnimation()
    }
  }

  useEffect(() => {
    if (notificationSnackbar.open) {
      playAnimation()
    }
  }, [notificationSnackbar, modal.type]) // eslint-disable-line

  useEffect(() => {
    const avatarImage =
      gamificationPlayer.rank.imageUrl &&
      gamificationPlayer.rank.imageUrl !== '' &&
      gamificationOpt?.enable
        ? gamificationPlayer.rank.imageUrl
        : images?.avatar_image

    setAvatarRankImage(avatarImage)
  }, [gamificationPlayer]) // eslint-disable-line

  return {
    levelUpAvatarAnimationContainerRef,
    levelUpBadgeAnimationContainerRef,
    rankUpAnimationContainerRef,
    numberAnimationRef,
    avatarRef,
    hiddenProgressBar,
    avatarRankImage,
    getDataSize,
    showBadge,
    showLevelUp,
    showRankUp,
  }
}
