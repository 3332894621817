import { ReactComponent as IconClaim } from '@/assets/icons/missions/icon-mission-claim.svg'
import { ReactComponent as IconCompleted } from '@/assets/icons/missions/icon-mission-completed.svg'
import { ReactComponent as IconLocked } from '@/assets/icons/missions/icon-mission-locked.svg'

import { ReactComponent as IconGoing } from '@/assets/icons/missions/icon-mission-going.svg'
import { MissionStatusEnum } from '@/enums'

export const MissionItem = {
  [MissionStatusEnum.CLAIM]: {
    id: MissionStatusEnum.CLAIM,
    label: 'claim',
    icon: <IconClaim />,
  },
  [MissionStatusEnum.COMPLETED]: {
    id: MissionStatusEnum.COMPLETED,
    label: 'completed',
    icon: <IconCompleted />,
  },
  [MissionStatusEnum.ACTIVE]: {
    id: MissionStatusEnum.ACTIVE,
    label: 'going',
    icon: <IconGoing />,
  },
  [MissionStatusEnum.PENDING]: {
    id: MissionStatusEnum.PENDING,
    label: 'locked',
    icon: <IconLocked />,
  },
  [MissionStatusEnum.ENDED]: {
    id: MissionStatusEnum.ENDED,
    label: 'ended',
    icon: <IconGoing />,
  },
}
