import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import {
  MissionHistoryThunks,
  MissionHistoryModels,
} from '@/state/mission-history'

export const getActiveMissionsExtraReducer = (
  builder: ActionReducerMapBuilder<MissionHistoryModels.MissionHistoryState>,
) => {
  builder.addCase(
    MissionHistoryThunks.getActiveMissionsThunk.pending,
    (state) => {
      state.isLoading = true
    },
  )
  builder.addCase(
    MissionHistoryThunks.getActiveMissionsThunk.fulfilled,
    (state, { payload }) => {
      state.missionsActive = payload.docs
      state.isLoading = false
    },
  )
  builder.addCase(
    MissionHistoryThunks.getActiveMissionsThunk.rejected,
    (state) => {
      state.isLoading = false
    },
  )
}
