import { makeStyles } from 'tss-react/mui'
import {
  alpha,
  typographyClasses,
  formControlLabelClasses,
  buttonBaseClasses,
  inputBaseClasses,
  gridClasses,
  checkboxClasses,
  circularProgressClasses,
  filledInputClasses,
  formControlClasses,
  outlinedInputClasses,
} from '@mui/material'

interface Props {
  client: string
}

export const useStyles = makeStyles<Props>()((theme, { client }) => {
  return {
    details: {
      padding: '20px 0px',
      minHeight: '400px',

      '& .imageDesktop': {
        '& img, svg': {
          borderRadius: '12px',
          width: '100%',
          height: '100%',
        },
      },

      '& .imageChips': {
        position: 'absolute',
        top: 20,
        left: 20,

        [theme.breakpoints.down('sm')]: {
          top: 'auto',
          bottom: '15px',
        },
      },

      '& .detailsContent': {
        ...(client === 'JP' && {
          paddingLeft: '60px',
          paddingRight: '60px',

          [theme.breakpoints.down('xl')]: {
            paddingLeft: '30px',
            paddingRight: '30px',
          },

          [theme.breakpoints.down('lg')]: {
            paddingLeft: '10px',
            paddingRight: '20px',
          },

          [theme.breakpoints.down('md')]: {
            paddingLeft: '0px',
            paddingRight: '0px',
          },
        }),

        '& .title':
          client === 'JP'
            ? {
                paddingBottom: '33px',
                fontSize: '50px',
                lineHeight: '58px',
                fontWeight: 700,
                fontStyle: 'italic',

                [theme.breakpoints.down('md')]: {
                  paddingBottom: '20px',
                  fontSize: '30px',
                  lineHeight: '36px',
                },
              }
            : {
                paddingBottom: '24px',
                fontSize: '24px',

                [theme.breakpoints.down('sm')]: {
                  paddingBottom: '10px',
                  fontSize: '20px',
                },
              },

        '& .description':
          client === 'JP'
            ? {
                color: theme.palette.common.black,
                paddingBottom: '30px',
                fontSize: '18px',
                maxWidth: '343px',

                [theme.breakpoints.down('md')]: {
                  maxWidth: '100%',
                  paddingBottom: '0px',
                },

                [theme.breakpoints.down('sm')]: {
                  fontSize: '16px',
                },
              }
            : {
                color: theme.palette.text.secondary,
              },
      },
    },

    tokensBox: {
      display: 'flex',
      alignItems: 'center',
      padding: client === 'JP' ? '12px 0px' : '12px 20px',
      gap: '10px',
      background:
        client === 'JP'
          ? 'transparent'
          : alpha(theme.palette.primary.main, 0.05),
      borderRadius: '8px',
      marginTop: '24px',
      width: 'max-content',

      [theme.breakpoints.down('md')]: {
        padding: client === 'JP' && '40px 0 0',
      },

      [theme.breakpoints.down('xs')]: {
        padding: client === 'JP' && '10px 0 0',
      },

      '&.compact': {
        padding: 0,
        margin: 0,
      },

      [`& .${typographyClasses.body2}`]: {
        color:
          client === 'JP'
            ? theme.palette.common.black
            : theme.palette.primary.main,
        fontSize: client === 'JP' ? '24px' : '20px',
        fontWeight: 700,
      },
    },

    remainingTokens: {
      ...(client === 'JP'
        ? {
            color: theme.palette.common.black,
            display: 'block',
            fontSize: '15px',
            marginBottom: '50px',

            [theme.breakpoints.down('md')]: {
              marginBottom: '0px',
            },
          }
        : {
            color: theme.palette.primary.main,
          }),
      marginTop: '8px',
      fontWeight: 700,
    },

    jpStepOne: {
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'end',

        '& form': {
          alignItems: 'end',
        },
      },

      [theme.breakpoints.down('xs')]: {
        display: 'block',

        '& form': {
          alignItems: 'initial',
        },
      },
    },

    jpStepTwo: {
      display: 'flex',
      gap: '100px',

      [theme.breakpoints.down('lg')]: {
        gap: '50px',
      },

      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        gap: '0px',
      },

      '& .locationWrapper': {
        maxWidth: '689px',
        width: 'auto',

        [theme.breakpoints.down('md')]: {
          maxWidth: '100%',
        },
      },

      '& .title': {
        marginTop: 2,
        marginBottom: 1,
        fontSize: '40px',
        fontStyle: 'italic',
        fontWeight: 700,

        [theme.breakpoints.down('md')]: {
          fontSize: '22px',
        },
      },
    },

    summaryWrapper: {
      marginTop: '30px',

      [theme.breakpoints.down('md')]: {
        marginTop: '0px',
      },

      '& .summaryTitle': {
        fontSize: '28px',
        fontStyle: 'italic',
        fontWeight: 700,
        paddingBottom: '30px',

        [theme.breakpoints.down('md')]: {
          fontSize: '22px',
          paddingBottom: '10px',
        },
      },

      '& .summaryDetails': {
        display: 'flex',
        gap: '45px',

        [theme.breakpoints.down('lg')]: {
          flexDirection: 'column',
          gap: '20px',
        },
      },

      '& .summaryImage': {
        width: '280px',
        borderRadius: '10px',
        marginBottom: '30px',

        [theme.breakpoints.down('lg')]: {
          width: '100%',
          marginBottom: '0px',
        },
      },
    },

    subtitle: {
      fontSize: '22px',
      lineHeight: '28px',
      fontWeight: 700,

      [theme.breakpoints.down('md')]: {
        fontSize: '18px',
        lineHeight: '27px',
      },

      '&.small': {
        [theme.breakpoints.down('lg')]: {
          fontSize: '18px',
        },
      },
    },

    loader: {
      display: 'flex',
      justifyContent: 'center',
      minHeight: '400px',
      alignItems: 'center',
    },

    form: {
      display: 'flex',
      flexDirection: 'column',

      [`& .${filledInputClasses.root}`]: {
        background:
          client === 'JP' ? theme.palette.common.white : 'none !important',
        border: `1px solid ${alpha(theme.palette.text.secondary, 0.2)}`,
        borderRadius: client === 'JP' ? '6px' : '16px',

        '&.Mui-focused, &.Mui-error, &:hover': {
          borderColor: theme.palette.primary.main,
        },

        '&::after, &::before': {
          display: 'none',
        },

        '& svg': {
          color: theme.palette.text.secondary,
        },
      },

      [`& .${outlinedInputClasses.root}`]: {
        background: theme.palette.common.white,
        borderRadius: '6px',

        '&.Mui-focused, &.Mui-error, &:hover fieldset': {
          borderColor: theme.palette.primary.main,
          borderWidth: '1px',
        },
      },

      [`& .${formControlClasses.root}`]: client === 'JP' && {
        marginTop: '5px',
        marginBottom: '18px',
      },

      [`& .${inputBaseClasses.input}`]:
        client === 'JP'
          ? {
              color: theme.palette.common.black,
            }
          : { color: theme.palette.text.secondary },

      '& .quantity': {
        [theme.breakpoints.down('md')]: {
          width: '105px',
          marginBottom: 0,
          marginTop: '20px',
        },
      },

      [`& .quantity .${inputBaseClasses.input}`]: {
        fontSize: '24px',
        fontWeight: 700,
        textAlign: 'center',
        padding: '25px 0px 0px !important',
      },

      '& fieldset': {
        borderRadius: client === 'JP' ? '6px' : '16px',
      },

      '& .grayCaption': {
        color: theme.palette.text.secondary,
      },

      '& .radioGroup': {
        border: `1px solid ${alpha(theme.palette.text.secondary, 0.2)}`,
        padding: '8px 16px',
        marginBottom: '16px',
        borderRadius: client === 'JP' ? '6px' : '16px',
        backgroundColor: client === 'JP' ? '#F0F0F0' : 'transparent',

        [`& .${formControlLabelClasses.label}`]: {
          color:
            client === 'JP'
              ? theme.palette.common.black
              : theme.palette.text.secondary,

          [theme.breakpoints.down('sm')]: {
            maxWidth: client === 'JP' ? '100%' : '50%',
          },

          [`& .${typographyClasses.root}`]: {
            fontSize: client === 'JP' ? '20px' : 'initial',

            [theme.breakpoints.down('md')]: {
              fontSize: 'initial',
            },
          },
        },

        '& .editButton': {
          position: 'absolute',
          top: '10px',
          right: '10px',
          color: alpha(theme.palette.text.secondary, 0.5),

          [`& .${typographyClasses.body1}`]: {
            color: theme.palette.primary.main,
          },
        },
      },

      '& .checkBox': {
        marginTop: '16px',
        marginBottom: '16px',

        [`& .${checkboxClasses.root}`]: {
          transform: 'scale(1.2)',
        },

        [`&.${formControlLabelClasses.root}`]: {
          color: theme.palette.text.secondary,
          letterSpacing: '0.75px',

          [`& .${typographyClasses.body1}`]: {
            paddingLeft: '10px',
          },
        },
      },

      [`& .${gridClasses['grid-xs-12']}`]: {
        width: '100%',
      },

      [`& .${buttonBaseClasses.root}[type=submit]`]: {
        width: '250px',
        height: '56px',
        padding: '10px 0px',
        marginTop: '8px',
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        borderRadius: '12px',

        [`&.${buttonBaseClasses.disabled}`]: {
          backgroundColor: alpha(theme.palette.primary.main, 0.6),
        },

        [`& .${circularProgressClasses.root}`]: {
          color: theme.palette.common.white,
          width: '25px !important',
          height: '25px !important',
          marginLeft: '10px',
        },

        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
      },
    },

    orderButton: {
      width: '280px',
      marginTop: '40px',

      [theme.breakpoints.down('md')]: {
        marginTop: '10px',
        width: '180px',
      },

      [theme.breakpoints.down('xs')]: {
        width: '220px',
        marginTop: '20px',
      },

      '& button': {
        borderRadius: '5px',
        lineHeight: '28px',
        textTransform: 'uppercase',
      },

      [`& button .${circularProgressClasses.root}`]: {
        color: theme.palette.common.white,
        width: '25px !important',
        height: '25px !important',
        marginLeft: '10px',
      },
    },
  }
})
