import { useSelector } from 'react-redux'
import { Box, Typography } from '@mui/material'
import { RootState } from '@/state'

import { SidebarMenuOptions } from '@/constants'
import { SidebarMenuItemType } from '@/enums'
import { useSidebarMenuStyles } from './menu.style'

type Props = {
  sidebarClient: string
  sidebarMenuItem: string
  handleChangeMenuItem?: (sidebarMenuItem: string) => void
}

export const SidebarMenuComponent = ({
  sidebarClient,
  sidebarMenuItem,
  handleChangeMenuItem,
}: Props) => {
  const { classes } = useSidebarMenuStyles({
    sidebarClient,
  })

  const { missionsEnable } = useSelector(
    ({ globalSettings }: RootState) => globalSettings,
  )

  const filterMenuOptions = missionsEnable
    ? Object.values(SidebarMenuOptions)
    : Object.values(SidebarMenuOptions).filter(
        (item) => item.id !== SidebarMenuItemType.MISSIONS,
      )
  return (
    <Box className={classes.sidebarMenu}>
      {filterMenuOptions.map((item) => {
        return (
          <Box
            className={`${classes.sidebarMenuItem} ${
              item.id === sidebarMenuItem ? 'active' : ''
            }`}
            onClick={() =>
              handleChangeMenuItem && handleChangeMenuItem(item.id)
            }
            key={item.id}
          >
            <Box className="menuIcon">{item.icon}</Box>
            <Typography>{item.label}</Typography>
          </Box>
        )
      })}
    </Box>
  )
}
