import { useSelector } from 'react-redux'
import { Box, IconButton, Typography } from '@mui/material'
import { RootState } from '@/state'

import { ReactComponent as IconFavoriteFull } from '@/assets/icons/icon-favorite-full.svg'
import { ReactComponent as IconFavoriteEmpty } from '@/assets/icons/icon-favorite-empty.svg'
import { ReactComponent as IconCloseGame } from '@/assets/icons/icon-close-game.svg'

import { useProfileStyles } from './style'

type Props = {
  Div: Element
}

export const SidebarProfileComponent = ({ Div }: Props) => {
  const { player } = useSelector(({ player }: RootState) => player)

  const { classes } = useProfileStyles()

  const callbackCloseGame = Div.getAttribute('data-handle-close-game')
  const callbackLikeGame = Div.getAttribute('data-handle-like-game')
  const isFavorite = Div.getAttribute('data-favorite') || 'false'

  const handleCloseGame = () => {
    if (typeof window[callbackCloseGame] === 'function') {
      window[callbackCloseGame]()
    }
  }

  const handleLikeGame = () => {
    if (typeof window[callbackLikeGame] === 'function') {
      window[callbackLikeGame]()
    }
  }

  return (
    <Box className={classes.profileContent}>
      <Typography variant="caption">{player.name}</Typography>
      <Box>
        {callbackLikeGame && (
          <IconButton size="small" onClick={handleLikeGame}>
            {isFavorite === 'true' ? (
              <IconFavoriteFull />
            ) : (
              <IconFavoriteEmpty />
            )}
          </IconButton>
        )}
        {callbackCloseGame && (
          <IconButton size="small" onClick={handleCloseGame}>
            <IconCloseGame />
          </IconButton>
        )}
      </Box>
    </Box>
  )
}
