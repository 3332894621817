import { makeStyles } from 'tss-react/mui'
import { darken, typographyClasses } from '@mui/material'
import { MikadoFontStyle } from '@/constants'

export const useStyles = makeStyles()((theme) => {
  return {
    rewardsWrapper: {
      [`& .${typographyClasses.body1}`]: {
        fontSize: 12,
        textTransform: 'uppercase',
        textAlign: 'center',
        ...MikadoFontStyle,
      },

      '& .rewardItem': {
        display: 'flex',
        justifyContent: 'left',
        gap: 5,
        alignItems: 'center',
        background: darken(theme.palette.common.white, 0.06),
        border: `1px solid ${darken(theme.palette.common.white, 0.1)}`,
        borderRadius: 3,
        padding: 4,
        marginTop: 5,
      },

      '& .rewardItemIcon': {
        padding: '3px 3px 0',

        '& svg': {
          width: 25,
          height: 25,
        },
      },

      '& .rewardItemDescription': {
        display: 'flex',
        flexDirection: 'column',

        [`& .${typographyClasses.body2}`]: {
          fontSize: 10,
          color: '#4A5468',
          fontFamily: 'Mikado',
          textTransform: 'capitalize',
          fontWeight: 700,
        },

        [`& .${typographyClasses.caption}`]: {
          fontSize: 12,
          lineHeight: 1,
          ...MikadoFontStyle,
          wordSpacing: 1,
        },
      },
    },
  }
})
