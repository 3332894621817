import { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { TimerOutlined } from '@mui/icons-material'

import { ActiveBoosterType } from '@/state/my-booster/models'
import { useStyles } from './style'
import { CircularProgressComponent } from './circular-progress.component'

import { useActiveBoostersItem } from '../hooks'

type Props = {
  Div: Element
  index: number
  booster: ActiveBoosterType
  showAllItems: boolean
}

export function ActiveBoosterItemComponent({
  Div,
  index,
  booster,
  showAllItems,
}: Props) {
  const {
    progress,
    timeRemaining,
    percentageProgress,
    getBoosterPercentageColor,
    boosterData,
  } = useActiveBoostersItem({
    timeEnd: booster.timeEnd,
    timeStart: booster.timeStart,
    Div,
    booster,
  })

  const { classes: classesStyle, cx } = useStyles({
    boosterType: getBoosterPercentageColor(),
    boosterColors: boosterData.colors,
  })

  const [isOpen, setIsOpen] = useState(index === 0 ? true : false)

  return (
    <>
      {progress !== 0 && (
        <Box
          className={`${cx({
            [classesStyle.container]: true,
            [classesStyle.isOpen]: isOpen,
            [classesStyle.none]: index >= 3 && showAllItems === null,
            [classesStyle.isHide]: index >= 3 && showAllItems === false,
            [classesStyle.isShow]: index >= 3 && showAllItems === true,
          })} opacityAnimation`}
          sx={{
            opacity: getBoosterPercentageColor() !== 'alert' && '1 !important',
          }}
        >
          <Box className={classesStyle.wrapper}>
            <Box
              className={classesStyle.box}
              onClick={() => setIsOpen(!isOpen)}
            >
              <CircularProgressComponent
                percentageProgress={percentageProgress}
                boosterRate={booster.boosterRate}
                image={boosterData.image}
                boosterType={getBoosterPercentageColor()}
                boosterColors={boosterData.colors}
              />
            </Box>
            <Box className={classesStyle.content}>
              <Box className={classesStyle.contentText}>
                <Typography fontWeight={500} variant="caption" lineHeight={1.5}>
                  {boosterData.title}
                </Typography>
                <Box display="flex" gap="5px" alignItems="center">
                  <TimerOutlined className={classesStyle.clockIcon} />
                  <Typography fontWeight={700} variant="caption">
                    {timeRemaining}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  )
}
