import { useCallback } from 'react'
import { debounce } from 'lodash'
import { PaginationResponse, OrderType } from '@/state/models'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type Props<T, C> = {
  setFilters: (newFilters: T) => void
  getAll: (newFilters: T) => void
  filters: T
  pagination: PaginationResponse
}

export const useTableFilter = <
  T extends {
    limit: number
    page: number
    order?: 'asc' | 'desc'
    sortBy?: keyof C
  },
  C,
>({
  setFilters,
  getAll,
  filters,
  pagination,
}: Props<T, C>) => {
  // eslint-disable-next-line
  const filterDelay = useCallback(
    debounce((newFilters: T) => {
      getAll(newFilters)
    }, 500),
    [],
  )

  const handleChangeFilter = (newFilter: Partial<T>, debounce?: boolean) => {
    const newFilters = {
      ...filters,
      ...newFilter,
      page: 1,
    }

    setFilters(newFilters)
    if (debounce) {
      filterDelay(newFilters)
    } else {
      getAll(newFilters)
    }
  }

  const handleChangePage = (newPage: number) => {
    const newFilters = { ...filters, page: newPage }
    setFilters(newFilters)
    getAll(newFilters)
  }

  const handleChangeLimit = (newLimit: number) => {
    const newFilters = {
      ...filters,
      limit: newLimit,
      page:
        newLimit > pagination.total ||
        Math.ceil(pagination.total / newLimit) < pagination.page
          ? 1
          : filters.page,
    }
    setFilters(newFilters)
    getAll(newFilters)
  }

  const handleChangeSort = useCallback(
    (property: keyof C) => {
      return (_event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const isDesc = filters.sortBy === property && filters.order === 'desc'
        const newFilters = {
          ...filters,
          sortBy: property,
          order: (isDesc ? 'asc' : 'desc') as OrderType,
        }
        setFilters(newFilters)
        getAll(newFilters)
      }
    },
    [filters.sortBy, filters.order], // eslint-disable-line
  )

  const handleClearFilters = (newFilters: T) => {
    setFilters(newFilters)
    getAll(newFilters)
  }

  return {
    handleChangePage,
    handleChangeFilter,
    handleChangeLimit,
    handleChangeSort,
    handleClearFilters,
  }
}
