import { useEffect, useRef, useState } from 'react'
import { Box, Slide, Typography } from '@mui/material'
import { useStyles } from './styles'

type Props = {
  counterValue: string | number
  counterTitle: string
  icon: React.ReactElement
  appears: number
}

export const ModalCounterComponent = ({
  counterValue,
  counterTitle,
  icon,
  appears,
}: Props) => {
  const [isVisible, setIsVisible] = useState(false)

  const containerRef = useRef(null)

  const { classes } = useStyles()

  useEffect(() => {
    const timerIn = setTimeout(() => {
      setIsVisible(true)
    }, appears)

    return () => {
      clearTimeout(timerIn)
    }
  }, []) // eslint-disable-line

  return (
    <Slide
      direction="up"
      in={isVisible}
      container={containerRef.current}
      timeout={1000}
    >
      <Box className={classes.counterContainer}>
        <Box className={classes.counterIcon}>
          <Box className={classes.counterIconContainer}>{icon}</Box>
        </Box>
        <Typography className={classes.counterValue}>{counterValue}</Typography>
        <Typography className={classes.counterTitle}>{counterTitle}</Typography>
      </Box>
    </Slide>
  )
}
