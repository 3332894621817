import {
  Tooltip,
  TooltipProps,
  alpha,
  styled,
  tooltipClasses,
} from '@mui/material'

type TooltipCustomProps = {
  maxwidth?: number
  width?: number
}

export const HtmlTooltip = styled(
  ({ className, ...props }: TooltipProps & TooltipCustomProps) => (
    <Tooltip arrow {...props} classes={{ popper: className }} />
  ),
)(({ theme, maxwidth, width }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    maxwidth: maxwidth || 250,
    width: width || 'auto',
    border: `1px solid ${alpha(theme.palette.common.black, 0.05)}`,
    padding: 8,
  },
  [`& .${tooltipClasses.arrow}`]: {
    '&::before': {
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${alpha(theme.palette.common.black, 0.05)}`,
    },
  },
}))
