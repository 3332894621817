export const buttonTheme = {
  root: {
    fontWeight: 700,
    borderRadius: 8,
    textTransform: 'capitalize' as 'capitalize',
    '&.end-icon': {
      justifyContent: 'space-between',
    },
  },
}
