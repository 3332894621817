import { useSelector } from 'react-redux'
import { RootState } from '@/state'
import { TranslationType } from '@/state/models'

export const usePlayerData = () => {
  const { gamificationPlayer } = useSelector(({ player }: RootState) => player)

  const { playerLocale } = useSelector(
    ({ initConfig }: RootState) => initConfig,
  )

  const getTranslationRankName = (translations: TranslationType[]) => {
    return (
      translations?.find(
        (item) => item.language.toUpperCase() === playerLocale.toUpperCase(),
      ) || translations?.[0]
    )
  }

  const translationRanks = getTranslationRankName(
    gamificationPlayer.rank.translations,
  )

  const translationNextRanks = getTranslationRankName(
    gamificationPlayer.nextRank.translations,
  )

  const playerRankName = translationRanks?.name || gamificationPlayer.rank.name

  const playerNextRankName =
    translationNextRanks?.name || gamificationPlayer.nextRank.name

  return { playerRankName, playerNextRankName }
}
