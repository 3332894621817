import { Grid, Skeleton } from '@mui/material'

interface Props {
  element: 'table' | 'product' | 'product-category'
  rows?: number
  carouselClass?: string
}

export const SkeletonLoader = ({ element, rows, carouselClass }: Props) => {
  const items = Array.from({ length: rows || 10 }, (_, index) => index + 1)

  switch (element) {
    case 'table': {
      return (
        <div>
          {items.map((item) => (
            <Skeleton height={64} key={item} />
          ))}
        </div>
      )
    }

    case 'product': {
      return (
        <Grid container spacing={2} flexWrap="nowrap" className={carouselClass}>
          <Grid flex="0 0 250px" item>
            <Skeleton variant="rectangular" width="100%" height={270} />
          </Grid>
          <Grid flex="0 0 250px" item>
            <Skeleton variant="rectangular" width="100%" height={270} />
          </Grid>
          <Grid flex="0 0 250px" item>
            <Skeleton variant="rectangular" width="100%" height={270} />
          </Grid>
          <Grid flex="0 0 250px" item>
            <Skeleton variant="rectangular" width="100%" height={270} />
          </Grid>
          <Grid flex="0 0 250px" item>
            <Skeleton variant="rectangular" width="100%" height={270} />
          </Grid>
          <Grid flex="0 0 250px" item>
            <Skeleton variant="rectangular" width="100%" height={270} />
          </Grid>
          <Grid flex="0 0 250px" item>
            <Skeleton variant="rectangular" width="100%" height={270} />
          </Grid>
        </Grid>
      )
    }

    case 'product-category': {
      return (
        <Grid container spacing={2} mb={2}>
          <Grid item xs>
            <Skeleton variant="rectangular" width="100%" height={270} />
          </Grid>
          <Grid item xs>
            <Skeleton variant="rectangular" width="100%" height={270} />
          </Grid>
          <Grid item xs>
            <Skeleton variant="rectangular" width="100%" height={270} />
          </Grid>
          <Grid item xs>
            <Skeleton variant="rectangular" width="100%" height={270} />
          </Grid>
          <Grid item xs>
            <Skeleton variant="rectangular" width="100%" height={270} />
          </Grid>
          <Grid item xs>
            <Skeleton variant="rectangular" width="100%" height={270} />
          </Grid>
        </Grid>
      )
    }

    default:
      return (
        <div>
          <Skeleton />
        </div>
      )
  }
}
