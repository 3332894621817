import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Typography } from '@mui/material'

import { RootState } from '@/state'
import { IconComponent } from '@/components/common'
import {
  ProductImageChipsComponent,
  ProductComponent,
  useProductForm,
} from '@/components/reward-shop'

import { getFormatNumber } from '@/utils'
import { ProductFormComponent } from './product-form.component'
import { useStyles } from '../style'

export function ProductDetailsComponent() {
  const { t } = useTranslation()

  const { currentProduct } = useSelector(
    ({ rewardShop }: RootState) => rewardShop,
  )

  const { playerLocale, client } = useSelector(
    ({ initConfig }: RootState) => initConfig,
  )

  const { gamificationPlayer } = useSelector(({ player }: RootState) => player)

  const { classes } = useStyles({ client })

  const {
    editLocation,
    productForm,
    quantityOptions,
    handleOnChange,
    handleOnSubmit,
    handleEditLocation,
  } = useProductForm()

  const remainingTokens =
    currentProduct?.reward?.price?.virtualCurrencyPrice * productForm.quantity >
    gamificationPlayer.tokens

  const translation =
    currentProduct?.translations?.find(
      (item) => item.language.toUpperCase() === playerLocale.toUpperCase(),
    ) || currentProduct?.translations?.[0]

  return (
    <ProductComponent>
      <>
        <Grid item sm={12} md={6}>
          <ProductImageChipsComponent />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="detailsContent">
            <Typography className="title">
              {translation?.name || currentProduct?.name}
            </Typography>
            <Typography className="description">
              {translation?.description || currentProduct?.description}
            </Typography>
            <Box className={classes.tokensBox} mb={1}>
              <Typography variant="body2">
                {getFormatNumber(
                  currentProduct?.reward?.price?.virtualCurrencyPrice,
                  playerLocale,
                )}
              </Typography>
              <IconComponent type="token" />
            </Box>
            <Typography
              variant="caption"
              className={classes.remainingTokens}
            >{`${t('remainingTokens')} ${getFormatNumber(
              gamificationPlayer.tokens,
              playerLocale,
            )}`}</Typography>
            <ProductFormComponent
              disableButton={remainingTokens}
              editLocation={editLocation}
              quantityOptions={quantityOptions}
              productForm={productForm}
              handleEditLocation={handleEditLocation}
              handleOnChange={handleOnChange}
              handleOnSubmit={handleOnSubmit}
            />
          </Box>
        </Grid>
      </>
    </ProductComponent>
  )
}
