import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import { uniqBy } from 'lodash'
import { MyBoosterThunks, MyBoosterModels } from '@/state'

import { RewardShopBoosterTypeEnum } from '@/enums'

export const getTokenActiveBoostersExtraReducer = (
  builder: ActionReducerMapBuilder<MyBoosterModels.MyBoosterState>,
) => {
  builder.addCase(
    MyBoosterThunks.getTokenActiveBoostersThunk.fulfilled,
    (state, { payload }) => {
      const tokenDocs =
        payload.docs?.map((token) => {
          return { ...token, boosterType: RewardShopBoosterTypeEnum.TOKEN }
        }) || []
      state.activeBoosters = uniqBy(
        [...state.activeBoosters, ...tokenDocs],
        '_id',
      )
    },
  )
}
