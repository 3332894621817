import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Country } from 'country-list'

import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { RootState } from '@/state'
import { ProductFormType } from '@/state/reward-shop/models'

import { IconComponent } from '@/components/common'
import { getFormatNumber } from '@/utils'
import { ImageDefault } from '@/constants'

import { DeliveryTypeEnum } from '@/enums'
import { useStyles } from '../style'

type Props = {
  disableButton: boolean
  disableByRank: boolean
  productForm: ProductFormType
  quantityOptions: number[]
  handleOnChange: (
    field: string,
    newValue: string | number | boolean | Country,
  ) => void
  handleOnSubmit: () => void
}

export function JPProductFormSummaryComponent({
  disableButton,
  disableByRank,
  productForm,
  quantityOptions,
  handleOnChange,
  handleOnSubmit,
}: Props) {
  const { t } = useTranslation()

  const { currentProduct, isLoadingOrder } = useSelector(
    ({ rewardShop }: RootState) => rewardShop,
  )

  const { playerLocale, client } = useSelector(
    ({ initConfig }: RootState) => initConfig,
  )

  const { gamificationPlayer } = useSelector(({ player }: RootState) => player)

  const { classes } = useStyles({ client })

  const theme = useTheme()

  const translation =
    currentProduct?.translations?.find(
      (item) => item.language.toUpperCase() === playerLocale.toUpperCase(),
    ) || currentProduct?.translations?.[0]

  const disableByFieldEmpty =
    productForm.deliveryType === DeliveryTypeEnum.CUSTOM &&
    Object.values(productForm).some(
      (value) => value?.toString().trim() === '' || value === null,
    )

  return (
    <Box className={classes.summaryWrapper}>
      <Typography className="summaryTitle">
        {t('myProduct').toUpperCase()}
      </Typography>
      <Box className="summaryDetails">
        <img
          src={currentProduct?.desktopImage || ImageDefault}
          alt={translation?.name || currentProduct?.name}
          className="summaryImage"
        />
        <Box>
          <Typography
            className={classes.subtitle}
            sx={{
              paddingBottom: '20px',
              fontStyle: 'italic',
            }}
          >
            {translation?.name || currentProduct?.name}
          </Typography>
          <Box>
            <Box className={`${classes.tokensBox} compact`} mb={1}>
              <IconComponent type="token" size={30} />
              <Typography variant="body2">
                {getFormatNumber(
                  currentProduct?.reward?.price?.virtualCurrencyPrice,
                  playerLocale,
                )}
              </Typography>
            </Box>
            <form className={classes.form}>
              <TextField
                className="quantity"
                label={t('qty')}
                variant="filled"
                margin="normal"
                select
                value={productForm.quantity}
                onChange={(e) => handleOnChange('quantity', e.target.value)}
                sx={{ width: '143px' }}
              >
                {quantityOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </form>
          </Box>
        </Box>
      </Box>

      <Typography
        className={`${classes.subtitle} small`}
        sx={{ paddingTop: '20px' }}
      >{`${t('remainingTokensAfter')} ${getFormatNumber(
        gamificationPlayer.tokens -
          productForm.quantity *
            currentProduct?.reward?.price?.virtualCurrencyPrice,
        playerLocale,
      )}`}</Typography>
      <Box className={classes.orderButton}>
        <Button
          onClick={handleOnSubmit}
          variant="contained"
          fullWidth
          disabled={disableButton || disableByFieldEmpty || disableByRank}
          endIcon={isLoadingOrder && <CircularProgress />}
        >
          {t('orderBindingly')}
        </Button>
        {(disableButton || disableByFieldEmpty || disableByRank) && (
          <Typography
            color={theme.palette.error.main}
            sx={{ marginTop: '8px' }}
          >
            {disableButton
              ? t('disabledSubmit')
              : disableByFieldEmpty
                ? t('requiredFieldMessage')
                : t('requiredRankMessage')}
          </Typography>
        )}
      </Box>
    </Box>
  )
}
