import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { uniqBy } from 'lodash'
import { MyBoosterModels } from '@/state/my-booster'

import { LIMIT } from '@/constants'
import {
  getLevelActiveBoostersExtraReducer,
  getMissionActiveBoostersExtraReducer,
  getMyBoostersExtraReducer,
  getTokenActiveBoostersExtraReducer,
} from './'

const initialState: MyBoosterModels.MyBoosterState = {
  myBoosters: [],
  pagination: {
    total: 0,
    limit: LIMIT,
    page: 1,
    pages: 1,
  },
  myBoostersCount: 0,
  isLoading: false,
  activeBoosters: [],
}

export const myBoosterSlice = createSlice({
  name: '@my-boosters',
  initialState,
  reducers: {
    setPage(state, action: PayloadAction<number>) {
      state.pagination.page = action.payload
    },
    addActiveBooster(
      state,
      action: PayloadAction<MyBoosterModels.SocketActivateBoosterType>,
    ) {
      state.activeBoosters = uniqBy(
        [...state.activeBoosters, action.payload],
        '_id',
      )
    },
    removeActiveBooster(
      state,
      action: PayloadAction<MyBoosterModels.SocketActivateBoosterType>,
    ) {
      const filterBoosters = state.activeBoosters.filter(
        (booster) => booster._id !== action.payload._id,
      )

      state.activeBoosters = filterBoosters
    },
    setLoadingFalse(state) {
      state.isLoading = false
    },
  },
  extraReducers: (builder) => {
    getLevelActiveBoostersExtraReducer(builder)
    getMyBoostersExtraReducer(builder)
    getTokenActiveBoostersExtraReducer(builder)
    getMissionActiveBoostersExtraReducer(builder)
  },
})

export const MyBoosterActions = myBoosterSlice.actions
