import { useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { RootState } from '@/state'

import { useBreakpoints } from '@/hooks'
import { ImageChips } from '@/components/common'
import { ImageDefault } from '@/constants'

export function ProductImageChipsComponent() {
  const { currentProduct } = useSelector(
    ({ rewardShop }: RootState) => rewardShop,
  )

  const { playerLocale } = useSelector(
    ({ initConfig }: RootState) => initConfig,
  )

  const { isMobile } = useBreakpoints()

  const translation =
    currentProduct?.translations?.find(
      (item) => item.language.toUpperCase() === playerLocale.toUpperCase(),
    ) || currentProduct?.translations?.[0]

  return (
    <Box position="relative">
      <Box className="imageDesktop">
        <img
          src={currentProduct?.desktopImage || ImageDefault}
          alt={translation?.name || currentProduct?.name}
        />
      </Box>
      {(currentProduct?.ranks?.length > 0 ||
        currentProduct?.tags?.length > 0) && (
        <Box className="imageChips">
          {currentProduct?.ranks?.length > 0 && (
            <ImageChips
              items={currentProduct?.ranks?.map((item) => ({
                name:
                  item?.translations?.find(
                    (trans) =>
                      trans.language.toUpperCase() ===
                      playerLocale.toUpperCase(),
                  )?.name ||
                  item?.translations?.[0]?.name ||
                  item?.name,
                id: item._id,
              }))}
              type="ranks"
              size={isMobile ? 'small' : 'medium'}
              margins="0 4px 4px 0"
            />
          )}
          {currentProduct?.tags?.length > 0 && (
            <ImageChips
              items={currentProduct?.tags}
              type="tags"
              size={isMobile ? 'small' : 'medium'}
              margins="0 4px 4px 0"
            />
          )}
        </Box>
      )}
    </Box>
  )
}
