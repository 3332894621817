import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()((theme) => {
  return {
    alertGroup: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
    },
    alertContainer: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontWeight: 700,
      width: '100%',
      display: 'flex',
      '& .GamanzaAlert-message': {
        width: '100%',
        padding: 0,
      },
    },
    alertIconWrapper: {
      position: 'absolute',
      display: 'flex',
      zIndex: 100,
      left: 0,
      width: '70px',
      height: '65px',
      [theme.breakpoints.up('sm')]: {
        left: -70,
        width: '145px',
        height: '115px',
      },
    },
    alertIcon: {
      [theme.breakpoints.up('sm')]: {
        width: '145px',
        height: '115px',
      },
      [theme.breakpoints.down('sm')]: {
        width: '70px',
        height: '65px',
      },
    },
    levelValue: {
      color: theme.palette.common.white,
      textAlign: 'center',
      fontWeight: 700,
      position: 'absolute',
      left: '50%',
      top: 10,
      lineHeight: '1',
      transform: 'translate(-50%, 0)',
      fontSize: '30px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '60px',
      },
    },
  }
})
