import { useTranslation } from 'react-i18next'
import { Typography, Box } from '@mui/material'
import { Error } from '@mui/icons-material'

import { useStyles } from './style'

export const MechanicsWarningComponent = () => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  return (
    <Box className={classes.warning}>
      <Error />
      <Box>
        <Typography
          variant="body2"
          component="span"
          sx={{
            textTransform: 'uppercase',
            fontWeight: 700,
            pr: 1,
          }}
        >
          {`${t('important')}:`}
        </Typography>
        <Typography variant="body2" component="span">
          {t('mechanicsWarning')}
        </Typography>
      </Box>
    </Box>
  )
}
