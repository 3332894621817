import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Box, Button, Typography } from '@mui/material'

import { ArrowForward } from '@mui/icons-material'
import { NotificationSnackbarComponent } from '@/components/common'
import { useBreakpoints, useGlobalUi, usePlayerData } from '@/hooks'

import { NotificationTypeEnum } from '@/enums'
import { RootState } from '@/state'
import { ModalProgressContentComponent } from './'

import { useStyles } from './styles'
import { useNotificationAnimations } from '../hooks'

export const NotificationsComponent = () => {
  const { t } = useTranslation()

  const { modal, handleOpenModal } = useGlobalUi()

  const { gamificationPlayer } = useSelector(({ player }: RootState) => player)

  const { classes } = useStyles()

  const { isMobile } = useBreakpoints()

  const { playerRankName } = usePlayerData()

  const { initialAnimationData, finalAnimationData } =
    useNotificationAnimations()

  return (
    <>
      {!modal.open && (
        <NotificationSnackbarComponent>
          <Box className={classes.notificationContainer}>
            <Box>
              <Typography fontWeight={700}>{t('congratulations')}</Typography>
              <Typography>
                {modal.type === NotificationTypeEnum.RANK_UP
                  ? t('congratulationsRankUp', {
                      rank: playerRankName,
                    })
                  : t('congratulationsLevelUp', {
                      level: gamificationPlayer.level.levelNumber,
                    })}
              </Typography>
            </Box>
            <Button
              className={classes.notificationButton}
              variant="outlined"
              onClick={() => handleOpenModal()}
            >
              {!isMobile && t('seeProgress')}
              <ArrowForward className={classes.notificationButtonIcon} />
            </Button>
          </Box>
        </NotificationSnackbarComponent>
      )}

      {modal.open && (
        <ModalProgressContentComponent
          initialAnimationData={initialAnimationData}
          finalAnimationData={finalAnimationData}
        />
      )}
    </>
  )
}
