import { makeStyles } from 'tss-react/mui'
import { alpha, buttonClasses, lighten, typographyClasses } from '@mui/material'
import {
  MikadoBlackFontStyle,
  MikadoFontStyle,
  MissionColors,
} from '@/constants'

import { MissionStatusEnum } from '@/enums'

interface Props {
  status: string
  sidebarClient: string
  expanded?: boolean
}

export const useMissionItemStyles = makeStyles<Props>()((
  theme,
  { status, sidebarClient, expanded },
) => {
  const missionColorStatus =
    MissionColors[sidebarClient].STATUS[status.toUpperCase()]

  return {
    missionWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      borderRadius: 16,
      width: 78,
      height: expanded ? 79 : 45,
      padding: 8,
      background: expanded ? missionColorStatus.backgroundWrapper : 'none',
      boxShadow: expanded
        ? `0px 8px 0px 0px ${missionColorStatus.shadow}`
        : 'none',
      cursor: 'pointer',

      [theme.breakpoints.down('sm')]: {
        width: expanded ? 78 : 62,
        padding: expanded ? 8 : '8px 0px',
        marginRight: expanded ? 0 : -30,
      },
    },

    missionContent: {
      width: '100%',
      height: '100%',
      borderRadius: 12,
      background: expanded ? missionColorStatus.backgroundContent : 'none',
      position: 'relative',

      '& .missionIcon svg': {
        position: 'absolute',
        top: -21,
        left: 0,
        right: 0,
        margin: '0 auto',
        width: '100%',
        height: expanded ? '100%' : 'auto',
        zIndex: 2,
      },

      [`& .${typographyClasses.body2}`]: {
        ...MikadoFontStyle,
        width: expanded ? '100%' : 'auto',
        position: 'absolute',
        bottom: 5,
        right: expanded ? 'auto' : 5,
        textAlign: 'center',
        fontSize: expanded ? 11 : 8,
        textTransform: 'uppercase',
        border: expanded
          ? 'none'
          : `1px solid ${alpha(theme.palette.common.white, 0.4)}`,
        background: expanded ? 'none' : missionColorStatus.backgroundContent,
        boxShadow: expanded
          ? 'none'
          : `0px 1px 0px 0px ${missionColorStatus.shadow}`,
        zIndex: 2,
        borderRadius: expanded ? 0 : 4,
        padding: expanded ? 0 : '2px 3px',
      },

      [`& .${typographyClasses.caption}`]: {
        position: 'absolute',
        width: 'max-content',
        bottom: expanded ? -15 : 5,
        left: expanded ? 0 : 'auto',
        right: expanded ? 0 : 5,
        margin: '0 auto',
        textAlign: 'center',
        fontSize: 9,
        borderRadius: 4,
        paddingLeft: 2,
        paddingRight: 2,
        background: missionColorStatus.backgroundContent,
        border: `1px solid ${lighten(
          missionColorStatus.backgroundContent,
          0.5,
        )}`,
        ...MikadoFontStyle,
      },
    },

    missionProgress: {
      width: '100%',
      height: expanded ? '100%' : 'auto',

      [`& .${typographyClasses.body1}`]: {
        fontSize: 12,
        display: expanded ? 'flex' : 'none',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        left: 0,
        right: 0,
        margin: '0 auto',
        top: expanded ? 0 : -3,
        zIndex: 1,
        ...MikadoFontStyle,
      },

      '& .missionCircularProgress': {
        position: 'absolute',
        top: -13.5,
        right: 0,
        left: 0,
        margin: '0 auto',
        color:
          MissionColors[sidebarClient].STATUS[
            MissionStatusEnum.ACTIVE.toUpperCase()
          ].progress,
      },

      '& circle': {
        strokeLinecap: 'round',
      },

      '& .missionIcon svg': {
        zIndex: 0,
      },

      '& .missionIconArrow svg': {
        width: 31,
        height: 31,
        position: 'absolute',
        top: -21,
        right: 0,
      },
    },

    missionItemHistory: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      background:
        status === MissionStatusEnum.COMPLETED
          ? missionColorStatus.backgroundWrapper
          : missionColorStatus.backgroundContent,
      border: `5px solid ${
        status === MissionStatusEnum.COMPLETED
          ? missionColorStatus.backgroundContent
          : missionColorStatus.backgroundWrapper
      }`,
      boxShadow: `0px 3px 0px 0px ${missionColorStatus.shadow}`,
      height: 58,
      borderRadius: 12,
      gap: 10,

      [`& .missionProgress .${typographyClasses.body1}`]: {
        display: 'flex !important',
        top: '0 !important',
      },

      '& .missionItemHistoryIcon': {
        transform: 'scale(0.65) translate(-13px, 5px)',
        width: 40,
        [`& .${typographyClasses.body2}`]: {
          display: 'none',
        },
      },

      '& .missionItemHistoryText': {
        [`& .${typographyClasses.body2}`]: {
          fontSize: 14,
          lineHeight: '18px',
          ...MikadoBlackFontStyle,
        },
      },

      [`& .${buttonClasses.root}`]: {
        position: 'absolute',
        right: 8,
        top: 8,
        minWidth: 30,
        padding: 0,
      },
    },
  }
})
