import { alpha, typographyClasses } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { ActiveBoostersColors } from '@/constants'

import { BoosterColorsType } from '@/state/my-booster/models'

interface Props {
  boosterType?: string
  boosterColors?: BoosterColorsType
}

export const useStyles = makeStyles<
  Props,
  'content' | 'contentText' | 'wrapper'
>()((theme, { boosterType = 'warning', boosterColors }, classes) => {
  const isWarningAlertType =
    boosterType === 'warning' || boosterType === 'alert'

  const textColor = !isWarningAlertType
    ? boosterColors?.textColor
      ? boosterColors.textColor
      : theme.palette.common.white
    : ActiveBoostersColors[boosterType].baseColor

  const baseColor =
    !isWarningAlertType && boosterColors?.baseColor
      ? boosterColors.baseColor
      : ActiveBoostersColors[boosterType].baseColor

  const backgroundColor =
    !isWarningAlertType && boosterColors?.background
      ? boosterColors.background
      : ActiveBoostersColors[boosterType].background

  const actionColor = theme.palette.common.white

  return {
    boostersContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'left',
      rowGap: '5px',
    },
    isOpen: {
      [`& .${classes.content}`]: {
        maxWidth: 300,
        transition: 'all 500ms',
        padding: '0 16px 0 48px',
      },
      [`& .${classes.contentText}`]: {
        opacity: 1,
        transition: 'all 750ms',
      },
    },
    wrapper: {
      width: 'auto',
    },
    box: {
      height: 42,
      width: 42,
      borderRadius: 42,
      position: 'absolute',
      zIndex: 1,
      background: isWarningAlertType
        ? ActiveBoostersColors[boosterType].backgroundCircular
        : backgroundColor,
      border: `1px solid ${baseColor}`,
      cursor: 'pointer',
      '& .completed': {
        color:
          !isWarningAlertType && boosterColors?.baseColor
            ? alpha(boosterColors.baseColor, 0.5)
            : ActiveBoostersColors[boosterType].borderColor,
        position: 'absolute',
        top: 0,
        left: 0,
      },

      '& .circular': {
        color: baseColor,
      },
    },
    contentText: {
      opacity: 0,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      transition: 'all 750ms',
    },
    content: {
      background: backgroundColor,
      border: `1px solid ${baseColor}`,
      color: textColor,
      height: 42,
      borderRadius: 42,
      maxWidth: 42,
      padding: 0,
      transition: 'all 500ms',
      overflow: 'hidden',
      lineHeight: 1,
      display: 'flex',
      alignItems: 'center',
    },
    container: {
      width: 'auto',
      height: 42,
      borderRadius: 42,
      display: 'inline-block',
      whiteSpace: 'nowrap',
      position: 'relative',
    },
    none: {
      [`& .${classes.wrapper}`]: {
        width: 0,
        opacity: 0,
      },
    },
    isHide: {
      [`& .${classes.wrapper}`]: {
        width: 0,
        opacity: 0,
        overflow: 'hidden',
        transition: 'all 500ms',
      },
    },
    isShow: {
      [`& .${classes.wrapper}`]: {
        opacity: 1,
        transition: 'all 500ms',
      },
    },

    clockIcon: {
      fontSize: '15px',
      color: textColor,
    },

    rateContainer: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      display: 'flex',
      alignItems: 'center',

      '& svg': {
        width: '31px',
        height: '31px',

        '& g path': {
          fill: isWarningAlertType
            ? `${ActiveBoostersColors[boosterType].baseColor} !important`
            : boosterColors?.baseColor && boosterColors?.baseColor,
        },
      },

      '& .rate': {
        position: 'absolute',
        fontSize: '8px',
        borderRadius: '6px',
        textAlign: 'center',
        border: '1px solid',
        padding: '1px',
        lineHeight: '12px',
        fontWeight: 700,
        minWidth: '25px',
        bottom: '-7px',
        left: '12px',
        borderColor: theme.palette.common.white,
        color: theme.palette.common.white,
        background:
          !isWarningAlertType && boosterColors?.baseColor
            ? boosterColors.baseColor
            : ActiveBoostersColors[boosterType].baseColorGradient,
      },
    },

    defaultActions: {
      padding: 0,
      minWidth: '42px',
      zIndex: 2,

      '& .floatingAction': {
        position: 'relative',
        height: '42px',
        width: '42px',
      },

      '& .lengthBoosters': {
        display: 'flex',
        minWidth: '19px',
        padding: '0px 4px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '1px',
        borderRadius: '4px',
        border: `0.5px solid ${actionColor}`,
        background: '#543183',
        position: 'absolute',
        bottom: '-3px',
        right: '-3px',
        fontSize: '8px',
        color: actionColor,
      },
    },

    customActions: {
      background: 'linear-gradient(180deg, #4F2D93 0%, #4D3186 100%)', // default color
      borderRadius: '50%',
      minWidth: '42px',
      width: '42px',
      height: '42px',

      '&.showAllItems': {
        padding: 0,
        color: actionColor,
        position: 'relative',
      },

      '&.hideMoreThan3Items': {
        svg: {
          fontSize: '1rem',
          fill: actionColor,
        },
      },

      [`& .${typographyClasses.body1}`]: {
        fontSize: '14px',
        fontWeight: 600,
      },
    },
  }
})
