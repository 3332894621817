import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Country } from 'country-list'

import { ArrowForward, CheckBox, Edit } from '@mui/icons-material'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { RootState } from '@/state'

import { DeliveryTypeEnum, RewardShopTypeEnum } from '@/enums'
import { ProductFormType } from '@/state/reward-shop/models'
import { useStyles } from '../style'

import { LocationFormComponent } from './location-form.component'
import { TooltipComponent } from '@/components/common'
import { ReactComponent as BoosterIcon } from '@/assets/boosterFloatingAction.svg'

type Props = {
  disableButton: boolean
  productForm: ProductFormType
  quantityOptions: number[]
  editLocation: boolean
  handleEditLocation: () => void
  handleOnChange: (
    field: string,
    newValue: string | number | boolean | Country,
  ) => void
  handleOnSubmit: () => void
}

export function ProductFormComponent({
  disableButton,
  productForm,
  quantityOptions,
  editLocation,
  handleEditLocation,
  handleOnChange,
  handleOnSubmit,
}: Props) {
  const { t } = useTranslation()

  const { currentProduct, isLoadingOrder } = useSelector(
    ({ rewardShop }: RootState) => rewardShop,
  )

  const { client } = useSelector(({ initConfig }: RootState) => initConfig)

  const { casinoPickup } = useSelector(
    ({ globalSettings }: RootState) => globalSettings,
  )

  const theme = useTheme()

  const { classes } = useStyles({ client })

  const disableByFieldEmpty =
    productForm.deliveryType === DeliveryTypeEnum.CUSTOM &&
    Object.values(productForm).some(
      (value) => value?.toString().trim() === '' || value === null,
    )

  const disableByRank = currentProduct?.ranks?.length > 0

  return (
    <Box py={2}>
      <form
        onSubmit={(event) => {
          event.preventDefault()
          handleOnSubmit()
        }}
        className={classes.form}
      >
        <TextField
          label={t('qty')}
          variant="filled"
          margin="normal"
          select
          value={productForm.quantity}
          onChange={(e) => handleOnChange('quantity', e.target.value)}
          sx={{ width: '120px' }}
        >
          {quantityOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>

        {currentProduct.reward.type === RewardShopTypeEnum.EXTERNAL && (
          <>
            <Typography
              variant="caption"
              className="grayCaption"
              sx={{
                marginTop: 2,
                marginBottom: 1,
              }}
            >
              {t('selectDelivery')}
            </Typography>
            <FormControl sx={{ width: '100%' }}>
              <RadioGroup
                value={productForm.deliveryType}
                onChange={(e) => handleOnChange('deliveryType', e.target.value)}
              >
                <Box className="radioGroup">
                  <FormControlLabel
                    value={DeliveryTypeEnum.CUSTOM}
                    control={<Radio />}
                    label={`${productForm.street}, ${productForm.postalCode}, ${productForm.city}, ${productForm.receives}`}
                  />
                  <Button
                    onClick={handleEditLocation}
                    endIcon={<Edit />}
                    className="editButton"
                  >
                    {t('edit')}
                  </Button>
                  {editLocation && (
                    <LocationFormComponent
                      handleOnChange={handleOnChange}
                      productForm={productForm}
                    />
                  )}
                </Box>
                {casinoPickup && (
                  <Box className="radioGroup">
                    <FormControlLabel
                      value={DeliveryTypeEnum.CASINO}
                      control={<Radio />}
                      label={t('casinoLocation')}
                    />
                  </Box>
                )}
              </RadioGroup>
            </FormControl>
            {productForm.deliveryType === DeliveryTypeEnum.CASINO && (
              <Typography
                variant="caption"
                className="grayCaption"
                sx={{
                  marginTop: -1,
                  marginBottom: 2.5,
                }}
              >
                <>
                  <strong>{`${t('note')}: `}</strong>
                  {t('casinoLocationMessage')}
                </>
              </Typography>
            )}
          </>
        )}

        <Box display="flex" justifyContent="flex-start">
          {currentProduct.reward.type === RewardShopTypeEnum.BOOSTER && (
            <>
              <FormControlLabel
                className="checkBox"
                control={
                  <Checkbox
                    name="ignoreDevice"
                    checked={productForm.activateBooster}
                    onChange={(e) =>
                      handleOnChange('activateBooster', e.target.checked)
                    }
                    checkedIcon={<CheckBox />}
                  />
                }
                label={t('activateBooster')}
              />
              <Box position="relative">
                <TooltipComponent
                  title={t('activateBooster')}
                  description={t('activeBoosterDescription')}
                  icon={
                    <Box display="flex" justifyContent="center">
                      <BoosterIcon width={36} height={36} />
                    </Box>
                  }
                  maxWidth={340}
                />
              </Box>
            </>
          )}
        </Box>

        <Button
          type="submit"
          variant="contained"
          fullWidth
          disabled={disableButton || disableByFieldEmpty || disableByRank}
          endIcon={isLoadingOrder ? <CircularProgress /> : <ArrowForward />}
        >
          {t('buyNow')}
        </Button>
        {(disableButton || disableByFieldEmpty || disableByRank) && (
          <Typography
            color={theme.palette.error.main}
            sx={{ marginTop: '8px' }}
          >
            {disableButton
              ? t('disabledSubmit')
              : disableByFieldEmpty
                ? t('requiredFieldMessage')
                : t('requiredRankMessage')}
          </Typography>
        )}
      </form>
    </Box>
  )
}
