import { createAsyncThunk } from '@reduxjs/toolkit'
import Container from 'typedi'
import { Rest } from '@/utils/api.utils'

import { OrderModels, WidgetEndpoints } from '@/state'
import { OrderActionTypes } from '../constants'

const apiService: Rest = Container.get(Rest)

export const getAllOrdersThunk = createAsyncThunk(
  OrderActionTypes.GET_ALL_ORDERS,
  async (payload: OrderModels.GetAllOrderPayload, thunkAPI) => {
    try {
      const response: OrderModels.GetAllOrdersResponse =
        await apiService.sendData({
          method: 'GET',
          endpoint: WidgetEndpoints.ORDERS,
          queries: payload,
        })

      return thunkAPI.fulfillWithValue({
        response,
        rewardType: payload.rewardType,
      })
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
