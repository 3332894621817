import { createAsyncThunk } from '@reduxjs/toolkit'
import { t } from 'i18next'
import Container from 'typedi'

import { Rest } from '@/utils/api.utils'
import {
  PlayerActions,
  PlayerModels,
  WidgetEndpoints,
  globalUiActions,
} from '@/state'
import { SnackbarSeverityEnum } from '@/enums'

import { PlayerActionTypes } from '../constants'

const apiService: Rest = Container.get(Rest)

export const setGamificationOptStatusThunk = createAsyncThunk(
  PlayerActionTypes.SET_GAMIFICATION_OPT_STATUS,
  async (payload: PlayerModels.SetGamificationOptStatusPayload, thunkApi) => {
    thunkApi.dispatch(PlayerActions.setIsLoading(true))
    try {
      const response: PlayerModels.GetGamificationOptStatusResponse =
        await apiService.sendData({
          method: 'PUT',
          endpoint: WidgetEndpoints.SET_GAMIFICATION_OPT_STATUS,
          body: payload,
        })

      thunkApi.dispatch(PlayerActions.setIsLoading(false))

      const success = response?.statusCode?.toString() === '200'

      if (!success) {
        thunkApi.dispatch(
          globalUiActions.setSnackbar({
            message: t('errorOccurred'),
            open: true,
            severity: SnackbarSeverityEnum.ERROR,
          }),
        )
      } else {
        thunkApi.dispatch(
          globalUiActions.setDialog({
            open: false,
          }),
        )
      }

      return response
    } catch (error) {
      throw error
    }
  },
)
