export const MikadoFontStyle = {
  textShadow: '0 2px 0 #00000099',
  WebkitTextStrokeWidth: '1px',
  WebkitTextStrokeColor: 'black',
  fontFamily: 'Mikado',
  fontWeight: '900',
  color: '#ffffff',
  letterSpacing: '-0.5px',
  wordSpacing: '2px',
}

export const MikadoBlackFontStyle = {
  textShadow: '0 1px 0 #00000099',
  WebkitTextStrokeWidth: '1px',
  WebkitTextStrokeColor: 'black',
  fontFamily: 'Mikado',
  fontWeight: '700',
  color: '#ffffff',
  letterSpacing: '-0.5px',
  wordSpacing: '2px',
}
