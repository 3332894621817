import { createAsyncThunk } from '@reduxjs/toolkit'
import Container from 'typedi'
import { Rest } from '@/utils/api.utils'

import { MissionHistoryModels, WidgetEndpoints } from '@/state'
import { MissionHistoryActionTypes } from '../constants'

const apiService: Rest = Container.get(Rest)

export const getExpiredMissionsThunk = createAsyncThunk(
  MissionHistoryActionTypes.GET_EXPIRED_MISSIONS,
  async (_, thunkAPI) => {
    try {
      const response: MissionHistoryModels.GetMissionsResponse =
        await apiService.sendData({
          method: 'GET',
          endpoint: WidgetEndpoints.GET_EXPIRED_MISSIONS,
        })

      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
