import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'

import { RewardType } from '@/state/player/models'
import { RootState } from '@/state'
import { RewardTypeEnum } from '@/enums'

import { ReactComponent as LevelBooster } from '@/assets/levelBooster.svg'
import { ReactComponent as MissionBooster } from '@/assets/missionBooster.svg'
import { ReactComponent as TokenBooster } from '@/assets/tokenBooster.svg'

import { ReactComponent as Bonus } from '@/assets/bonus-icon.svg'
import { IconComponent } from '@/components/common'
import { useStyles } from './styles'

type Props = {
  reward: RewardType
}

export const ModalRewardComponent = ({ reward }: Props) => {
  const { classes } = useStyles()

  const { t } = useTranslation()

  const { playerLocale } = useSelector(
    ({ initConfig }: RootState) => initConfig,
  )

  const rewardName = () => {
    const rewardTranslation = reward.translations?.find(
      (translation) => translation.language === playerLocale.toUpperCase(),
    )?.description

    switch (reward.type) {
      case RewardTypeEnum.BONUS_OFFER:
        return rewardTranslation || reward?.bonusOfferId

      case RewardTypeEnum.LEVEL_BOOSTER:
        return (
          rewardTranslation || `${reward?.boosterRate}X ${t('levelBooster')}`
        )

      case RewardTypeEnum.MISSION_BOOSTER:
        return (
          rewardTranslation || `${reward?.boosterRate}X ${t('missionBooster')}`
        )

      case RewardTypeEnum.TOKEN_BOOSTER:
        return (
          rewardTranslation || `${reward?.boosterRate}X ${t('tokenBooster')}`
        )

      case RewardTypeEnum.TOKENS:
        return rewardTranslation || `${reward?.amount} ${t('tokens')}`
    }
  }

  const rewardIcon = () => {
    switch (reward.type) {
      case RewardTypeEnum.TOKENS:
        return <IconComponent type="token" size={36} />

      case RewardTypeEnum.TOKEN_BOOSTER:
        return <TokenBooster className={classes.rewardIcons} />

      case RewardTypeEnum.LEVEL_BOOSTER:
        return <LevelBooster className={classes.rewardIcons} />

      case RewardTypeEnum.MISSION_BOOSTER:
        return <MissionBooster className={classes.rewardIcons} />

      case RewardTypeEnum.BONUS_OFFER:
        return <Bonus className={classes.rewardIcons} />
    }
  }

  return (
    <Box className={classes.rewardContainer}>
      <Box display="flex" justifyContent="center" minWidth={40} minHeight={40}>
        {rewardIcon()}
      </Box>
      <Typography className={classes.rewardName}>{rewardName()}</Typography>
    </Box>
  )
}
