import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Check, Close, Search } from '@mui/icons-material'

import { Avatar, Box, Button, Typography, Grid } from '@mui/material'
import { RewardShopActions, RootState, useAppDispatch } from '@/state'
import { MenuItemType } from '@/enums'

import { useStyles } from './style'

type Props = {
  title?: string
  message?: string
  iconType?: 'search' | 'success' | 'error'
  buttonContent?: React.ReactNode
  hideButtonContent?: boolean
  hideIcon?: boolean
}

export function ContentIconComponent({
  iconType,
  title,
  message,
  buttonContent,
  hideButtonContent,
  hideIcon,
}: Props) {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const { client } = useSelector(({ initConfig }: RootState) => initConfig)

  const handleChangeMenuItem = (newValue: string) => {
    dispatch(RewardShopActions.setMenuSelected(newValue))
    dispatch(RewardShopActions.resetRewardShopPage())
  }

  const { classes } = useStyles()

  const getIconType = (): React.ReactNode => {
    switch (iconType) {
      case 'search':
        return <Search />

      case 'success':
        return <Check />

      case 'error':
        return <Close />
    }
  }

  return (
    <Box
      pt={client === 'JP' ? '0px' : '80px'}
      pb={client === 'JP' ? '20px' : '80px'}
      px="10%"
      className={classes.content}
    >
      <Grid container>
        {!hideIcon && (
          <Grid item xs={12}>
            <Avatar className={`${classes.avatar} ${iconType}`}>
              {getIconType()}
            </Avatar>
          </Grid>
        )}
        {title && (
          <Grid item xs={12}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 700,
                marginTop: 4,
                textAlign: 'center',
                fontSize: '20px',
              }}
            >
              {title}
            </Typography>
          </Grid>
        )}
        {message && (
          <Grid item xs={12}>
            <Typography
              variant="body2"
              mt={4}
              mb={2}
              textAlign="center"
              fontWeight={500}
            >
              {message}
            </Typography>
          </Grid>
        )}
        {!hideButtonContent && (
          <Grid item xs={12}>
            <Box width={180} mt={2} mx="auto">
              {buttonContent ? (
                buttonContent
              ) : (
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => handleChangeMenuItem(MenuItemType.REWARD_SHOP)}
                  sx={{ width: '100%', padding: '10px 0' }}
                >
                  {t('goShopping')}
                </Button>
              )}
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}
