import { useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '@/state'

import {
  JPProductFormStepOneComponent,
  useProductForm,
} from '@/components/reward-shop'
import { JPProductFormStepTwoComponent } from './jp-product-form.step-2.component'

export function JPProductDetailsComponent() {
  const { currentProduct } = useSelector(
    ({ rewardShop }: RootState) => rewardShop,
  )

  const { gamificationPlayer } = useSelector(({ player }: RootState) => player)

  const [formStep, setFormStep] = useState(1)

  const handleChangeStepForm = (step: number) => {
    setFormStep(step)
  }

  const { productForm, quantityOptions, handleOnChange, handleOnSubmit } =
    useProductForm()

  const remainingTokens =
    currentProduct?.reward?.price?.virtualCurrencyPrice * productForm.quantity >
    gamificationPlayer.tokens

  const disableByRank = currentProduct?.ranks?.length > 0

  return formStep === 1 ? (
    <JPProductFormStepOneComponent
      disableButton={remainingTokens}
      disableByRank={disableByRank}
      productForm={productForm}
      quantityOptions={quantityOptions}
      handleOnSubmit={handleOnSubmit}
      handleOnChange={handleOnChange}
      handleChangeStepForm={handleChangeStepForm}
    />
  ) : (
    <JPProductFormStepTwoComponent
      disableButton={remainingTokens}
      disableByRank={disableByRank}
      productForm={productForm}
      quantityOptions={quantityOptions}
      handleOnChange={handleOnChange}
      handleOnSubmit={handleOnSubmit}
      handleChangeStepForm={handleChangeStepForm}
    />
  )
}
