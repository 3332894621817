import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Button,
  CircularProgress,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  alpha,
  colors,
} from '@mui/material'

import { MoreVert, Repeat } from '@mui/icons-material'
import { MissionHistoryModels } from '@/state'
import { menuProps } from '@/constants'

import { GeneralStatusExtendedEnum } from '@/enums'

type Props = {
  missionBundle: MissionHistoryModels.MissionHistoryBundleType
  color: string
  icon: React.ReactNode
  timeFrameFormat: string
  handleUpdateBundleStatus: (
    bundleId: string,
    status: GeneralStatusExtendedEnum,
  ) => void
}

export const MissionBundleTitleComponent = ({
  missionBundle,
  color,
  icon,
  timeFrameFormat,
  handleUpdateBundleStatus,
}: Props) => {
  const { t } = useTranslation()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  return (
    <>
      <Box position="relative" display="inline-flex">
        <Box
          sx={{
            inset: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: alpha(color, 0.15),
            borderRadius: '50%',
            border: `3.5px solid ${alpha(colors.grey[500], 0.7)}`,
          }}
        >
          {icon}
        </Box>
        <CircularProgress
          variant="determinate"
          value={missionBundle.state.progress}
          size={52}
          thickness={3}
          sx={{ color: color }}
        />
        {missionBundle.settings.repetition && (
          <Box
            sx={{
              top: 0,
              right: -10,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: color,
              borderRadius: 13,
              paddingX: 0.5,
              paddingY: 0,
              height: 18,
            }}
          >
            <Typography variant="caption" fontWeight={700}>
              {missionBundle.settings.priority}
            </Typography>
            <Repeat sx={{ width: 12 }} />
          </Box>
        )}
      </Box>
      <Box pl={2} display="flex" justifyContent="space-between" width="100%">
        <Box>
          <Typography>{missionBundle.internalDetails.name}</Typography>
          <Typography variant="caption">
            {`${t(
              `missionHistory.timeFrameTypeOptions.${missionBundle.settings.timeFrame.timeFrameType}`,
            )} - ${timeFrameFormat}`}
          </Typography>
        </Box>
        {(missionBundle.status === GeneralStatusExtendedEnum.ACTIVE ||
          missionBundle.status === GeneralStatusExtendedEnum.PAUSED) && (
          <Tooltip arrow title={t('missionHistory.options')}>
            <Button
              size="small"
              onClick={(event) => {
                setAnchorEl(event.currentTarget)
                event.stopPropagation()
              }}
              sx={{
                backgroundColor: alpha(colors.grey[300], 0.5),
                minWidth: 'auto',
                width: 23,
                height: 23,
                borderRadius: '50%',
              }}
            >
              <MoreVert fontSize="inherit" htmlColor={colors.grey[500]} />
            </Button>
          </Tooltip>
        )}
      </Box>

      <Menu
        {...menuProps}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {missionBundle.status !== GeneralStatusExtendedEnum.ACTIVE && (
          <MenuItem
            onClick={(event) => {
              handleUpdateBundleStatus(
                missionBundle._id,
                GeneralStatusExtendedEnum.ACTIVE,
              )
              setAnchorEl(null)
              event.stopPropagation()
            }}
          >
            <ListItemText primary={t('missionHistory.active')} />
          </MenuItem>
        )}
        {missionBundle.status !== GeneralStatusExtendedEnum.PAUSED && (
          <MenuItem
            onClick={(event) => {
              handleUpdateBundleStatus(
                missionBundle._id,
                GeneralStatusExtendedEnum.PAUSED,
              )
              setAnchorEl(null)
              event.stopPropagation()
            }}
          >
            <ListItemText primary={t('missionHistory.pause')} />
          </MenuItem>
        )}
      </Menu>
    </>
  )
}
