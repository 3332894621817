import { alpha, buttonClasses, typographyClasses } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

type Props = {
  tryAgain: boolean
}

export const useStyles = makeStyles<Props>()((theme, tryAgain) => {
  return {
    content: {
      padding: '15px',
      borderRadius: '4px',
      color: theme.palette.error.main,
      display: 'flex',
      alignItems: 'center',
      border: `1px solid ${alpha(theme.palette.common.black, 0.15)}`,
      boxShadow: `2px 2px 2px ${alpha(theme.palette.common.black, 0.1)}`,
      justifyContent: tryAgain ? 'space-between' : 'inherit',

      [`& .${buttonClasses.root}`]: {
        padding: 0,
        color: theme.palette.error.main,
      },
    },
    message: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',

      [`& .${typographyClasses.body2}`]: {
        fontWeight: 700,
      },
    },
    tryAgain: {},
  }
})
