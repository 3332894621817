import { useCallback } from 'react'
import {
  MissionHistoryActions,
  MissionHistoryThunks,
  MissionHistoryModels,
  useAppDispatch,
} from '@/state'
import { removeEmptyProp } from '@/utils'

export const useMissionsHistoryActions = () => {
  const dispatch = useAppDispatch()

  const claimEasterEgg = useCallback(
    (payload: MissionHistoryModels.ClaimEasterEggPayload) => {
      dispatch(MissionHistoryThunks.claimEasterEggThunk(payload))
    },
    [dispatch],
  )

  const getAllMissionBundles = useCallback(
    (filters: MissionHistoryModels.MissionHistoryFiltersType) => {
      const newFilters = removeEmptyProp(filters)
      dispatch(MissionHistoryThunks.getAllMissionBundlesThunk(newFilters))
    },
    [dispatch],
  )

  const getOneMissionBundle = useCallback(
    (bundleId: string) => {
      dispatch(MissionHistoryThunks.getOneMissionBundleThunk({ bundleId }))
    },
    [dispatch],
  )

  const setMissionsHistoryFilters = useCallback(
    (filters: MissionHistoryModels.MissionHistoryFiltersType) => {
      dispatch(MissionHistoryActions.setFilters(filters))
    },
    [dispatch],
  )

  const updateMissionBundleStatus = useCallback(
    (payload: MissionHistoryModels.UpdateMissionBundleStatusPayload) => {
      dispatch(MissionHistoryThunks.updateBundleStatusThunk(payload))
    },
    [dispatch],
  )

  return {
    claimEasterEgg,
    getAllMissionBundles,
    getOneMissionBundle,
    setMissionsHistoryFilters,
    updateMissionBundleStatus,
  }
}
