import { Box, Divider, LinearProgress, Typography } from '@mui/material'
import { MissionModels } from '@/state'
import { GeneralStatusExtendedEnum } from '@/enums'

import { ReactComponent as IconObjectiveCompleted } from '@/assets/icons/missions/icon-objective-completed.svg'
import { ReactComponent as IconObjectiveActive } from '@/assets/icons/missions/icon-objective-active.svg'
import { ReactComponent as IconObjectiveLocked } from '@/assets/icons/missions/icon-objective-locked.svg'

import { useDialogObjectivesStyles } from './objetives.style'
import { useDialogMissionObjectives } from '../../hooks'

type Props = {
  objectives: MissionModels.ObjectiveMissionTypes[] // TODO: This type will change in the future
  status: GeneralStatusExtendedEnum
  client: string
}

export const DialogMissionObjectivesComponent = ({
  objectives,
  status,
  client,
}: Props) => {
  const { getObjectiveName } = useDialogMissionObjectives()

  const { classes } = useDialogObjectivesStyles({ client })

  const linearBars = Array.from(new Array(6), (_, index) => (
    <Box className="linearBar" key={`border_${index}`} />
  ))

  const getIcon = (progress: number) => {
    if (progress === 100) {
      return <IconObjectiveCompleted />
    }

    if (status === GeneralStatusExtendedEnum.ACTIVE) {
      return <IconObjectiveActive />
    }

    return <IconObjectiveLocked />
  }

  return (
    <Box className={classes.dialogObjetivesWrapper}>
      <Divider />
      <Box className={classes.dialogObjectivesContent}>
        {objectives?.map((objective) => {
          return (
            <Box className={classes.dialogObjetive} key={objective._id}>
              {getIcon(Number(objective.state?.progress))}
              <Box className="objectiveDescription">
                <Typography>{getObjectiveName(objective)}</Typography>
                {Number(objective.state?.progress) !== 100 && (
                  <Box className="objectiveProgressBar">
                    <Box className="linearBorder" />
                    <Box className="linearBars">{linearBars}</Box>
                    <LinearProgress
                      variant="determinate"
                      value={objective.state?.progress || 0}
                      className={
                        status === GeneralStatusExtendedEnum.ENDED ||
                        status === GeneralStatusExtendedEnum.INACTIVE ||
                        status === GeneralStatusExtendedEnum.PENDING
                          ? 'inactive'
                          : ''
                      }
                    />
                    <Typography variant="caption" className="linearBalance">
                      {`${objective.state?.completed || 0}/${
                        objective.state?.total || 0
                      }`}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}
