import { Service } from 'typedi'
import SocketSubscriptionService from './socket.service'
import { decodeJWT } from './jwt.util'

export type ApiMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'

export const objectToQueryString = (obj: any) =>
  Object.keys(obj)
    .filter((key) => obj[key] !== '')
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join('&')

/**
 * @description Handle API REST calls
 * @class Rest
 */

export type ApiResult = 'success' | 'failure'

type ResponseType = 'blob' | 'json'

export interface RequestParams {
  method: ApiMethod
  endpoint: string
  headers?: Object
  body?: Object
  queries?: Object
  responseType?: ResponseType
}

@Service()
export class Rest {
  /**
   *
   * @description Method to perform REST requests
   * @memberof Rest
   */

  private getToken = () =>
    JSON.parse(localStorage.getItem('gamanzaengage_token'))

  sendData = async <D>(params: {
    url?: string
    method: ApiMethod
    endpoint: string
    body?: unknown
    queries?: any
    token?: string
  }): Promise<D> => {
    const { body, method, endpoint, queries } = params
    const url = this.getURL({ url: params.url, endpoint, queries })
    const token = this.getToken() || params.token
    try {
      const req = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        method,
      }
      if (body) req['body'] = JSON.stringify(body)
      const response = await fetch(url, req)

      const text = await response.text()

      if (text === '') {
        return { statusCode: response.status } as any
      }

      // if (response.status.toString() === '401') {
      //   store.dispatch(AuthThunks.logoutThunk())
      // }

      const data = JSON.parse(text)
      return { ...data, statusCode: response.status }
    } catch (error) {
      return error as any
    }
  }

  sendFile = async <D>(params: {
    endpoint: string
    body?: any
    token?: string
  }): Promise<D> => {
    const { body, endpoint } = params
    const url = this.getURL({ endpoint })
    const token = this.getToken() || params.token
    try {
      const req = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: 'POST',
        body,
      }
      const response = await fetch(url, req)

      const text = await response.text()

      if (text === '') {
        return { statusCode: response.status } as any
      }

      // if (response.status.toString() === '401') {
      //   store.dispatch(AuthThunks.logoutThunk())
      // }

      const data = JSON.parse(text)
      return { ...data, statusCode: response.status }
    } catch (error) {
      return error as any
    }
  }

  /**
   *
   * @description Build the url to fetch
   * @memberof Rest
   */
  private getURL = (params?: {
    endpoint: string
    url?: string
    queries?: string
  }) => {
    const { endpoint, queries } = params
    let queryParams
    if (queries) queryParams = objectToQueryString(queries)

    const serverUrl =
      params?.url ||
      JSON.parse(localStorage.getItem('gamanzaengage_serviceUrl'))
    const url$ = queries
      ? `${serverUrl}${endpoint}?${queryParams}`
      : `${serverUrl}${endpoint}`

    return url$
  }
}

/**
 * @function wsSubscriptionFactory
 * Singleton Factory function to create an only one instance of the Socket Service
 */
export const wsSubscriptionFactory = function (namespace?: string) {
  let segmentSubscription: SocketSubscriptionService | undefined

  return {
    getInstance: () => {
      if (!segmentSubscription) {
        segmentSubscription = new SocketSubscriptionService({
          namespace,
        })
      }
      return segmentSubscription
    },
    destroy: () => {
      if (segmentSubscription) {
        segmentSubscription?.disconnect()
        segmentSubscription = undefined
      }
    },
  }
}

export const hasTokenExpired = () => {
  const token = JSON.parse(localStorage.getItem('gamanzaengage_token'))

  const { decoded, isValid } = decodeJWT(token)

  if (isValid) {
    return decoded.exp < Math.floor(Date.now() / 1000)
  } else {
    return false
  }
}
