import { useSelector } from 'react-redux'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import {
  TimeZoneUTCEnum,
  TimeZoneEnum,
  TimeFrameEnum,
  GeneralStatusExtendedEnum,
} from '@/enums'
import { getDateTimeFromIsoUTC } from '@/utils/date.util'
import { dateMonthTableFormat, dateTableFormat } from '@/constants/date'

import { RootState, MissionHistoryModels } from '@/state'
import { useMissionsHistoryActions } from './use-missions-history-actions.hook'

export const useMissionBundleItem = (
  missionBundle: MissionHistoryModels.MissionHistoryBundleType,
) => {
  const { t } = useTranslation()

  const { currentHistoryBundles } = useSelector(
    ({ missionHistory }: RootState) => missionHistory,
  )

  const { claimEasterEgg, getOneMissionBundle, updateMissionBundleStatus } =
    useMissionsHistoryActions()

  const handleOneMissionBundle = () => {
    if (
      currentHistoryBundles.length === 0 ||
      !currentHistoryBundles.find((item) => item._id === missionBundle._id)
    ) {
      getOneMissionBundle(missionBundle._id)
    }
  }

  const formatDate = (date?: string, formatDate?: string) => {
    return date
      ? format(
          getDateTimeFromIsoUTC(date, TimeZoneUTCEnum[TimeZoneEnum.GMT_0]),
          formatDate,
        )
      : '-'
  }

  const getTimeFrameFormat = () => {
    const { timeFrame } = missionBundle.settings
    switch (timeFrame.timeFrameType) {
      case TimeFrameEnum.DAILY:
        return formatDate(
          (timeFrame as MissionHistoryModels.DateTimeFrameType).date,
          dateTableFormat,
        )

      case TimeFrameEnum.WEEKLY:
      case TimeFrameEnum.RANGE:
        return `${formatDate(
          (timeFrame as MissionHistoryModels.DateRangeTimeFrameType).startDate,
          dateTableFormat,
        )} ${t('missionHistory.to').toLowerCase()} ${formatDate(
          (timeFrame as MissionHistoryModels.DateRangeTimeFrameType).endDate,
          dateTableFormat,
        )}`

      case TimeFrameEnum.MONTHLY:
        return formatDate(
          (timeFrame as MissionHistoryModels.DateTimeFrameType).date,
          dateMonthTableFormat,
        )

      case TimeFrameEnum.LIFETIME:
        return t('missionHistory.infinite')

      default:
        return '-'
    }
  }

  const handleClaimEasterEgg = (
    originalId: string,
    bundleId: string,
    easterEggId: string,
  ) => {
    claimEasterEgg({ originalId, bundleId, easterEggId })
  }

  const handleUpdateBundleStatus = (
    bundleId: string,
    status: GeneralStatusExtendedEnum,
  ) => {
    updateMissionBundleStatus({
      bundleId,
      status,
    })
  }

  return {
    currentHistoryBundles,
    getTimeFrameFormat,
    handleOneMissionBundle,
    handleUpdateBundleStatus,
    handleClaimEasterEgg,
  }
}
