import { createAsyncThunk } from '@reduxjs/toolkit'
import Container from 'typedi'
import { t } from 'i18next'

import { Rest } from '@/utils/api.utils'
import { SnackbarSeverityEnum } from '@/enums'
import { globalUiActions } from '@/state/global-ui'

import { WidgetEndpoints } from '@/state/endpoints'
import { RootState } from '@/state'
import { rewardText } from '@/utils'

import { MissionHistoryActionTypes } from '../constants'
import { MissionHistoryModels } from '../'

const apiService: Rest = Container.get(Rest)

export const claimEasterEggThunk = createAsyncThunk(
  MissionHistoryActionTypes.CLAIM_EASTER_EGG,
  async (payload: MissionHistoryModels.ClaimEasterEggPayload, thunkAPI) => {
    try {
      let response: MissionHistoryModels.ClaimEasterEggResponse =
        await apiService.sendData({
          method: 'POST',
          endpoint: WidgetEndpoints.CLAIM_EASTER_EGG,
          body: {
            bundleId: payload.originalId,
            easterEggId: payload.easterEggId,
          },
        })

      if (response.easterEgg) {
        const { initConfig, player } = thunkAPI.getState() as RootState

        const category = response.easterEgg.categories.find(
          (category) =>
            category.playerCategoryId === player.gamificationPlayer.category.id,
        )

        if (category) {
          const rewardsText = category.rewards.map((reward) => {
            return rewardText(reward, initConfig.playerLocale)
          })

          thunkAPI.dispatch(
            globalUiActions.setSnackbar({
              open: true,
              severity: SnackbarSeverityEnum.SUCCESS,
              message: t('missionHistory.claimedSuccessfully', {
                what: rewardsText.join(' - '),
              }),
            }),
          )
        }
      } else {
        thunkAPI.dispatch(
          globalUiActions.setSnackbar({
            open: true,
            severity: SnackbarSeverityEnum.ERROR,
            message: t('missionHistory.claimedNoContent'),
          }),
        )

        return thunkAPI.rejectWithValue(response.message)
      }

      return response.easterEgg
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
