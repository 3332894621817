import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import {
  MissionHistoryThunks,
  MissionHistoryModels,
} from '@/state/mission-history'

export const getOneMissionBundleExtraReducer = (
  builder: ActionReducerMapBuilder<MissionHistoryModels.MissionHistoryState>,
) => {
  builder.addCase(
    MissionHistoryThunks.getOneMissionBundleThunk.fulfilled,
    (state, { payload }) => {
      state.currentHistoryBundles = payload
        ? [...state.currentHistoryBundles, { ...payload }]
        : state.currentHistoryBundles
    },
  )
}
