import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import {
  MissionHistoryThunks,
  MissionHistoryModels,
} from '@/state/mission-history'

export const claimEasterEggExtraReducer = (
  builder: ActionReducerMapBuilder<MissionHistoryModels.MissionHistoryState>,
) => {
  builder.addCase(
    MissionHistoryThunks.claimEasterEggThunk.fulfilled,
    (state, { payload, meta }) => {
      const { bundleId } = meta.arg

      const currentBundles = state.currentHistoryBundles.map(
        (currentBundle) => {
          if (currentBundle._id === bundleId) {
            const newEasterEggs = currentBundle.easterEggs.map((easterEgg) => {
              if (
                easterEgg.condition.easterEggId ===
                payload.condition.easterEggId
              ) {
                return payload
              } else {
                return easterEgg
              }
            })

            return {
              ...currentBundle,
              easterEggs: newEasterEggs,
            }
          } else {
            return currentBundle
          }
        },
      )

      state.currentHistoryBundles = currentBundles
    },
  )
}
