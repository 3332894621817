import Lottie from 'lottie-react'
import { Box, Typography, colors } from '@mui/material'
import { IconComponent } from '@/components/common'

import { getFormatNumber } from '@/utils'
import { LottieAnimationData } from '@/state/models'
import { useTokensLottieAnimations } from '../../hooks'

import {
  TokensProfileBigAnimationsComponent,
  TokensProfileSmallAnimationsComponent,
} from './'

type Props = {
  textColor: string
  bigWin: boolean
  showAnimation: boolean
  setShowAnimation: (showAnimation: boolean) => void
  animationData: LottieAnimationData
}

export function TokensLottieAnimationComponent({
  textColor,
  bigWin,
  showAnimation,
  setShowAnimation,
  animationData,
}: Props) {
  const {
    bigBoxRef,
    bigCircleOneRef,
    bigCircleTwoRef,
    boxRef,
    circleRef,
    client,
    numberRef,
    opacityCircleRef,
    playerLocale,
    smallLottieRef,
    oldTokens,
    // TODO: We could use this animation in the future
    // bigLottieRef,
  } = useTokensLottieAnimations({
    bigWin,
    setShowAnimation,
    showAnimation,
  })

  return (
    <>
      <Box
        ref={boxRef}
        position="absolute"
        top={-4}
        left={-25}
        width="calc(100% + 35px)"
        height="calc(100% + 8px)"
        borderRadius={10}
        bgcolor={colors.amber[500]}
        sx={{ opacity: 0 }}
        display={showAnimation ? 'inline' : 'none'}
        zIndex={10}
      />

      <TokensProfileBigAnimationsComponent
        bigBoxRef={bigBoxRef}
        bigCircleOneRef={bigCircleOneRef}
        bigCircleTwoRef={bigCircleTwoRef}
        opacityCircleRef={opacityCircleRef}
        display={bigWin ? 'inline' : 'none'}
      />

      <TokensProfileSmallAnimationsComponent
        circleRef={circleRef}
        display={!bigWin ? 'inline' : 'none'}
      />

      <Box position="relative" display="flex" zIndex={20}>
        {/* TODO: We could use this animation in the future
        <Box display={bigWin ? 'inline' : 'none'}>
          <Lottie
            animationData={
              client === 'JP' ? bigWinAnimationJP : bigWinAnimation
            }
            autoplay={false}
            loop={false}
            lottieRef={bigLottieRef}
            style={
              client === 'JP'
                ? {
                    width: 211,
                    position: 'absolute',
                    left: -90,
                    top: -60,
                  }
                : {
                    width: 284,
                    position: 'absolute',
                    left: -127.5,
                    top: -102,
                  }
            }
          />
        </Box> */}

        <Box display={!bigWin ? 'inline' : 'none'}>
          <Lottie
            animationData={animationData}
            autoplay={false}
            loop={false}
            lottieRef={smallLottieRef}
            style={
              client === 'JP'
                ? {
                    width: 130,
                    position: 'absolute',
                    left: -39,
                    top: -37,
                  }
                : {
                    width: 241,
                    position: 'absolute',
                    left: -97.5,
                    top: -88,
                  }
            }
          />
        </Box>

        <IconComponent type="token" size={19} />
      </Box>
      <Typography
        variant="body2"
        fontWeight={900}
        fontSize={14}
        ref={numberRef}
        color={textColor}
        lineHeight={1}
        zIndex={20}
      >
        {getFormatNumber(oldTokens, playerLocale)}
      </Typography>
    </>
  )
}
