import { Backdrop, CircularProgress } from '@mui/material'

type Props = {
  open: boolean
}

export const FullPageLoader = ({ open }: Props) => {
  return (
    <Backdrop sx={{ zIndex: '1500!important' }} open={open}>
      <CircularProgress />
    </Backdrop>
  )
}
