import { useEffect, useState } from 'react'

type Props = {
  endDate: string
}

export const useRemainingTime = ({ endDate }: Props) => {
  const [timeRemaining, setTimeRemaining] = useState(null)
  const [remaining, setRemaining] = useState(-1)

  useEffect(() => {
    const endTime = new Date(endDate).getTime()

    const timerInterval = setInterval(() => {
      const now = new Date().getTime()
      const remaining = Math.max(endTime - now, 0)

      const days = Math.floor(remaining / (1000 * 60 * 60 * 24))
      const hours = Math.floor(
        (remaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
      )
      const minutes = Math.floor((remaining % (1000 * 60 * 60)) / (1000 * 60))

      // TODO: We could use this const in the future
      // const seconds = Math.floor((remaining % (1000 * 60)) / 1000)

      setTimeRemaining(
        `${days > 0 ? `${days}d` : ''} ${hours > 0 ? `${hours}h` : ''} ${
          minutes >= 1 && days === 0 ? `${minutes}m` : ''
        }`,
      )

      setRemaining(remaining)

      if (remaining === 0) {
        clearInterval(timerInterval)
      }
    }, 1000)

    return () => clearInterval(timerInterval)
  }, []) // eslint-disable-line

  return {
    timeRemaining,
    remaining,
  }
}
