import { createAsyncThunk } from '@reduxjs/toolkit'
import Container from 'typedi'
import { RewardShopModels, WidgetEndpoints } from '@/state'

import { Rest } from '@/utils/api.utils'
import { RewardShopActionTypes } from '../constants'

const apiService: Rest = Container.get(Rest)

export const getOneProductThunk = createAsyncThunk(
  RewardShopActionTypes.GET_ONE_PRODUCT,
  async (payload: RewardShopModels.GetOneProductPayload, thunkAPI) => {
    try {
      const response: RewardShopModels.ProductTypeResponse =
        await apiService.sendData({
          method: 'GET',
          endpoint: `${WidgetEndpoints.ITEMS}/${payload._id}`,
        })

      return response
    } catch (error) {
      throw error
    }
  },
)
