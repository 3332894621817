import { useEffect, useRef, useState } from 'react'
import { gsap, Power1 } from 'gsap'
import { NotificationTypeEnum } from '@/enums'

import { useGlobalUi } from '@/hooks'
import { AssetsModels, PlayerModels } from '@/state'

type Props = {
  gamificationOpt: PlayerModels.GamificationOptType
  gamificationPlayer: PlayerModels.GamificationPlayerType
  images: AssetsModels.AssetsType
}

export const useSidebarAvatarActions = ({
  gamificationOpt,
  gamificationPlayer,
  images,
}: Props) => {
  const [avatarRankImage, setAvatarRankImage] = useState(
    gamificationPlayer?.rank?.imageUrl,
  )
  const { notificationSnackbar, modal } = useGlobalUi()

  const avatarRef = useRef<HTMLDivElement>(null)

  const rankUpAnimation = () => {
    const avatar = avatarRef.current

    const tl = gsap.timeline()
    tl.to(avatar, { y: '7.25rem', duration: 0.5, ease: Power1.easeInOut })
      .call(
        () =>
          gamificationPlayer?.rank?.imageUrl !== avatarRankImage &&
          setAvatarRankImage(gamificationPlayer?.rank?.imageUrl),
      )
      .to(avatar, { duration: 0.3, delay: 0.2 })
      .to(avatar, { y: '0 ', duration: 0.5, ease: Power1.easeInOut })
  }

  useEffect(() => {
    if (
      notificationSnackbar.open &&
      gamificationPlayer?.rank?.imageUrl !== avatarRankImage &&
      modal.type === NotificationTypeEnum.RANK_UP
    ) {
      rankUpAnimation()
    }
  }, [notificationSnackbar, modal.type]) // eslint-disable-line

  useEffect(() => {
    const avatarImage =
      gamificationPlayer.rank.imageUrl &&
      gamificationPlayer.rank.imageUrl !== '' &&
      gamificationOpt?.enable
        ? gamificationPlayer.rank.imageUrl
        : images?.avatar_image

    setAvatarRankImage(avatarImage)
  }, [gamificationPlayer]) // eslint-disable-line

  return {
    avatarRef,
    avatarRankImage,
  }
}
