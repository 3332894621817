import {
  format,
  endOfMonth,
  startOfMonth,
  startOfYear,
  endOfYear,
  subYears,
  subMonths,
} from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { DateFilterEnum, TimeZoneEnum, TimeZoneUTCEnum } from '@/enums'

const today = new Date()

const todayUTC = utcToZonedTime(today, TimeZoneUTCEnum[TimeZoneEnum.GMT_0])

const lastMonth = subMonths(todayUTC, 1)

const lastYear = subYears(todayUTC, 1)

export const dateFromTime = 'T00:00:00.000'

export const dateToTime = 'T23:59:59.000'

export const formatFromDate = (date: Date) =>
  format(date, 'yyyy-MM-dd') + dateFromTime

export const formatToDate = (date: Date) =>
  format(date, 'yyyy-MM-dd') + dateToTime

export const dateFilters = {
  [DateFilterEnum.CURRENT_MONTH]: {
    dateStart: formatFromDate(startOfMonth(todayUTC)),
    dateEnd: formatToDate(endOfMonth(todayUTC)),
  },
  [DateFilterEnum.LAST_MONTH]: {
    dateStart: formatFromDate(startOfMonth(lastMonth)),
    dateEnd: formatToDate(endOfMonth(lastMonth)),
  },
  [DateFilterEnum.CURRENT_YEAR]: {
    dateStart: formatFromDate(startOfYear(todayUTC)),
    dateEnd: formatToDate(endOfYear(todayUTC)),
  },
  [DateFilterEnum.LAST_YEAR]: {
    dateStart: formatFromDate(startOfYear(lastYear)),
    dateEnd: formatToDate(endOfYear(lastYear)),
  },
  [DateFilterEnum.LIFETIME]: {
    dateStart: '2018-01-01T00:00:00.000',
    dateEnd: formatToDate(endOfMonth(todayUTC)),
  },
}
