import { makeStyles } from 'tss-react/mui'
import { alpha, gridClasses, typographyClasses } from '@mui/material'

export const useStyles = makeStyles()((theme) => {
  return {
    content: {
      [`& .${gridClasses['grid-xs-12']}`]: {
        width: '100%',
      },

      [`& .${typographyClasses.body2}`]: {
        color: theme.palette.text.secondary,
      },
    },
    avatar: {
      width: 140,
      height: 140,
      margin: '0 auto',

      svg: {
        fontSize: '45px',
        color: theme.palette.common.white,
        width: 80,
        height: 80,
        borderRadius: '50%',
        border: '20px solid',
      },

      '&.search': {
        backgroundColor: alpha(theme.palette.warning.main, 0.2),

        svg: {
          borderColor: theme.palette.warning.main,
          backgroundColor: theme.palette.warning.main,
        },
      },
      '&.success': {
        backgroundColor: alpha(theme.palette.success.main, 0.2),

        svg: {
          borderColor: theme.palette.success.main,
          backgroundColor: theme.palette.success.main,
        },
      },
      '&.error': {
        backgroundColor: alpha(theme.palette.error.main, 0.2),

        svg: {
          borderColor: theme.palette.error.main,
          backgroundColor: theme.palette.error.main,
        },
      },
    },
  }
})
