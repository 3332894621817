import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { OrderModels } from '@/state/order'
import { LIMIT } from '@/constants'

import { getAllOrdersExtraReducer } from './get-all.reducer'

const initialState: OrderModels.OrderState = {
  orders: [],
  pagination: {
    total: 0,
    limit: LIMIT,
    page: 1,
    pages: 1,
  },
  isLoading: false,
}

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setPage(state, action: PayloadAction<number>) {
      state.pagination.page = action.payload
    },
  },
  extraReducers: (builder) => {
    getAllOrdersExtraReducer(builder)
  },
})

export const OrdersActions = orderSlice.actions
