export const SidebarColors = {
  // TODO: At the moment the STANDARD style is equals to JP
  STANDARD: {
    HEADER: {
      primaryGradient:
        'linear-gradient(315deg, #D90036 0%, #E32A58 50%, #D90036 100%)',
      color: '#D90036',
      color2: '#CA1B46',
      color3: '#CFCFCF',
      border1: '#F7003F',
      border2: '#BABABA',
    },
    LEVEL: {
      background: '#FEE36E',
      border: '#FCC30B',
      shadow: '#CB5705',
      progress: '#2D3647',
    },
    MENU: {
      background: '#F2F2F2',
      active: 'linear-gradient(180deg, #D90036 0%, #E32A58 49%, #D90036 100%)',
    },
  },
  JP: {
    HEADER: {
      primaryGradient:
        'linear-gradient(315deg, #D90036 0%, #E32A58 50%, #D90036 100%)',
      color: '#D90036',
      color2: '#CA1B46',
      color3: '#CFCFCF',
      border1: '#F7003F',
      border2: '#BABABA',
    },
    LEVEL: {
      background: '#FEE36E',
      border: '#FCC30B',
      shadow: '#CB5705',
      progress: '#2D3647',
    },
    MENU: {
      background: '#F2F2F2',
      active: 'linear-gradient(180deg, #D90036 0%, #E32A58 49%, #D90036 100%)',
    },
  },
}
