import { makeStyles } from 'tss-react/mui'
import {
  alpha,
  typographyClasses,
  formControlLabelClasses,
  buttonBaseClasses,
  checkboxClasses,
  filledInputClasses,
  dialogClasses,
  radioClasses,
  circularProgressClasses,
} from '@mui/material'

export const useStyles = makeStyles()((theme) => {
  return {
    dialog: {
      [`& .${dialogClasses.paper}`]: {
        borderRadius: '16px',
      },
    },

    title: {
      display: 'flex!important',
      justifyContent: 'space-between',

      '& svg': {
        color: theme.palette.text.primary,
        fontSize: '24px',
      },
    },

    content: {
      '.subtitle': {
        fontWeight: 400,
        color: theme.palette.text.secondary,
      },
    },

    warning: {
      display: 'flex',
      gap: '12px',
      borderRadius: '4px',
      border: `1px solid ${alpha(theme.palette.text.secondary, 0.2)}`,
      margin: '16px 0',
      padding: '16px',
      boxShadow: `2px 2px 2px 0px ${alpha(theme.palette.text.secondary, 0.2)}`,

      '& svg': {
        color: theme.palette.error.main,
      },

      [`& .${typographyClasses.body2}`]: {
        color: theme.palette.error.main,
      },
    },

    form: {
      [`& .${formControlLabelClasses.root}`]: {
        color: theme.palette.text.secondary,
        paddingLeft: '8px',
      },

      [`& .${radioClasses.root}`]: {
        marginLeft: '5px',
      },

      [`& .${filledInputClasses.root}`]: {
        background: 'none !important',
        border: `1px solid ${alpha(theme.palette.text.secondary, 0.2)}`,
        borderRadius: '16px',

        '&.Mui-focused, &.Mui-error, &:hover': {
          borderColor: theme.palette.primary.main,
        },

        '&::after, &::before': {
          display: 'none',
        },
      },
    },

    confirm: {
      alignItems: 'start',
      color: theme.palette.text.secondary,
      marginTop: '8px',

      [`& .${checkboxClasses.root}`]: {
        marginRight: '2px',
        marginLeft: '5px',
        marginTop: '-8px',
        transform: 'scale(1.2)',

        '&:not(.Mui-checked) svg': {
          color: theme.palette.grey[300],
          background: theme.palette.grey[300],
          borderRadius: '4px',
          transform: 'scale(0.8)',
        },
      },
    },

    actions: {
      padding: '20px 24px',
      [`& .${buttonBaseClasses.root}`]: {
        padding: '8px 0',
        minWidth: '220px',
      },

      [`& .${buttonBaseClasses.root}[type=submit]`]: {
        [`&.${buttonBaseClasses.disabled}`]: {
          backgroundColor: alpha(theme.palette.primary.main, 0.6),
          color: theme.palette.common.white,
        },

        [`& .${circularProgressClasses.root}`]: {
          color: theme.palette.common.white,
          width: '25px !important',
          height: '25px !important',
          marginLeft: '10px',
        },
      },
    },
  }
})
