import { Box, Typography } from '@mui/material'
import { GeneralStatusExtendedEnum } from '@/enums'
import { MissionModels } from '@/state'

import { IconColorByStatus } from '@/constants'
import { useMissionItem } from '@/components/mission-history'
import { ProgressComponent } from '../'

import { useMissionStyles } from './style'

export type Props = {
  missionStatus: GeneralStatusExtendedEnum
  objective: MissionModels.ObjectiveMissionTypes
}

export function ObjectiveProgressComponent({
  missionStatus,
  objective,
}: Props) {
  const { getObjectiveName } = useMissionItem()

  const statusProgress = () => {
    if (
      missionStatus === GeneralStatusExtendedEnum.ACTIVE ||
      objective.state.progress === 100
    ) {
      return objective.state.progress === 100
        ? IconColorByStatus[GeneralStatusExtendedEnum.COMPLETED]
        : IconColorByStatus[GeneralStatusExtendedEnum.ACTIVE]
    }

    return IconColorByStatus[missionStatus]
  }

  const { classes } = useMissionStyles({
    status: statusProgress().color,
  })

  return (
    <Box className={classes.objectiveProgressWrapper}>
      <Box className={classes.objectiveStatusIcon}>{statusProgress().icon}</Box>
      <Box width="100%">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          mb={1}
          gap="5px"
        >
          <Typography
            display="flex"
            alignItems="center"
            textTransform="capitalize"
          >
            {getObjectiveName(objective)}
          </Typography>
        </Box>
        <ProgressComponent
          color={IconColorByStatus[GeneralStatusExtendedEnum.COMPLETED].color}
          state={objective.state}
        />
      </Box>
    </Box>
  )
}
