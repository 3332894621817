/**
 * @function getCurrencyFormatNumber
 * This function returns the numbers with the Swiss format,
 * separated by apostrophe (default) with minimum and maximum two decimals
 */
export const getCurrencyFormatNumber = (
  num: number,
  currency: string,
  locale: string,
) => {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    currencyDisplay: 'narrowSymbol',
  })

  return formatter.format(num)
}

/**
 * @function getFormatNumber
 * This function returns the numbers with the Swiss format, separated by apostrophe (default)
 */
export const getFormatNumber = (num: number, locale: string) => {
  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(num)
}
