import { createAsyncThunk } from '@reduxjs/toolkit'
import Container from 'typedi'
import { Rest } from '@/utils/api.utils'

import { WidgetEndpoints } from '@/state/endpoints'
import { MissionHistoryActionTypes } from '../constants'
import { MissionHistoryModels } from '../'

const apiService: Rest = Container.get(Rest)

export const updateBundleStatusThunk = createAsyncThunk(
  MissionHistoryActionTypes.UPDATE_BUNDLE_STATUS,
  async (
    payload: MissionHistoryModels.UpdateMissionBundleStatusPayload,
    thunkAPI,
  ) => {
    try {
      const response: MissionHistoryModels.UpdateMissionBundleStatusResponse =
        await apiService.sendData({
          method: 'PATCH',
          endpoint: WidgetEndpoints.UPDATE_BUNDLE_STATUS,
          body: payload,
        })

      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
