import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Country } from 'country-list'

import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import { RootState } from '@/state'
import { DeliveryTypeEnum } from '@/enums'

import { ProductFormType } from '@/state/reward-shop/models'
import { useStyles } from '../style'
import { JPLocationFormComponent } from './jp-location-form.component'

import { JPProductFormSummaryComponent } from './jp-product-form-summary.component'

type Props = {
  disableButton: boolean
  disableByRank: boolean
  productForm: ProductFormType
  quantityOptions: number[]
  handleOnChange: (
    field: string,
    newValue: string | number | boolean | Country,
  ) => void
  handleOnSubmit: () => void
  handleChangeStepForm: (step: number) => void
}

export function JPProductFormStepTwoComponent({
  disableButton,
  disableByRank,
  productForm,
  quantityOptions,
  handleOnChange,
  handleOnSubmit,
  handleChangeStepForm,
}: Props) {
  const { t } = useTranslation()

  const { client } = useSelector(({ initConfig }: RootState) => initConfig)

  const { casinoPickup } = useSelector(
    ({ globalSettings }: RootState) => globalSettings,
  )

  const { classes } = useStyles({ client })

  return (
    <Box py={2}>
      <Button
        variant="text"
        onClick={() => {
          handleChangeStepForm(1)
        }}
        sx={{ textAlign: 'left', textDecoration: 'underline' }}
      >
        {t('backToProduct').toUpperCase()}
      </Button>
      <Box className={classes.jpStepTwo}>
        <Box className="locationWrapper">
          <form onSubmit={handleOnSubmit} className={classes.form}>
            <Typography className="title">
              {t('addressOrder').toUpperCase()}
            </Typography>
            <FormControl sx={{ width: '100%' }}>
              <RadioGroup
                value={productForm.deliveryType}
                onChange={(e) => handleOnChange('deliveryType', e.target.value)}
              >
                <Box className="radioGroup">
                  <FormControlLabel
                    value={DeliveryTypeEnum.CUSTOM}
                    control={<Radio />}
                    label={
                      <>
                        <Typography fontWeight={700}>
                          {t('casinoLocationCustom')}
                        </Typography>
                      </>
                    }
                  />
                  {productForm.deliveryType === DeliveryTypeEnum.CUSTOM && (
                    <JPLocationFormComponent
                      handleOnChange={handleOnChange}
                      productForm={productForm}
                    />
                  )}
                </Box>
                {casinoPickup && (
                  <Box className="radioGroup">
                    <FormControlLabel
                      value={DeliveryTypeEnum.CASINO}
                      control={<Radio />}
                      label={
                        <>
                          <Typography component="span" fontWeight={700}>
                            {t('casinoLocationJPOne')}
                          </Typography>
                          <Typography
                            component="span"
                            sx={{ paddingLeft: '5px' }}
                          >
                            {t('casinoLocationJPTwo')}
                          </Typography>
                          <Typography
                            fontWeight={700}
                            sx={{ paddingTop: '10px' }}
                          >
                            {t('casinoLocationJPThree')}
                          </Typography>
                        </>
                      }
                    />
                  </Box>
                )}
              </RadioGroup>
            </FormControl>
            {productForm.deliveryType === DeliveryTypeEnum.CASINO && (
              <Typography
                variant="caption"
                className="grayCaption"
                sx={{
                  marginTop: -1,
                  marginBottom: 2.5,
                }}
              >
                <>
                  <strong>{`${t('note')}: `}</strong>
                  {t('casinoLocationMessage')}
                </>
              </Typography>
            )}
          </form>
        </Box>
        <JPProductFormSummaryComponent
          disableButton={disableButton}
          disableByRank={disableByRank}
          productForm={productForm}
          quantityOptions={quantityOptions}
          handleOnChange={handleOnChange}
          handleOnSubmit={handleOnSubmit}
        />
      </Box>
    </Box>
  )
}
