import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Box, Tab, Tabs, Typography, Link } from '@mui/material'

import { LoyaltyProgramLinks, MenuListOptions } from '@/constants'
import { MenuItemType } from '@/enums'
import { RootState } from '@/state'

import { useStyles } from './jp-style'
import { useMenu } from '../../../hooks/use-menu'

type Props = {
  Div: Element
}

export function JPMenuComponent({ Div }: Props) {
  const { t } = useTranslation()

  const showDescription = Div.getAttribute('data-show-description') || 'true'

  const { myBoostersCount } = useSelector(
    ({ myBooster }: RootState) => myBooster,
  )

  const { menuSelected } = useSelector(
    ({ rewardShop }: RootState) => rewardShop,
  )

  const { playerLocale } = useSelector(
    ({ initConfig }: RootState) => initConfig,
  )

  const { gamificationOpt } = useSelector(({ player }: RootState) => player)

  const { classes } = useStyles()

  const { handleChangeMenuItem } = useMenu()

  const handleChange = (newValue: string) => {
    handleChangeMenuItem(newValue)
  }

  return (
    <>
      <Tabs
        value={menuSelected}
        onChange={(_, newValue) => handleChange(newValue)}
        className={classes.jpTabs}
        TabIndicatorProps={{
          style: { display: 'none' },
        }}
      >
        <Tab
          key="loyalty-program"
          className={classes.jpTabContent}
          disabled={!gamificationOpt?.enable || false}
          label={
            <Link
              href={LoyaltyProgramLinks[playerLocale.toUpperCase()]}
              sx={{ textDecoration: 'none' }}
            >
              <Typography className="tabLabel">
                {t('loyaltyProgram')}
              </Typography>
            </Link>
          }
        />
        {Object.values(MenuListOptions).map((item) => {
          return (
            <Tab
              key={item.id}
              className={classes.jpTabContent}
              value={item.id}
              onClick={() => {
                if (item.id === MenuItemType.REWARD_SHOP) {
                  handleChange(item.id)
                }
              }}
              disabled={!gamificationOpt?.enable || false}
              label={
                <>
                  <Typography className="tabLabel">
                    {t(`${item.label}`)}
                  </Typography>
                  {item.id === MenuItemType.BOOSTERS &&
                    myBoostersCount !== 0 && (
                      <Box className="boostersCount">
                        <Typography variant="caption" lineHeight="23px">
                          {myBoostersCount}
                        </Typography>
                      </Box>
                    )}
                </>
              }
            />
          )
        })}
      </Tabs>
      {showDescription === 'true' && (
        <Typography className={classes.rewardShopDescription}>
          {t('rewardShopDescription')}
        </Typography>
      )}
    </>
  )
}
