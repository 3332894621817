export const mission = {
  missionHistory: {
    active: 'Active',
    easterEgg: 'Claim Easter Egg',
    pause: 'Pause',
    anyOrder: 'Any order {{what}}',
    status: 'Status',
    missions: 'Missions',
    to: 'To',
    infinite: 'Infinite',
    thisOrder: 'In this order {{what}}',
    validityTime: 'Validity Time',
    value: 'Value',
    objectives: 'Objectives',
    godsonAccountStatus: 'Godson Account Status',
    minimumAmount: 'Minimum Amount',
    paymentMethods: 'Payment Methods',
    options: 'Options',
    noResultsFound: 'No results found.',
    noResultsFoundCaption:
      'What you searched for was unfortunately not found. Please try another combination.',
    channels: 'Channels',
    claimedSuccessfully:
      'The easter egg was claimed successfully. You won: {{what}}',
    claimedNoContent: 'There are no easter eggs to claim.',
    channelsOptions: {
      email: 'Email',
      sms: 'SMS',
      phone: 'Phone',
      post: 'Post',
    },
    accountStatusOptions: {
      INITIAL: 'Initial',
      PROVISIONAL: 'Provisional',
      ACTIVE: 'Active',
      FULLY_IDENTIFIED: 'Fully Identified',
      BLOCKED_FULL: 'Blocked Full',
      BLOCKED_PARTIAL: 'Blocked Partial',
      CLOSED_PROVISIONAL: 'Closed Provisional',
      CLOSED_ACTIVE: 'Closed Active',
      INACTIVE: 'Inactive',
    },
    timeFrameTypeOptions: {
      daily: 'Daily',
      lifetime: 'Lifetime',
      monthly: 'Monthly',
      range: 'Date Range',
      weekly: 'Weekly',
    },
    generalStatusTypeOptions: {
      active: 'Active',
      completed: 'Completed',
      ended: 'Ended',
      inactive: 'Inactive',
      paused: 'Paused',
      pending: 'Pending',
    },
    missionConditionOptions: {
      quantity: 'Quantity',
      total_bet_amount: 'Total Bet Amount',
      successive_quantity: 'Successive Quantity',
      total_win_amount: 'Total Win Amount',
    },
    missionLevelOptions: {
      any: 'Any',
      specific_level: 'Specific Level',
    },
    missionEventOptions: {
      completed_deposit: 'Completed Deposit',
      completed_withdrawal: 'Completed Withdrawal',
      wager: 'Wager',
      win: 'Win',
      refer_a_friend: 'Refer a friend',
      login: 'Login',
      activate_account: 'Activate Account (KYC)',
      account_verification: 'Account Verification',
      self_assessment_test: 'Self Assessment Test',
      opt_in: 'Marketing Preferences',
      level_up: 'Level Up',
    },
    missionTimeOptions: {
      any_time: 'Any Time',
      period: 'Period',
      specific_date: 'Specific Date',
    },
  },
}
