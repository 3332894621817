import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box, LinearProgress, Typography } from '@mui/material'

import { MissionHistoryModels, MissionModels, RootState } from '@/state'
import { GeneralStatusExtendedEnum } from '@/enums'
import { ReactComponent as IconAccessTime } from '@/assets/icons/missions/icon-access-time.svg'

import { ReactComponent as IconChestClosed } from '@/assets/icons/missions/icon-chest-closed.svg'
import { ReactComponent as IconChestOpened } from '@/assets/icons/missions/icon-chest-opened.svg'
import { ReactComponent as IconMissionClock } from '@/assets/icons/missions/icon-mission-clock.svg'

import { useRemainingTime } from '@/hooks'
import { useDialogProgressStyles } from './progress.style'
import { DialogMissionRewardsComponent } from './'

type Props = {
  state: MissionHistoryModels.MissionProgressType // TODO: This type will change in the future
  status: GeneralStatusExtendedEnum
  rewardCategories?: MissionModels.MissionRewardCategoryType[]
  endDate: string
  client: string
}

export const DialogMissionProgressComponent = ({
  state,
  status,
  rewardCategories,
  endDate,
  client,
}: Props) => {
  const { t } = useTranslation()

  const { classes } = useDialogProgressStyles({ client })

  const { timeRemaining } = useRemainingTime({ endDate })

  const { gamificationPlayer } = useSelector(({ player }: RootState) => player)

  const getMissionStatusText = () => {
    switch (status) {
      case GeneralStatusExtendedEnum.ACTIVE:
        return t('missionProgress')

      case GeneralStatusExtendedEnum.COMPLETED:
        return t('missionCompleted')

      case GeneralStatusExtendedEnum.ENDED:
        return t('missionEnded')

      case GeneralStatusExtendedEnum.INACTIVE:
      case GeneralStatusExtendedEnum.PENDING:
        return t('missionLocked')
    }
  }

  const linearBars = Array.from(new Array(6), (_, index) => (
    <Box className="linearBar" key={`border_${index}`} />
  ))

  return (
    <Box className={classes.dialogMissionProgress}>
      <Box className="missionProgressTitle">
        <Typography>{getMissionStatusText()}</Typography>
        {status && status === GeneralStatusExtendedEnum.ACTIVE && (
          <Box display="flex" justifyContent="right" alignItems="center">
            <IconAccessTime />
            <Typography variant="body2">{timeRemaining}</Typography>
          </Box>
        )}
      </Box>
      <Box className="missionProgressBar">
        <Box className="linearBorder" />
        <Box className="linearBars">{linearBars}</Box>
        <LinearProgress
          variant="determinate"
          value={Number(state?.progress) || 0}
        />
        <Typography variant="caption" className="linearBalance">
          <IconMissionClock />
          {`${state?.completed || 0}/${state?.total || 0}`}
        </Typography>
        {status && (
          <Box
            className={`linearChest ${
              status === GeneralStatusExtendedEnum.COMPLETED
                ? 'opened'
                : 'closed'
            }`}
          >
            {status === GeneralStatusExtendedEnum.COMPLETED ? (
              <IconChestOpened />
            ) : (
              <IconChestClosed />
            )}
          </Box>
        )}
      </Box>
      {status === GeneralStatusExtendedEnum.COMPLETED && (
        <DialogMissionRewardsComponent
          client={client}
          rewards={
            rewardCategories?.filter((category) =>
              typeof category.playerCategoryId === 'string'
                ? category.playerCategoryId === gamificationPlayer.category.id
                : category.playerCategoryId._id ===
                  gamificationPlayer.category.id,
            )[0].rewards || []
          }
        />
      )}
    </Box>
  )
}
