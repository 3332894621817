import { useTranslation } from 'react-i18next'
import { Box, Chip, Typography, useTheme } from '@mui/material'
import { Flag } from '@mui/icons-material'

import { MissionModels } from '@/state'
import { IconColorByStatus } from '@/constants'
import { useMissionItem } from '@/components/mission-history'

import { GeneralStatusExtendedEnum } from '@/enums'
import {
  MissionGroupDetailsComponent,
  ObjectiveProgressComponent,
  useMissionStyles,
} from './'

type Props = {
  mission: MissionModels.MissionType
}

export function MissionDetailsComponent({ mission }: Props) {
  const { t } = useTranslation()

  const { getValidityTime } = useMissionItem()

  const { classes } = useMissionStyles({})

  const theme = useTheme()

  const chipColor = IconColorByStatus[mission.status].color

  return (
    <>
      <Box display="flex" alignItems="center" mb={2} gap={1}>
        <Typography fontWeight={700}>{`${t(
          'missionHistory.status',
        )}:`}</Typography>
        <Chip
          size="small"
          sx={{
            minWidth: '91px',
            color: theme.palette.common.white,
            backgroundColor: chipColor,
          }}
          label={t(`missionHistory.generalStatusTypeOptions.${mission.status}`)}
        />
      </Box>
      <Box display="flex" alignItems="center" mb={2} gap={1}>
        <Typography fontWeight={700}>{`${t(
          'missionHistory.validityTime',
        )}:`}</Typography>
        <Typography>{getValidityTime(mission.validityTime)}</Typography>
      </Box>
      <MissionGroupDetailsComponent
        title={`${t('missionHistory.objectives')}:`}
        subtitle={`${mission.state.completed}/${mission.state.total}`}
        groupIcon={<Flag className={classes.groupIcon} />}
        children={
          mission.objectives &&
          mission.objectives.map((objective) => {
            return (
              <ObjectiveProgressComponent
                missionStatus={mission.status as GeneralStatusExtendedEnum}
                objective={objective}
                key={objective.missionId}
              />
            )
          })
        }
      />
    </>
  )
}
