import { useTranslation } from 'react-i18next'
import { ArrowBack } from '@mui/icons-material'
import { Box, Typography, Button, Breadcrumbs, Link } from '@mui/material'

import { MenuItemType } from '@/enums'
import { useBreakpoints } from '@/hooks'
import { useStyles } from './standard-style'

type Props = {
  handleChangeMenuItem: (newValue: string) => void
  getLastBreadcrumbs: () => string
}

export function BreadcrumbsComponent({
  handleChangeMenuItem,
  getLastBreadcrumbs,
}: Props) {
  const { t } = useTranslation()

  const { isDesktop } = useBreakpoints()

  const { classes } = useStyles()

  return (
    <Box className={classes.breadcrumbsContent}>
      <Button
        variant="contained"
        onClick={() => {
          handleChangeMenuItem(MenuItemType.REWARD_SHOP)
        }}
      >
        <ArrowBack />
      </Button>
      {isDesktop && (
        <Breadcrumbs className="breadcrumbLink">
          <Link
            onClick={() => {
              handleChangeMenuItem(MenuItemType.REWARD_SHOP)
            }}
          >
            <Typography>{t('rewardShop')}</Typography>
          </Link>
          <Typography textTransform="capitalize">
            {getLastBreadcrumbs()}
          </Typography>
        </Breadcrumbs>
      )}
    </Box>
  )
}
