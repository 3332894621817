import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Lottie from 'lottie-react'

import { Box, Slide, Typography } from '@mui/material'
import { useGlobalUi, usePlayerData } from '@/hooks'
import { GeneralModels, RootState } from '@/state'

import { NotificationTypeEnum } from '@/enums'
import { useNotificationInitial } from '../hooks'
import { useStyles } from './styles'

type Props = {
  initialAnimationData: GeneralModels.LottieAnimationData
}

export const ModalStatusInitialComponent = ({
  initialAnimationData,
}: Props) => {
  const { t } = useTranslation()

  const { modal } = useGlobalUi()

  const { gamificationPlayer } = useSelector(({ player }: RootState) => player)

  const { assetsRoute } = useSelector(
    ({ globalSettings }: RootState) => globalSettings,
  )

  const { playerRankName } = usePlayerData()

  const { isVisible, isVisibleLevel, containerRef } = useNotificationInitial()

  const { classes } = useStyles()

  return (
    <>
      <Box className={classes.initialContainerTop}>
        <Lottie animationData={initialAnimationData} loop={false} />
        {modal.type === NotificationTypeEnum.LEVEL_UP && (
          <Slide
            direction="up"
            in={isVisibleLevel}
            container={containerRef.current}
            timeout={750}
          >
            <Box className={classes.initialLevel}>
              <Typography className={classes.initialLevelValue}>
                {gamificationPlayer.level.levelNumber}
              </Typography>
            </Box>
          </Slide>
        )}
      </Box>
      <Box className={classes.initialContainerBottom}>
        <Slide
          direction="up"
          in={isVisible}
          container={containerRef.current}
          timeout={750}
        >
          <Box>
            <Box className={classes.initialBoxBackground}>
              <Box className={classes.initialBoxBackgroundImage}>
                <img
                  src={`${assetsRoute}/casino-ui-widgets/assets/images/modal-background.svg`}
                  alt="modal background"
                />
              </Box>
            </Box>
            <Box pt={6}>
              <Typography className={classes.initialCongratulationsTitle}>
                {t('congratulations')}
              </Typography>
              <Typography className={classes.initialCongratulationsDescription}>
                {modal.type === NotificationTypeEnum.RANK_UP
                  ? t('congratulationsRankUp', {
                      rank: playerRankName,
                    })
                  : t('congratulationsLevelUp', {
                      level: gamificationPlayer.level.levelNumber,
                    })}
              </Typography>
            </Box>
          </Box>
        </Slide>
      </Box>
    </>
  )
}
