import { format, getYear } from 'date-fns'
import { Box, Typography, Divider } from '@mui/material'
import { MissionHistoryModels } from '@/state'

import { TimeZoneEnum, TimeZoneUTCEnum } from '@/enums'
import { getDateTimeFromIsoUTC } from '@/utils'
import { MissionHistoryBundleComponent } from '../bundle'

type Props = {
  date: string
  missionsBundles: MissionHistoryModels.MissionHistoryBundleType[]
  last: boolean
}

export const MissionBundlesByGroupComponent = ({
  date,
  missionsBundles,
  last,
}: Props) => {
  const dateUTC = getDateTimeFromIsoUTC(
    date,
    TimeZoneUTCEnum[TimeZoneEnum.GMT_0],
  )

  return (
    <Box display="flex" mb={last ? 2 : 8} gap={3}>
      <Box textAlign="right" width={64}>
        <Typography variant="h5" fontWeight={700}>
          {format(dateUTC, 'MMM')}
        </Typography>
        <Typography variant="body2">{getYear(dateUTC)}</Typography>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box flexGrow={1}>
        {missionsBundles &&
          missionsBundles.map((missionBundle) => (
            <Box key={missionBundle._id}>
              <MissionHistoryBundleComponent missionBundle={missionBundle} />
            </Box>
          ))}
      </Box>
    </Box>
  )
}
