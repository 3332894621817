import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import { MyBoosterThunks, MyBoosterModels } from '@/state'

export const getMyBoostersExtraReducer = (
  builder: ActionReducerMapBuilder<MyBoosterModels.MyBoosterState>,
) => {
  builder.addCase(MyBoosterThunks.getAllMyBoostersThunk.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(
    MyBoosterThunks.getAllMyBoostersThunk.fulfilled,
    (state, { payload }) => {
      const { docs, totalDocs, totalPages, limit, page } = payload
      state.myBoosters = docs || []
      state.pagination = {
        total: totalDocs || 0,
        limit: limit || 12,
        page: page || 1,
        pages: totalPages || 0,
      }
      state.myBoostersCount = totalDocs
      state.isLoading = false
    },
  )

  builder.addCase(MyBoosterThunks.getAllMyBoostersThunk.rejected, (state) => {
    state.isLoading = false
  })
}
