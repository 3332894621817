import {
  Check,
  HourglassEmpty,
  Pause,
  PlayArrow,
  Stop,
  ToggleOff,
} from '@mui/icons-material'
import { colors } from '@mui/material'
import { GeneralStatusExtendedEnum } from '@/enums'

export const IconColorByStatus = {
  [GeneralStatusExtendedEnum.ACTIVE]: {
    icon: <PlayArrow htmlColor={colors.green[500]} />,
    color: colors.green[500],
  },
  [GeneralStatusExtendedEnum.COMPLETED]: {
    icon: <Check htmlColor={colors.teal[500]} />,
    color: colors.teal[500],
  },
  [GeneralStatusExtendedEnum.ENDED]: {
    icon: <Stop htmlColor={colors.red[500]} />,
    color: colors.red[500],
  },
  [GeneralStatusExtendedEnum.INACTIVE]: {
    icon: <ToggleOff htmlColor={colors.grey[500]} />,
    color: colors.grey[500],
  },
  [GeneralStatusExtendedEnum.PAUSED]: {
    icon: <Pause htmlColor={colors.pink[300]} />,
    color: colors.pink[300],
  },
  [GeneralStatusExtendedEnum.PENDING]: {
    icon: <HourglassEmpty htmlColor={colors.orange[700]} />,
    color: colors.orange[700],
  },
}
