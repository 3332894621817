import { wsSubscriptionFactory } from '@/utils/api.utils'

export const getSocketInstance = () => {
  if (!window['GAMZWEBSOCKET']) {
    window['GAMZWEBSOCKET'] = wsSubscriptionFactory('rtm')
      .getInstance()
      .getSocket()

    return window['GAMZWEBSOCKET']
  } else {
    return window['GAMZWEBSOCKET']
  }
}
