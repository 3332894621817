import { makeStyles } from 'tss-react/mui'
import { alpha, typographyClasses } from '@mui/material'
import { MikadoFontStyle, SidebarColors } from '@/constants'

interface Props {
  sidebarClient?: string
}

export const useSidebarMenuStyles = makeStyles<Props>()((
  theme,
  { sidebarClient },
) => {
  return {
    sidebarMenu: {
      position: 'absolute',
      bottom: 0,
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      width: '100%',
      background: theme.palette.common.white,
      padding: 2,
      gap: 2,
    },

    sidebarMenuItem: {
      width: '100%',
      height: 58,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      background: SidebarColors[sidebarClient].MENU.background,
      borderRadius: 2,
      boxShadow: `2px 2px 0px 0px ${alpha(
        theme.palette.common.black,
        0.04,
      )} inset`,
      cursor: 'pointer',
      transition: 'width 1s',

      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% / 5)',
      },

      '& .menuIcon svg': {
        width: '100%',
        height: '100%',
      },

      [`& .${typographyClasses.body1}`]: {
        display: 'none',
      },

      '&.active': {
        background: SidebarColors[sidebarClient].MENU.active,
        minWidth: 100,

        '& .menuIcon': {
          marginTop: -40,

          '& svg': {
            width: 45,
          },
        },

        [`& .${typographyClasses.body1}`]: {
          display: 'block',
          fontSize: 12,
          textAlign: 'center',
          textTransform: 'uppercase',
          marginTop: -4,
          ...MikadoFontStyle,
        },

        [theme.breakpoints.down('sm')]: {
          width: 'calc(100% / 3.5)',
          paddingLeft: 5,
          paddingRight: 5,
        },

        [theme.breakpoints.down('xs')]: {
          width: 'calc(100% / 3)',
        },
      },
    },
  }
})
