import { ReactComponent as IconTokensJP } from '@/assets/icons/icon-token-jp.svg'
import { ReactComponent as IconTokens } from '@/assets/icons/icon-token.svg'
import { ReactComponent as IconTokenBooster } from '@/assets/tokenBooster.svg'

import { ReactComponent as IconLevelBooster } from '@/assets/levelBooster.svg'
import { ReactComponent as IconMissionBooster } from '@/assets/missionBooster.svg'
import { ReactComponent as IconBonus } from '@/assets/bonus-icon.svg'

import { ReactComponent as IconXPPointsJP } from '@/assets/icons/icon-xp-jp.svg'
import { ReactComponent as IconXPPoints } from '@/assets/icons/icon-xp.svg'
import { RewardTypeEnum } from '@/enums'

export const RewardTypeInformation = (client: string) => ({
  [RewardTypeEnum.TOKENS]: {
    id: RewardTypeEnum.TOKENS,
    label: 'coins',
    icon: client === 'JP' ? <IconTokensJP /> : <IconTokens />,
  },
  [RewardTypeEnum.BONUS_OFFER]: {
    id: RewardTypeEnum.TOKENS,
    label: 'bonusOffer',
    icon: <IconBonus />,
  },
  [RewardTypeEnum.LEVEL_BOOSTER]: {
    id: RewardTypeEnum.TOKENS,
    label: 'levelBooster',
    icon: <IconLevelBooster />,
  },
  [RewardTypeEnum.MISSION_BOOSTER]: {
    id: RewardTypeEnum.TOKENS,
    label: 'missionBooster',
    icon: <IconMissionBooster />,
  },
  [RewardTypeEnum.TOKEN_BOOSTER]: {
    id: RewardTypeEnum.TOKENS,
    label: 'tokenBooster',
    icon: <IconTokenBooster />,
  },
  [RewardTypeEnum.XP]: {
    id: RewardTypeEnum.XP,
    label: 'xpPoints',
    icon: client === 'JP' ? <IconXPPointsJP /> : <IconXPPoints />,
  },
})
