import { useSelector } from 'react-redux'
import { Box, Button, Dialog, Typography } from '@mui/material'
import { useGlobalUi } from '@/hooks'

import {
  DialogTypeEnum,
  GeneralStatusExtendedEnum,
  UIDialogsEnum,
} from '@/enums'
import { ReactComponent as ImgDialogHeader } from '@/assets/images/missions/dialog-mission-header.svg'
import { ReactComponent as ImgDialogBorder } from '@/assets/images/missions/dialog-mission-border.svg'

import { ReactComponent as ImgDialogDecorationLeft } from '@/assets/images/missions/dialog-mission-left.svg'
import { ReactComponent as ImgDialogDecorationRight } from '@/assets/images/missions/dialog-mission-right.svg'
import { ReactComponent as IconCloseDialogMission } from '@/assets/icons/missions/icon-close-dialog-mission.svg'

import { RootState } from '@/state'
import { useDialogMissionStyles } from './style'
import {
  DialogMissionObjectivesComponent,
  DialogMissionProgressComponent,
} from './'

export const DialogMissionComponent = () => {
  const { dialog, handleWaitCloseDialog } = useGlobalUi()

  const { client } = useSelector(({ initConfig }: RootState) => initConfig)

  const { missionsActive, missionActiveBundles, missionsExpired } = useSelector(
    ({ missionHistory }: RootState) => missionHistory,
  )

  const dialogClient = client && client === 'JP' ? client : 'STANDARD'

  const { classes } = useDialogMissionStyles()

  const getSelectedMission = () => {
    if (dialog.object?.type === DialogTypeEnum.ACTIVE_MISSIONS) {
      return missionsActive?.find(
        (mission) => mission._id === dialog.object?.missionId,
      )
    }

    if (dialog.object?.type === DialogTypeEnum.MISSIONS) {
      return missionActiveBundles
        ?.find((bundle) =>
          bundle?.missionIds?.find(
            (mission) => mission._id === dialog.object?.missionId,
          ),
        )
        ?.missionIds?.find(
          (mission) => mission._id === dialog.object?.missionId,
        )
    }

    if (dialog.object?.type === DialogTypeEnum.MISSION_HISTORY) {
      return missionsExpired?.find(
        (mission) => mission._id === dialog.object?.missionId,
      )
    }
  }

  const selectedMission = getSelectedMission()

  return (
    <Dialog
      open={dialog.open && dialog.id === UIDialogsEnum.MISSION_DETAILS}
      onClose={handleWaitCloseDialog}
      className={classes.dialogMission}
    >
      <Box className={classes.dialogMissionWrapper}>
        <Box className={classes.dialogMissionHeader}>
          <Box className="imgDialogDecorationLeft">
            <ImgDialogDecorationLeft />
          </Box>
          <Box className="imgDialogDecorationRight">
            <ImgDialogDecorationRight />
          </Box>
          <Box className="imgDialogBorder">
            <ImgDialogBorder />
          </Box>
          <Box className="imgDialogHeader">
            <ImgDialogHeader />
          </Box>
          <Typography>{dialog.object?.title || dialog.object?.name}</Typography>
          <Button
            className="btnCloseDialog"
            onClick={() => handleWaitCloseDialog()}
          >
            <IconCloseDialogMission />
          </Button>
        </Box>
        <Box className={classes.dialogMissionContent}>
          <DialogMissionProgressComponent
            state={selectedMission?.state}
            status={selectedMission?.status as GeneralStatusExtendedEnum}
            endDate={selectedMission?.endDate}
            rewardCategories={selectedMission?.rewardCategories}
            client={dialogClient}
          />
          <DialogMissionObjectivesComponent
            objectives={selectedMission?.objectives}
            status={selectedMission?.status as GeneralStatusExtendedEnum}
            client={dialogClient}
          />
        </Box>
      </Box>
    </Dialog>
  )
}
