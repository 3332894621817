import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getData, Country } from 'country-list'

import { Autocomplete, Grid, TextField, TextFieldProps } from '@mui/material'
import { ProductFormType } from '@/state/reward-shop/models'
import { RootState } from '@/state'

type Props = {
  productForm: ProductFormType
  handleOnChange: (field: string, newValue: string | number | Country) => void
}

type CountryType = {
  name: string
  code: string
}

export function LocationFormComponent({ productForm, handleOnChange }: Props) {
  const { t } = useTranslation()

  const { countries } = useSelector(({ player }: RootState) => player)

  const listCountries: Country[] =
    countries.length > 0
      ? getData().filter(
          (country) =>
            countries.findIndex(
              (index) => index.toUpperCase() === country.code,
            ) !== -1,
        )
      : getData()

  const textFieldsProps: TextFieldProps = {
    variant: 'filled',
    fullWidth: true,
    margin: 'normal',
  }

  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={12} sm={6}>
        <Autocomplete
          disableClearable
          options={listCountries}
          getOptionLabel={(option: CountryType) => option.name}
          value={productForm.country}
          onChange={(e, value) => handleOnChange('country', value)}
          renderInput={(params) => (
            <TextField
              {...params}
              {...textFieldsProps}
              label={t('country')}
              error={!productForm.country}
              helperText={!productForm.country && t('requiredField')}
            />
          )}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          {...textFieldsProps}
          label={t('city')}
          value={productForm.city}
          onChange={(e) => handleOnChange('city', e.target.value)}
          error={productForm.city.trim() === ''}
          helperText={productForm.city.trim() === '' && t('requiredField')}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          {...textFieldsProps}
          label={t('street')}
          value={productForm.street}
          onChange={(e) => handleOnChange('street', e.target.value)}
          error={productForm.street.trim() === ''}
          helperText={productForm.street.trim() === '' && t('requiredField')}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          {...textFieldsProps}
          label={t('streetNumber')}
          value={productForm.streetNumber}
          onChange={(e) => handleOnChange('streetNumber', e.target.value)}
          error={productForm.streetNumber.trim() === ''}
          helperText={
            productForm.streetNumber.trim() === '' && t('requiredField')
          }
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          {...textFieldsProps}
          label={t('postalCode')}
          value={productForm.postalCode}
          onChange={(e) => handleOnChange('postalCode', e.target.value)}
          error={productForm.postalCode.trim() === ''}
          helperText={
            productForm.postalCode.trim() === '' && t('requiredField')
          }
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          {...textFieldsProps}
          label={t('receives')}
          value={productForm.receives}
          onChange={(e) => handleOnChange('receives', e.target.value)}
          error={productForm.receives.trim() === ''}
          helperText={productForm.receives.trim() === '' && t('requiredField')}
        />
      </Grid>
    </Grid>
  )
}
