import React from 'react'
import { Provider } from 'react-redux'
import * as ReactDOMClient from 'react-dom/client'

import 'reflect-metadata'
import i18next from 'i18next'
import { PersistGate } from 'redux-persist/integration/react'

import {
  createTheme,
  PaletteOptions,
  PaletteMode,
  ThemeProvider,
  StyledEngineProvider,
  GlobalStyles,
  Theme,
  Components,
} from '@mui/material'
import { TypographyOptions } from '@mui/material/styles/createTypography'
import { App } from './App'

import {
  AssetsActions,
  AssetsModels,
  InitConfigActions,
  store,
  loginThunk,
  PlayerActions,
  PlayerThunks,
  gamificationSubscription,
  persistor,
  MyBoosterThunks,
  globalUiActions,
  GlobalSettingsThunks,
} from './state'
import { buttonTheme } from './theme/index.theme'
import { hasTokenExpired, decodeJWT } from './utils'

import './i18n'
import { GlobalUIComponents } from './global-ui'

const getBasePath = (): string => {
  const currentScript = document.currentScript as HTMLScriptElement | null
  if (currentScript && currentScript.src) {
    return currentScript.src.toString().replace(/\/?bundle\.min\.js$/, '/')
  }
  return '/'
}

const basePath = getBasePath()

export type ConfigProps = {
  init: {
    serviceUrl: string
    clientId: string
    playerIdentityToken: string
    playerLocale: string
    client?: string
  }
  player: {
    name: string
    balanceAmount: number
    shippingAddress: {
      city: string
      country: string
      street: string
      streetNumber: string
      postalCode: string
    }
    currency?: string
    countries?: string[]
  }
  lookAndFeel?: {
    typography: TypographyOptions
    palette: PaletteOptions
    components?: Components<Omit<Theme, 'components'>>
  }
  translations?: {
    locale: string
    keys: {
      [x: string]: string
    }
  }[]
  assets?: AssetsModels.AssetsType
  /* TODO: Maybe in the future we will use this prop
  callbacks?: {
    reward_shop?: {
      next_btn?: (props: any) => void
    }
  } */
}

const typography = {
  fontFamily: '"Roboto", sans-serif',
}

const palette = {
  mode: 'light' as PaletteMode,
}

function GamificationWidgets() {
  let theme: Theme

  const config = ({
    init,
    player,
    lookAndFeel,
    translations,
    assets,
  }: ConfigProps) => {
    theme = createTheme({
      typography: lookAndFeel?.typography || typography,
      palette: lookAndFeel?.palette || palette,
      components: {
        ...lookAndFeel?.components,
        MuiButton: {
          styleOverrides: buttonTheme,
        },
      },
      breakpoints: {
        values: {
          xs: 400,
          sm: 600,
          md: 992,
          lg: 1200,
          xl: 1536,
        },
        unit: 'px',
      },
    })

    async function render() {
      const playerId = store.getState().initConfig.playerIdentityToken

      store.dispatch(InitConfigActions.setBaseUrl(basePath))

      if (
        hasTokenExpired() ||
        playerId === undefined ||
        playerId !== init.playerIdentityToken
      ) {
        const resultAction = await store.dispatch(
          loginThunk({
            clientId: init.clientId,
            identityToken: init.playerIdentityToken,
            serviceUrl: init.serviceUrl.replace(/\/+$/, ''),
          }),
        )

        if (loginThunk.fulfilled.match(resultAction)) {
          if (resultAction.payload.accessToken) {
            init && store.dispatch(InitConfigActions.setInitConfig(init))
            const decoded = decodeJWT(resultAction.payload.accessToken)
            if (decoded.isValid) {
              store.dispatch(InitConfigActions.setPlayerId(decoded.decoded.sub))
            }
          }
        }
      }

      assets && store.dispatch(AssetsActions.setImages(assets))
      player && store.dispatch(PlayerActions.setPlayerData(player))
      const initPlayerID = store.getState().initConfig.playerId
      if (initPlayerID !== '') {
        store.dispatch(PlayerThunks.getRankDetailsThunk())
        store.dispatch(PlayerThunks.getTokenDetailsThunk())
        store.dispatch(PlayerThunks.getGamificationOptStatusThunk())
        store.dispatch(GlobalSettingsThunks.getGlobalSettingsThunk())
        store.dispatch(MyBoosterThunks.getLevelActiveBoostersThunk())
        store.dispatch(MyBoosterThunks.getTokenActiveBoostersThunk())
        store.dispatch(
          MyBoosterThunks.getMissionActiveBoostersThunk({
            playerId: initPlayerID,
          }),
        )
      }

      gamificationSubscription()

      i18next.changeLanguage(init.playerLocale)
      store.dispatch(InitConfigActions.setPlayerLocale(init.playerLocale))

      if (translations) {
        translations.forEach((item) => {
          i18next.addResourceBundle(
            item.locale.toLowerCase(),
            'translation',
            item.keys,
            true,
            true,
          )
        })
      }

      renderWidgets()
    }

    validationRender(render)
  }

  /**
   * @function reload
   * @description: This function allows you to load multiple widgets that were not loaded before
   */
  const reload = (newLocale?: string) => {
    async function render() {
      newLocale && i18next.changeLanguage(newLocale)
      newLocale && store.dispatch(InitConfigActions.setPlayerLocale(newLocale))
      renderWidgets()
    }

    validationRender(render)
  }

  /**
   * @function reloadOne
   * @description: This function allows you load one widget by id, and only render that widget
   */
  const reloadOne = (widgetId: string, newLocale?: string) => {
    async function render() {
      newLocale && i18next.changeLanguage(newLocale)
      newLocale && store.dispatch(InitConfigActions.setPlayerLocale(newLocale))
      renderWidget(widgetId)
    }

    validationRender(render)
  }

  const renderGlobalUIComponents = () => {
    const hasSnackbar = store.getState().globalUI.hasSnackbarComponent

    if (!hasSnackbar && theme) {
      const UIDiv = document.createElement('div')
      UIDiv.classList.add('global_ui_components')
      document.body.appendChild(UIDiv)
      const bodyRoot = ReactDOMClient.createRoot(UIDiv)
      store.dispatch(globalUiActions.setHasSnackbar())
      rootRender(bodyRoot)
    }
  }

  const renderWidgets = () => {
    renderGlobalUIComponents()

    const WidgetDivs = document.querySelectorAll('.gamification_widget')

    if (WidgetDivs && WidgetDivs.length > 0) {
      WidgetDivs.forEach((Div) => {
        const root = ReactDOMClient.createRoot(Div)

        rootRender(root, Div)
      })
    } else {
      // eslint-disable-next-line
      console.warn(
        `The widgets app could not identify any div element with the property classname="gamification_widget". Please remember that all widgets must contain the classname="gamification_widget"`,
      )
    }
  }

  const renderWidget = (widgetId: string) => {
    renderGlobalUIComponents()

    const WidgetDiv = document.getElementById(widgetId)

    if (WidgetDiv) {
      const root = ReactDOMClient.createRoot(WidgetDiv)

      rootRender(root, WidgetDiv)
    } else {
      // eslint-disable-next-line
      console.warn(
        `A div element with WIDGET_ID: ${widgetId} was not found in the app. Please check the documentation to verify the list of widgets`,
      )
    }
  }

  const rootRender = (root: ReactDOMClient.Root, Div?: Element) => {
    root.render(
      <React.StrictMode>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme || createTheme()}>
            <GlobalStyles styles={{ '*': { boxSizing: 'inherit' } }} />
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                {Div && <App Div={Div} />}
                {!Div && <GlobalUIComponents />}
              </PersistGate>
            </Provider>
          </ThemeProvider>
        </StyledEngineProvider>
      </React.StrictMode>,
    )
  }

  const validationRender = (render: () => Promise<void>) => {
    if (document.readyState === 'complete') {
      render()
    } else {
      window.addEventListener('load', () => {
        render()
      })
    }
  }

  return { config, reload, reloadOne }
}

export default GamificationWidgets()
