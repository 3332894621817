import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
  MyBoosterActions,
  MyBoosterModels,
  MyBoosterThunks,
  RootState,
  useAppDispatch,
} from '@/state'
import {
  RewardProcessorBoosterTypeEnum,
  RewardProcessorStatusEnum,
  RewardShopBoosterTypeEnum,
} from '@/enums'
import { LIMIT } from '@/constants'

export const useMyBoostersActions = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { playerId } = useSelector(({ initConfig }: RootState) => initConfig)

  const { gamificationPlayer } = useSelector(({ player }: RootState) => player)

  const { myBoosters, pagination } = useSelector(
    ({ myBooster }: RootState) => myBooster,
  )

  const filters = {
    playerId: playerId,
    rewardType: [
      RewardShopBoosterTypeEnum.LEVEL,
      RewardShopBoosterTypeEnum.TOKEN,
    ],
    status: RewardProcessorStatusEnum.PENDING,
    limit: LIMIT,
  }

  const boosterType = (type: RewardShopBoosterTypeEnum) => {
    switch (type) {
      case RewardShopBoosterTypeEnum.LEVEL:
        return t('levelBooster')

      case RewardShopBoosterTypeEnum.TOKEN:
        return t('tokenBooster')

      case RewardShopBoosterTypeEnum.MISSION:
        return t('missionBooster')

      default:
        return t('levelBooster')
    }
  }

  const getActiveBoosterType = (type: RewardShopBoosterTypeEnum) => {
    switch (type) {
      case RewardShopBoosterTypeEnum.LEVEL:
        return RewardProcessorBoosterTypeEnum.LEVEL

      case RewardShopBoosterTypeEnum.TOKEN:
        return RewardProcessorBoosterTypeEnum.TOKEN

      case RewardShopBoosterTypeEnum.MISSION:
        return RewardProcessorBoosterTypeEnum.MISSION

      default:
        return RewardProcessorBoosterTypeEnum.LEVEL
    }
  }

  const handlePrevPage = () => {
    const prevPage = pagination.page - 1
    dispatch(MyBoosterActions.setPage(prevPage))
    dispatch(
      MyBoosterThunks.getAllMyBoostersThunk({
        ...filters,
        limit: pagination.limit,
        page: prevPage,
      }),
    )
  }

  const handleNextPage = () => {
    const nextPage = pagination.page + 1
    dispatch(MyBoosterActions.setPage(nextPage))
    dispatch(
      MyBoosterThunks.getAllMyBoostersThunk({
        ...filters,
        limit: pagination.limit,
        page: nextPage,
      }),
    )
  }

  const getAllMyBoosters = useCallback(
    (filters: MyBoosterModels.BoosterFiltersType) => {
      dispatch(MyBoosterThunks.getAllMyBoostersThunk(filters))
    },
    [dispatch],
  )

  const handleActivateBooster = useCallback(
    async (orderId: string) => {
      const filteredReward = myBoosters.find((reward) => reward.id === orderId)

      if (filteredReward) {
        const boosterType = getActiveBoosterType(
          filteredReward.earnedReward.type,
        )
        dispatch(
          MyBoosterThunks.activateBoosterThunk({
            boosterType: boosterType,
            missionId:
              boosterType === RewardProcessorBoosterTypeEnum.MISSION
                ? filteredReward.id // TODO: This value will be change in the future when we have the mission data
                : undefined,
            levelId: gamificationPlayer.level.id || undefined,
            id: filteredReward.id,
          }),
        )
      }
    },
    [dispatch, myBoosters], // eslint-disable-line
  )

  return {
    boosterType,
    getAllMyBoosters,
    handlePrevPage,
    handleNextPage,
    handleActivateBooster,
  }
}
