import { useMediaQuery, useTheme } from '@mui/material'

export const useBreakpoints = () => {
  const theme = useTheme()

  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const isXsMobile = useMediaQuery(theme.breakpoints.down('xs'))

  return { isDesktop, isTablet, isMobile, isXsMobile }
}
