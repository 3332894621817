import { useCallback } from 'react'
import {
  MyBoosterModels,
  MyBoosterThunks,
  PaginationType,
  RewardShopActions,
  RewardShopModels,
  RewardShopThunks,
  useAppDispatch,
} from '@/state'
import { RewardShopTypeEnum, RewardShopViewTypeEnum } from '@/enums'

export const useRewardShopActions = () => {
  const dispatch = useAppDispatch()

  const getAllProducts = useCallback(
    (filters: RewardShopModels.ProductFiltersType) => {
      dispatch(RewardShopThunks.getAllProductsThunk(filters))
    },
    [dispatch],
  )

  const getAllMyBoosters = useCallback(
    (filters: MyBoosterModels.GetAllBoostersPayload) => {
      dispatch(MyBoosterThunks.getAllMyBoostersThunk(filters))
    },
    [dispatch],
  )

  const getOneProduct = useCallback(
    (productId: string) => {
      dispatch(RewardShopThunks.getOneProductThunk({ _id: productId }))
    },
    [dispatch],
  )

  const setPagination = useCallback(
    (pagination: PaginationType) => {
      dispatch(RewardShopActions.setPagination(pagination))
    },
    [dispatch],
  )

  const setRewardType = useCallback(
    (rewardShopType: RewardShopTypeEnum) => {
      dispatch(RewardShopActions.setRewardType(rewardShopType))
    },
    [dispatch],
  )

  const setRewardShopPage = useCallback(
    (rewardShopPage: RewardShopViewTypeEnum) => {
      dispatch(RewardShopActions.setRewardShopPage(rewardShopPage))
    },
    [dispatch],
  )

  const orderSubmit = useCallback(
    (orderProduct: RewardShopModels.OrderProductPayload) => {
      dispatch(RewardShopThunks.orderProductThunk(orderProduct))
    },
    [dispatch],
  )

  const resetRewardShopPage = useCallback(() => {
    dispatch(RewardShopActions.resetRewardShopPage())
  }, [dispatch])

  const resetOrderProduct = useCallback(() => {
    dispatch(RewardShopActions.resetOrderProduct())
  }, [dispatch])

  return {
    getAllProducts,
    getAllMyBoosters,
    getOneProduct,
    setPagination,
    setRewardType,
    setRewardShopPage,
    orderSubmit,
    resetRewardShopPage,
    resetOrderProduct,
  }
}
