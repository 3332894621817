import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RewardProcessorStatusEnum, RewardShopBoosterTypeEnum } from '@/enums'

import { MyBoosterModels, RootState } from '@/state'
import { LIMIT } from '@/constants'
import { parsePeriodTime } from '@/utils'

import { useMyBoostersActions } from './'

export const useMyBoosters = () => {
  const { myBoosters, pagination, isLoading } = useSelector(
    ({ myBooster }: RootState) => myBooster,
  )

  const { playerLocale, playerId, client } = useSelector(
    ({ initConfig }: RootState) => initConfig,
  )

  const { gamificationOpt } = useSelector(({ player }: RootState) => player)

  const {
    boosterType,
    getAllMyBoosters,
    handlePrevPage,
    handleNextPage,
    handleActivateBooster,
  } = useMyBoostersActions()

  const myBoosterFiltered = myBoosters.map((myBooster) => {
    const translation =
      myBooster.earnedReward.translations?.find(
        (item) => item.language.toUpperCase() === playerLocale.toUpperCase(),
      ) ||
      myBooster.earnedReward.translations?.[0] ||
      myBooster.metadata

    return {
      id: myBooster.id,
      title:
        translation?.name ||
        `${boosterType(myBooster.earnedReward.type)} (${
          myBooster.earnedReward.boosterRate
        }x - ${parsePeriodTime(myBooster.earnedReward.timeFrame)})`,
      description: translation?.description,
      image: myBooster.earnedReward.mobileImage,
      type: myBooster.earnedReward.type,
    }
  })

  const filtersBoosters: MyBoosterModels.BoosterFiltersType = {
    playerId: playerId,
    rewardType: [
      RewardShopBoosterTypeEnum.LEVEL,
      RewardShopBoosterTypeEnum.TOKEN,
    ],
    status: RewardProcessorStatusEnum.PENDING,
    limit: LIMIT,
    page: 1,
  }

  useEffect(() => {
    if (!myBoosters.length) {
      getAllMyBoosters(filtersBoosters)
    }
  }, []) // eslint-disable-line

  return {
    myBoosterFiltered,
    pagination,
    isLoading,
    client,
    gamificationOpt,
    handlePrevPage,
    handleNextPage,
    handleActivateBooster,
  }
}
