import { alpha, typographyClasses } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { MikadoFontStyle } from '@/constants'

export const useMissionStyles = makeStyles()((theme) => {
  return {
    missionWrapper: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: -45,
      animation: 'flexAnimationOpacity 0.35s ease-in forwards',

      '@keyframes flexAnimationOpacity': {
        '0%': {
          opacity: 0,
        },

        '100%': {
          opacity: 1,
        },
      },

      [theme.breakpoints.down('sm')]: {
        margin: '0px auto',
        maxWidth: 458,
      },

      '@media only screen and (max-width: 506px)': {
        maxWidth: 373,
      },

      '@media only screen and (max-width: 421px)': {
        maxWidth: 288,
      },

      '@media only screen and (max-width: 335px)': {
        maxWidth: 268,
      },
    },

    missionTabs: {
      minHeight: 'auto',

      '& .GamanzaTabs-flexContainer': {
        justifyContent: 'center',
        gap: 3,
      },

      '& button': {
        borderRadius: '8px 8px 0px 0px',
        width: 100,
        backgroundColor: `${theme.palette.common.white} !important`,
        margin: 0,

        '& p': {
          fontSize: 14,
          textTransform: 'uppercase',
          ...MikadoFontStyle,
        },
      },

      '& button.Mui-selected': {
        '& p': {
          zIndex: 1,
        },
        '& span': {
          width: 'calc(100% - 5px)',
          backgroundColor: alpha(theme.palette.common.black, 0.1),
          height: 14,
          position: 'absolute',
          top: 3,
          left: 0,
          right: 0,
          borderTopLeftRadius: 6,
          borderTopRightRadius: 6,
          margin: '0 auto',
        },
      },

      '& button:not(.Mui-selected)': {
        span: {
          backgroundColor: `${theme.palette.common.white} !important`,
          opacity: 0.5,
        },
      },
    },

    missionTabContent: {
      width: '100%',
      backgroundColor: theme.palette.common.white,
      borderRadius: 8,
      padding: '16px 20px',
      boxShadow: `0px -2px 8px 0px ${alpha(theme.palette.common.black, 0.15)}`,
      maxHeight: 500,
      overflow: 'scroll',
      marginBottom: -30,

      [theme.breakpoints.down('sm')]: {
        marginBottom: 0,
      },

      '@media only screen and (max-width: 335px)': {
        padding: '12px 10px',
      },
    },

    bundleContent: {
      '& .bundleHeader': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 16,

        [`& .${typographyClasses.body1}`]: {
          fontSize: 16,
          textTransform: 'uppercase',
          ...MikadoFontStyle,
        },

        '& .remaining': {
          display: 'flex',
          alignItems: 'center',
          gap: 5,
          fontSize: 12,
          textTransform: 'uppercase',
          borderRadius: 5,
          backgroundColor: alpha(theme.palette.common.black, 0.2),
          padding: '4px 6px',
          ...MikadoFontStyle,
        },
      },
    },

    listMissions: {
      display: 'flex',
      gap: '22px 7px',
      justifyContent: 'left',
      flexWrap: 'wrap',
    },

    listMissionsHistory: {
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
      justifyContent: 'left',
      flexWrap: 'wrap',
    },
  }
})
