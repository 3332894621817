import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Box, Tabs, Typography } from '@mui/material'
import { MissionTabsEnum } from '@/enums'
import { TabButton } from '@/theme/tabs.theme'

import { DialogMissionComponent, TabPanel } from '@/components/common'
import { RootState } from '@/state'
import { useMissionsActions } from '@/components/sidebar/hooks'

import { useMissionStyles } from './style'
import { MissionBundlesComponent, MissionHistoryComponent } from './'

export const SidebarMissionComponent = () => {
  const { t } = useTranslation()

  const { missionActiveBundles } = useSelector(
    ({ missionHistory }: RootState) => missionHistory,
  )

  const { getActiveBundles, getExpiredMissions } = useMissionsActions()

  const { classes } = useMissionStyles()

  const [missionTab, setMissionTab] = useState(MissionTabsEnum.MISSIONS)

  const tabsArray = [
    {
      id: MissionTabsEnum.MISSIONS,
      label: t(`${MissionTabsEnum.MISSIONS}`),
      component: <MissionBundlesComponent />,
    },
    {
      id: MissionTabsEnum.HISTORY,
      label: t(`${MissionTabsEnum.HISTORY}`),
      component: <MissionHistoryComponent />,
    },
  ]

  useEffect(() => {
    if (
      missionTab === MissionTabsEnum.MISSIONS &&
      missionActiveBundles.length === 0
    ) {
      getActiveBundles()
    } else {
      getExpiredMissions()
    }
  }, [missionTab]) // eslint-disable-line

  return (
    <>
      <Box className={classes.missionWrapper}>
        <Tabs
          value={missionTab}
          onChange={(_event, newValue) => {
            setMissionTab(newValue)
          }}
          TabIndicatorProps={{
            style: { display: 'none' },
          }}
          sx={{ marginTop: 1 }}
          className={classes.missionTabs}
        >
          {tabsArray.map((item) => (
            <TabButton
              value={item.id}
              key={item.id}
              label={<Typography>{item.label}</Typography>}
            />
          ))}
        </Tabs>
        {tabsArray.map((item) => (
          <TabPanel key={item.id} value={missionTab} index={item.id}>
            <Box className={classes.missionTabContent}>{item.component}</Box>
          </TabPanel>
        ))}
      </Box>
      <DialogMissionComponent />
    </>
  )
}
