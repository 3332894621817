export interface DecodedJWTStatus {
  isValid: boolean
  decoded: Session | null
}

export interface Session {
  clientId: string
  exp: number
  iat: number
  name: string
  sessionId: string
  sub: string
  type: string
}

function parseJwt(token: string): Session | null {
  return JSON.parse(window.atob(token.split('.')[1]))
}

/**
 *
 * * JWT Decoder
 *
 * Also returns if the token received is valid
 *
 * @param {string} token - The JWT token
 * @returns the JWT payload
 * @returns if the token is valid
 */
export const decodeJWT = (token: string | undefined): DecodedJWTStatus => {
  let decoded: Session

  try {
    decoded = token && parseJwt(token)

    return {
      isValid: !!decoded,
      decoded: decoded || undefined,
    }
  } catch (err) {
    return {
      isValid: false,
      decoded: undefined,
    }
  }
}
