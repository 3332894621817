import { RewardShopBoosterTypeEnum } from '@/enums'

export const ActiveBoostersColors = {
  [RewardShopBoosterTypeEnum.LEVEL]: {
    baseColor: '#4962F3',
    baseColorGradient: 'linear-gradient(315deg, #875EFF 0%, #4163F1 100%)',
    background: 'linear-gradient(180deg, #4F2D93 0%, #4D3186 100%)',
    borderColor: '#725c9e',
  },
  [RewardShopBoosterTypeEnum.MISSION]: {
    baseColor: '#2FB496',
    baseColorGradient: 'linear-gradient(135deg, #2FB496 0%, #36C77E 100%)',
    background: 'linear-gradient(90deg, #155972 0%, #155E6E 100%)',
    borderColor: '#2c6d7d',
  },
  [RewardShopBoosterTypeEnum.TOKEN]: {
    baseColor: '#AA41F0',
    baseColorGradient: 'linear-gradient(128deg, #AA41F0 0%, #ED516E 100%)',
    background: 'linear-gradient(90deg, #612C90 0%, #642B82 100%)',
    borderColor: '#73418d',
  },
  alert: {
    baseColor: '#DC2D2D',
    baseColorGradient: '#DC2D2D',
    backgroundCircular: '#FF7F7F',
    background: '#FFD2D2',
    borderColor: '#ff999a',
  },
  warning: {
    baseColor: '#FFAB00',
    baseColorGradient: '#FFAB00',
    backgroundCircular: '#FFECA8',
    background: '#FFF7D2',
    borderColor: '#dccc92',
  },
}
