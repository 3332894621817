import { useState } from 'react'
import { SidebarMenuItemType } from '@/enums'

export const useSidebarActions = () => {
  const [openSidebar, setOpenSidebar] = useState(true)
  const [sidebarMenuItem, setSidebarMenuItem] = useState(
    SidebarMenuItemType.HOME,
  )

  const handleOpenSidebar = () => {
    setOpenSidebar(!openSidebar)
  }

  const handleChangeMenuItem = (sidebarMenuItem: SidebarMenuItemType) => {
    setSidebarMenuItem(sidebarMenuItem)
  }

  return {
    openSidebar,
    sidebarMenuItem,
    handleOpenSidebar,
    handleChangeMenuItem,
  }
}
