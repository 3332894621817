import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  Box,
  BoxProps,
  LinearProgress,
  SxProps,
  Typography,
  TypographyProps,
  colors,
  useTheme,
} from '@mui/material'

import { RootState } from '@/state'
import { getFormatNumber } from '@/utils'
import { usePlayerData } from '@/hooks'

import { IconComponent, TooltipComponent } from '@/components/common'

export function PointsComponent() {
  const { t } = useTranslation()

  const { gamificationPlayer, gamificationOpt } = useSelector(
    ({ player }: RootState) => player,
  )

  const { playerLocale } = useSelector(
    ({ initConfig }: RootState) => initConfig,
  )

  const theme = useTheme()

  const { playerRankName, playerNextRankName } = usePlayerData()

  const typographyCaptionProps: TypographyProps = {
    variant: 'caption',
    fontWeight: 500,
    fontSize: 14,
    color: theme.palette.text.secondary,
    component: 'p',
  }
  const typographyh6Props: TypographyProps = {
    variant: 'h6',
    fontWeight: 700,
    fontSize: 14,
    maxWidth: '100%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    lineHeight: '1',
  }

  const level = gamificationPlayer.level.levelNumber ?? '-'

  type PointCardProps = {
    props: {
      icon: React.ReactNode
      name: string
      value: string | number
      tooltipText: string
      progress?: React.ReactNode
      sx?: SxProps
    }
  }

  function PointCardComponent({
    props: { icon, name, value, tooltipText, progress, sx },
  }: PointCardProps) {
    const iconPointsProps: BoxProps = {
      paddingY: 0.5,
      paddingX: 1,
      borderRadius: 2,
      width: '100%',
      bgcolor: theme.palette.background.paper,
      flex: '1 0 0',
      minWidth: 0,
    }

    const imageIcon = (
      <Box display="flex" justifyContent="center" height={36}>
        {icon}
      </Box>
    )

    return (
      <Box {...iconPointsProps}>
        <Box sx={sx}>
          {imageIcon}
          <Box
            position="relative"
            display="inline-block"
            maxWidth="95%"
            ml={-2.5}
          >
            <Typography {...typographyh6Props}>{name}</Typography>
            <TooltipComponent
              title={name}
              description={tooltipText}
              icon={imageIcon}
            />
          </Box>
        </Box>

        <Typography {...typographyCaptionProps}>
          {gamificationOpt?.enable ? value : '--'}
        </Typography>

        {progress}
      </Box>
    )
  }

  const xpPointsProgress = (
    <Box
      height={6}
      width="100%"
      display="flex"
      alignItems="center"
      position="relative"
      mt={1}
      py={1}
      px={0.5}
      borderRadius={2}
      sx={{
        background: theme.palette.grey[200],
      }}
    >
      <LinearProgress
        variant="determinate"
        value={gamificationOpt?.enable ? gamificationPlayer.rank.progress : 0}
        sx={{
          width: 'calc(100% - 16px)',
          height: 8,
          borderRadius: 1,
          backgroundColor: 'transparent',
        }}
      />
      <Box
        position="absolute"
        right={-10}
        top={-3}
        p="2px"
        border={`1px solid ${colors.orange[500]}`}
        borderRadius="50%"
        width={22}
        height={22}
        bgcolor={theme.palette.common.white}
        display="flex"
        alignItems="center"
      >
        <IconComponent type="rank" size={20} />
      </Box>
    </Box>
  )

  return (
    <Box display="flex" flexDirection="column">
      <Box
        display="flex"
        textAlign="center"
        justifyContent="space-between"
        gap={1}
      >
        <PointCardComponent
          props={{
            icon: <IconComponent type="rank" size={36} />,
            name: t('rank'),
            value: playerRankName,
            tooltipText: t('infoRank'),
          }}
        />

        <PointCardComponent
          props={{
            icon: <IconComponent type="level" size={36} />,
            name: t('level'),
            value: level,
            tooltipText: t('infoLevel'),
          }}
        />

        <PointCardComponent
          props={{
            icon: <IconComponent type="token" size={36} />,
            name: t('tokens'),
            value: getFormatNumber(gamificationPlayer.tokens, playerLocale),
            tooltipText: t('infoTokens'),
          }}
        />
      </Box>

      <Box
        paddingTop={0.5}
        paddingBottom={1}
        paddingX={2}
        borderRadius={2}
        mt={1}
        textAlign="center"
        bgcolor={theme.palette.background.paper}
      >
        <PointCardComponent
          props={{
            icon: <IconComponent type="xp" size={36} />,
            name: t('xpPoints'),
            value: getFormatNumber(gamificationPlayer.xpBalance, playerLocale),
            progress: xpPointsProgress,
            tooltipText: t('infoXPPoints'),
            sx: {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 2.5,
              ml: -2.5,
            },
          }}
        />

        {gamificationOpt?.enable &&
          !!gamificationPlayer?.pointsUntilNextRank && (
            <Box px={1} display="flex" justifyContent="space-between">
              <Typography {...typographyCaptionProps}>
                <strong>
                  {getFormatNumber(
                    gamificationPlayer.pointsUntilNextRank,
                    playerLocale,
                  )}
                </strong>
                {` ${t('forNextRank')}`}
              </Typography>
              <Typography {...typographyCaptionProps} fontWeight={700}>
                {playerNextRankName}
              </Typography>
            </Box>
          )}
      </Box>
    </Box>
  )
}
