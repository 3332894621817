import { makeStyles } from 'tss-react/mui'
import { typographyClasses } from '@mui/material/Typography'
import { alpha, darken } from '@mui/material'

import { MikadoFontStyle, SidebarColors } from '@/constants'

interface Props {
  openSidebar?: boolean
  sidebarClient?: string
  isMobile?: boolean
}

export const usePointsStyles = makeStyles<Props>()((
  theme,
  { openSidebar, sidebarClient, isMobile },
) => {
  return {
    pointsCardsContent: {
      display: 'flex',
      textAlign: 'center',
      justifyContent: 'space-between',
      gap: openSidebar && !isMobile ? 4 : 8,
      flexDirection: openSidebar || isMobile ? 'row' : 'column',
      opacity: openSidebar && !isMobile ? 0 : 1,
      animation:
        openSidebar && !isMobile
          ? 'flexAnimationOpacity 0.35s ease-in forwards 0.3s'
          : 'none',

      '@keyframes flexAnimationOpacity': {
        '0%': {
          opacity: 0,
        },

        '100%': {
          opacity: 1,
        },
      },

      [theme.breakpoints.down('sm')]: {
        justifyContent: 'left',
        flexWrap: 'wrap',
      },
    },

    pointCardContent: {
      borderRadius: 8,
      backgroundColor:
        openSidebar && !isMobile
          ? SidebarColors[sidebarClient].HEADER.color2
          : SidebarColors[sidebarClient].HEADER.color3,
      border:
        openSidebar && !isMobile
          ? `2px solid ${darken(
              SidebarColors[sidebarClient].HEADER.color,
              0.3,
            )}`
          : `2px solid ${SidebarColors[sidebarClient].HEADER.border2}`,
      minWidth: 70,
      maxWidth: 100,
      width: 'auto',
      position: 'relative',
      padding: openSidebar ? '0 9px' : 0,

      [theme.breakpoints.down('sm')]: {
        minWidth: 89,
        minHeight: 56,
      },

      [`& .${typographyClasses.caption}`]: {
        lineHeight: 1.2,
        height: 'calc(100% - 30px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 14,
        ...MikadoFontStyle,
      },

      '& svg': {
        position: 'relative',
      },
    },

    pointTopBorder: {
      backgroundColor: alpha(theme.palette.common.white, 0.3),
      height: 14,
      width: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      borderLeft:
        openSidebar && !isMobile
          ? `1px solid ${SidebarColors[sidebarClient].HEADER.color2}`
          : 'none',
      borderRight:
        openSidebar && !isMobile
          ? `1px solid ${SidebarColors[sidebarClient].HEADER.color2}`
          : 'none',
      borderTop:
        openSidebar && !isMobile
          ? `1px solid ${SidebarColors[sidebarClient].HEADER.color2}`
          : 'none',
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
    },
  }
})
