import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import {
  MissionHistoryThunks,
  MissionHistoryModels,
} from '@/state/mission-history'

export const updateBundleStatusExtraReducer = (
  builder: ActionReducerMapBuilder<MissionHistoryModels.MissionHistoryState>,
) => {
  builder.addCase(
    MissionHistoryThunks.updateBundleStatusThunk.fulfilled,
    (state, { payload }) => {
      const updateStatus = (
        missionBundles: MissionHistoryModels.MissionHistoryBundleType[],
      ) => {
        return missionBundles.map((item) => {
          if (item._id !== payload._id) {
            return item
          } else {
            return {
              ...item,
              status: payload.status,
            }
          }
        })
      }
      state.missionHistoryBundles = updateStatus(state.missionHistoryBundles)
      state.currentHistoryBundles = updateStatus(state.currentHistoryBundles)
    },
  )
}
