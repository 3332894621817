import { createAsyncThunk } from '@reduxjs/toolkit'
import { Container } from 'typedi'
import { Rest } from '@/utils/api.utils'

const apiService: Rest = Container.get(Rest)

type LoginPayload = {
  clientId: string
  identityToken: string
  serviceUrl: string
}

export const loginThunk = createAsyncThunk(
  '@auth/LOGIN',
  async (payload: LoginPayload) => {
    const { serviceUrl, ...body } = payload
    try {
      const response: {
        accessToken: string
      } = await apiService.sendData({
        method: 'POST',
        endpoint: '/api/oauth/v1/token',
        url: payload.serviceUrl,
        body,
      })

      if (response.accessToken) {
        localStorage.setItem(
          'gamanzaengage_token',
          JSON.stringify(response.accessToken),
        )
        localStorage.setItem(
          'gamanzaengage_serviceUrl',
          JSON.stringify(serviceUrl),
        )
      }

      return response
    } catch (error) {
      throw error
    }
  },
)
