import React from 'react'
import { Box, SxProps } from '@mui/material'

type TabPanelProps = {
  children?: React.ReactNode
  index: number | string
  value: number | string
  sx?: SxProps
}

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, sx, ...other } = props

  return (
    <Box hidden={value !== index} sx={sx} {...other}>
      {value === index && <>{children}</>}
    </Box>
  )
}
