import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Button, Typography } from '@mui/material'

import { useGlobalUi } from '@/hooks'
import { UIDialogsEnum } from '@/enums'
import { RootState } from '@/state'

import { MechanicsModalComponent } from './mechanics-modal-component'

type Props = {
  Div: Element
}

export function MechanicsStatusComponent({ Div }: Props) {
  const { t } = useTranslation()

  const { gamificationOpt } = useSelector(({ player }: RootState) => player)

  // Get Data Attributes
  const disabled = Div.getAttribute('data-disabled') || 'false' // Optional: Prop to disabled the CTA and show disabled message

  const { handleOpenDialog } = useGlobalUi()

  const handleButtonClick = () => {
    handleOpenDialog({ id: UIDialogsEnum.MECHANICS_FORM })
  }

  return (
    <>
      <Typography>
        {disabled !== 'true'
          ? gamificationOpt?.enable
            ? t('mechanicsDescriptionOptOut')
            : t('mechanicsDescriptionOptIn')
          : t('mechanicsDisableState')}
      </Typography>
      {disabled !== 'true' && (
        <Button
          variant="contained"
          onClick={handleButtonClick}
          sx={{
            marginTop: 3,
          }}
        >
          {gamificationOpt?.enable
            ? t('mechanicsCTAOptOut')
            : t('mechanicsCTAOptIn')}
        </Button>
      )}
      <MechanicsModalComponent Div={Div} />
    </>
  )
}
