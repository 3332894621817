import parse from 'html-react-parser'
import { Box, Dialog, IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'

import { UIDialogsEnum } from '@/enums'
import { useDialogOnSiteStyles } from './style'
import { OnSiteHTMLContentComponent } from './onsite-html-content.component'

import { useOnsiteNotification } from '../hooks'

export const OnSiteNotificationsComponent = () => {
  const {
    dialogOnSite,
    currentNotification,
    notificationMessage,
    handleCloseOnsite,
  } = useOnsiteNotification()

  const { classes } = useDialogOnSiteStyles()

  return (
    <Dialog
      open={
        dialogOnSite.open &&
        dialogOnSite.id === UIDialogsEnum.ON_SITE_NOTIFICATION
      }
      onClose={handleCloseOnsite}
      className={classes.dialog}
    >
      <Box className={classes.onsiteDialogContainer}>
        <IconButton
          className={`${classes.buttonClose} onsite-notification__buttonClose`}
          size="small"
          aria-label="close"
          onClick={handleCloseOnsite}
        >
          <Close />
        </IconButton>
        <Box className={classes.htmlContent}>
          <OnSiteHTMLContentComponent
            content={parse(notificationMessage?.contentHtml || '')}
            notificationObject={currentNotification}
          />
        </Box>
      </Box>
    </Dialog>
  )
}
