import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import {
  MissionHistoryThunks,
  MissionHistoryModels,
} from '@/state/mission-history'

export const getAllMissionBundlesExtraReducer = (
  builder: ActionReducerMapBuilder<MissionHistoryModels.MissionHistoryState>,
) => {
  builder.addCase(
    MissionHistoryThunks.getAllMissionBundlesThunk.pending,
    (state) => {
      state.isLoading = true
    },
  )
  builder.addCase(
    MissionHistoryThunks.getAllMissionBundlesThunk.fulfilled,
    (state, { payload }) => {
      const { docs, totalDocs, totalPages, limit, page } = payload
      state.missionHistoryBundles = docs?.length > 0 ? docs : []
      state.pagination = {
        total: totalDocs || 0,
        limit: limit || 25,
        page: page || 1,
        pages: totalPages || 0,
      }
      state.isLoading = false
    },
  )
  builder.addCase(
    MissionHistoryThunks.getAllMissionBundlesThunk.rejected,
    (state) => {
      state.isLoading = false
    },
  )
}
