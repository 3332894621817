import { createAsyncThunk } from '@reduxjs/toolkit'
import Container from 'typedi'
import { Rest } from '@/utils/api.utils'

import { RewardShopModels, WidgetEndpoints } from '@/state'
import { RewardShopActionTypes } from '../constants'

const apiService: Rest = Container.get(Rest)

export const getAllProductsThunk = createAsyncThunk(
  RewardShopActionTypes.GET_ALL_PRODUCTS,
  async (payload: RewardShopModels.GetAllProductsPayload, thunkAPI) => {
    try {
      const response: RewardShopModels.GetAllProductsResponse =
        await apiService.sendData({
          method: 'GET',
          endpoint: WidgetEndpoints.ITEMS,
          queries: { status: 'active', ...payload },
        })

      return thunkAPI.fulfillWithValue({
        response,
        rewardType: payload.rewardType,
      })
    } catch (error) {
      throw error
    }
  },
)
