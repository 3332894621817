import { useSelector } from 'react-redux'
import { RootState } from '@/state'
import { RewardShopViewTypeEnum } from '@/enums'

import {
  CategoryComponent,
  ProductDetailsComponent,
  ProductCategoriesComponent,
  useRewardShop,
  JPProductDetailsComponent,
} from './'

export function RewardShopComponent() {
  const { rewardShopPage } = useRewardShop()

  const { client } = useSelector(({ initConfig }: RootState) => initConfig)

  return (
    <>
      {rewardShopPage === RewardShopViewTypeEnum.PRODUCTS ? (
        <ProductCategoriesComponent />
      ) : rewardShopPage === RewardShopViewTypeEnum.CATEGORY ? (
        <CategoryComponent />
      ) : client === 'JP' ? (
        <JPProductDetailsComponent />
      ) : (
        <ProductDetailsComponent />
      )}
    </>
  )
}
